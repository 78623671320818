import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles, Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
//function
import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    button:{
        color:'#64b5f6',
        textDecoration:'underline'
    },
    enforcement:{
        fontWeight:'bold',
        marginTop: theme.spacing(2),
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        },
        color: 'black',
        backgroundColor:'white'
    },
    regulation:{
        textAlign:'center',
        fontWeight:'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subtitle:{
        textAlign: 'right',
    },
    title:{
        fontWeight:'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

export default function TermOfService(){
    const classes = useStyles({
        root:{
            backgroundColor:'white'
        }
    })

    const history = useHistory()

    const handlePlan = () => {
        history.push('/help')
    }

    const handleSubscription = () => {
        history.push('/subscription/plan')
    }

    const title = "利用規約｜Enclopedia【公式サイト】";
    const description = "ファッションレンタルサービスEnclopedia(アンクロペディア)の利用規約に関するページです"

    useEffect(() => {
        headRewrite(title, description)
    }, [])

    return(
        <Container maxWidth={'md'} style={{padding: 'initial'}}>
            <Card className={classes.root}>
                <CardContent>
                <Typography variant="subtitle1" component="div" className={classes.subtitle}>Enclopedia運営</Typography>
                <Typography variant="h1" component="h1" className={classes.regulation}>
                    利用規約
                </Typography>
                <Typography variant="subtitle1" component='p'>
                この規約は、お客様が、Enclopeida（アンクロペディア）運営（以下「Enclopedia」）が提供する「ファッションレンタル」サービス（以下「本サービス」）をご利用頂く際の取扱いにつき定めるものです。本規約に同意した上で本サービスをご利用ください。
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１条（定義）
                </Typography>
                <Typography variant="subtitle1" component='p'>
                    本規約上で使用する用語の定義は、次に掲げるとおりとします。<br/>
                    （１）本サービス：Enclopediaが運営するインターネット上のウェブサイトおよび
                    アプリケーションソフトウェア（以下「本サービス」を通じて提供するファッションアイテム等（以下「レンタル商品」といいます。）のレンタルサービスを行い、
                    サービス提供は第条に定める方法によるものとします。<br/>
                    （２）本サイト：本サービスのコンテンツが掲載されたウェブサイト<br/>
                    （３）本コンテンツ：本サービス上で提供される文字、音、静止画、動画、ソフトウェアプログラム、コード等の総称、投稿情報を含む<br/>
                    （４）利用者：本サービスを利用する全ての方<br/>
                    （５）登録利用者：本サイトの利用者登録が完了した方<br/>
                    （６）ID：本サービスの利用のために登録利用者が固有に持つ文字列<br/>
                    （７）パスワード：IDに対応して登録利用者が固有に設定する暗号<br/>
                    （８）個人情報：住所、氏名、職業、電話番号等個人を特定することのできる情報の総称<br/>
                    （９）登録情報：登録利用者が本サイトにて登録した情報の総称（投稿情報は除く）<br/>
                    （１０）知的財産：発明、考案、植物の新品種、意匠、著作物その他の人間の創造的活動により生み出されるもの（発見または解明がされた自然の法則または現象であって、
                    産業上の利用可能性があるものを含む）、商標、商号その他事業活動に用いられる商品または役務を表示するもの及び営業秘密その他の事業活動に有用な技術上または営業上の情報<br/>
                    （１１）知的財産権：特許権、実用新案権、育成者権、意匠権、著作権、商標権その他の知的財産に関して法令により定められた権利または法律上保護される利益に係る権利
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第２条（本規約への同意）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）利用者は、本利用規約に同意頂いた上で、本サービスを利用できるものとします<br/>
                    （２）利用者が、本サービスをアカウント登録及び、本規約への同意手続を行った時点で、利用者とEnclopediaとの間で、本規約の諸規定に従った利用契約が成立するものとします。<br/>
                    （３）利用者が未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスをご利用ください。<br/>
                    （４）未成年者の利用者が、法定代理人の同意がないにもかかわらず同意があると偽りまたは年齢について成年と偽って本サービスを利用した場合、
                    その他行為能力者であることを信じさせるために詐術を用いた場合、本サービスに関する一切の法律行為を取り消すことは出来ません。<br/>
                    （５）本規約の同意時に未成年であった利用者が成年に達した後に本サービスを利用した場合、当該利用者は本サービスに関する一切の法律行為を追認したものとみなされます。<br/>
                    （６）本規約に基づく本アプリの使用承諾契約（以下、「本契約」といいます。）は、お客さまが本規約に同意した時にEnclopediaとお客様との間で効力を生じるものといます。
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第３条（規約の変更）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）Enclopediaは、利用者の承諾を得ることなく、いつでも、本規約の内容を改定することができるものとし、利用者はこれを異議なく承諾するものとします。<br/>
                    （２）Enclopediaは、本規約を改定するときは、その内容についてEnclopedia所定の方法により利用者に通知します。<br/>
                    （３）前本規約の改定の効力は、Enclopediaが前項により通知を行った時点から生じるものとします。<br/>
                    （４）利用者は、本規約変更後、本サービスを利用した時点で、変更後の本利用規約に異議なく同意したものとみなされます。
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第４条（会員の登録条件と登録の認証）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）会員とは、この項に定める方法により入会を申し込み、Enclopediaが入会を承諾し、本サービスの利用登録された方で、退会手続きを行っていない方をいいます。なお、入会申込及び退会申込は本サービス上において随時行うことができます。<br/>
                    （２）本サービスへの入会を希望する方（以下「登録希望者」）は、本規約に同意した上で、所定の方法で入会の申込を行ってください。<br/>
                    （３）入会の申込をした方は、弊社がその申込を承諾し、ID登録が完了した時点から登録ユーザとなります。<br/>
                    （４）弊社は、登録ユーザ向けにメールで連絡事項の告知や広告その他の情報提供を行います。あらかじめご了承ください。<br/>
                        *以下の場合には会員登録ができません<br/>
                    （５）有効な電子メールおよびスマートフォンなどの本サービスに必要なインターネット設備を所有していない方。<br/>
                    （６）既にアカウントを作成していてる方
                    （７）指定したお支払い方法をご利用いただけない場合
                    （８）登録希望者が次の各号のいずれか一つに該当する場合は、弊社の判断により入会申込を承諾しないことがあります。<br/>
                    &emsp; 一、登録希望者が、弊社の定める方法によらず入会の申込を行った場合<br/>
                    &emsp; 二、登録希望者が、過去に本規約または弊社の定めるその他の利用規約等に違反したことを理由として退会処分を受けた者である場合<br/>
                    &emsp; 三、登録希望者が、不正な手段をもって登録を行っていると弊社が判断した場合<br/>
                    &emsp; 四、登録希望者が、本人以外の情報を登録している場合<br/>
                    &emsp; 五、その他弊社が不適切と判断した場合<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第５条（アカウントの管理）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）利用者は、利用に際して登録した情報（以下、「登録情報」といいます。メールアドレスやID・パスワード等を含みます）について、自己の責任の下、任意に登録、管理するものとします。利用者は、これを第三者に利用させ、または貸与、譲渡、名義変更、売買などをしてはならないものとします。<br/>
                    （２）登録情報によって本サービスの利用があった場合、利用登録をおこなった本人が利用したものと扱うことができ、当該利用によって生じた結果ならびにそれに伴う一切の責任については、利用登録を行った本人に帰属するものとします。<br/>
                    （３）利用者は、登録情報の不正使用によってEnclopediaまたは第三者に損害が生じた場合、Enclopediaおよび第三者に対して、当該損害を賠償するものとします。 <br/>
                    （４）登録情報の管理は、利用者が自己の責任の下で行うものとし、登録情報が不正確または虚偽であったために利用者が被った一切の不利益および損害に関して、Enclopediaは責任を負わないものとします。<br/>
                    （５）登録情報が盗用されまたは第三者に利用されていることが判明した場合、利用者は直ちにその旨をEnclopediaに通知するとともに、Enclopediaからの指示に従うものとします。
                    Enclopediaまたは第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為。<br/>
                    （６）会員は、入会の申込にあたって、氏名、住所、電話番号、生年月日、電子メールアドレスなどの属性情報につき正確な情報をEnclopediaに対して提供しなければなりません。これらの情報が事実と相違することにより、会員に不利益が生じることがあっても、理由のいかんを問わずEnclopediaは一切の責任を負いません。<br/>
                    （７）会員は、氏名、住所、電話番号、生年月日、電子メールアドレス等、会員登録時もしくは商品アイテム借り受け時に変更が発生した時は、本サービス上に定める方法により遅滞なくEnclopediaに届け出るものとします。万一会員が登録内容の変更を怠ったために会員に不利益が生じることがあっても、理由のいかんを問わずEnclopediaは一切の責任を負いません。<br/>
                    （８）会員は、登録内容の変更を怠ったためにEnclopediaに損害を生じさせた場合、その損害を賠償する責任を追うものとします。<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第６条（個人情報等の取り扱い）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    個人情報及び利用者情報については、別途定める「Enclopediaファッションレンタルサービスプライバシーポリシー」に則り、適正に取り扱うこととします。
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第７条（禁止行為）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    本サービスの利用に際し、Enclopediaは、利用者に対し、次に掲げる行為を禁止します。Enclopediaにおいて、利用者が禁止事項に違反したと認めた場合、利用者用の一時停止、退会処分その他Enclopediaが必要と判断した措置を取ることができます。<br/>
                    （１）Enclopediaまたは第三者の知的財産権を侵害する行為<br/>
                    （２）Enclopediaまたは第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為<br/>
                    （３）Enclopediaまたは第三者の財産を侵害する行為、または侵害する恐れのある行為<br/> 
                    （４）Enclopediaまたは第三者に経済的損害を与える行為<br/>
                    （５）Enclopediaまたは第三者に対する脅迫的な行為<br/>
                    （６）コンピューターウィルス、有害なプログラムを仕様またはそれを誘発する行為<br/>
                    （７）本サービス用インフラ設備に対して過度な負担となるストレスをかける行為<br/>
                    （８）当サイトのサーバーやシステム、セキュリティへの攻撃<br/>
                    （９）Enclopedia提供のインターフェース以外の方法でEnclopediaサービスにアクセスを試みる行為<br/>
                    （１０）一人の利用者が、複数の利用者IDを取得する行為<br/>
                    （１１）会員がレンタルした商品を第三者に貸し出す行為<br/>
                    （１２）レンタルした商品を別のものと入れ替える行為<br/>
                    （１３）本サービスまたは商品を利用した違法行為<br/>
                    （１５）貸し出したアイテムを故意に損傷させる行為<br/>
                    （１６）貸し出したアイテムを別のものと入れ替えて返却する行為<br/>
                    （１７）貸し出したアイテムを第三者に貸し出す行為<br/>
                    （１８）上記の他、Enclopediaが不適切と判断する行為<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第８条（商品のレンタル等)
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）会員は、本規約等への同意を通じ、レンタルアイテムの借り受け条件（以下、総称して「レンタル条件」といいます。）を確認したものとみなします。
                    レンタルアイテムの返却は期限を遵守して行うこと。<br/>
                    Enclopediaは、本サービスに定める方法で決定されたレンタルアイテムを会員に発送します。会員が同時にレンタルできる着数については以下をご参照ください。<br/>
                    <Button className={classes.button} onClick={handlePlan}>サブスクリプション有料プランについてのよくある質問</Button><br/>
                    （２）Enclopediaは、レンタルアイテムの発送や返却の受付、処理を完了した場合、会員に電子絵メール等Enclopediaの指定の手段を持って通知するものとします<br/>
                    （３）原則としてレンタルアイテム確定後Enclopediaが配送手続きを開始した商品の変更や取り消しは行うことができません<br/>
                    （４）付属品のあるレンタルアイテムに関しては、付属品も含めてレンタルアイテムの返却を行うものとします。<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第９条（レンタルした商品の損傷及び紛失等)
                </Typography>
                <Typography variant="subtitle1" component="p">
                    会員は、次の、次のいずれかに該当する場合に直ちに定める方法でEnclopediaにその旨を連絡し、商品レンタルのために支払った料金以外に第13条で定める違約金を支払うものとする。<br/>
                    破損、汚損の状態に関しての判断はEnclopedia運営の基準に従うものとします。
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）会員がレンタル商品の貸出中に発生したいかなる事象（盗難、事故、火事等）による紛失、損傷した場合<br/>
                    （２）会員が本サービスに登録したお届け先の住所に誤りがありレンタル商品が紛失してしまった場合<br/>
                    （３）原則としてレンタルアイテム確定後Enclopediaが配送手続きを開始した商品の変更や取り消しは行うことができません<br/>
                    （４）付属品のあるレンタルアイテムに関しては、付属品も含めてレンタルアイテムの返却を行うものとします。<br/>
                    （５）会員はレンタルしたアイテムの代わりに別のものを返却しないものとします。（偽物入れ替え防止)<br/>
                    （６）返却時にレンタルアイテム以外のものが送付された場合に、当該送付物に対する補償等を一切負わないものとし、会員の方が発送した日から起算して１ヶ月後には当該送付物当該送付物の所有権は破棄されたものとし処分いたします。<br/>
                    誤送付における責任は会員様ににおける一切の責任は会員が会員が負うものとします。また、会員会員から申し出があった場合や、個人情報記載されたものに関しては発見した場合に、運営からご連絡後会員から了承があった場合に限り当該誤送付物を着払いにて送付させていただきます。
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１０条（本サービスプラン）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）本サービスで、利用いただけるプランは以下をご参照下さい。<br/>
                    <Button className={classes.button} onClick={handleSubscription}>有料プランについてよくある質問</Button><br/>
                    （２）会員は、Enclopediaの定める方法により利用プランの変更の申込みを行なうことができます。変更後のプランは、次月（変更の申込日以降に到来する月額料金が発生する日）から適用されます。<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１１条（会員の認証方法等）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）本サービスは、会員のEメールアドレスと会員があらかじめ指定したパスワードの組み合わせによって会員を認証します。会員は、Eメールアドレスとパスワードを入力することで本サービスの利用が可能となります<br/>
                    （２）会員は、自身のパスワードを第三者に譲渡し、または使用させることができません。<br/>
                    （３）会員は、自己のパスワードの管理と使用についての一切の責任を負い、当該パスワードの使用による本サービスの利用については、それが自身によるもの他者によるものとを問わず、その使用に関わる

                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１２条（免責）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）Enclopediaは、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。<br/>
                    （２）Enclopediaは、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。<br/>
                    （３）Enclopediaは、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません<br/>
                    （４）Enclopediaは、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のＯＳのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、利用者はあらかじめ了承するものとします。Enclopediaは、かかる不具合が生じた場合にEnclopediaが行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。<br/>
                    （５）利用者は、AppStore、GooglePlay等のサービスストアの利用規約および運用方針の変更等に伴い、本サービスの一部又は全部の利用が制限される可能性があることをあらかじめ了承するものとします。<br/>
                    （６）Enclopediaは、本サービスを利用したことにより直接的または間接的に利用者に発生した損害について、一切賠償責任を負いません。<br/>
                    （７）Enclopediaは、利用者その他の第三者に発生した機会逸失、業務の中断その他いかなる損害（間接損害や逸失利益を含みます）に対して、Enclopediaが係る損害の可能性を事前に通知されていたとしても、一切の責任を負いません。<br/>
                    （８）第１項の至前項の規定は、Enclopediaに故意または重過失が存する場合又は契約書が消費者契約法上の消費者に該当する場合には適用しません。<br/>
                    （９）前項が適用される場合であっても、Enclopediaは、過失（重過失を除きます。）による行為によって利用者に生じた損害のうち、特別な事情から生じた損害については、一切賠償する責任を負わないものとします。<br/>
                    （１０）本サービスの利用に関しEnclopediaが損害賠償責任を負う場合、当該損害が発生した月に利用者から受領した利用額を限度として賠償責任を負うものとします。<br/>
                    （１１）利用者と他の利用者との間の紛争及びトラブルについて、Enclopediaは一切責任を負わないものとします。利用者と他の利用者でトラブルになった場合でも、両者同士の責任で解決するものとし、Enclopediaには一切の請求をしないものとします。<br/>
                    （１２）利用者は、本サービスの利用に関連し、他の利用者に損害を与えた場合または第三者との間に紛争を生じた場合、自己の費用と責任において、かかる損害を賠償またはかかる紛争を解決するものとし、Enclopediaには一切の迷惑や損害を与えないものとします。<br/>
                    （１３）利用者の行為により、第三者からEnclopediaが損害賠償等の請求をされた場合には、利用者の費用（弁護士費用）と責任で、これを解決するものとします。Enclopediaが、当該第三者に対して、損害賠償金を支払った場合には、利用者は、Enclopediaに対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。<br/>
                    （１４）利用者が本サービスの利用に関連してEnclopediaに損害を与えた場合、利用者の費用と責任においてEnclopediaに対して損害を賠償（訴訟費用及び弁護士費用を含む）するものとします<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１３条（違約金）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）盗難、紛失の場合:販売価格の100%<br/>
                    （２）破損、汚染より利用ができなくなった場合:販売価格の100%<br/>
                    （３）レンタルされた商品が別のものとすり替えが行われたことが発覚した場合:販売価格の100％<br/>
                    （４）破損、汚染した商品が修繕できる場合:販売価格の30%
                </Typography>
                <Typography variant="subtitle1" component="p">
                    利用者は、本サービス上にあらゆる広告が含まれる場合があること、Enclopediaまたはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、Enclopediaによって随時変更されます。 
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１3条（権利譲渡の禁止）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    （１）利用者は、本規約上の地位および本規約に基づく権利または義務の全部または一部を第三者に譲渡してはならないものとします。<br/>
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１4条（広告の掲載について）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    利用者は、本サービス上にあらゆる広告が含まれる場合があること、Enclopediaまたはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、Enclopediaによって随時変更されます。 
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.title}>
                    第１５条（キャンペーン）
                </Typography>
                <Typography variant="subtitle1" component="p">
                    利用者は、本サービス上にあらゆる広告が含まれる場合があること、Enclopediaまたはその提携先があらゆる広告を掲載する場合があることを理解しこれを承諾したものとみなします。本サービス上の広告の形態や範囲は、Enclopediaによって随時変更されます。 
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.enforcement}>
                    2023年1月31日施行
                </Typography>
                </CardContent>
            </Card>
        </Container>
    )
}