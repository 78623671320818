import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { headRewrite } from "../function/headRewrite";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    divider:{
        marginTop:theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    caption:{
        marginBottom: theme.spacing(2),
    },
    content:{
        whiteSpace: 'pre-line'
    },
    return_image:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    sencond_title:{
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    },
    subtitle:{
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    title:{
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    }
}))

export default function DeliveryHelpScreen(){
    const classes = useStyles()

    const title = 'ヘルプセンター（アイテムの返却について）｜Enclopedia【公式サイト】'
    const description = 'Enclopedia(アンクロペディア）ファッションレンタルサービスに関するアイテムの返却についてのサポート'
    headRewrite(title, description)

    return(
        <div className={classes.root}>
            <Container maxWidth={"md"} style={{padding: 'initial'}}>
                <Typography variant="h1" component="h1" className={classes.title} align="center">
                    アイテムの返却について
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.caption}>
                    レンタルしたアイテムを返却する際購入したアイテムを除く全てのアイテムを返却用袋に入れて頂き以下手順に従い返却を行ってください。
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" className={classes.subtitle}>
                    １、全アイテムを袋（お届け時に同梱されていた袋）に入れて封をする（お届けの際の梱包材は破棄してください）
                </Typography>
                <Grid container spacing={3} className={classes.return_image}>
                    <Grid key={"help_image"} item xs={12} sm={12} md={6} lg={6}>
                        <img alt="発送イメージ" src={process.env.REACT_APP_IMAGE_URL + "subscription/enclopedia-help3.webp"} style={{width: '100%'}}/>
                    </Grid>
                    <Grid key={"help_content"} item xs={12} sm={12} md={6} lg={6}>
                    <Typography variant="subtitle1" component="p" align="center">
                        返却の際に必要な物一式になります、こちらは発送時の梱包材に同梱されております。
                    </Typography>
                    </Grid>
                </Grid>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    購入したアイテムに関しましては返却する必要はありません、同梱してある専用の返却袋を無くしてしまった場合は任意の梱包をしてください。
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" className={classes.subtitle}>
                    ２、やることリスト、または予約履歴一覧から返却の手続き画面へ移動
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                   {"やることリスト、または予約一覧から返却手続き画面へ移動してください。その後専用の返却袋に貼り付けてある配送番号と画面上の配送番号が一致しているかどうか確認してください。\
                   \n専用の袋を無くしてしっまた場合には任意の袋と発送用紙（着払い）を用意し返却画面の配送用番号を変更して下さい。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" className={classes.subtitle}>
                    ３、返送場所へ持ち込む
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    返送できる場所はお近くのコンビニ（セブン、ファミリーマート）、またはヤマト運輸となります。
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" className={classes.subtitle}>
                    ４、返却ボタンを押す
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    返却ボタンを押して返送手続きが完了となります。
                </Typography>
                <Divider classes={{root:classes.divider}}/>
            </Container>
        </div>
    )
}