import axios from 'axios'

import {
    ACCOUNT_REGISTER_REQUEST,
    ACCOUNT_REGISTER_SUCCESS,
    ACCOUNT_REGISTER_FAIL
} from '../constants/accountConstants'

//account register action
export const register = (first_name, last_name, username, phoneNumber, email, password) => async(dispatch) => {
    try{
        dispatch({type:ACCOUNT_REGISTER_REQUEST})

        const config = { headers:{'Content-type':'application/json'}}
        const {data} = await axios.post(process.env.REACT_APP_URL + '/api/account/create',
                                        {'first_name':first_name,
                                         'last_name':last_name,
                                          'username':username,
                                          'phone_number':phoneNumber,
                                          'email':email,
                                          'password':password}, config)


        dispatch({type:ACCOUNT_REGISTER_SUCCESS, payload:data})

        localStorage.setItem('accountInfo', JSON.stringify(data))
    }catch{
        dispatch({
            type:ACCOUNT_REGISTER_FAIL, payload:'入力したEメールアドレスは既に別のユーザが使用しています'
        })
    }
}