import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from '@material-ui/icons/Add'
import { Button, Modal } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import CheckoutForm from "../components/Checkout/CheckoutForm";
import Loader from "../components/Loader";
import Message from "../components/Message";

import { baseAxios } from "../jwtInterceptor";

//クレジットカード情報をリストで表示するために
const useStyles = makeStyles((theme) => ({
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3, 3, 15, 3)
        }
    },
    button:{
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
    },
    back_button:{
        backgroundColor: 'initial',
        marginTop: theme.spacing(1)
    },
    card: {
        color:'black',
        backgroundColor:'white',
        width: '100%',
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            marginLeft: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(3,8,3,2),
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,8,3,2),
        },
    },
    card_content:{
        backgroundColor: 'white'
    },
    card_container:{
        display: 'flex'
    },
    card_main:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(3),
        width: '100%'
    },
    container:{
        padding: 'initial',
        marginTop: theme.spacing(10)
    },
    delete_button:{
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
        marginTop: theme.spacing(2)
    },
    grid:{
        marginTop: theme.spacing(2)
    },
    image:{
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2)
        },
        width:'40px'
    },
    message:{
        marginTop: theme.spacing(1)
    },
    modal_card:{
        color: 'black',
        backgroundColor: 'White'
    },
    modal_container:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    modal_button:{
        marginTop: theme.spacing(2),
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
    },
    modal_title:{
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },

    },
    radio:{
        height: '40px',
        width: '40px',
        marginTop: '100px',
        color: theme.palette.primary['400'],
        '&:hover':{
            backgroundColor:'initial'
        },
        [theme.breakpoints.up('sm')]:{
            marginLeft: theme.spacing(3),
        },
    },
    topic_path:{
        marginTop: theme.spacing(1),
        display:'flex'
    },
    topic_path_button:{
        backgroundColor: 'initial',
        '&:hover':{
            backgroundColor:'initial'
        }
    },
}))

function  CreditListScreen(){
    const classes = useStyles()
    const history = useHistory()

    //defaultの支払いpaymentIdをこちらに入れるようにする
    const [selectedValue, setSelectedValue] = useState('')
    const [credits, setCredits] = useState('')
    const [listLoading, setListLoading] = useState(true)
    const [listError, setListError] = useState(false)
    const {loading, error} = useSelector(state => state.secretInfo)
    const [message, setMessage] = useState('')
    const [open, setOpen] = useState('')

    const handleAccountService = () => {
        history.push('/management')
    }

    const handleChange = (event) => {
        setSelectedValue(event.target.value)
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        const data = {
            'payment_method': event.target.value
        }
        baseAxios.put('/api/stripe/credit/', data).then(
            (response) => {
                setMessage(response['data']['message'])
            }
        )
    }

    const handleDelete = (event, payment_method) => {
        event.preventDefault()
        setListLoading(true)
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        baseAxios.delete(`/api/stripe/credit/?payment_method=${payment_method}`).then(
            (response) => {
                console.log(response)
                setSelectedValue(response['data']['default_payment'])
                setCredits(response['data']['cards'])
                setOpen(false)
                setListLoading(false)
            }
        ).catch(
            () => {
                setListError('クレジットカードを削除できませんでした。')
                setListLoading(false)
            }
        )
    }

    const handleCreate = (event) => {
        event.preventDefault()
        history.push('/add_credit')
    }

    const handleOpen= (event) => {
        event.preventDefault()
        //レンタル商品がある場合には削除をできないようにする
        setOpen((prevOpen) => !prevOpen)
    }

    //modalを閉じる
    const handleClose = () => {
        setOpen(false)
    }

    const handleBack = () => {
        history.goBack()
    }

    const cardLogos = {
        'visa': process.env.REACT_APP_IMAGE_URL + 'credit/visa.png',
        'jcb': process.env.REACT_APP_IMAGE_RUL + 'credit/jcb.png',
        'master': process.env.REACT_APP_IMAGE_URL + 'credit/master.png',
        'amex': process.env.REACT_APP_IMAGE_URL + 'credit/amex.png'
    }

    useEffect(() => {
        setListLoading(true)
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        baseAxios.get('/api/stripe/credit/').then(
            (response) => {
                setSelectedValue(response['data']['default_payment'])
                setCredits(response['data']['cards'])
                setListLoading(false)
            }
        ).catch(
            () => {
                setListLoading(false)
                setListError(true)
            }
        )
    },[])

    //Radioボタンに関してdefaultの値をどのように設定する
    return (
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p" >
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    クレジットカード情報
                </Typography>
            </div>
            <Container maxWidth={'md'} className={classes.container}>
                {
                listLoading || loading ?
                <div className={classes.loader}>
                    <Loader />
                </div>
                :
                listError || error ?
                <Card className={classes.card_main}>
                    <CheckoutForm/>
                </Card>
                :
                <>
                {listError ?<Message>{message}</Message> : ''}
                <Card className={classes.card_main}>
                    <CardContent>
                        <Typography variant="h3" style={{fontWeight:'bold'}} component="h3" align="center">
                            クレジットカード情報
                        </Typography>
                        {credits.map((credit)=>(
                            <>
                            <div className={classes.card_container}>
                            <Radio
                                key={credit.payment_method_id}
                                checked={selectedValue === credit.payment_method_id}
                                onChange={handleChange}
                                color='primary'
                                className={classes.radio}
                                value={credit.payment_method_id}
                                name="radio-buttons"
                                disableRipple={true}
                                inputProps={{
                                    'aria-label': credit.payment_method_id,
                                }}
                            />
                            <Card className={classes.card}>
                                <CardContent className={classes.card_content}>
                                   
                                    <div className={classes.card_brand}>
                                        <Typography variant="subtitle1" component="p">
                                            カード種類：
                                        </Typography>
                                        <div className={classes.image}>
                                            <img className={classes.image} src={cardLogos[credit.brand]}/>
                                        </div>
                                        <Typography variant="subtitle1" component="p">
                                            {credit.brand}
                                        </Typography>
                                    </div>
                                    <Typography variant="subtitle1" component="p" className={classes.card_info}>
                                        カード番号: {`**** **** **** ${credit.number}`}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" className={classes.card_info}>
                                        有効期限: {credit.exp_month + '/' + credit.exp_year}
                                    </Typography>
                                    {selectedValue === credit.payment_method_id ? 
                                    <Typography variant="subtitle2" component="p" className={classes.message}>
                                       ※現在支払いに使用しているクレジットカード
                                    </Typography>
                                    :
                                    ''
                                    }
                                    <Button onClick={handleOpen} className={classes.delete_button} fullWidth>
                                        クレジットカード削除
                                    </Button>
                                </CardContent>
                            </Card>
                            <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            >
                                <Container maxWidth={"sm"} className={classes.modal_container}>
                                    <Card className={classes.modal_card}>
                                        <CardContent>
                                            {
                                                selectedValue === credit.payment_method_id ? 
                                                <>
                                                <Typography variant="subtitle1" component="p" align="center" className={classes.modal_title}>
                                                    削除できません
                                                </Typography>
                                                <Typography variant="subtitle1" component="p" className={classes.modal_message}>
                                                    現在お支払いに利用しているクレジットカードです、お支払いに利用するクレジットカードを変更してから再度削除を行なってください。
                                                </Typography>
                                                <Button className={classes.modal_button} fullWidth onClick={handleClose}>
                                                    閉じる
                                                </Button>
                                                </>
                                                :
                                                <>
                                                <Typography variant="subtitle1" component="p" align="center" className={classes.modal_title}>
                                                    クレジットカード削除
                                                </Typography>
                                                <Typography variant="subtitle1" component="p" className={classes.modal_message}>
                                                    削除したクレジットカードは支払いにご利用できなくなります、このクレジットカードを本当に削除しますか？
                                                </Typography>
                                                <Button className={classes.modal_button} fullWidth onClick={(event) => {handleDelete(event, credit.payment_method_id)}}>
                                                    削除
                                                </Button>
                                                </>
                                            }
                                        </CardContent>
                                    </Card>
                                    
                                </Container>
                            </Modal>
                            </div>
                            </>
                        ))}
                        <div className={classes.button_container}>
                            <Button
                            className={classes.button}
                            fullWidth
                            onClick={handleCreate}>
                            <Box style={{ paddingTop:5}}>
                                <AddIcon/>
                            </Box>
                            <Typography variant="subtitle1" style={{fontWeight:'bold', margin: 'auto'}}>
                                クレジットカード情報を追加する
                            </Typography>
                            </Button>
                        </div>
                    </CardContent>
                </Card>
                </>
                }
                <Button className={classes.back_button} onClick={handleBack}>
                        戻る
                </Button>
            </Container>
        </div>
  
    )
}

export default CreditListScreen