import {
    PRODUCT_DETAIL_REQUEST,
    PRODUCT_DETAIL_SUCCESS,
    PRODUCT_DETAIL_FAIL,
    PRODUCT_GENDER_REQUEST,
    PRODUCT_GENDER_SUCCESS,
    PRODUCT_GENDER_FAIL,
    PRODUCT_SIZE_REQUEST,
    PRODUCT_SIZE_SUCCESS,
    PRODUCT_SIZE_FAIL,
    PRODUCT_GENDER_FILTER_SUCCESS,
    PRODUCT_GENDER_ORIGIN_DATA,

    //reviewに関するreducer
    REVIEW_CREATE_REQUEST,
    REVIEW_CREATE_SUCCESS,
    REVIEW_CREATE_FAIL,
    REVIEW_LIST_GET_REQUEST,
    REVIEW_LIST_GET_FAIL,
    REVIEW_LIST_GET_SUCCESS,
    REVIEW_LIST_REQUEST,
    REVIEW_LIST_SUCCESS,
    REVIEW_LIST_FAIL,
    //variation
    VARIATION_GET_REQUEST,
    VARIATION_GET_SUCCESS,
    VARIATION_GET_FAIL,

    //すべてのproductを取得するためのdata
    PRODUCT_LIST_REQUEST, 
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    //全てのproductを絞り込むためのreducer
    PRODUCT_RENTAL,
    PRODUCT_PLAN,
    //全てのproductの順番を並び替えるreducer
    PRODUCT_CATEGORY_REQUEST,
    PRODUCT_CATEGORY,
    PRODUCT_CATEGORY_FAIL,
    PRODUCT_EVALUATION_REVIEW_REQUEST,
    PRODUCT_EVALUATION_REVIEW,
    PRODUCT_EVALUATION_REVIEW_FAIL,
    PRODUCT_EVALUATION_REVIEW_DESC_REQUEST,
    PRODUCT_EVALUATION_REVIEW_DESC,
    PRODUCT_EVALUATION_REVIEW_DESC_FAIL,
    PRODUCT_EVALUATION_FAVORITE_REQUEST,
    RRODUCT_EVALUATION_FAVORITE,
    PRODUCT_EVALUATION_FAVORITE_FAIL,
    PRODUCT_EVALUATION_FAVORITE_DESC_REQUEST,
    PRODUCT_EVALUATION_FAVORITE_DESC,
    PRODUCT_EVALUATION_FAVORITE_DESC_FAIL,
    PRODUCT_RESERVATION_ORDER_REQUEST,
    PRODUCT_RESERVATION_ORDER,
    PRODUCT_RESERVATION_ORDER_FAIL,
    PRODUCT_RESERVATION_ORDER_DESC_REQUEST,
    PRODUCT_RESERVATION_ORDER_DESC,
    PRODUCT_RESERVATION_ORDER_DESC_FAIL,
    PLAN_ORIGIN_DATA,
    PORDUCT_PRICE_ORDER_REQUEST,
    PRODUCT_PRICE_ORDER_SUCCESS,
    PRODUCT_PRICE_ORDER_FAIL,
    PRODCUT_PRICE_ORDER_DESC_REQUEST,
    PRODUCT_PRICE_ORDER_DESC_SUCCESS,
    PRODUCT_PRICE_ORDER_DESC_FAIL,

    //productの閲覧履歴をlocalstorageに保存する
    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAIL,
    //検索バーで取得した商品の順番の並び替えを行うためのreducer
    SEARCH_PRODUCT_GENDER_ORIGIN_DATA,
    SEARCH_PRODUCT_GENDER_FILTER_SUCCESS,
    SEARCH_EVALUATION_FAVORITE_REQUEST,
    SEARCH_EVALUATION_FAVORITE,
    SEARCH_EVALUATION_FAVORITE_FAIL,
    SEARCH_EVALUATION_FAVORITE_DESC_REQUEST,
    SEARCH_EVALUATION_FAVORITE_DESC,
    SEARCH_EVALUATION_FAVORITE_DESC_FAIL,
    SEARCH_EVALUATION_REVIEW_REQUEST,
    SEARCH_EVALUATION_REVIEW,
    SEARCH_EVALUATION_REVIEW_FAIL,
    SEARCH_EVALUATION_REVIEW_DESC_REQUEST,
    SEARCH_EVALUATION_REVIEW_DESC,
    SEARCH_EVALUATION_REVIEW_DESC_FAIL,
    SEARCH_RESERVATION_ORDER_REQUEST,
    SEARCH_RESERVATION_ORDER_SUCCESS,
    SEARCH_RESERVATION_ORDER_FAIL,
    SEARCH_RESERVATION_DESC_ORDER_REQUEST,
    SEARCH_RESERVATION_DESC_ORDER_SUCCESS,
    SEARCH_RESERVATION_DESC_ORDER_FAIL,
    SEARCH_PRICE_ORDER_REQUEST,
    SEARCH_PRICE_ORDER_SUCCESS,
    SEARCH_PRICE_ORDER_FAIL,
    SEARCH_PRICE_ORDER_DESC_REQUEST,
    SEARCH_PRICE_ORDER_DESC_SUCCESS,
    SEARCH_PRICE_ORDER_DESC_FAIL,
    SEARCH_PRODUCT_CATEGORY_REQUEST,
    SEARCH_PRODUCT_CATEGORY,
    SEARCH_PRODUCT_CATEGORY_FAIL,
    SEARCH_PLAN_ORIGIN_DATA,
    SEARCH_TAG_REQUEST,
    SEARCH_TAG_SUCCESS,
    SEARCH_TAG_FAIL,
    //検索バーで取得した商品を絞り込むためのreducer
    SEARCH_PRODUCT_PLAN,
    SEARCH_PRODUCT_RENTAL,
    //tagから取得
    TAG_PRODUCT_REQUEST,
    TAG_PRODUCT_SUCCESS,
    TAG_PORODUCT_FAIL,
    TAG_EVALUATE_REQUEST,
    TAG_EVALUATE_SUCCESS,
    TAG_EVALUATE_FAIL,
    TAG_EVALUATE_DESC_REQUEST,
    TAG_EVALUATE_DESC_SUCCESS,
    TAG_EVALUATE_DESC_FAIL,
    TAG_FAVORITE_REQUEST,
    TAG_FAVORITE_SUCCESS,
    TAG_FAVORITE_FAIL,
    TAG_FAVORITE_DESC_REQUEST,
    TAG_FAVORITE_DESC_SUCCESS,
    TAG_FAVORITE_DESC_FAIL,
    TAG_CATEGORY_REQUEST,
    TAG_CATEGORY_SUCCESS,
    TAG_CATEGORY_FAIL,
    TAG_PLAN_ORIGIN_DATA,
    TAG_PRODUCT_PLAN,
    TAG_PRODUCT_GENDER_ORIGIN_DATA,
    TAG_PRODUCT_GENDER_FILTER_SUCCESS,
    //realted product
    PRODUCT_RELATED_REQUEST,
    PRODUCT_RELATED_SUCCESS,
    PRODUCT_RELATED_FAIL,
    TAG_PRICE_REQUEST,
    TAG_PRICE_SUCCESS,
    TAG_PRICE_FAIL,
    TAG_PRICE_DESC_REQUEST,
    TAG_PRICE_DESC_SUCCESS,
    TAG_PRICE_DESC_FAIL,
} 
    from '../constants/productConstants'

//productを個別に取得するためのreducer
export const productDetailReducers = (state = {}, action) => {
    switch(action.type){
        case PRODUCT_DETAIL_REQUEST:
            return {loading: true, product:{}}
        case PRODUCT_DETAIL_SUCCESS:
            return {loading: false, product: action.payload}
        case PRODUCT_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}


//searchbarから取得した検索結果を返す
export const productSearchReducer = (state = { products: {'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case PRODUCT_SEARCH_REQUEST:
            return {loading: true, products: {'results':[], 'count': 0}}
        case PRODUCT_SEARCH_SUCCESS:
            return { loading: false, products: action.payload}
        case PRODUCT_SEARCH_FAIL:
            return { loading: false, error: action.payload}
        case SEARCH_EVALUATION_FAVORITE_REQUEST:
            return {loading:true, products: {'results':[], 'count': 0}}
        case SEARCH_EVALUATION_FAVORITE:
            return {loading:false, products: action.payload}
        case SEARCH_EVALUATION_FAVORITE_FAIL:
            return {loading:false, error: action.payload}
        case SEARCH_EVALUATION_FAVORITE_DESC_REQUEST:
            return {loading: true, products: {'results':[], 'count': 0}}
        case SEARCH_EVALUATION_FAVORITE_DESC:
            return {loading:false, products: action.payload}
        case SEARCH_EVALUATION_FAVORITE_DESC_FAIL:
            return {loading:false, error: action.payload}
        case SEARCH_EVALUATION_REVIEW_REQUEST:
            return {loading:true, products: {'results':[], 'count': 1}}
        case SEARCH_EVALUATION_REVIEW:
            return {loading:false, products: action.payload}
        case SEARCH_EVALUATION_REVIEW_FAIL:
            return {loading:false, error: action.payload}
        case SEARCH_EVALUATION_REVIEW_DESC_REQUEST:
            return {loading:true, products: {'results':[], 'count': 0}}
        case SEARCH_EVALUATION_REVIEW_DESC:
            return {loading:false, products: action.payload}
        case SEARCH_EVALUATION_REVIEW_DESC_FAIL:
            return {loading:false, error:action.payload}
        case SEARCH_PRODUCT_CATEGORY_REQUEST:
            return {loading:true, products: {'results':[], 'count': 0}}
        case SEARCH_PRODUCT_CATEGORY:
            return {loading:false, products: action.payload}
        case SEARCH_PRODUCT_CATEGORY_FAIL:
            return {loading:false, error:action.payload}
        case  SEARCH_PRICE_ORDER_REQUEST:
            return {loading:true, products: {'results': [], 'count': 0}}
        case  SEARCH_PRICE_ORDER_SUCCESS:
            return {loading: false, products: action.payload}
        case SEARCH_PRICE_ORDER_FAIL:
            return {loading: false, error: action.payload}
        case  SEARCH_PRICE_ORDER_DESC_REQUEST:
            return {loading:true, products: {'results': [], 'count': 0}}
        case  SEARCH_PRICE_ORDER_DESC_SUCCESS:
            return {loading: false, products: action.payload}
        case SEARCH_PRICE_ORDER_DESC_FAIL:
            return {loading: false, error: action.payload}
        case SEARCH_PRODUCT_PLAN:
            return {products: action.payload}
        case SEARCH_PRODUCT_RENTAL:
            return {products: action.payload}
        case SEARCH_PLAN_ORIGIN_DATA:
            return {originData: action.payload}
        case SEARCH_RESERVATION_ORDER_REQUEST:
            return {loading: true, products: {'results':[], 'count': 0}}
        case SEARCH_RESERVATION_ORDER_SUCCESS:
            return{loading:false, products: action.payload}
        case SEARCH_RESERVATION_ORDER_FAIL:
            return {loading:false, error: action.payload}
        case SEARCH_RESERVATION_DESC_ORDER_REQUEST:
            return {loading:true, products: {'results':[], 'count': 0}}
        case SEARCH_RESERVATION_DESC_ORDER_SUCCESS:
            return {loading:false, products: action.payload}
        case SEARCH_RESERVATION_DESC_ORDER_FAIL:
            return {loading:false, products: action.payload}
        case SEARCH_PRODUCT_GENDER_FILTER_SUCCESS:
            return {products: action.payload}
        default:
            return state
    }
}

export const searchGenderOriginProductsReducer = (state = {originData: {'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case SEARCH_PRODUCT_GENDER_ORIGIN_DATA:
            return {originData: action.payload}
        default:
            return state
    }
}

export const searchOriginDataReducer = (state = {originData: {'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case SEARCH_PLAN_ORIGIN_DATA:
            return {originData: action.payload}
        default:
            return state
    }
}

//ファイルからの取得のため削除して良い
export const productListReducers = (state = { products: [] }, action) => {
    switch(action.type){
        case PRODUCT_LIST_REQUEST:
            return { loading: true, products: []}

        case PRODUCT_LIST_SUCCESS:
            return { loading:false, products: action.payload }
        
        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

//dbから取得したもの
export const productsListReducer = (state = {products : {'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case PRODUCT_LIST_REQUEST:
            return {loading: true, products: []}
        case PRODUCT_LIST_SUCCESS:
            return {loading: false, products: action.payload }
        case PRODUCT_LIST_FAIL:
            return {loading: false, error: action.payload }
        case PRODUCT_RENTAL:
            return {products: action.payload}
        case PRODUCT_PLAN:
            return {products: action.payload}
        case PRODUCT_EVALUATION_REVIEW_REQUEST:
            return {loading: true, products: []}
        case PRODUCT_EVALUATION_REVIEW:
            return {loading:false, products: action.payload}
        case PRODUCT_EVALUATION_REVIEW_FAIL:
            return {loading: false, error:action.payload}
        case PRODUCT_EVALUATION_REVIEW_DESC_REQUEST:
            return {loading: true}
        case PRODUCT_EVALUATION_REVIEW_DESC:
            return {loading:false, products: action.payload}
        case PRODUCT_EVALUATION_REVIEW_DESC_FAIL:
            return {loading:false, error: action.payload}
        case PRODUCT_EVALUATION_FAVORITE_REQUEST:
            return {loading: true}
        case RRODUCT_EVALUATION_FAVORITE:
            return {loading:false, products: action.payload}
        case PRODUCT_EVALUATION_FAVORITE_FAIL:
            return {loading:false, error: action.payload}
        case PRODUCT_EVALUATION_FAVORITE_DESC_REQUEST:
            return {loading: true}
        case PRODUCT_EVALUATION_FAVORITE_DESC:
            return {loading:false, products: action.payload}
        case PRODUCT_EVALUATION_FAVORITE_DESC_FAIL:
            return {loading:false, error:action.payload}
        case PRODUCT_RESERVATION_ORDER_REQUEST:
            return {loading:true}
        case PRODUCT_RESERVATION_ORDER:
            return {loading:false, products: action.payload}
        case PRODUCT_RESERVATION_ORDER_FAIL:
            return {loading:false, error: action.payload}
        case PRODUCT_RESERVATION_ORDER_DESC_REQUEST:
            return {loading:true}
        case PRODUCT_RESERVATION_ORDER_DESC:
            return {loading:false, products: action.payload}
        case PRODUCT_RESERVATION_ORDER_DESC_FAIL:
            return {loading:false, error:action.payload}
        case PORDUCT_PRICE_ORDER_REQUEST:
            return {loading:true}
        case PRODUCT_PRICE_ORDER_SUCCESS:
            return {loading:false, products:action.payload}
        case PRODUCT_PRICE_ORDER_FAIL:
            return {loading:false, error: action.payload}
        case PRODCUT_PRICE_ORDER_DESC_REQUEST:
            return {loading:true}
        case PRODUCT_PRICE_ORDER_DESC_SUCCESS:
            return {loading:false, products: action.payload}
        case PRODUCT_PRICE_ORDER_DESC_FAIL:
            return {loading:false, error: action.payload}
        case PRODUCT_CATEGORY_REQUEST:
            return {loading:true}
        case PRODUCT_CATEGORY:
            return {loading:false, products: action.payload}
        case PRODUCT_CATEGORY_FAIL:
            return {loading:false, error: action.payload}
        case PRODUCT_GENDER_FILTER_SUCCESS:
            return {products: action.payload}
        default:
            return state
    }
}

//絞り込みをおこなった元のデータを保存しておきチェックボタンで表示切り替えできるように保存しておく
export const originDataReducer = (state = {originData:[]}, action) => {
    switch(action.type){
        case PLAN_ORIGIN_DATA:
            return {originData: action.payload}
        default:
            return state
    }
}

export const genderOriginDataReducer = (state = {originData:''}, action) => {
    switch(action.type){
        case PRODUCT_GENDER_ORIGIN_DATA:
            return {originData: action.payload}
        default:
            return state
    }
}

//男女別にproductを取得
export const productGenderReducer = (state = {products: []}, action) => {
    switch(action.type){
        case PRODUCT_GENDER_REQUEST:
            return {loading: true, products:[]}
        case PRODUCT_GENDER_SUCCESS:
            return {loading: false, products: action.payload}
        case PRODUCT_GENDER_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

//sizeをstoreに登録するためのreducer
export const productSizeReducer = (state = {sizes:[]}, action) => {
    switch(action.type){
        case PRODUCT_SIZE_REQUEST:
            return { size_loading: true,sizes:[]}
        case PRODUCT_SIZE_SUCCESS:
            return { size_loading: false, sizes: action.payload }
        case PRODUCT_SIZE_FAIL:
            return { size_loading: false, size_error: action.payload }
        default:
            return state
    }
}


//variationを首都k数リュ田mネオ
export const variationReducer = (state = {variations:[]}, action) => {
    switch(action.type){
        case VARIATION_GET_REQUEST:
            return {variation_loading: true, variations:[]}
        case VARIATION_GET_SUCCESS:
            return {vatiation_loading: false, variations: action.payload}
        case VARIATION_GET_FAIL:
            return {variation_loading:false, variation_error:action.payload}
        default:
            return state
    }
}

//tagを使用してproductを取得するreducer
export const productTagReducer  = (state = {products:{'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case TAG_PRODUCT_REQUEST:
            return {loading: true}
        case TAG_PRODUCT_SUCCESS:
            return {loading: false, products: action.payload}
        case TAG_PORODUCT_FAIL:
            return {loading:false, error:action.payload}
        case TAG_EVALUATE_REQUEST:
            return {loading: true}
        case TAG_EVALUATE_SUCCESS:
            return {loading: false, products: action.payload}
        case TAG_EVALUATE_FAIL:
            return {loading:false, error: action.payload}
        case TAG_EVALUATE_DESC_REQUEST:
            return {loading: true}
        case TAG_EVALUATE_DESC_SUCCESS:
            return{loading:false, products: action.payload}
        case TAG_EVALUATE_DESC_FAIL:
            return {laoding:false, error: action.payload}
        case TAG_FAVORITE_REQUEST:
            return {loading: true}
        case TAG_FAVORITE_SUCCESS:
            return {loading:false, products: action.payload}
        case TAG_FAVORITE_FAIL:
            return {laoding:false, error: action.payload}
        case TAG_FAVORITE_DESC_REQUEST:
            return {loading: true}
        case TAG_FAVORITE_DESC_SUCCESS:
            return{loading: false, products: action.payload}
        case TAG_FAVORITE_DESC_FAIL:
            return{loading: false, error: action.payload}
        case TAG_PRICE_REQUEST:
            return {loading: true}
        case TAG_PRICE_SUCCESS:
            return {loading: false, products: action.payload}
        case TAG_PRICE_FAIL:
            return {loading: false, error: action.payload}
        case TAG_PRICE_DESC_REQUEST:
            return {loading: true}
        case TAG_PRICE_DESC_SUCCESS:
            return {loading: false, products: action.payload}
        case TAG_PRICE_DESC_FAIL:
            return {loading: false, error: action.payload}
        case TAG_CATEGORY_REQUEST:
            return {loading: true}
        case TAG_CATEGORY_SUCCESS:
            return {loading:false, products: action.payload}
        case TAG_CATEGORY_FAIL:
            return {loading: false, error: action.payload}
        case TAG_PRODUCT_PLAN:
            return {products: action.payload}
        case TAG_PRODUCT_GENDER_FILTER_SUCCESS:
            return {products: action.payload}
        default:
            return state
    }
}

export const tagGenderOriginProductsReducer = (state = {originData: ''}, action) => {
    switch(action.type){
        case TAG_PRODUCT_GENDER_ORIGIN_DATA:
            return {originData: action.payload}
        default:
            return state
    }
}

export const originTagDataReducer = (state = {originData:[]}, action) => {
    switch(action.type){
        case TAG_PLAN_ORIGIN_DATA:
            return {originData: action.payload}
        default:
            return state
    }
}

//reviewを作成するためのreducer
export const reviewCreateReducer = (state = {reviews: []}, action) => {
    switch(action.type){
        case REVIEW_CREATE_REQUEST:
            return {review_loading: true, review:[]}
        case REVIEW_CREATE_SUCCESS:
            return {review_loading:false, review: action.payload}
        case REVIEW_LIST_GET_SUCCESS:
            return {reviews: action.payload}
        case REVIEW_CREATE_FAIL:
            return {review_loading: false, error: action.payload}
        default:
            return state
    }
}

//reviewをリストで確認する
export const reviewListReducer = (state = {reviews: []}, action) => {
    switch(action.type){
        case REVIEW_LIST_GET_REQUEST:
            return {review_loading: true, reviews: []}
        case REVIEW_LIST_GET_SUCCESS:
            return {review_loading:false, reviews: action.payload}
        case REVIEW_LIST_GET_FAIL:
            return {review_loading: false, error: action.apyload}
        default:
            return state
    }
}

//reviewのリスト全てを取得する
export const reviewsReducer = (state = {reviews:[]}, action) => {
    switch(action.type){
        case REVIEW_LIST_REQUEST:
            return {loading:true}
        case REVIEW_LIST_SUCCESS:
            return {loading:false, reviews:action.payload}
        case REVIEW_LIST_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}

//related product
export const relatedProductReducer = (state = {related:[]}, action) => {
    switch(action.type){
        case PRODUCT_RELATED_REQUEST:
            return {loading: true, related:[]}
        case PRODUCT_RELATED_SUCCESS:
            return {loading: false, related: action.payload}
        case PRODUCT_RELATED_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

//検索したタグ一覧の取得
export const searchTagListReducer = (state = {}, action) =>{
    switch(action.type){
        case SEARCH_TAG_REQUEST:
            return {loading: true}
        case SEARCH_TAG_SUCCESS:
            return {loading: false, tags: action.payload}
        case SEARCH_TAG_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}