import {React, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { Button, CardContent } from "@material-ui/core";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Card } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TextField from '@material-ui/core/TextField'
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

//function
import StripeInput from "./StripeInput";

//action
import { stripe_card_update } from "../../actions/stripeAction";
import { stripe_card_register } from "../../actions/stripeAction";
//import { next_step } from "../../actions/checkoutAction";

//textfieldを使用してmaterail uiでcardformのデザインを変えるようにsルウ
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js"

//component

const useStyles = makeStyles((theme) => ({
    button:{
        backgroundColor: 'black',
        marginTop: theme.spacing(2),
        color:'white',
        '&:hover':{
            backgroundColor: theme.palette.primary['800']
        }
    },
    credit_image:{
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    image:{
        width:'10%',
        margin:'auto'
    },
    inner:{
        margin:theme.spacing(3,0,3,0)

       
    },
    input_title:{
        color:theme.palette.primary['700'],
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        },
    },
    title:{
        color:'black',
        textAlign:'center', 
        marginBottom: 20, 
        borderBottom:`1px solid ${theme.palette.primary['700']}`
    },
    text:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    text_field:{
        border: `1px solid ${theme.palette.primary['400']}`,
        '&:focus':{
            border: 'white'
        },
        color:'black'
    },
}))



//ここでemailのみ取得するactionを実行する
//subscription用のチェエクアウト
const CheckoutForm = () => {


    //使用できるカードの種類を記載する。
    const cardLogos = [
        "https://media.enclopedia-official.com/credit/visa.png",
        "https://media.enclopedia-official.com/credit/jcb.png",
        'https://media.enclopedia-official.com/credit/master.png',
        "https://media.enclopedia-official.com/credit/amex.png",
        
    ]

    const dispatch = useDispatch()
    const classes = useStyles()
    const location = useLocation()
    
    //useSelector 
    const { accountPublicInfo } = useSelector(state => state.accountPublicInfo)

    //useState
    const [error, setError] = useState(null);
    const [email, setEmail] = useState(accountPublicInfo.email);
    const [title, setTitle] = useState('')
    console.log(error)
    //stripe
    const stripe = useStripe();
    const elements = useElements();

    //決済処理をそれそれのpathごとに実行していくためのhandle
    const handleSubmit = async (event) => {
        event.preventDefault()
        const card_number = elements.getElement(CardNumberElement);
        const {paymentMethod, error} = await stripe.createPaymentMethod({
            type: 'card',
            card: card_number,
        });
        //pathに応じて処理を分けるようにする
        if(error){
            setError(error)
        }
        switch(location.pathname){
            case '/checkout/rental':
                dispatch(stripe_card_register(paymentMethod.id, email))
                break;
            case '/checkout/basic/':
                dispatch(stripe_card_register(paymentMethod.id, email))
                break;
            case '/checkout/premium/':
                dispatch(stripe_card_register(paymentMethod.id, email))
                break;
            //支払い方法を変更する際に利用する
            case '/credit_card/update':
                dispatch(stripe_card_update(paymentMethod.id))
                break;
            case '/credit_list':
                dispatch(stripe_card_register(paymentMethod.id, email))
                break
                //credit screenの場合にはcreditカード情報の更新もしくは登録を行うなうようにする

        }
        //formに入力したクレジットカード情報の値をセキュリティ強化のために暗号化する必要がある
       
    };

    useEffect(() => {
        console.log(location.pathname)
        switch(location.pathname){
            case '/checkout/basic/':
                setTitle('クレジットカード支払い')
                break;
            case '/checkout/premium':
                setTitle('クレジットカード支払い')
                break;
            case '/checkout/rental':
                setTitle('クレジットカード支払い')
                break;
            case '/credit_list':
                setTitle('クレジットカード登録')
                break;
            case '/credit_card/update':
                setTitle('クレジットカード情報更新')
                break;
                //credit screenの場合にはcreditカード情報の更新もしくは登録を行うなうようにする
        }

    },[])


    return (
        <Card style={{color:'black', backgroundColor:'white'}}>
            <CardContent>
            <Typography variant="h3" component="h3" className={classes.title}>{title}</Typography>
                <div className={classes.credit_image}>
                    {cardLogos.map((logo, index) => (
                        <div key={index} className={classes.image}>
                            <img src={logo} alt='クレジットカード' style={{width:'100%'}}/>
                        </div>
                    ))}
                </div>
                <form onSubmit={handleSubmit} className={classes.stripe_form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                InputLabelProps={{
                                    classes:{
                                        root:classes.input_title
                                    }
                                }}
                                InputProps={{
                                    classes:{
                                        notchedOutline:classes.text,
                                        root:classes.text,
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                id="email"
                                name="name"
                                label="Eメールアドレス"
                                type="email"
                                placeholder="Eメールアドレス"
                                required
                                value={email}
                                onChange={(event) =>{
                                    setEmail(event.target.value)
                                }}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                            label="クレジットカード番号"
                            name="credit_number"
                            variant="outlined"
                            autoFocus={true}
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes:{
                                    root:classes.input_title
                            }}}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps:{
                                    component:CardNumberElement
                                },
                                classes:{
                                    notchedOutline:classes.text,
                                    root:classes.text,
                                }
                            }}/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                            label="有効期限"
                            name="credit_exp"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes:{
                                    root:classes.input_title
                                }
                                }}
                            InputProps={{
                                inputComponent: StripeInput,
                                inputProps:{
                                    component:CardExpiryElement
                                },
                                classes:{
                                    notchedOutline:classes.text,
                                    root:classes.text,
                                }
                            }}/>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <TextField
                            label="せキュリティ番号"
                            name="card_cvc"
                            variant="outlined"
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes:{
                                    root:classes.input_title
                                }}}
                            InputProps={{
                                inputComponent:StripeInput,
                                inputProps:{
                                    component:CardCvcElement
                                },
                                classes:{
                                    notchedOutline:classes.text,
                                    root:classes.text,
                                }
                            }}/>
                        </Grid>

                        </Grid>
                        <Button 
                        fullWidth
                        type="submit" 
                        className={classes.button} 
                        >
                            この支払い方法を選択
                        </Button>
                    </form>
            </CardContent>
        </Card>
    )
};

export default CheckoutForm;