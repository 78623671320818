import { 
    RESERVATION_GET_REQUEST,
    RESERVATION_GET_SUCCESS,
    RESERVATION_GET_FAIL,
    RESERVATION_LATEST_GET_REQUEST,
    RESERVATION_LATEST_GET_SUCCESS,
    RESERVATION_LATEST_GET_FAILED,
    RESERVATION_LIST_REQUEST,
    RESERVATION_LIST_SUCCESS,
    RESERVATION_LIST_FAIL,
    RESERVATION_HALF_REQUEST,
    RESERVATION_HALF_SUCCESS,
    RESERVATION_HALF_FAIL,
    RENTAL_RESERVATION_LIST_REQUEST,
    RENTAL_RESERVATION_LIST_SUCCESS,
    RENTAL_RESERVATION_LIST_FAIL,
    RETURN_RENTAL_PRODUCT_REQUEST,
    RETURN_RENTAL_PRODUCT_SUCCESS,
    RETURN_RENTAL_PRODUCT_FAIL,
    RESREVATION_ALREADY_REQUEST,
    RESERVATION_ALREADY_SUCCESS,
    RESERVATION_ALREADY_FAIL,
    RESERVATION_ITEM_GET_REQUEST,
    RESERVATION_ITEM_GET_SUCCESS,
    RESERVATION_ITEM_GET_FAILED,
    RESERVATION_DAY_SAVE
 } from "../constants/reservationConstan";

export const reservationListReducer = (state={reservations:[]}, action) => {
     switch(action.type){
        case RESERVATION_LIST_REQUEST:
            return {loading: true, reservations: []}
        case RESERVATION_LIST_SUCCESS:
            return {loading:false, reservations: action.payload}
        case RESERVATION_LIST_FAIL:
            return {loading:false, error: action.payload}
        case RESERVATION_HALF_REQUEST:
            return {loading: true}
        case RESERVATION_HALF_SUCCESS:
            return {loading:false, reservations: action.payload}
        case RESERVATION_HALF_FAIL:
            return {loading:false, error: action.payload}
        case RENTAL_RESERVATION_LIST_REQUEST:
            return {loading:true}
        case RENTAL_RESERVATION_LIST_SUCCESS:
            return {laoding:false, reservations: action.payload}
        case RENTAL_RESERVATION_LIST_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
     }
 }

 //status3のreservation dataを取得
 export const reservationGetReducer = (state='', action) => {
    switch(action.type){
        case RESERVATION_GET_REQUEST:
            return {reservation_loading: true}
        case RESERVATION_GET_SUCCESS:
            return {reservation_loading: false, reservation: action.payload}
        case RESERVATION_GET_FAIL:
            return {reservation_loading: false, error: action.payload}
        default:
            return state
    }
 }

 //最新のdataを取得
 export const reservationLatestReducer = (state={reservations:[]}, action) => {
    switch(action.type){
        case RESERVATION_LATEST_GET_REQUEST:
            return {loading: true, reservations: []}
        case RESERVATION_LATEST_GET_SUCCESS:
            return {loading: false, reservations: action.payload}
        case RESERVATION_LATEST_GET_FAILED:
            return{laoding: false, reservations: ''}
        default:
            return state
    }
 }

//商品の返却時の伝票番号と貸出状況の更新を行う

export const returnProductReducer = (state='', action) => {
    switch(action.type){
        case RETURN_RENTAL_PRODUCT_REQUEST:
            return {loading: true}
        case RETURN_RENTAL_PRODUCT_SUCCESS:
            return {loading:false, message: action.payload}
        case RETURN_RENTAL_PRODUCT_FAIL:
            return {loading:false, return_error: action.payload}
        default:
            return state
    }
}

export const reservationAlreadyRentalReducer = (state={}, action) => {
    switch(action.type){
        case RESREVATION_ALREADY_REQUEST:
            return {loading: true, rental: false}
        case RESERVATION_ALREADY_SUCCESS:
            return {loading: false, rental: action.payload}
        case RESERVATION_ALREADY_FAIL:
            return {loading: false}
        default:
            return state
    }
}

export const reservationItemGetReducer = (state={}, action) => {
    switch(action.type){
        case RESERVATION_ITEM_GET_REQUEST:
            return {loading: true}
        case RESERVATION_ITEM_GET_SUCCESS:
            return {loading: false, reservationItem: action.payload}
        case RESERVATION_ITEM_GET_FAILED:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const reservationDaySaveReducer = (state='', action) => {
    switch(action.type){
        case RESERVATION_DAY_SAVE:
            return {reservation_day: action.payload}
        default:
            return state
    }
}