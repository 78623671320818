import {
    ORDER_REQUEST,
    ORDER_SUCCESS,
    ORDER_FAILED,
    ORDER_DELETE,
    ORDER_ITEM_LIST_REQUEST,
    ORDER_ITEM_LIST_SUCCESS,
    ORDER_ITEM_LIST_FAILED,
    ORDER_ITEM_GET_REQUEST,
    ORDER_ITEM_GET_SUCCESS,
    ORDER_ITEM_GET_FAILED,
    BUYING_INFO_SAVE,
    BUYING_INFO_DELETE
} from '../constants/buyingConstants'

export const orderReducer = (state = '', action) => {
    switch(action.type){
        case ORDER_REQUEST:
            return {loading: true}
        case ORDER_SUCCESS:
            return {loading: false, order: action.payload}
        case ORDER_FAILED:
            return {loading: false, error: action.payload}
        case ORDER_DELETE:
            return {order: action.payload}
        default:
            return state
    }
}

export const orderItemList = (state = {}, action) => {
    switch(action.type){
        case ORDER_ITEM_LIST_REQUEST:
            return {loading: true}
        case ORDER_ITEM_LIST_SUCCESS:
            return {loading: false, orderItem: action.payload}
        case ORDER_ITEM_LIST_FAILED:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const orderItemGet = (state = {}, action) => {
    switch(action.type){
        case ORDER_ITEM_GET_REQUEST:
            return {loading: true}
        case ORDER_ITEM_GET_SUCCESS:
            return {loading: false, orderItem: action.payload}
        case ORDER_ITEM_GET_FAILED:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const buyingInfoReducer = (state = {}, action) => {
    switch(action.type){
        case BUYING_INFO_SAVE:
            return {buying: action.payload}
        case BUYING_INFO_DELETE:
            return {buying: action.payload}
        default:
            return state
    }
}