import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import { Button, Card } from "@material-ui/core";
import { BsChevronLeft } from 'react-icons/bs'
import { CardMedia } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Box } from '@material-ui/core';

//import
import ClosetCreateComponent from "./ClosetCreateComponent";
import Loader from "./Loader";

//action method
import { create_basic_cloth } from "../actions/closetAction";
import { create_premium_cloth } from "../actions/closetAction";

const useStyles = makeStyles((theme) => ({
    button:{
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        }
    },
    card_box:{
        display:'flex'
    },
    card_inner:{
        display:'flex'
    },
    card_closet:{
        backgroundColor:'white', 
        color:'black', 
        marginBottom: theme.spacing(1),
        width:"100%"
    },
    card_content:{
        color:'black',
    },
    card_content_invalid:{
        color:'black',
        marginTop:theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    closet_button:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    closet_list:{
        padding: 'initial',
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    closet_not_valid:{
        backgroundColor: theme.palette.primary['400'],
        color:'black', 
        marginBottom: theme.spacing(1),
        width:"100%"
    },
    card_media:{
        width:50, 
        height:50, 
        backgroundColor:'white'
    },
    icon_button:{
        '&:hover':{
            backgroundColor:'initial'
        },
        padding:'initial'
    },
    inner_container:{
        backgroundColor: 'white',
        color:'black',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    list:{
        height:'300px',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY:'scroll',
    },
    root:{
        color:'black'
    },
    title:{
        fontWeight:'bold', 
        color:'black',
        marginBottom: theme.spacing(1)
    }
}))

//closetのリストを表示するためのcomponent
const ClosetListComponent = ({product_id, changeState}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    //useState
    const [open, setOpen] = useState(false)

    //子コンポーネントに渡すstate
    const ChangeClothState = (isState) => {
        setOpen(isState)
    }
    //useSelector
    const {loading, closets, error} = useSelector(state => state.closetList)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)

    //planごとに一つのclosetに追加できる数を制限する。
    //同じ商品は表示できないようにする
    const handleClick = (closet) => {
        if(subscriptionInfo.plan === 'premium'){
            dispatch(create_premium_cloth(product_id, closet))
            changeState(false)
        }else{
            dispatch(create_basic_cloth(product_id, closet))
            changeState(false)
        }
    }

    const handleClose = () => {
        changeState(false)
    }
    //クローゼット作成用のモーダルを開くためのhandle
    const handleClosetOpen = () => {
        setOpen(true)
    }
    //クローゼットを作成するためのモーダルを閉じるためのhandle
    const handleClosetClose = () => {
        setOpen(false)
    }

    //クローゼットを
    return (
    <>
        <Container maxWidth={"sm"} className={classes.closet_list}>
            <Card className={classes.inner_container}>
                <CardContent>
                <IconButton
                 onClick={handleClose}
                 disableRipple={true}
                 disableFocusRipple={true}
                 className={classes.icon_button}
                 size="medium"
                 color="inherit"
                 >
                     <BsChevronLeft/>
                 </IconButton>
                <div> 
                <Typography variant="subtitle1" component="p" className={classes.title} align="center">
                    クローゼットに保存
                </Typography>
                
                <Typography variant="caption" style={{color:'#9e9e9e'}}>
                        全てのクローゼット
                </Typography>
                </div>
                {
                    loading ?
                    <Loader/>
                    :
                    error ?
                    <Typography variant="subtitle1" component="p">
                        クローゼットを取得できませんでした
                    </Typography>
                    :
                    <List className={classes.list}>
                        {
                        closets.map((closet) => (
                            (closet.cloth.length === 3) ?
                            <ListItem style={{padding: 'initial'}}>
                            <Card key={closet.id} sx={{display:'flex',height: 50}} className={classes.closet_not_valid}>
                                    <Box className={classes.card_box}>
                                        <Box className={classes.card_media}>
                                            <CardMedia
                                            component="img"
                                            image={closet.cloth[0]? closet.cloth[0].product.img : 'none'}
                                            alt={closet.closet_name}
                                            />
                                        </Box>
                                        <CardContent  className={classes.card_content_invalid} style={{padding:'initial'}}>
                                            <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                                                {closet.closet_name}
                                            </Typography>
                                            <Typography variant="subtitle2" component="p">
                                                クローゼットがいっぱいになりました
                                            </Typography>
                                        </CardContent>
                                    </Box>
                            </Card>
                        </ListItem>
                            :
                            closet.cloth.find((clo) => clo.product.id === product_id) ?
                            <ListItem style={{padding: 'initial'}}>
                                <Card key={closet.id} sx={{display:'flex',height: 50}} className={classes.closet_not_valid}>
                                        <Box className={classes.card_box}>
                                            <Box className={classes.card_media}>
                                                <CardMedia
                                                component="img"
                                                image={closet.cloth[0]? closet.cloth[0].product.img : 'none'}
                                                alt={closet.closet_name}
                                                />
                                            </Box>
                                            <CardContent  className={classes.card_content_invalid} style={{padding:'initial'}}>
                                                <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                                                    {closet.closet_name}
                                                </Typography>
                                                <Typography variant="subtitle2" component="p">
                                                    追加済
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                </Card>
                            </ListItem>
                            :
                            <ListItem style={{padding: 'initial'}}>
                                <Card key={closet.id} sx={{display:'flex',height: 50}} className={classes.card_closet}>
                                    <CardActionArea onClick={() => {handleClick(closet.id)}} style={{width:"100%", '&.MuiCardActionArea-focusHighlight':{
                                        backgroundColor: 'initial',
                                        '&:hover':{backgroundColor: 'initial'}
                                    }}}>
                                        <Box className={classes.card_box}>
                                            <Box className={classes.card_media}>
                                                <CardMedia
                                                component="img"
                                                image={closet.cloth[0]? closet.cloth[0].product.img : 'none'}
                                                alt={closet.closet_name}
                                                />
                                            </Box>
                                            <CardContent  className={classes.card_content} style={{paddingBottom:'initial'}}>
                                                <Typography variant="subtitle1" component="p" style={{fontWeight:'bold', color:'black',margin:'initial', padding:'initial'}}>
                                                    {closet.closet_name}
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            </ListItem>
                        ))}
                    </List>
                }

                <Box className={classes.closet_button}>
                    <Button
                    className={classes.button}
                    fullWidth
                    onClick={handleClosetOpen}
                    style={{paddingTop:5, color:'black'}}>
                        <Box style={{ paddingTop:5}}>
                            <AddIcon/>
                        </Box>
                        <Typography
                        variant="subtitle1"
                        style={{fontWeight:'bold', margin:'auto'}}>
                            クローゼットを新規作成する
                        </Typography>
                    </Button>
                </Box>
      
            <Modal
            style={{zIndex:4000}}
            open={open}
            onClose={handleClosetClose}
            aria-labelledby="modal-modal-title"
            >
             <ClosetCreateComponent changeState={ChangeClothState} productId={product_id}/>
         </Modal>
         </CardContent>
         </Card>
        </Container>
        
         
    </>

    )
}

export default ClosetListComponent