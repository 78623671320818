import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//action
//import { feature_product_get } from "../actions/analyticsAction";
//import { feature_brand_get } from "../actions/analyticsAction";
import { browsing_history_get } from "../actions/historyAction";

//component
import PageSwitchingComponent from "../components/PageSwitchingComponent";

//loader
import Loader from "../components/Loader";

const useStyles = makeStyles((theme) => ({
    brand_card:{
        [theme.breakpoints.down('sm')]:{
            height:'220x',
            width: '350px'
        },
        [theme.breakpoints.up('sm')]:{
            height: '350px',
            width: '450px'
        }
    },
    brand_list_item:{
        display: 'block',
        padding: 'initial',
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            height:'300px',
            width: '400px'
        },
        [theme.breakpoints.up('xs')]:{
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            height: '350px',
            width: '450px'
        }
    },
    card:{
        [theme.breakpoints.down('sm')]:{
            height: '200px',
            width: '200px',
        },
        [theme.breakpoints.up('sm')]:{
            height: '250px',
            width: '250px'
        }
    },
    card_media:{
        width: '100%',
        height: '100%'
    },
    card_browsing:{
        boxShadow: 'initial',
        [theme.breakpoints.down("sm")]:{
            height: "100px",
            width: "100px"
        },
        [theme.breakpoints.up("sm")]:{
            height:"150px",
            width:"150px"
        }
    },
    container:{
        padding: 'initial',
        marginBottom: theme.spacing(15)
    },
    date:{
        marginBottom: theme.spacing(5),
        fontWeight: 'bold'
    },
    grid:{
        marginTop: theme.spacing(5)
    },
    grid_title:{
        marginTop: theme.spacing(1),
        fontWeight: 'bold'
    },
    feature:{
        marginTop: theme.spacing(5),
        fontWeight: 'bold'
    },
    idea_card:{
        borderRadius: '30px',
        boxShadow: 'initial',
        height: '400px'
    },
    list:{
        display:'flex',
        overflow: 'hidden',
        overflowX: 'scroll',
        padding: 'initial',
    },
    list_item:{
        display: 'block',
        padding: 'initial',
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            height: '200px',
            width: '200px',
            
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            height: '250px',
            width: '250px'
        }
    },
    list_item_browsing:{
        display: 'block',
        padding: 'initial',
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            height: '100px',
            width: '100px',
            
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            height: '150px',
            width: '150px'
        }
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3)
        }
    },
    subtitle:{
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    subtitle_container:{
        width:'100%'
    },
    title:{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    }
}))


const IdeaScreen = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const {accountInfo} = useSelector(state => state.account)
    //const {loading, features, error} = useSelector(state => state.featureProduct)
    //const {brand_loading, feature_brand, brand_error} = useSelector(state => state.featureBrand)
    const {history_loading, browsing_history} = useSelector(state => state.browsing)

    const hamdleClick = (id) => {
        history.push(`/product/${id}`)
    }

    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    //const handleBrandClick = (id, brand_name) => {history.push(`/brand/${brand_name}/?number=${id}`)}

    const handleSubscription = () => {
        history.push('/subscription/plan')
    }

    const handleInvitation = () => {
        history.push('/invitation_coupon')
    }

    useEffect(() => {
        //dispatch(feature_product_get())
        //dispatch(feature_brand_get())
        if(accountInfo){
            dispatch(browsing_history_get())
        }

    },[])

    return(
        
        history_loading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        <Container maxWidth={"lg"} className={classes.container}>
            <PageSwitchingComponent/>
                <div className={classes.root}>
                    <Typography variant="h1" component="h1" align="center" className={classes.title}>
                        アイデア
                    </Typography>
                    <Typography variant="h2" component="p" align="center" className={classes.date}>
                        {year + '年' + ' ' + month + '月' + ' ' + day + '日'}
                    </Typography>

                    {/*閲覧履歴の表示*/}  
                    {
                        browsing_history.length > 0 ?
                        <div>
                        <Typography variant="h2" component="p" className={classes.feature}>
                            閲覧した商品
                        </Typography>
                        <List className={classes.list}>
                        {
                            browsing_history.map((product) => (
                                <ListItem className={classes.list_item_browsing} key={product.id} onClick={() => hamdleClick(product.id)}>
                                    <Card className={classes.card_browsing}>
                                        <CardActionArea>
                                            <CardMedia
                                            component="img"
                                            className={classes.card_media}
                                            image={product.img}
                                            alt="閲覧履歴"
                                            loading="lazy"
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <Typography variant="subtitle1" component="p" noWrap className={classes.stitleub}>
                                        {product.product_name}
                                    </Typography>
                                </ListItem>
                            ))
                        }
                        </List>
                        </div>
                        :
                        ''
                    }

                    {/*google analyticsから取得した統計を反映 */}
                                        
                    <Grid container spacing={3} className={classes.grid}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Card className={classes.idea_card}>
                                <CardActionArea onClick={handleSubscription}>
                                    <CardMedia
                                    component="img"
                                    className={classes.card_media}
                                    style={{height: "400px"}}
                                    image={"https://media.enclopedia-official.com/media/idea/mercari_idea_1.png"}
                                    alt="サブスクリプション"
                                    loading="lazy"
                                    />
                                </CardActionArea>
                            </Card>
                            <Typography variant="subtitle1" component="h2" align="center" className={classes.grid_title}>
                                Enclopediaサブスクリプション
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Card className={classes.idea_card}>
                                <CardActionArea onClick={handleInvitation}>
                                    <CardMedia
                                    style={{height: "400px"}}
                                    component="img"
                                    className={classes.card_media}
                                    image={"https://media.enclopedia-official.com/media/idea/enclopedia_coupon.webp"}
                                    alt="友達招待クーポン"
                                    loading="lazy"
                                    />
                                </CardActionArea>
                            </Card>
                            <Typography variant="subtitle1" component="h2" align="center" className={classes.grid_title}>
                                友達招待クーポン獲得方法
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* 
                    {
                        error ?
                        ''
                        :
                        <>
                        <Typography variant="h2" comopnent="h2" className={classes.feature}>
                            ピックアップ
                        </Typography>
                        <Container maxWidth={"md"} className={classes.container}>
                            <List className={classes.list}>
                                {feature_brand.map((brand) => (
                                <ListItem className={classes.brand_list_item} key={brand.id} onClick={() => handleBrandClick(brand.brand.id, brand.brand.brand_name)}>
                                <Typography variant="subtitle1" component="p" className={classes.subtitle} noWrap>
                                    {brand.brand.brand_name}
                                </Typography>

                                <Card className={classes.brand_card}>
                                    {
                                        brand_loading ?
                                        <Loader/>
                                        :
                                        brand_error ?
                                        ''
                                        :
                                        <CardActionArea>
                                            <CardMedia
                                            component="img"
                                            className={classes.brand_card_media}
                                            image={process.env.REACT_APP_URL + brand.brand.img}
                                            loading="lazy"
                                            />
                                        </CardActionArea>
                                    }
                                    </Card>
                                </ListItem>
                                ))}
                            </List>
                            <Typography variant="h2" component="div" className={classes.feature}>
                                注目のアイテム
                            </Typography>
                            <List className={classes.list}>
                                {
                                    features.map((feature) => (
                                        <ListItem className={classes.list_item} key={feature.product.id} onClick={() => hamdleClick(feature.product.id)}>
                                            <div className={classes.subtitle_container}>
                                                <Typography variant="subtitle1" component="p" noWrap className={classes.subtitle}>
                                                    {feature.product.product_name}
                                                </Typography>
                                            </div>
                                            <Card className={classes.card}>
                                                    <CardActionArea>
                                                        <CardMedia
                                                        component="img"
                                                        className={classes.card_media}
                                                        image={process.env.REACT_APP_URL + feature.product.img}
                                                        alt="注目のアイテム"
                                                        loading="lazy"
                                                        />
                                                    </CardActionArea>
                                                
                                            </Card>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </Container>
                    </>
                    }
                    */}
                </div>
            

        </Container>
    )
}

export default IdeaScreen
