export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'

export const CART_GET_REQUEST = 'CART_GET_REQUEST'
export const CART_GET_SUCCESS = 'CART_GET_SUCCESS'
export const CART_GET_FAIL = 'CART_GET_FAIL'

export const CART_CERATE_REQUEST = 'CART_CERATE_REQUEST'
export const CART_CREATE_SUCCESS = 'CART_CREATE_SUCCESS'
export const CART_CREATE_FAIL = 'CART_CREATE_FAIL'

export const CART_DELETE = 'CART_DELETE'
export const CART_DELETE_FAIL = 'CART_DELETE_FAIL'

export const CARTITEM_CREATE_REQUEST = 'CARTITEM_CREATE_REQUEST'
export const CARTITEM_CREATE_SUCCESS = 'CARTITEM_CREATE_SUCCESS'
export const CARTITEM_CREATE_FAIL = 'CARTITEM_CREATE_FAIL'

export const CARTITEM_BASIC_CREATE_REQUEST = 'CARTITEM_BASIC_CREATE_REQUEST'
export const CARTITEM_BASIC_CREATE_SUCCESS = 'CARTITEM_BASIC_CREATE_SUCCESS'
export const CARTITEM_BASIC_CREATE_FAIL = 'CARTITEM_BASIC_CREATE_FAIL'

export const CARTITEM_PREMIUM_CREATE_REQUEST = 'CARTITEM_PREMIUM_CREATE_REQUEST'
export const CARTITEM_PREMIUM_CREATE_SUCCESS = 'CARTITEM_PREMIUM_CREATE_SUCCESS'
export const CARTITEM_PREMIUM_CREATE_FAIL = 'CARTITEM_PREMIUM_CREATE_FAIL'

export const CARTITEM_BASIC_UPDATE = 'CARTITEM_BASIC_UPDATE'
export const CARTITEM_BASIC_UPDATE_FAIL = 'CARTITEM_BASIC_UPDATE_FAIL'

export const CARTITEM_PREMIUM_UPDATE = 'CARTITEM_PREMIUM_UPDATE'
export const CARTITEM_PREMIUM_UPDATE_FAIL = 'CARTITEM_PREMIUM_UPDATE_FAIL'

export const CARTITEM_ERROR_MESSAGE_CREAR = 'CARTITEM_ERROR_MESSAGE_CREAR'

export const CARTITEM_GET_REQUEST = 'CARTITEM_GET_REQUEST'
export const CARTITEM_GET_SUCCESS = 'CARTITEM_GET_SUCCESS'
export const CARTITEM_GET_FAIL = 'CARTITEM_GET_FAIL'

export const CARTITEM_UPDATE= 'CARTITEM_UPDATE'

export const CARTITEM_DELETE = 'CARTITEM_DELETE'
