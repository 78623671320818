import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Card, CardContent, CardActionArea, CardMedia,} from "@material-ui/core";
import { Container } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//action
import { reservation_half_get } from "../actions/reservationAction";
import { rental_reservation_list_get } from "../actions/reservationAction";
//compoennt
import Loader from "../components/Loader";
import ReservationComponent from "../components/ReservationComponent";

//このコンポーネントではeresrvationの確認とキャンセルなどの処理を時効する用意する
const useStyles = makeStyles((theme) =>({
    button:{
        '&:hover':{
            backgroundColor:'initial'
        }
    },
    card:{
        height: '500px',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(15),
        color: 'black',
        backgroundColor: 'white'
    },
    container:{
        padding: 'initial'
    },
    denied_reservations:{
        display: 'flex'
    },
    image:{
        height:"200px",
        width:"200px"
    },
    image_container:{
        [theme.breakpoints.up('xs')]:{
            marginRight: theme.spacing(1),
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]:{
            marginRight: theme.spacing(3),
        }
        
    },
    inner_container:{
        display: 'flex'
    },
    list:{
        display:'flex',
        overflow: 'hidden',
        overflowX:'scroll',
        padding: 'initial'
    },
    list_item:{
        padding:'initial',
        width:'initial'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    not_login:{
        margin: theme.spacing(2),
        height:'100vh'
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin:theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3)
        },
        margin: theme.spacing(1),
        marginBottom: theme.spacing(15)
    },
    subtitle:{
        marginBottom: theme.spacing(2)
    },
    select:{
        marginLeft: 'auto'
    },
    title:{
        marginBottom : theme.spacing(2)
    },
    topic_path:{
        display:'flex',
        marginBottom: theme.spacing(3)
    }
}));



//reservationを取得するにはhome画面でreservationで
const ReservationHistoryScreen = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    //useState
    const [message, setMessage] = useState('')
    const [period, setPeriod] = useState(1)

    //useSelector 
    const {accountInfo} = useSelector(state => state.account)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)
    const {loading, reservations, error} = useSelector(state=>state.reservationInfo)

    const handleClick = (product_id) => {
        history.push(`/product/${product_id}`)
    }

    const handleChange = (event) => {
        setPeriod(event.target.value)
    }

    const handleAccountService = () => {
        history.push('/management')
    }

    const handlePeriod = () => {
        dispatch(rental_reservation_list_get(subscriptionInfo.plan))
    }

    const handleHalfPeriod = () => {
        //半年間分のdataを取得するようにする
        dispatch(reservation_half_get())
    }


    //statusがdeniedの場合は下にレンタルし忘れの商品として紹介する用意する必要がある
    //reservationを更新日から今日まで予約アイテムを取得するようにするするようにsルウ
    useEffect(() => {
        if(accountInfo){
            dispatch(rental_reservation_list_get(subscriptionInfo.plan))
        }else{
            setMessage('アカウントにログインして下さい')
        }
    },[])

    return(
        loading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        error ?
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={ handleAccountService } className={classes.button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2,  cursor:'pointer'}}>
                    {'予約履歴'}
                </Typography>
            </div>
            <Container className={classes.container}>
                <div className={classes.inner_container}>
                    <Typography variant="h1" component="p" className={classes.title}>
                        予約履歴
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }}  className={classes.select}>
                        <InputLabel id="simple-select-helper-label">期間</InputLabel>
                        <Select
                        value={period}
                        onChange={handleChange}
                        displayEmpty
                        label="period"
                        >
                            <MenuItem value={1} onClick={handlePeriod}>
                                今月
                            </MenuItem>
                            <MenuItem value={6} onClick={handleHalfPeriod}>
                                半年分
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="subtitle1" component="p" align="center">
                            今月の間に予約した商品がありません
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </div>
        :
        message ?
        <div className={classes.not_login}>
            <Typography variant="h1" component="h1" className={classes.title}>
                予約履歴
            </Typography>
            <Typography variant="subtitle1" component="p">
                {message}
            </Typography>
        </div>
        :
        
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={ handleAccountService } className={classes.button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    {'予約履歴'}
                </Typography>
            </div>
            
            <Container maxWidth={'md'} className={classes.container}>
                <div className={classes.inner_container}>
                    <Typography variant="h1" component="h1" className={classes.title}>
                        予約履歴
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }}  className={classes.select}>
                        <InputLabel id="simple-select-helper-label">期間</InputLabel>
                        <Select
                        value={period}
                        onChange={handleChange}
                        displayEmpty
                        label="period"
                        >
                            <MenuItem value={1} onClick={handlePeriod}>
                                    今月
                            </MenuItem>
                            <MenuItem value={6} onClick={handleHalfPeriod}>
                                半年分
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {
                    reservations.map((resrevation) => (
                        resrevation.status != 2 ?
                        <ReservationComponent key={resrevation.id} reservation={resrevation}/> 
                        :
                        ''
                    ))
                }
                {reservations.plan === 'rental' ?
                <>
                <Typography variant="subtitle1" component="p" align="right">
                    送料：<strong>{reservations.shipping_price}</strong>
                </Typography>
                <Typography variant="subtitle1" component="p" align="right">
                    小計：<strong>{reservations.total_price}</strong>
                </Typography>
                </>
                :
                ''
                }

            </Container>
            <Typography variant="h2" component="h2" className={classes.subtitle}>
                予約し忘れた商品はありませんか？
            </Typography>
             
            <List className={classes.list}>
                {
                    reservations.map((reservation) => (
                        reservation.status === 2 ?
                        reservation.reservation_items.map((item) => (
                            <ListItem key={item.id} className={classes.list_item}>
                                <Card key={item.id} className={classes.image_container}>
                                    <CardActionArea onClick={() => {handleClick(item.product.id)}}>
                                        <CardMedia
                                        component="img"
                                        src={item.product.img} className={classes.image}/>
                                    </CardActionArea>
                                </Card>
                            </ListItem>
                        ))
                       
                        :
                        ''
                    ))
                }
            </List>
        </div>
    )
}

export default ReservationHistoryScreen