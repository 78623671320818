import { 
    CHATGPT_IMAGE_REQUEST,
    CHATGPT_IMAGE_SUCCESS,
    CHATGPT_IMAGE_FAIL,
    CHATGPT_IMAGE_DELETE,
    STYLING_LIST_REQUEST,
    STYLING_LIST_SUCCESS,
    STYLING_LIST_FAIL
} from "../constants/chatgptConstants";

export const chatgptImageReducer = (state = {image: ''}, action) => {
    switch(action.type){
        case CHATGPT_IMAGE_REQUEST:
            return {loading: true, image: ''}
        case CHATGPT_IMAGE_SUCCESS:
            return {loading: false, image: action.payload}
        case CHATGPT_IMAGE_FAIL:
            return {laoding: false, error: '画像の生成に失敗しました,しばらくしてから再度お試し下さい。'}
        case CHATGPT_IMAGE_DELETE:
            return{image: action.payload}
        default:
            return state
    }
}

export const stylingListReducer = (state = {}, action) => {
    switch(action.type){
        case STYLING_LIST_REQUEST:
            return {styling_loading: true}
        case STYLING_LIST_SUCCESS:
            return {styling_loading:false, styling: action.payload}
        case STYLING_LIST_FAIL:
            return {styling_loading: false, styling_error: action.payload}
        default:
            return state
    }
}