import React,{ useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardMedia } from '@material-ui/core'
import { CardContent } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
//function
import { pageView } from '../function/gaTraking';
import { headRewrite } from '../function/headRewrite';

const useStyles = makeStyles((theme) => ({
  block:{
    [theme.breakpoints.down['sm']]:{
      margin: theme.spacing(0)
    },
    [theme.breakpoints.up['xs']]:{
      margin: theme.spacing(5)
    },
    [theme.breakpoints.up['sm']]:{
      margin: theme.spacing(5)
    },
    [theme.breakpoints.up['md']]:{
      margin: theme.spacing(5)
    },
    margin:theme.spacing(5)
  },
  button:{
    backgroundColor:'inherit',
    color: theme.palette.primary['800'],
    border: `solid 1px ${theme.palette.primary['800']}`,
    marginRight: theme.spacing(2),
    '&:hover':{
      background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
      border: 'inherit'
    },
  },
  button_area:{
    marginTop:theme.spacing(3),
    textAlign:'center'
  },
  card:{
    boaderRadius: '10%',
    color:'black',
    backgroundColor:'white',
    boxShadow: 'initial',
    [theme.breakpoints.down('sm')]:{
      height:'500px'
    },
    [theme.breakpoints.up('sm')]:{
      height:'750px'
    },
    [theme.breakpoints.up('md')]:{
      height:'520px'
    },

  },
  card_button:{
    color: 'black',
    background: theme.palette.primary['200'],
    '&:hover':{
      background: theme.palette.primary['400'],
    },
    marginTop: theme.spacing(1)
  },
  card_disabled_button:{
    color: 'black',
    background: theme.palette.primary['200'],
    "&:disabled": {
      color: "black",
      backgroundColor: theme.palette.primary['400']
    },
    marginTop: theme.spacing(1)
  },
  cardStyle:{
    boaderRadius: '10%',
    color:'black',
    backgroundColor:'white',
    boxShadow: 'initial !important'
  },
  catch_phrase:{
    textAlign:'center',
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(10)
  },
  content:{
    marginTop: theme.spacing(1),
    fontWeight:'bold'
  },
  divider:{
    backgroundColor: 'white',
    border: `3px solid white`,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    width: '100%'
  },
  media:{
    [theme.breakpoints.up('xs')]:{
      width:'270px'
    },
    [theme.breakpoints.up('sm')]:{
      width:'380px'

    },
    [theme.breakpoints.up('md')]:{
      width:'285px'
    },
    [theme.breakpoints.up('lg')]:{
      width:'310px'

    },
    [theme.breakpoints.up('xl')]:{
      width:'380px'
    }
  },
  not_login_button:{
    color: theme.palette.primary['800'],
    background: 'white',
    fontWeight: 'bold',
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  not_login_catch_phrase:{
    textAlign:'center',
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(3)
  },
  plan:{
    fontWeight:'bold',
    textAlign:'center',
    marginBottom: theme.spacing(1),
    borderBottom: `solid 1px ${theme.palette.primary['400']}`
  },
  root:{
  [theme.breakpoints.down('sm')]:{
      margin: theme.spacing(10,1,15,1)
  },
  [theme.breakpoints.up('sm')]:{
      margin: theme.spacing(10,2,15,2)
  },
  [theme.breakpoints.up('md')]:{
      margin: theme.spacing(10,3,15,3)
  }
  },
  submit:{
    marginBottom: theme.spacing(2),
    boxShadow:`2px 2px 2x`
  },
  subtitle:{
    fontWeight: 'bold',
    textAlign:'center',
    borderBottom: 'solid 1px black'
  },
  subtitle_media:{
    position:'relative',
  },
  subtitle_media_display:{
    position: 'absolute',
    [theme.breakpoints.up('xs')]:{
      top:'100px',
      left: '140px'
    },
    [theme.breakpoints.up('sm')]:{
      top:'140px',
      left: '350px'
    },
    [theme.breakpoints.up('md')]:{
      top:'80px',
      left: '100px'
    },
    [theme.breakpoints.up('lg')]:{
      top:'120px',
      left: '200px'
    },
    [theme.breakpoints.up('xl')]:{
      top:'140px',
      left: '250px'
    }
  },
  subtitle_phrase:{
    marginTop:theme.spacing(3),
    textAlign:'center'
  },
  title:{
    textAlign:'center',
    fontWeight:'bold',
  }
}))



function StripePlanScreen(){
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  //useeffectについて返す
  const {accountInfo} = useSelector(state => state.account)
  //const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)

  //head data
  const title = "サブスクリプション詳細｜Enclopedia【公式サイト】"
  const description = "ファッションレンタルサービスEnclopedia(アンクロペディア)のサブスクリプション詳細に関するページです。"

  {/*
    const handlePlanClcik = (id, plan_name, price) => {
      history.push(`/checkout/${plan_name}/?plan=${id}&price=${price}`)
    
  */}


  const handleRegister = () => {
    history.push('/register')
  }

  useEffect(() => {
    headRewrite(title, description)
    pageView('subscription register screen', location.pathname)
  }, [dispatch])

  return(
  <div className={classes.root}>
    {/*
    <Typography variant='h1' component="h1" className={classes.title}>
      今すぐ始めよう
    </Typography>
    {
      accountInfo ?
      <Typography variant='subtitle1' component="p" className={classes.catch_phrase}>
        you are you I’m me 好きなものを自由に
      </Typography>
      :
      <>
      <Typography variant='subtitle1' component="p" className={classes.not_login_catch_phrase}>
        you are you I’m me 好きなものを自由に
      </Typography>
      <div style={{width: '100%', textAlign: 'center'}} >
        <Button className={classes.not_login_button} onClick={handleRegister}>
          アカウント登録
        </Button>
      </div>
      </>
    }

    */}
    <Typography variant='h1' component="h1" className={classes.title}>
      Enclopediaサブスクリプションは現在準備中です
    </Typography>
    {
      accountInfo ?
      <Typography variant='subtitle1' component="p" className={classes.catch_phrase}>
        月額制プランは現在準備中です、レンタルプランのみご利用いただけますので下記の内容をご確認下さい。
      </Typography>
      :
      <>
      <Typography variant='subtitle1' component="p" className={classes.not_login_catch_phrase}>
        Enclopediaは価値のある服を保存と共有を目的としたコミュニティです
      </Typography>
      <div style={{width: '100%', textAlign: 'center'}} >
        <Button className={classes.not_login_button} onClick={handleRegister}>
          アカウント登録
        </Button>
      </div>
      </>
    }


      <Grid container spacing={3} >
        <Grid key="light_plan" item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography variant='h2' component="h2" className={classes.plan}>
            レンタルプラン
          </Typography>
          <Card className={classes.card}>
              <CardMedia
                component="img"
                image={process.env.REACT_APP_IMAGE_URL + "subscription/subsc_ren.png"}
                style={{boxShadow: 'initial !important'}}
                alt="light plan"
              />
              <CardContent>
                <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                  月額料金: なし
                </Typography>
                <Typography variant="caption">
                  ※会員登録済みの方は自動的にレンタルプランに加入します、サブスクリプションに加入しているお客様は解約を行うことでレンタルプランに変更されます。
                </Typography>
                <Typography variant="h3" component="div" className={classes.content}>
                  プラン内容:
                </Typography>
                <Typography variant="subtitle1" component="p">
                  一着レンタルする毎に料金が発生します。1回発送毎に3着まで月に何回でもレンタルすることができます。使用機会の少ないお客様や、お試しで利用したいお客様におすすめです。
                </Typography>
              </CardContent>
          </Card>
        </Grid>

        <Grid item key={process.env.REACT_APP_STRIPE_BASIC_PLAN}  xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography variant='h2' component="h2" className={classes.plan}>
            ベーシックプラン
          </Typography>
          <Card className={classes.card}>
              <CardMedia
                component="img"
                image={process.env.REACT_APP_IMAGE_URL + "subscription/subsc_bas.png"}
                style={{boxShadow: 'initial !important'}}
                alt="light plan"
              />
              <CardContent>
                <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                  月額料金: {process.env.REACT_APP_BASIC_UNIT_AMOUNT}
                </Typography>
                <Typography variant="caption">
                  ※登録日から一ヶ月毎の支払いになります。
                </Typography>
                <Typography variant="h3" component="h3" className={classes.content}>
                    プラン内容:
                </Typography>
                <Typography variant="subtitle1" component="p">
                  1回の発送毎に自由に3着まで、月に3回レンタルすることができます。週末のお出かけで利用したいお客様におすすめです。
                </Typography>
                {/*
                  <Typography variant="subtitle1" component="p" align='center'>
                  {
                    accountInfo ?
                    subscriptionInfo.plan === 'basic' ?
                      <Button className={classes.card_disabled_button} disabled={true}>
                          現在加入中のブラン
                      </Button>
                      :
                      <Button className={classes.card_button} onClick={() => handlePlanClcik(process.env.REACT_APP_STRIPE_BASIC_PLAN, 'basic', process.env.REACT_APP_BASIC_UNIT_AMOUNT)}>
                        ベーシックプラン登録
                      </Button>
                    :
                    ''
                  }
                  </Typography>
                */}
              </CardContent>
          </Card>
        </Grid>
        <Grid item key={process.env.REACT_APP_STRIPE_PREMIUM_PLAN} xs={12} sm={12} md={4} lg={4} xl={4}>
          <Typography variant='h2' component="h2" className={classes.plan}>
            プレミアムプラン
          </Typography>
          <Card className={classes.card}>
            <CardMedia
                component="img"
                image={process.env.REACT_APP_IMAGE_URL + "subscription/subsc_pre.png"}
                style={{boxShadow: 'initial !important'}}
                alt="light plan"
              />
              <CardContent>
                <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                  月額料金: {process.env.REACT_APP_PREMIUM_UNIT_AMOUNT}
                </Typography>
                <Typography variant="caption">
                  ※登録日から一ヶ月毎のお支払いになります
                </Typography>
                <Typography variant="h3" component="div" className={classes.content}>
                    プラン内容:
                </Typography>
                <Typography variant="subtitle1" component="p">
                  1回の発送毎に自由に3着まで、月に何回でもレンタルすることができます。おしゃれを楽しみたいお客様におすすめです。
                </Typography>
                {/*
                <Typography variant="subtitle1" component="div" align='center'>
                  {
                    accountInfo ?
                      subscriptionInfo.plan === 'premium' ?
                        <Button className={classes.card_disabled_button} disabled={true}>
                            現在加入中のブラン
                        </Button>
                        :
                        <Button className={classes.card_button} onClick={() => handlePlanClcik(process.env.REACT_APP_STRIPE_PREMIUM_PLAN, 'premium', process.env.REACT_APP_PREMIUM_UNIT_AMOUNT)}>
                          プレミアムプラン登録
                        </Button>
                      :
                      ''
                  }
                  </Typography>
                */}
              </CardContent>
          </Card>
        </Grid>
        <Divider className={classes.divider}/>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.block}>
            <Typography variant='h4' component="h4" className={classes.subtitle}>
              いろんな系統に挑戦
            </Typography>
            <Typography variant='h5' component="h5" className={classes.subtitle_phrase}>
              豊富な種類の中から自由に選択、着たい服を自分で自由に選択できるからもっとファッションが楽しくなる!
            </Typography>
            <div className={classes.button_area}>
              <Button onClick={()=>{history.push('/')}} className={classes.button}>
                <Typography variant="h2" component="h2" >
                  商品を見る
                </Typography>
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className={classes.subtitle_media}>
            <img src={process.env.REACT_APP_IMAGE_URL + "subscription/subscription_screen3.jpeg"}
              alt="light plan"
              className={classes.media}
            />
            <div className={classes.subtitle_media_display}>
              <img
                src={process.env.REACT_APP_IMAGE_URL + "subscription/subscription_screen4.jpeg"}
                alt="light plan"
                className={classes.media}
              />
            </div>
        </div>
        </Grid>
        <Divider className={classes.divider}/>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Card className={classes.cardStyle}>
            <CardMedia
              component="img"
              image= {process.env.REACT_APP_IMAGE_URL + "subscription/subsc_ren.png"}
              style={{boxShadow: 'initial !important'}}
              alt="light plan"
            />
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <div className={classes.block}>
            <Typography variant='h4' component="h4" className={classes.subtitle}>
              使い方も簡単
            </Typography>
            <Typography variant='h5' component="h5" className={classes.subtitle_phrase}>
              アプリケーション内で着用したい服を探して予約するだけ、予約から３日後の到着だからすぐに着用できる。<br/>
              返却の際のクリーニングも不要でそのまま返却。
            </Typography>
          </div>
        </Grid>
        <Divider className={classes.divider}/>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <div className={classes.block}>
            <Typography variant='h4' component="h4" className={classes.subtitle}>
              自分に合った料金プランから選択
            </Typography>
            <Typography variant='h5' component="h5" className={classes.subtitle_phrase}>
              レンタル毎に支払いのプランから毎月定額制でレンタルできるプランまで、自分に合ったプランを選択Enclopediaでおしゃれが楽しめる！
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
        <Card className={classes.cardStyle}>
          <CardMedia
            component="img"
            image={process.env.REACT_APP_IMAGE_URL + "subscription/subsc_ren.png"}
            style={{boxShadow: 'initial !important'}}
            alt="light plan"
          />
          </Card>
        </Grid>
        <Divider className={classes.divider}/>
      </Grid>
  </div>
  )
}

export default StripePlanScreen


