import { 
    READ_GET_REQUEST,
    READ_GET_SUCCESS,
    READ_GET_FAIL,
    READ_UPDATE_REQUEST,
    READ_UPDATE_SUCCESS,
    READ_UPDATE_FAIL,
    NEWS_LIST_GET_REQUEST,
    NEWS_LIST_GET_SUCCESS,
    NEWS_LIST_GET_FAIL,
    NOTIFICATION_LIST_GET_REQUEST,
    NOTIFICATION_LIST_GET_SUCCESS,
    NOTIFICATION_LIST_GET_FAIL,
    NEWS_GET_REQUEST,
    NEWS_GET_SUCCESS,
    NEWS_GET_FAIL,
    NOTIFICATION_GET_REQUEST,
    NOTIFICATION_GET_SUCCESS,
    NOTIFICATION_GET_FAIL,
    TODO_LIST_REQUEST,
    TODO_LIST_SUCCESS,
    TODO_LIST_FAIL,
    TODO_RETURN_ITEM_REQUEST,
    TODO_RETURN_ITEM_SUCCESS,
    TODO_RETURN_ITEM_FAIL,
    TODO_PURCHASED_ITEM_REQUEST,
    TODO_PURCHASED_ITEM_SUCCESS,
    TODO_PURCHASED_ITEM_FAIL
 } from "../constants/notificationConstants";
 import { baseAxios } from "../jwtInterceptor";

 ///未読の通知が何件あるのかを取得するaction
 export const read_get = () => async(dispatch) => {
    dispatch({type:READ_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/notification/read/list/').
    then(
        (response) => {
            dispatch({type:READ_GET_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:READ_GET_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
 }

//未読のメッセージを既読状態に更新
export const read_update = () => async(dispatch) => {
    dispatch({type:READ_UPDATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {}
    await baseAxios.put('/api/notification/read/update', data)
    .then(
        (response) => {
            dispatch({type:READ_UPDATE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: READ_UPDATE_FAIL, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}


 //newをlistで取得する

 export const news_list_get = () => async(dispatch) => {
     dispatch({type: NEWS_LIST_GET_REQUEST})
     baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
     await baseAxios.get('/api/notification/news/list/').then(
         (response) => {
            dispatch({type: NEWS_LIST_GET_SUCCESS, payload: response['data']})
         }
     ).catch(
         (error) => {
            dispatch({type: NEWS_LIST_GET_FAIL, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
         }
     )
 }

 //個人への通知をlistで取得する
 export const notification_list_get = () => async(dispatch) => {
    dispatch({type: NOTIFICATION_LIST_GET_REQUEST})
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        await baseAxios.get('/api/notification/list/').then(
            (response) => {
                dispatch({type:NOTIFICATION_LIST_GET_SUCCESS, payload:response['data']})
            }
        ).catch(
            (error) => {
                dispatch({type:NOTIFICATION_LIST_GET_FAIL,
                    payload:error.response && error.response.data.message
                    ? 
                    error.response.data.message
                    :
                    error.message})
            }
         )
     
 }

//
//各ニュースをそれぞれ表示させる
export const news_get = (news_id) => async(dispatch) => {
    dispatch({type:NEWS_GET_REQUEST})
    baseAxios.get(`/api/notification/news/${news_id}/`)
    .then((response) => {
        dispatch({type:NEWS_GET_SUCCESS, payload:response.data})
    }).catch((error) => {
        dispatch({
           type:NEWS_GET_FAIL,
           payload:error.response && error.response.data.message
           ? 
           error.response.data.message
           :
           error.message
        })
    })
}

//個人通知のdataを取得するaction
export const notification_get = (news_id) => async(dispatch) => {
    dispatch({type:NOTIFICATION_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    baseAxios.get(`/api/notification/${news_id}/`)
    .then((response) => {
        dispatch({type:NOTIFICATION_GET_SUCCESS, payload:response.data})
    }).catch((error) => {
        dispatch({
           type:NOTIFICATION_GET_FAIL,
           payload:error.response && error.response.data.message
           ? 
           error.response.data.message
           :
           error.message
        })
    })
}

export const todo_list = () => async(dispatch) => {
    dispatch({type: TODO_LIST_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    baseAxios.get('/api/notification/todo/list').then(
        (response) => {
            localStorage.setItem('todo', JSON.stringify(response['data']))
            dispatch({type:TODO_LIST_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            localStorage.setItem('todo', JSON.stringify([]))
            dispatch({type: TODO_LIST_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const todo_return_item = () => async(dispatch) => {
    dispatch({type: TODO_RETURN_ITEM_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {}
    baseAxios.put('/api/notification/todo/item/return', data).then(
        (response) => {
            dispatch({type: TODO_RETURN_ITEM_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: TODO_RETURN_ITEM_FAIL, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

export const todo_purchase_item = (id) => async(dispatch) => {
    dispatch({type: TODO_PURCHASED_ITEM_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {}
    baseAxios.patch(`/api/notification/todo/item/purchased/${id}`, data).then(
        (response) => {
            dispatch({type: TODO_PURCHASED_ITEM_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: TODO_PURCHASED_ITEM_FAIL, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}