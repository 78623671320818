import React from "react";
import { useHistory } from "react-router-dom";
import { Card } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Home from "@material-ui/icons/Home";
import { History } from "@material-ui/icons";
import { makeStyles} from "@material-ui/core/styles";
import { AccountCircle } from "@material-ui/icons";
import Payment from "@material-ui/icons/Payment";
import { Security } from "@material-ui/icons";
import { Subscriptions } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
// import secret information from component directory

const useStyles = makeStyles((theme) => ({
    card:{
        backgroundColor:'white',
        color:'black',
        border:'1px',
        borderColor: theme.palette.primary['200'],
        boxShadow: 'initial !important'
    },
    card_inner:{
        margin: theme.spacing(2),
        display:'flex',
    },
    content:{
        flex: '1 0 auto',
        padding: 'initial',
        '& :lastchild':{
            paddingBottom:'initial'
        }
    },
    description:{
        marginLeft:theme.spacing(2),
        opacity:0.6
    },
    history:{
        color:'black',
        height:'50px',
        width:'50px',
        displayl: 'flex'
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin:theme.spacing(3, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(3, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,3,15,3),
            minHeight:'100vh',
        },
    },
    service:{
        display: 'flex',
        flexDirection:'column',
        marginLeft: theme.spacing(2),
    },
    service_root:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(15)
    }
    })
)

const ManagementScreen = () => {
    const classes = useStyles()
    const history = useHistory()


    
//addressScreenレダイレクトする
const handleAddress = () => {
    history.push('/address')
}

//user情報欄へリダイレクトする
const handleSecure = () => {
    history.push('/security')
}

//予約状況確認画面にリダイレクトする
const handleReservationHitory = () => {
    history.push('/reservation_history')
}

const handleSubscription = () => {
    history.push('/subscription/info')
}

    const handleCredit = () => {
        history.push('/credit_list')
    }

    const handleCloset = () => {
        history.push('/profile')
    }

    return(
        <div className={classes.root}>
            <Typography variant="h1" component='h1'>
                アカウントサービス
            </Typography>
            <div className={classes.service_root}>
                <Grid container spacing={2}>
                    <Grid key='reservation' item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={handleReservationHitory}>
                                <div className={classes.card_inner}>
                                    <History className={classes.history}/>
                                    <CardContent className={classes.content} style={{padding:'initial'}}>
                                        <Typography variant="h3" className={classes.service}>
                                            予約履歴
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.description}>
                                            予約情報の確認、管理
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid key='account' item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={handleSecure}>
                                <div className={classes.card_inner}>
                                    <Security className={classes.history}/>
                                    <CardContent className={classes.content} style={{padding:'initial'}}>
                                        <Typography variant="h3" className={classes.service}>
                                            ログイン、セキュリティ
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.description}>
                                            アカウント情報の変更
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid key='subscription' item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={handleSubscription}>
                                <div className={classes.card_inner}>
                                    <Subscriptions className={classes.history}/>
                                    <CardContent className={classes.content} style={{padding:'initial'}}>
                                        <Typography variant="h3" className={classes.service}>
                                            サブスクリプション
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.description}>
                                            サブスクリプション、プラン管理
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid key='adress' item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={handleAddress}>
                                <div className={classes.card_inner}>
                                    <Home className={classes.history}/>
                                    <CardContent className={classes.content} style={{padding:'initial'}}>
                                        <Typography variant="h3" className={classes.service}>
                                            住所
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.description}>
                                            登録住所の変更
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid key='closet' item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={handleCloset}>
                                <div className={classes.card_inner}>
                                    <AccountCircle className={classes.history}/>
                                    <CardContent className={classes.content} style={{padding:'initial'}}>
                                        <Typography variant="h3" className={classes.service}>
                                            クローゼット
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.description}>
                                            クローゼットの管理、プロフィール変更
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid key='payment'item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <Card className={classes.card}>
                            <CardActionArea onClick={handleCredit}>
                                <div className={classes.card_inner}>
                                    <Payment  className={classes.history}/>
                                    <CardContent className={classes.content} style={{padding:'initial'}}>
                                        <Typography variant="h3" className={classes.service}>
                                            支払い方法
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.description}>
                                            お客様の支払い方法の管理
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>                   
                </Grid>
            </div>
        </div>
    )
}

export default ManagementScreen