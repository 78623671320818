import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem} from "@material-ui/core";
import { FormLabel, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";

//action
import { checkout } from "../../actions/stripeAction";
import { subscription_register } from "../../actions/stripeAction";
import { issuing_list } from "../../actions/couponAction";
import { coupon_discount } from "../../actions/couponAction";
import { coupon_discount_delete } from "../../actions/couponAction";
//compoennt
import CreditInfoComponent from "./CreditInfoComponent";
import AddressInfoComponent from "./AddressInfoComponent";
import Loader from "../Loader";


const useStyles = makeStyles((theme) => ({
    accordion:{
        "&:before":{
            backgroundColor: 'initial'
        }
    },
    address_container:{
    },
    address_info:{
        margin:theme.spacing(1)
    },
    billing:{
        marginTop: theme.spacing(1)
    },
    button:{
        color:'black',
        backgroundColor: theme.palette.primary['300'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
        
    },
    card:{
        color: 'black',
        backgroundColor: 'white'
    },
    caption:{
        color:theme.palette.primary['600']
    },
    credit_brand:{
        display: 'flex'
    },
    credit_info:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    divider:{
        background: theme.palette.primary['700'],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    image:{
        [theme.breakpoints.down('xs')]:{
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('xs')]:{
            marginRight: theme.spacing(2)
        },
        width:'40px'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'25%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    order:{
        marginBottom: theme.spacing(3)
    },
    title:{
        marginBottom: theme.spacing(3),
    }
}))

//住所などcard情報が取得できる場合には自動的に最終ページの確認画面のみとする
export default function ConfirmationScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    //const location = useLocation()
    const {id} = useParams()
    const plan = id

    //useState
    const [delivery, setDelivery] = useState('指定なし')
    //couponを選択した場合にはtotla_priceの編集をする必要がある
    const [coupon, setCoupon] = useState('クーポンが選択されていません')

    //useSelector
    const {accountPublicInfo} = useSelector(state => state.accountPublicInfo)
    const {credit_info} = useSelector(state => state.secretInfo)
    const {adressInfo} = useSelector(state => state.adress)
    const {cart} = useSelector(state => state.cart)
    const { cartItem } = useSelector(state => state.cartItemDB)
    const {reservation_message, reservation_loading} = useSelector(state => state.reservation)
    const {issuing, error} = useSelector(state => state.issuing)
    const {discount_price} = useSelector(state => state.discount)
    const {reservation_day} = useSelector(state => state.reservationDay)

     const handleChange = (event) => {
        setDelivery(event.target.value)
     }

     const handleDiscountChange = (event) => {
        setCoupon(event.target.value)
        if(event.target.value == 'クーポンが選択されていません'){
            dispatch(coupon_discount_delete())
        }else{
            dispatch(coupon_discount(event.target.value, cart.total_price))
        }
     }

    //一回払いのcheckout subscriptionについての決済の確定を行う。
    const handleCheckout = () => {
        switch(location.pathname){
            case '/checkout/rental':
                if(coupon == 'クーポンが選択されていません'){
                    dispatch(checkout(accountPublicInfo.email, adressInfo.id, credit_info.payment_method_id, discount_price ? discount_price['price'] : cart.total_price, cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'], plan, cart.id, reservation_day.start_day, reservation_day.end_day, delivery))
                }else{
                    dispatch(checkout(accountPublicInfo.email, adressInfo.id, credit_info.payment_method_id, discount_price ? discount_price['price'] : cart.total_price, cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'], plan, cart.id, reservation_day.start_day, reservation_day.end_day, delivery, coupon))
                }
                window.gtag('event', 'purchase', {
                    currency:'jpy',
                    value: discount_price ? discount_price['price'] : cart.total_price,
                    transaction_id:cart.id,
                    items:[{
                        
                    }]
                })
                break;
            case '/checkout/basic/':{
                const split_word = location.search ? location.search.split('='):''
                const price_id = split_word[1]
                const price = split_word[2]
                dispatch(subscription_register(accountPublicInfo.email, price_id, plan, credit_info.payment_method_id, price, delivery))
                window.gtag('event', 'purchase', {
                    currency:'jpy',
                    value:price,
                    items:[{
                        id: price_id,
                        name: plan,
                        category: 'subscription',
                        price: price
                    }]
                })
                break;
            }
            case  '/checkout/premium/':{
                const split_word = location.search ? location.search.split('='):''
                const price_id = split_word[1]
                const price = split_word[2]
                dispatch(subscription_register(accountPublicInfo.email, price_id, plan, credit_info.payment_method_id, price, delivery))

                window.gtag('event', 'purchase', {
                    currency:'jpy',
                    value:price,
                    items:[{
                        id: price_id,
                        name: plan,
                        category: 'subscription',
                        price: price
                    }]
                })
                break;
            }


        }
    }

    useEffect(() => {
        dispatch(coupon_discount_delete())
        if(cartItem.length == 0){
            history.push('/cart/your')
        }
        if(cartItem.length == 0){
            history.push('cart/your/')
        }
        if(location.pathname == '/checkout/rental'){
            dispatch(issuing_list('rental'))
        }else{
            dispatch(issuing_list('subscription'))
        }

        if(reservation_message){
            setTimeout(
                function(){ history.push('/')}, 200
            )
           
        }
    },[reservation_message])
    return(
        reservation_loading ?
        <div className={classes.loader}>
             <Loader/>
        </div>
        :
        <div className={classes.root}>
            <Typography variant="h1" component="h1" align="center" className={classes.title}>
                ③予約確定
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={8}>
                    <Card className={classes.card}>
                        <CardContent className={classes.card_content}>
                            {
                                location.pathname === "/checkout/rental" ?
                                <>
                                <Typography variant="h2" component="h2" >
                                    お届け情報
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.credit_info}>
                                    お届け日：{reservation_day ? (reservation_day.start_day.getMonth() + 1) + '月' + reservation_day.start_day.getDate() + '日' : ''}
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.credit_info}>
                                    お届け時間の指定
                                </Typography>
                                <div>
                                    <FormControl required component="fieldset">
                                        <Select
                                         labelId="delevery_time_label"
                                         id="delevery_time"
                                         value={delivery}
                                         defaultValue={'指定なし'}
                                         onChange={handleChange}
                                         >
                                            <MenuItem value={'指定なし'}>指定なし</MenuItem>
                                            <MenuItem value={'午前中'}>午前中</MenuItem>
                                            <MenuItem value={'14時〜16時頃'}>14時〜16時頃</MenuItem>
                                            <MenuItem value={'16時〜18時頃'}>16時〜18時頃</MenuItem>
                                            <MenuItem value={'18時〜20時頃'}>18時〜20時頃</MenuItem>
                                            <MenuItem value={'19時〜21時頃'}>19時〜21時頃</MenuItem>
                                         </Select>
                                    </FormControl>
                                </div>
                                <Divider className={classes.divider}/>
                                </>
                            :
                            ''
                            }
                            <Typography variant="h2" component="h2">
                                    クーポン
                            </Typography>
                            <Accordion className={classes.accordion} style={{boxShadow: 'initial', }}>
                                <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{padding: 'initial'}}
                                >
                                {
                                    issuing ?
                                    <Typography variant="subtitle1" component="p">{"使用できるクーポンがあります。"}</Typography>
                                    :
                                    <Typography variant="subtitle1" component="p">{"使用できるクーポンはありません。"}</Typography>
                                }
                                </AccordionSummary>
                                <AccordionDetails style={{padding: 'initial'}}>
                                {
                                    error ?
                                    <Typography variant="subtitle1" component="div">
                                        クーポンは存在しません。
                                    </Typography>
                                    :
                                    issuing ?
                                    <div className={classes.form}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" className={classes.form_label}>クーポン</FormLabel>
                                            <RadioGroup
                                            key="coupon"
                                            margin="normal"
                                            name="coupon"
                                            id="coupon"
                                            aria-label="coupon"
                                            value={coupon}
                                            onChange={handleDiscountChange}>
                                                <FormControlLabel value={'クーポンが選択されていません'} control={<Radio style={{color: 'initial'}}/>} label='クーポンが選択されていません'/>
                                                {
                                                    issuing.map((issue) => (
                                                        <>
                                                        <FormControlLabel value={issue.coupon.id} control={<Radio style={{color: 'initial'}}/>} label={issue.coupon.name + (issue.coupon.amount_off !== 0 ? issue.coupon.amount_off + '円割引' : issue.coupon.percent_off + '%割引')}/>
                                                        </>
                                                    ))
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    :
                                    <Typography variant="subtitle1" component="div">
                                        クーポンはありません。
                                    </Typography>
                                }
                              </AccordionDetails>
                            </Accordion>
                            <Divider className={classes.divider}/>
                            <Typography variant="h2" component="h2">
                                    住所情報
                            </Typography>
                            <AddressInfoComponent addressInfo={adressInfo}/>
                            <Divider className={classes.divider}/>
                            <div className={classes.credit_content}>
                                <Typography variant="h2" component="h2">
                                    お支払い情報
                                </Typography>
                                <div className={classes.credit_info}>
                                   <CreditInfoComponent creditInfo={credit_info}/>
                                </div>
                            </div>
                            <Divider className={classes.divider}/>
                        </CardContent>
                    </Card>
                </Grid>
                {
                location.pathname === '/checkout/basic/'?
                <Grid item xs={12} sm={12} md={4}>
                        <Card className={classes.card}>
                            <CardContent className={classes.card_content}>
                                <Button fullWidth className={classes.button} onClick={handleCheckout}>
                                    登録を確定
                                </Button>
                                <Divider className={classes.divider}/>
                                <Typography variant="h2" component="h2" className={classes.order}>
                                    注文内容:
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    登録プラン: ベーシックプラン
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    月額料金: {plan === 'basic' ? 7800 : 14800} 円 
                                </Typography>
                            </CardContent>
                        </Card> 
                    </Grid>
                    :
                    location.pathname ===  '/checkout/premium/' ?
                    <Grid item xs={12} sm={12} md={4}>
                        <Card className={classes.card}>
                            <CardContent className={classes.card_content}>
                                <Button fullWidth className={classes.button} onClick={handleCheckout}>
                                    登録を確定
                                </Button>
                                <Divider className={classes.divider}/>
                                <Typography variant="h2" component="h2" className={classes.order}>
                                    注文内容:
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    登録プラン: プレミアムプラン
                                </Typography>
                                <Typography variant="subtitle1" component="p">
                                    月額料金: {plan === 'basic' ? 7800 : 14800} 円 
                                </Typography>
                            </CardContent>
                        </Card> 
                    </Grid>
                    :
                    <Grid item xs={12} sm={12} md={4}>
                    <Card className={classes.card}>
                        <CardContent className={classes.card_content}>
                            <Button fullWidth className={classes.button} onClick={handleCheckout}>
                            予約を確定
                            </Button>
                            <Typography variant="caption" component="div" className={classes.caption}>
                                「予約を確定」ボタンを押してご注文いただくことで、
                                お客様は当サイトの各種規約、プライバシー規約および当サイト上のレンタル条件に同意の上、
                                商品を予約されたことになります。 価格、配送手数料については必ず商品ページおよびこちらをご確認ください
                            </Typography>
                            <Divider className={classes.divider}/>
                            <Typography variant="h2" component="h2">
                                注文内容:
                            </Typography>
                            <Typography variant="subtitle1" component="p">
                                商品の小計: ¥{cart.total_price}
                            </Typography>
                            <Typography variant="subtitle1" component="p">
                                配送料: ¥{cartItem.length != 0 ? cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'] : ''}
                            </Typography>
                            {
                                discount_price ?
                                <Typography variant="subtitle1" component="p">
                                    クーポン割引: ¥{cart.total_price - discount_price.price}
                                </Typography>
                                :
                                ''
                            }
                            <Typography variant="subtitle1" component="p">
                                合計: ¥{ discount_price ? discount_price['price'] + (cartItem.length != 0 ? cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'] : '') : cart.total_price + (cartItem.length != 0 ? cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'] : '')}
                            </Typography>
                            <Typography variant="h2" component="h2" className={classes.billing}>
                                ご請求額: ¥{ discount_price ? discount_price['price'] + (cartItem.length != 0 ? cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'] : '') : cart.total_price + (cartItem.length != 0 ? cart.cartitems > 1 ? 1000 : cartItem[0]['product']['price']['shipping']['shipping_price'] : '')}
                            </Typography>
                            <Divider className={classes.divider}/>
                        </CardContent>
                    </Card>
                </Grid>
                    
                }
                
            </Grid>
        </div>
    )
}
