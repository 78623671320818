import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

//component
import Loader from '../../components/Loader';
import AddressForm from '../AdressFormComponent';

//action
import { get_adress } from '../../actions/adressAction';
import { update_adress } from '../../actions/adressAction';


//adressのscreenが直に表示されてしまっているため
const useStyles = makeStyles((theme) => ({
    card:{
        backgroundColor:'white',
        color:'black',
        marginTop: theme.spacing(10)
    },
    font:{
        marginBottom: theme.spacing(2)
    },
    modal:{
        marginTop: theme.spacing(5)
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,3,15,3)
        }
    },
    title:{
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    },
    topic_path:{
        display:'flex'
    },
    topic_path_button:{

    }
}));



export const Adress = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory()
   

    //useSelector
    const userAdress = useSelector(state => state.adress)
    const {adressInfo, loading, error} = userAdress

    //modalを開くhandle
    const handleUpdate = () => {
        history.push('/address_update')
    }

    const handleAccountService = () => {
        history.push('/management')
    }

    //useEffectがうまく起動していないので修正action
    //useEffectでリクエストを受けるたびにget_adressでadress情報を取得する
    useEffect(() => {
        dispatch(get_adress())
    },[dispatch, update_adress])


    return (
        <>
        <div className={classes.root}>
        <   div className={classes.topic_path}>
                <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                        {'住所'}
                </Typography>
            </div>
            {
                loading ?
                <div className={classes.loader}>
                    <Loader/>
                </div>
                :
                error ?
                <div className={classes.root}>
                    <Container maxWidth={"md"} className={classes.container}>
                        <Card className={classes.card}>
                            <CardContent>
                                <AddressForm/> 
                            </CardContent>
                        </Card>
                    </Container>
            </div>
                :
                adressInfo ?
                adressInfo.prefecture == null || adressInfo.region == null ?
                    <div>
                        <Container maxWidth={"md"} className={classes.container}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <AddressForm/> 
                                </CardContent>
                            </Card>
                        </Container>
                    </div>
                :
                <Container maxWidth={'md'} style={{
                    padding:'initial'
                }}>
                    <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h2" component="h2"  align="center" className={classes.title}>
                                    アドレス情報
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                    郵便番号: {adressInfo ? adressInfo.postalcode : ''}
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                    {adressInfo ? '都道府県：' + adressInfo.prefecture  : '住所を登録して下さい'}
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                    {adressInfo ? '市区町村:' + adressInfo.region : '住所を登録して下さい'}
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                    {adressInfo ? '以下の住所：' + adressInfo.region + adressInfo.address : '住所を登録して下さい'}
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                    {adressInfo ? '建物名・部屋番号：' + adressInfo.building_name  : '住所を登録して下さい'}
                                </Typography>
                                <Button onClick={handleUpdate} style={{color:'#2196f3', marginTop:2}}>変更</Button>
                            </CardContent>
                    </Card>
                </Container>
                :
                ''
             }
        </div>
        </>
    )
}

