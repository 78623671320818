import {
    ACCOUNT_REGISTER_REQUEST,
    ACCOUNT_REGISTER_SUCCESS,
    ACCOUNT_REGISTER_FAIL,
} from '../constants/accountConstants'

export const accountRegisterReducer = (state = {}, action) => {
    switch(action.type){
        case ACCOUNT_REGISTER_REQUEST:
            return {loading: true}
        case ACCOUNT_REGISTER_SUCCESS:
            return { loading: false, accountInfo: action.payload}
        case ACCOUNT_REGISTER_FAIL:
            return { loading: false, error: action.payload}
        default:
            return state
    }
}