import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import { order_item_get } from "../actions/buyingAction";
import Loader from "../components/Loader";

import { baseAxios } from "../jwtInterceptor";

const useStyles = makeStyles((theme) => ({
    button:{
        color: 'black',
        background: 'white'
    },
    divider:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    error:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(20, 2, 20, 2)
        },        
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(20, 3, 20, 3)
        },
    },    
    image:{
        width: '100%',
        height:"100%",
        borderRadius:'1%'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    order_divider:{
        marginBottom: theme.spacing(2)
    },
    product_description:{
        [theme.breakpoints.up("md")]:{
            display: 'flex'
        }
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    title:{
        marginBottom: theme.spacing(3)
    }
}))

function get_reciept(id, setState, setLoading, setError){
    setLoading(true)
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    baseAxios.get(`/api/stripe/buying/receipt/?order=${id}`).then(
        (response) => {
            setLoading(false)
            setState(response['data'])
        }
    ).catch(
        () => {
            setLoading(false)
            setError(true)
        }
    )
}

export default function OrderItemHistoryScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const {id} = useParams()

    const [receipt, setReceipt] = useState('')
    const [receiptLoading, setReceiptLoading] = useState(false)
    const [receiptError, setReceiptError] = useState(false)

    const {loading, orderItem, error} = useSelector(state => state.orderItem)

    const day = new Date(orderItem ? orderItem.created_at : '')
    const ordered_day = day.getFullYear() + '年' + (day.getMonth() + 1) + '月' + day.getDate() + '日'

    const handleClick = () => {
        window.location.replace(receipt['invoice_url'])
    }

    useEffect(() => {
        dispatch(order_item_get(id))
        get_reciept(id, setReceipt, setReceiptLoading, setReceiptError)
    },[])

    return(
        loading & receiptLoading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        error ?
        <div className={classes.error}>
            <Typography variant="h2" component="h2" align="center">
                {error}
            </Typography>
        </div>
        :
        orderItem ?
        <div className={classes.root}>
            <Container maxWidth={"md"} style={{padding: 'initial'}}>
                <Typography variant="h1" component="h1" align="center" className={classes.title}>
                    アイテム購入履歴
                </Typography>
                <Typography variant="subtitle1" component="p" align="right">
                    注文番号:&ensp;&ensp;&ensp;{orderItem.order.order_id}
                </Typography>
                <Divider classes={{root:classes.order_divider}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                        <img src={orderItem.reservation_item.product.img} alt={orderItem.reservation_item.product.product_name} className={classes.image}/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7}>
                        <Typography variant="h2" component="h2">
                            {orderItem.reservation_item.product.product_name}
                        </Typography>
                        <Divider classes={{root:classes.divider}}/>
                        <div className={classes.product_description}>
                            <Typography variant="subtitle1" component="p" className={classes.product_name}>
                            注文済み：
                            </Typography>
                            <div className={classes.content}>
                                <Typography variant="subtitle1" component="p" className={classes.price}>
                                    {ordered_day}
                                </Typography>
                            </div>
                        </div>
                        <Divider classes={{root:classes.divider}}/>
                        <div className={classes.content}>
                            <Typography variant="subtitle1" component="p" className={classes.caption}>
                                合計金額（税込）
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.price}>
                                {orderItem.order.total_price} 円
                            </Typography>
                        </div>
                        <Divider classes={{root:classes.divider}}/>
                        {
                            receiptError ?
                            ''
                            :
                            <Button fullWidth className={classes.button} onClick={handleClick}>
                                領収書を依頼
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Container>
        </div>
        :
        ''
    )

}