export const STRIPE_PLAN_REQUEST = 'STRIPE_PLAN_REQUEST'
export const STRIPE_PLAN_SUCCESS = 'STRIPE_PLAN_SUCCESS'
export const STRIPE_PLAN_FAIL = 'STRIPE_PLAN_FAIL'

export const SUBSCRIPTION_INFO_REQUEST = 'SUBSCIRPTION_INFO_REQUEST'
export const SUBSCRIPTION_INFO_SUCCESS = 'SUBSCRIPTION_INFO_SUCCESS'
export const SUBSCRIPTION_INFO_FAIL = 'SUBSCRIPTION_INFO_FAIL'

//checkout
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILED = 'CHECKOUT_FAILED'

//userのcredit情報を取得する
export const USER_SECRET_INFO_REQUEST = 'USER_SECRET_INFO_REQUEST'
export const USER_SECRET_INFO_SUCCESS = 'USER_SECRET_INFO_SUCCESS'
export const USER_SECRET_INFO_FAIL = 'USER_SECRET_INFO_FAIL'

export const CREDIT_REGISTER_REQUEST = 'CREDIT_REGISTER_REQUEST'
export const CREDIT_REGISTER_SUCCESS = 'CREDIT_REGISTER_SUCCESS'
export const CREDIT_REGISTER_FAIL = 'CREDIT_REGISTER_FAIL'

//stripe elementのcard情報をstoreで管理する
export const STRIPE_ELEMENT_REGISTER = 'STRIPE_ELEMENT_REGISTER '
export const STRIPE_ELEMENT_REGISTER_FAIL = 'STRIPE_ELEMENT_REGISTER_FAIL'

//stripe elementカード番号を取得
export const CARD_ELEMENT_SAVE = 'CARD_ELEMENT_SAVE'

//stirpe card情報の削除するようにする
export const CARD_ELEMENT_DELETE = 'CARD_ELEMENT_DELETE'
export const CARD_ELEMENT_DELETE_FAIL = 'CARD_ELEMENT_DELETE_FAIL'

export const CARD_ELEMENT_UPDATE_REQUEST = 'CARD_ELEMENT_UPDATE_REQUEST'
export const CARD_ELEMENT_UPDATE_SUCCESS = 'CARD_ELEMENT_UPDATE_SUCCESS'
export const CARD_ELEMENT_UPDATE_FAIL = 'CARD_ELEMENT_UPDATE_FAIL'

//サブスクリプションの将来的な支払いのdataを取得
export const SUBSCRIPTION_UPCOMING_INVOICE_REQUEST = 'SUBSCRIPTION_UPCOMING_INVOICE_REQUEST'
export const SUBSCRIPTION_UPCOMING_INVOICE_SUCCESS = 'SUBSCRIPTION_UPCOMING_INVOICE_SUCCESS'
export const SUBSCRIPTION_UPCOMING_INVOICE_FAIL = 'SUBSCRIPTION_UPCOMING_INVOICE_FAIL'

//subscription加入ユーザの予約するための処理
export const SUBSCRIPTION_RESERVATION_REQUEST = 'SUBSCRIPTION_RESERVATION_REQUEST'
export const SUBSCRIPTION_RESERVATION_SUCCESS = 'SUBSCRIPTION_RESERVATION_SUCCESS'
export const SUBSCRIPTION_RESERVATION_FAIL = 'SUBSCRIPTION_RESERVATION_FAIL'

//subscription invoiceを取得する
export const SUBSCRIPTION_INVOICE_REQUEST = 'SUBSCRIPTION_INVOICE_REQUEST'
export const SUBSCRIPTION_INVOICE_SUCCESS = 'SUBSCRIPTION_INVOICE_SUCCESS'
export const SUBSCRIPTION_INVOICE_FAIL = 'SUBSCRIPTION_INVOICE_FAIL'

export const INVOICE_REQUEST = 'INVOICE_REQUEST'
export const INVOICE_SUCCESS = 'INVOICE_SUCCESS'
export const INVOICE_FAIL = 'INVOICE_FAIL '

export const INVOICE_CLEAR = 'INVOICE_CLEAR'

//subscriptionに登録するため
export const SUBSCRIPTION_REGISTER_REQUEST = 'SUBSCRIPTION_REGISTER_REQUEST'
export const SUBSCRIPTION_REGISTER_SUCCESS = 'SUBSCRIPTION_REGISTER_SUCCESS'
export const SUBSCRIPTION_REGISTER_FAIL = 'SUBSCRIPTION_REGISTER_FAIL'

//subscriptionプランを更新する
export const SUBSCRIPTION_UPDATE_REQEUST = 'SUBSCRIPTION_UPDATE_REQEUST'
export const SUBSCRIPTION_UPDATE_SUCCESS = 'SUBSCRIPTION_UPDATE_SUCCESS'
export const SUBSCRIPTION_UPDATE_FAIL = 'SUBSCRIPTION_UPDATE_FAIL'

//subscriptionプランを解約する
export const SUBSCRIPTION_CANCEL_REQUEST = 'SUBSCRIPTION_CANCEL_REQUEST'
export const SUBSCRIPTION_CANCEL_SUCCESS = 'SUBSCRIPTION_CANCEL_SUCCESS'
export const SUBSCRIPTION_CANCEL_FAIL = 'SUBSCRIPTION_CANCEL_FAIL'

//rentalプランの場合のcheckoutを保存して確定時に使用する

export const SUBSCRIPTION_PAYMENT_INFO_SAVE = 'SUBSCRIPTION_PAYMENT_INFO_SAVE'

//card情報リストの取得
export const CREDIT_LIST_REQUEST = 'CREDIT_LIST_REQUEST'
export const CREDIT_LIST_SUCCESS = 'CREDIT_LIST_SUCCESS'
export const CREDIT_LIST_FAIL    = 'CREDIT_LIST_FAIL'

//取得したメッセージをクリアする
export const RESERVATION_MESSAGE_CLEAR = 'RESERVATION_MESSAGE_CLEAR'