import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import EmailUpdateForm from "../components/EmailUpdateForm";
import Message from '../components/Message'

const useStyles = makeStyles((theme) => ({
    container:{
        padding: 'initial'
    },
    message:{
        marginBottom: theme.spacing(1)
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3, 3, 15, 3)
        }
    },
    return_button:{
        color: 'white',
        backgroundColor: 'initial',
        marginTop: theme.spacing(1),
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    topic_path:{
        display:'flex',
        marginBottom: theme.spacing(3)
    },
    topic_path_button:{
        '&:hover':{
            backgroundColor:'initial'
        }
    },
}))

function EmailUpdateScreen(){
    const classes = useStyles()
    const history = useHistory()
    const {message, error} = useSelector(state => state.email)

    const handleAccountService = () => {
        history.push('/management')
    }

    const handleAccountInfo = () => {
        history.push('/security')
    }

    const handleGoback = () => {
        history.goBack()
    }

    return(
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Button onClick={handleAccountInfo} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウント情報
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    {'Eメールの更新'}
                </Typography>
            </div>
            <Container maxWidth={"md"} className={classes.container}>
                {message ? <div className={classes.message}><Message>{message}</Message></div>:''}
                {error ? <div className={classes.message}><Message>{error}</Message></div>:''}
                <EmailUpdateForm/>
                <Button className={classes.return_button} onClick={handleGoback} disableFocusRipple={true} disableRipple={true}>
                    戻る
                </Button>
            </Container>
        </div>
    )
}

export default EmailUpdateScreen