import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { BsChevronLeft } from 'react-icons/bs';
import { Card } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Container } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import { delete_cloth } from "../actions/closetAction";

import Loader from "./Loader";

const useStyles = makeStyles((theme) => ({
    card:{
        color:'black',
        backgroundColor:'white',
        display:'flex',
        height: '500px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    card_content:{

    },
    cloth:{
        position:'relative',
        display:'flex',
        color:'black',
        backgroundColor:'white',
        width:'100%'
    },
    container:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        padding:'initial'
    },
    description:{
        marginBottom: theme.spacing(2)
    },
    icon_button:{
        padding:'initial',
        '&:hover':{
            backgroundColor:'white'
        }
    },
    input:{
        position:'absolute',
        top:'45%',
        right:'0%',
        zIndex:1000
    },
    icon:{
        color:'black'
    },
    list:{
       color:'black',
       background:'white',
       padding:'initial'
    },
    list_item:{
        display:'flex',
        padding:'initial',
        margin:'initial',
        width:'100%',
        marginBottom: theme.spacing(2)
    },
    loader:{
        marginTop:'50%',
        marginLeft: '42%',
        minHeight:'100vh',
        minWidth:'100vw'
    },
    media:{
       width:'100%'
    },
    media_container:{
        width:'100px'
    },
    title:{
        fontWeight:'bold',
        marginBottom: theme.spacing(2)
    },
}))


//dispatchされた時に削除した差分分だけproductを表示する必要がある
const ClothListComponent = ({closetInfo, closetState}) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const { cloth } = useSelector(state => state.clothList)
    const {loading, error} = useSelector(state => state.clothList)

    const handelClothProduct = (product_id) => {
        history.push(`product/${product_id}`)
    }

    const handleClothDelete = (id) => {
        dispatch(delete_cloth(id, closetInfo.id))
    }

    const handleClose = () => {
        closetState(false)
    }
    

    return(
        <Container maxWidth={'sm'} className={classes.container}>
            
            {
            error ?
            <Typography variant="subtitle1" component="p" align="center">
                アイテムを取得できませんでした
            </Typography>
            :
            <Card className={classes.card}>
                <CardContent style={{width:'100%'}}>
                {
                loading ?
                    <div className={classes.loader}>
                        <Loader/>
                    </div>
                    :
                    <>
                    <IconButton
                     onClick={handleClose}
                     disableRipple={true}
                     disableFocusRipple={true}
                     className={classes.icon_button}
                     size="medium"
                     color="inherit"
                     >
                         <BsChevronLeft/>
                     </IconButton>
                    <Typography variant="h2" component="h2" align="center" className={classes.title} >
                        {closetInfo.closet_name}
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.description}>
                        {closetInfo.description}
                    </Typography>
                    <List className={classes.list}>
                    {
                    cloth ?
                    cloth.map((clo) => (
                        <ListItem key={clo.id} className={classes.list_item}>
                            <Card className={classes.cloth}>
                                <div className={classes.media_container}>
                                    <CardMedia className={classes.media} component="img" image={clo.product.img} title={clo.product.product_name}/>
                                </div>
                                <CardActionArea onClick={()=>{handelClothProduct(clo.product.id)}}>
                                    <CardContent className={classes.card_content}>
                                        <Typography variant="subtitle2" component="p" className={classes.product_name}>
                                            {clo.product.product_name}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <IconButton
                                 onClick={() => {handleClothDelete(clo.id)}}
                                 disableRipple
                                 disableFocusRipple
                                 className={classes.input}
                                 >
                                     <DeleteIcon className={classes.icon}/>
                                 </IconButton>
                            </Card>
                        </ListItem>
                    ))
                    :
                    ''
                    }
                    </List>
                    </>
                }
                </CardContent>
                
            </Card>
            }
        </Container>
    )
}

export default ClothListComponent