import React, { useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { useLocation} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { CardActionArea } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../components/Loader'
import { List } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import Message from '../components/Message'
import Typography from '@material-ui/core/Typography';

//media
import all_category from '../image/category1.png'

//action
import { search_category } from '../actions/productActions';
import { product_category } from '../actions/productActions';
import { brand_category_get } from '../actions/categoryAction';
import { brand_products_get } from '../actions/categoryAction';
import { brand_type_get } from '../actions/categoryAction';
import { get_tag_category } from '../actions/productActions';
import { get_tag_product } from '../actions/productActions';
import { get_tag_type } from '../actions/productActions';
import { type_get } from '../actions/categoryAction';
import { search_type } from '../actions/productActions';
import { listProducts } from '../actions/productActions';
import { search_products } from '../actions/productActions';
//gender filter
import { brand_gender_data_save } from '../actions/categoryAction';
import { gender_data_save } from '../actions/productActions';
import { search_gender_data_save } from '../actions/productActions';
import { product_type } from '../actions/productActions';
import { tag_gender_data_save } from '../actions/productActions';
import { type_category_get } from '../actions/categoryAction';
const useStyles = makeStyles((theme) => ({
    card:{
        backgroundColor: 'transparent',
        boxShadow:'none',
        [theme.breakpoints.down('sm')]:{
            width:'70px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            width:'72px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]:{
            width:'74px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('lg')]:{
            width:'77px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
    },
    list:{
        display:'flex',
        overflow: 'hidden',
        overflowX:'scroll',
        padding: 'initial'
    },
    list_item:{
        padding:'initial',
        width:'initial',
    },
    cardMedia:{
        borderRadius:'50%',
        '&:hover':{
            opacity: '0.5'
        },
        [theme.breakpoints.down('sm')]:{
            width:'70px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            width:'72px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]:{
            width:'74px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('lg')]:{
            width:'77px',
            height:'100%',
            marginRight: theme.spacing(1)
        },
    },
    cardHeader:{
        textAlign:'center',
        fontSize:'10px'
    },
    category:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1,1,2,1),
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(1,2,2,2),
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(1,3,2,3),
        },
        color:'black'
    },
    category_name:{
        textAlign:'center',
    },
    media:{
        borderRadius:'50%',
        [theme.breakpoints.down('sm')]:{
            width:'70px',
            height:'70px',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            width:'72px',
            height:'72px',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]:{
            width:'74px',
            height:'74px',
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('lg')]:{
            width:'77px',
            height:'74px',
            marginRight: theme.spacing(1)
        },
    }
}))

//選択したカテゴリー一覧にリダイレクトさせる
function Category({keyword, brand_name, handle, handleTitle, pagination}){
    const dispatch = useDispatch()
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()

    const categories_list = useSelector(state => state.category)
    const {category_loading, category_error, categories} = categories_list
    
    //parameterにtagやbrandなどの識別子を付与してidを格納するようにする、url部分には名刺を利用するようにするs
    const handleClick = async(id, category_name) => {
        handle()
        handleTitle(category_name)
        if(category_name === 'トップス' || category_name === 'ジャケット' || category_name === 'コート' 
            || category_name === 'パンツ' || category_name === 'スカート' || category_name === 'ワンピース'
            || category_name === 'スーツ' || category_name === 'バッグ' || category_name === 'シューズ' 
            || category_name === 'ドレス' || category_name === 'ハット' || category_name === 'アクセサリー' 
            || category_name === 'ルームウェア' 
            || category_name === 'その他'
        ){
            dispatch(type_category_get(id))
            switch(location.pathname){
                case '/':
                    //こちらの処理もcategoryとtypeに分岐する必要がある
                    history.push(`/?page=1&title=${category_name}`)
                    await dispatch(product_type(id, 1))
                    dispatch(gender_data_save())
                    break;
                case `/search/${keyword}/`:{
                    history.push(`/search/${keyword}/?page=1&title=${category_name}`)
                    await dispatch(search_type(keyword, id, 1))
                    dispatch(search_gender_data_save())
                    break;
                }
                case `/brand/${brand_name}/`:{
                    history.push(`/brand/${brand_name}/?number=${keyword}&page=1&title=${category_name}`)
                    await dispatch(brand_type_get(keyword, id, 1))
                    dispatch(brand_gender_data_save())
                    break;
                }
                case `/tag/${keyword}/`:{
                    history.push(`/tag/${keyword}/?page=1&title=${category_name}`)
                    await dispatch(get_tag_type(keyword, id, 1))
                    dispatch(tag_gender_data_save())
                    break;
                }
                default:
                    return
            }
        }else{
            handleTitle(category_name)
            switch(location.pathname){
                case '/':
                    history.push(`/?page=1&title=${category_name}`)
                    //こちらの処理もcategoryとtypeに分岐する必要がある
                    await dispatch(product_category(id, 1))
                    dispatch(gender_data_save())
                    return;
                case `/search/${keyword}/`:{
                    history.push(`/search/${keyword}/?page=1&title=${category_name}`)
                    await dispatch(search_category(keyword, id, 1))
                    dispatch(search_gender_data_save())
                    return;
                }
                case `/brand/${brand_name}/`:{
                    history.push(`/brand/${brand_name}/?number=${keyword}&page=1&title=${category_name}`)
                    await dispatch(brand_category_get(keyword, id, 1))
                    dispatch(brand_gender_data_save())
                    return;
                }
                case `/tag/${keyword}/`:{
                    history.push(`/tag/${keyword}/?page=1&title=${category_name}`)
                    await dispatch(get_tag_category(keyword, id, 1))
                    dispatch(tag_gender_data_save())
                    return;
                }
            }
        }
    }

    const handleAll = async() => {
        handle()
        dispatch(type_get())
        handleTitle('新着')
        pagination(1)
        switch(location.pathname){
            case '/':
                history.push('/?page=1&title=新着')
                await dispatch(listProducts(1))
                dispatch(gender_data_save())
                return
            case `/search/${keyword}/`:
                history.push(`/search/${keyword}/?page=1&title=新着`)
                await dispatch(search_products(keyword, 1))
                dispatch(search_gender_data_save())
                return
            case `/brand/${brand_name}/`:
                history.push(`/brand/${brand_name}/?number=${keyword}&page=1&title=新着`)
                await dispatch(brand_products_get(keyword, 1))
                dispatch(brand_gender_data_save())
                return
            case `/tag/${keyword}/`:
                history.push(`/tag/${keyword}/?page=1&title=新着`)
                await dispatch(get_tag_product(keyword, 1))
                dispatch(tag_gender_data_save())
                return
        }

    }

    useEffect(() => {
       dispatch(type_get())
    },[])

    return(
        <div className={classes.category}>   
            {
                category_error ?
                    <Message>{category_error['message']}</Message>
                    :
                    <List className={classes.list}>
                    <ListItem className={classes.list_item} key="all_category">
                        <Card className={classes.card}>
                            <CardContent className={classes.category_name} style={{padding: 'initial'}}>
                                <Typography variant='subtitle2' component="p" noWrap style={{textAlign:'center'}}>
                                    すべて
                                </Typography>
                                <div>
                                    <CardActionArea onClick={handleAll} className={classes.media}>
                                        <CardMedia
                                            component="img"
                                            className={classes.cardMedia}
                                            src={all_category}
                                            title="すべて"
                                            alt="すべて"
                                        />
                                    </CardActionArea> 
                                </div>
                            </CardContent>
                        </Card>
                    </ListItem>
                    {[categories || []].map((category) => (
                            category.map((cate) => (
                            category_loading ?
                            <Loader/>
                            :
                                <ListItem className={classes.list_item} key={cate.id}> 
                                <Card className={classes.card} >
                                    <CardContent className={classes.category_name} style={{padding: 'initial'}}>
                                        <Typography variant='subtitle2' component="p" noWrap style={{textAlign:'center'}}>
                                            {cate.category_name}
                                        </Typography>
                                    </CardContent>
                                    <div>
                                        <CardActionArea onClick={() => {handleClick(cate['id'], cate.category_name)}} className={classes.media}>
                                            <CardMedia
                                                component="img"
                                                className={classes.cardMedia}
                                                src={cate.image}
                                                title={cate.category_name}
                                                alt={cate.category_name}
                                            />
                                        </CardActionArea> 
                                    </div>
                                </Card>
                            </ListItem>
                            
                        ))
                    ))}

                </List>
            }
        </div>
    
    )
}

export default Category