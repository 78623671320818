import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//action
import { update_email } from '../actions/accountAction';

import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    button:{
        marginTop: theme.spacing(3),
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
    },
    card:{
        color: 'black',
        backgroundColor: 'white'
    },
    error:{
        marginBottom: theme.spacing(1)
    },
    input_text:{
        color:'black'
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    root:{
        marginTop: theme.spacing(10)
    }
}))

const validationSchema = yup.object({
    email: yup
    .string('')
    .email('正しい形式で入力してください')
    .required('Eメールを入力して下さい'),
    password: yup
    .string('')
    .min(8, 'パスワードは8文字以上です')
    .required('入力を入力して下さい'),
    new_email: yup
    .string('')
    .email('正しい形式で入力してください')
    .required('変更するEメールを入力して下さい'),
})

const EmailUpdateForm = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)

    const formik = useFormik({
        initialValues:{
            email:'',
            password:'',
            new_email: ''
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            dispatch(update_email(values.email, values.password, values.new_email, subscriptionInfo.plan))
        }
    })
    
    return(
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h3" component="h3" align='center'>
                        Eメール更新
                    </Typography>
                    <form className={classes.form} onSubmit={formik.handleSubmit}>
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Eメール"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    InputLabelProps={{
                        classes:{
                            root: classes.input_text
                        }
                    }}
                    InputProps={{
                        classes:{
                            notchedOutline:classes.text_field,
                            root: classes.text_field
                        }
                    }}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    />
                        <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="パスワード"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    InputLabelProps={{
                        classes:{
                            root: classes.input_text
                        }
                    }}
                    InputProps={{
                        classes:{
                            notchedOutline:classes.text_field,
                            root: classes.text_field
                        }
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    />
                    <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="new_email"
                    label="新しいEメール"
                    name="new_email"
                    autoComplete="new_email"
                    autoFocus
                    InputLabelProps={{
                        classes:{
                            root: classes.input_text
                        }
                    }}
                    InputProps={{
                        classes:{
                            notchedOutline:classes.text_field,
                            root: classes.text_field
                        }
                    }}
                    value={formik.values.new_email}
                    onChange={formik.handleChange}
                    error={formik.touched.new_email && Boolean(formik.errors.new_email)}
                    helperText={formik.touched.new_email && formik.errors.new_email}
                    />
                    <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.button}
                    >
                        更新
                    </Button>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default EmailUpdateForm


