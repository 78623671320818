import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    address_info:{
        marginTop:theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    font:{
        marginBottom:theme.spacing(1)
    },
    button:{
        color:'black',
        backgroundColor: theme.palette.primary['300'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
        
    },
}))

export default function AddressInfoComponent({addressInfo}){
    const classes = useStyles()
    const history = useHistory()

    const handleAddress = () => {
        history.push('/address_update')
    }
    return(
        <div className={classes.address_info}>
            <Typography variant="subtitle1" component="p" className={classes.font}>
                郵便番号: {addressInfo ? addressInfo.postalcode : ''}
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.font}>
                {addressInfo ? '都道府県：' + addressInfo.prefecture  : '住所を登録して下さい'}
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.font}>
                {addressInfo ? '市区町村:' + addressInfo.region : '住所を登録して下さい'}
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.font}>
                {addressInfo ? '以下の住所：' + addressInfo.region + addressInfo.address : '住所を登録して下さい'}
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.font}>
                {addressInfo ? '建物名・部屋番号：' + addressInfo.building_name  : '住所を登録して下さい'}
            </Typography>
            <Button fullWidth onClick={handleAddress} className={classes.button}>
                住所を変更する
            </Button>
        </div>
    )
}