import React, {useState} from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//import formik and yup
import { useFormik } from "formik";
import * as yup from 'yup';
//component
import Loader from "../Loader";

import { baseAxios } from "../../jwtInterceptor";

const useStyles = makeStyles((theme) => ({
    button:{
        color: 'white',
        backgroundColor: 'initial',
        marginTop: theme.spacing(1),
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    card:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(10),
    },
    forget:{
        marginTop: theme.spacing(1),
        color:'black'
    },
    input_text:{
        color:'black'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3, 3, 15, 3)
        }
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    topic_path:{
        display:'flex',
        marginBottom: theme.spacing(3)
    },
    topic_path_button:{
        '&:hover':{
            backgroundColor:'initial'
        }
    },
    submit:{
        marginTop:theme.spacing(2)
    }

}))

const validationSchema = yup.object().shape({
    password: yup
        .string('')
        .matches(/(?=.*[a-z])/, "小文字を含めてください")
        .matches(/(?=.*[0-9])/, "数字を含めてください")
        .min(8, 'パスワードは8文字以上です')
        .required('パスワードは入力必須です'),
    newPassword: yup
        .string('')
        .matches(/(?=.*[a-z])/, "小文字を含めてください")
        .matches(/(?=.*[A-Z])/, "大文字を含めてください")
        .matches(/(?=.*[0-9])/, "数字を含めてください")
        .min(8, 'パスワードは8文字以上です')
        .required('dパスワードは入力必須です'),
    })

const PasswordChangeScreen = () => {
    const history = useHistory()
    const classes = useStyles()

    //useState
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState(false)

    const gobackHandle = () => {
        history.goBack()
    }

    const handleAccountService = () => {
        history.push('/management')
    }

    const handleAccountInfo = () => {
        history.push('/security')
    }

    const forgetHandle = () => {
        history.push('/passwrod_reset/email/confirm')
    }

    const formik = useFormik({
        initialValues:{
            password:'',
            newPassword: ''
        },
        validationSchema:validationSchema,
        onSubmit: async(values) => {
            setLoading(true)
            baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
            const data = {
                'password': values.password,
                'new_password': values.newPassword
            }
            await baseAxios.post('/api/account/password/change',data).then(
                (response) => {
                    setMessage(response['data']['message'])
                    history.push('/security')
                }
            ).catch(
                (error) => {
                    setMessage(
                        error.response && error.response.data.message
                        ? 
                        error.response.data.message
                        :
                        error.message)
                }
            )
            setLoading(false)
        }
    })
    
    return(
        <div className={classes.root}>
            {
                loading ?
                <div className={classes.loader}>
                    <Loader/>
                </div>
                :
                <>
                    <div className={classes.topic_path}>
                        <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                            <Typography variant="subtitle2" component="p">
                                アカウントサービス
                            </Typography>
                        </Button>
                        <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                            {'＞'}
                        </Typography>
                        <Button onClick={handleAccountInfo} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                            <Typography variant="subtitle2" component="p">
                                アカウント情報
                            </Typography>
                        </Button>
                        <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                            {'＞'}
                        </Typography>
                        <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                            {'パスワードの更新'}
                        </Typography>
                    </div>
                    <Container maxWidth={"md"} style={{padding: 'initial'}}>
                        <Card className={classes.card}>
                            <CardContent>
                                {message && <Typography variant="subtitle1" component="p" align="center">{message}</Typography>}
                                <Typography variant="h2" component="h2" align="center">
                                    パスワード変更
                                </Typography>
                                <form className={classes.form} onSubmit={formik.handleSubmit}>
                                    <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="password"
                                    label="元のパスワード"
                                    type="password"
                                    id="password"
                                    InputLabelProps={{
                                        classes:{
                                            root: classes.input_text
                                        }
                                    }}
                                    InputProps={{
                                        classes:{
                                            notchedOutline:classes.text_field,
                                            root: classes.text_field
                                        }
                                    }}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    />
                                    <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    required
                                    name="newPassword"
                                    label="新しいパスワード"
                                    type="password"
                                    id="newPassword"
                                    InputLabelProps={{
                                        classes:{
                                            root: classes.input_text
                                        }
                                    }}
                                    InputProps={{
                                        classes:{
                                            notchedOutline:classes.text_field,
                                            root: classes.text_field
                                        }
                                    }}
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    error={formik.touched.newPassword&& Boolean(formik.errors.newPassword)}
                                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                                    />
                                    <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className={classes.submit}
                                    >
                                        変更
                                    </Button>
                                </form>
                                <Typography variant="subtitle1" align="right" component="div">
                                    <Button onClick={forgetHandle} className={classes.forget}>
                                        パスワードを忘れた方
                                    </Button>
                                </Typography>
                            </CardContent>
                        </Card>
                        <Button className={classes.button} onClick={gobackHandle} disableFocusRipple={true} disableRipple={true}>
                            戻る
                        </Button>
                    </Container>
                </>
            }
        </div>
        
    )
}

export default PasswordChangeScreen
