import React, { useState} from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Collapse } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import { ExpandMore } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Link } from "@material-ui/core";

//function
import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    button:{
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    card:{
        width: '100%',
        borderRadius: '10px',
        boxShadow: 'initial !important'
    },
    card_media:{
        width: '100%',
    },
    description:{
        marginBottom: theme.spacing(3)
    },
    grid:{
        marginBottom: theme.spacing(2)
    },
    help_title:{
        marginTop: theme.spacing(2)
    },
    list_item:{
        marginBottom: theme.spacing(3),
        borderBottom: 'solid 1px black'
    },
    main:{
        marginBottom: theme.spacing(10)
    },
    root:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(1,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin:theme.spacing(2,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,15,15,15)
        }
    },
    subtitle:{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        fontWeight: 'bold'
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5)
        },
        [theme.breakpoints.up('sm')]:{
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10),
        },
        textAlign: 'center',
        fontWeight: 'bold'
    },
    topic_path:{
        display: 'flex',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(1,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin:theme.spacing(2,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            marginTop: theme.spacing(3)
        }
    }
}))

//ヘルプセンター専用のsearch barを作成する
function HelpScreen(){
    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        history.push('/')
    }

    const [open, setOpen] = useState(false)
    const [question, setQuestion] = useState(false)
    const [question1, setQuestion1] = useState(false)
    const [question2, setQuestion2] = useState(false)
    const [question3, setQuestion3] = useState(false)
    const [question4, setQuestion4] = useState(false)


    //head data
    const title = "ヘルプセンター｜Enclopedia【公式サイト】"
    const description = "Enclopedia（アンクロペディア）ファッションレンタルサービスに関するヘルプページです。"
    const keyword = "ファッションレンタルサービス、サブスクリプション、Enclopedia、アンクロペディア、ヘルプ"

    headRewrite(title, description, keyword)

    const handleGuide1 = () => {
        history.push('/help_guide')
    }

    const handleGuide2 = () => {
        history.push('/help_price')
    }

    const handleGuide3 = () => {
        history.push('/help_reservation')
    }

    const handleGuide4 = () => {
        history.push('/help_delivery')
    }

    const handleOpen = () => {
        setOpen(!open)
    }

    const handleQuestion = () => {
        setQuestion(!question)
    }
    
    const handleQuestion1 = () => {
        setQuestion1(!question1)
    }
    
    const handleQuestion2 = () => {
        setQuestion2(!question2)
    }

    const handleQuestion3 = () => {
        setQuestion3(!question3)
    }

    const handleQuestion4 = () => {
        setQuestion4(!question4)
    }

    return(
        <>
            <div className={classes.topic_path}>
                <Button onClick={ handleClick } className={classes.button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        ホーム
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:20, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2, paddingLeft: 'initiail', cursor:'pointer'}}>
                    {'ヘルプセンター'}
                </Typography>
            </div>
            <div className={classes.root}>
                <Typography variant="h1" component="h1" className={classes.title}>
                    ヘルプセンター
                </Typography>
                <Typography variant="h2" component="h2" className={classes.subtitle}>
                    ガイド
                </Typography>
                <Grid container spacing={2} className={classes.main}>
                        <Grid className={classes.grid} key="help_1" item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <CardActionArea component={Link} path='/help_guide' onClick={handleGuide1}>
                                <Card className={classes.card}>
                                <CardMedia
                                className={classes.card_media}
                                component="img"
                                src={'https://media.enclopedia-official.com/subscription/logo.webp'}
                                title="product"
                                alt={'help'}
                                />
                                </Card>
                            </CardActionArea>
                            <Typography variant="h3" component="h3" className={classes.help_title}>
                                <strong>はじめる前に</strong>
                            </Typography>
                        </Grid>
                        <Grid className={classes.grid} key="help_2" item xs={12} sm={6} md={3} lg={3}  xl={3}>
                            <CardActionArea component={Link} path='/help_price' onClick={handleGuide2}>
                                <Card className={classes.card}>
                                <CardMedia
                                className={classes.card_media}
                                component="img"
                                src={'https://media.enclopedia-official.com/subscription/enclopedia-help1.webp'}
                                title="product"
                                alt={'help'}
                                />
                                </Card>
                            </CardActionArea>
                            <Typography variant="h3" component="h3" className={classes.help_title}>
                                <strong>料金について</strong>
                            </Typography>
                        </Grid>
                        <Grid className={classes.grid} key="help_3" item xs={12} sm={6} md={3} lg={3}  xl={3}>
                            <CardActionArea component={Link} path='/help_reservation' onClick={handleGuide3}>
                                <Card className={classes.card}>
                                <CardMedia
                                className={classes.card_media}
                                component="img"
                                src={process.env.REACT_APP_IMAGE_URL + "subscription/enclopedia-help2.webp"}
                                title="product"
                                alt={'help'}
                                />
                                </Card>
                            </CardActionArea>
                            <Typography variant="h3" component="h3" className={classes.help_title}>
                                <strong>予約方法について</strong>
                            </Typography>
                        </Grid>
                        <Grid className={classes.grid} key="help_2" item xs={12} sm={6} md={3} lg={3}  xl={3}>
                            <CardActionArea omponent={Link} path='/help_delivery' onClick={handleGuide4}>
                                <Card className={classes.card}>
                                <CardMedia
                                className={classes.card_media}
                                component="img"
                                src={process.env.REACT_APP_IMAGE_URL + "subscription/enclopedia-help4.webp"}
                                title="product"
                                alt={'help'}
                                />
                                </Card>
                            </CardActionArea>
                            <Typography variant="h3" component="h3" className={classes.help_title}>
                                <strong>返却方法について</strong>
                            </Typography>
                        </Grid>
                </Grid>
                <Container maxWidth={"md"} style={{padding: 'initial'}}>
                    <Typography variant="h2" component="h2" className={classes.subtitle} style={{textAlign: 'center'}}>
                        よくある質問
                    </Typography> 
                    <List className={classes.list}>
                        <ListItemButton onClick={handleOpen} style={{padding: 'initial'}}>
                            <ListItemText component="p" primary="一ヶ月にレンタルできる回数は？"className={classes.list_item} />
                            { open ? <ExpandLess/> : <ExpandMore/> }
                        </ListItemButton>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List className={classes.description} component="div" disablePadding>
                                <ListItemText component="p" primary="プランごとによって異なります。プレミアムプラン及びレンタルプランは無制限となっており、ベーシックプランに関しましては月に4回までとなっております。"/>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleQuestion} style={{padding: 'initial'}}>
                            <ListItemText component="p" primary="返却の際に洗濯、クリーニングは必要？" className={classes.list_item}/>
                            { question ? <ExpandLess/> : <ExpandMore/> }
                        </ListItemButton>
                        <Collapse in={question} timeout="auto" unmountOnExit>
                            <List className={classes.description} component="div" disablePadding>
                                <ListItemText component="p" primary="ご自身での洗濯、クリーニングはお控え下さい。商品は返却後にEnclopedia運営でクリーニングを行います。"/>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleQuestion1} style={{padding: 'initial'}}>
                            <ListItemText component="p" primary="洋服のレンタルは何日間借りることができる？" className={classes.list_item}/>
                            { question1 ? <ExpandLess/> : <ExpandMore/> }
                        </ListItemButton>
                        <Collapse in={question1} timeout="auto" unmountOnExit>
                            <List className={classes.description} component="div" disablePadding>
                            <ListItemText component="p" primary="商品詳細ページに表示される商品到着日と返却日を含めないで１０日間でレンタルすることが可能です、汚れた場合に汚れが酸化して落ちにくくなる期間を考慮しています。\
                            次のお客様にも気持ちよくご利用していただくためご了承ください。"/>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleQuestion2} style={{padding: 'initial'}}>
                            <ListItemText component="p" primary="気に入ったアイテムは買い取ることはできますか？" className={classes.list_item}/>
                            { question2 ? <ExpandLess/> : <ExpandMore/> }
                        </ListItemButton>
                        <Collapse in={question2} timeout="auto" unmountOnExit>
                            <List className={classes.description} component="div" disablePadding>
                                <ListItemText component="p" primary="商品はレンタルしている期間のみ予約履歴から購入することができます、試着後気に入ったアイテムのみ購入することができます。"/>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleQuestion3} style={{padding: 'initial'}}>
                            <ListItemText component="p" primary="汚れてしまった場合は？" className={classes.list_item}/>
                            { question3 ? <ExpandLess/> : <ExpandMore/> }
                        </ListItemButton>
                        <Collapse in={question3} timeout="auto" unmountOnExit>
                            <List className={classes.description} component="div" disablePadding>
                                <ListItemText component="p" primary="Enclopedia運営でクリーニングを行います、汚れがついてしまった場合には期限を守り返却をして下さい（故意である場合、使用複数回に渡り、使用できないほどに商品が劣化してしまった場合に限り買い取っていただく場合がございます。詳しくは利用規約の第１３条をご確認ください。)"/>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleQuestion4} style={{padding: 'initial'}}>
                            <ListItemText component="p" primary="返却手数料とは？" className={classes.list_item}/>
                            { question4 ? <ExpandLess/> : <ExpandMore/> }
                        </ListItemButton>
                        <Collapse in={question4} timeout="auto" unmountOnExit>
                            <List className={classes.description} component="div" disablePadding>
                                <ListItemText component="p" primary="レンタルプランにおいてアイテムの返却手数料を頂戴しております、料金に関しては各アイテムのサイズに対しての料金となりますので各アイテム詳細ページをご覧下さい。
                                複数まとめてレンタルする場合は合計した配送料が1000円以上を超過する場合一律1000円でまとめて返却となります。
                                サブスクリプションに登録しているユーザに関しては返却手数料はかかりません。"/>
                            </List>
                        </Collapse>
                    </List>
                </Container>
            </div>
        </>
    )
}

export default HelpScreen