import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Loader from "../components/Loader";

//action
import { order_action } from "../actions/buyingAction";
import { order_info_delete } from "../actions/buyingAction";
import { buying_info_delete } from "../actions/buyingAction";
import { reservation_item_get } from "../actions/reservationAction";
import { todo_purchase_item } from "../actions/notificationAction";

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: 'white',
        marginTop: theme.spacing(3)
    },
    card:{
        color: 'black',
        background: 'white',
        boxShadow: 'initial'
    },
    caption:{
        whiteSpace: 'pre-line'
    },
    content:{
        display: 'flex'
    },
    divider:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    message:{
        marginTop: theme.spacing(3),
        whiteSpace: 'pre-line'
    },
    modal_message:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre-line'
    },
    modal_button:{
        boxShadow: 'initial',
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover': {
            backgroundColor: theme.palette.primary['400']
        },
    },
    modal_container:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'25%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(15, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(20, 2, 20, 2)
        },        
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(20, 3, 20, 3)
        },
    },
    payment:{
        [theme.breakpoints.down('sm')]:{
            width: '70%',
        },
        [theme.breakpoints.up('sm')]:{
            width: '85%',
        },
        textAlign: 'right'
    },
    subtitle:{
        marginTop: theme.spacing(4),
        fontWeight: 'bold'
    },
    title:{
        fontWeight: 'bold'
    }

}))

export default function PurChaseConfirmedScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const {id} = useParams()

    const [open, setOpen] = useState(false)

    const {buying} = useSelector(state => state.buying)
    const {loading, order, error} = useSelector(state => state.order)
    const {reservationItem} = useSelector(state => state.reservationItem)

    const handleClick = () => {
        if(reservationItem.reservation.status !== 3 || reservationItem.is_bought){
            setOpen(true)
        }else{
            dispatch(order_action(buying['payment_method'], buying['payment_id'], id, buying['total_price']))
            dispatch(todo_purchase_item(id))
        }
    }

    const handleSuccess = () => {
        dispatch(buying_info_delete())
        history.push('/')
        dispatch(order_info_delete())
    }

    const handleFailed = () => {
        history.push(`/buying/${id}`)
        dispatch(order_info_delete())
    }


    const handleBack = (event) => {
        event.preventDefault()
        history.goBack()
        window.scrollTo({
            top:0
        });
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        dispatch(reservation_item_get(id))
    },[])

    return(
        loading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        order ?
        <div className={classes.root}>
            <Container maxWidth={"sm"} style={{padding: 'initial'}}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" component="h2" align="center" style={{fontWeight: 'bold'}}>
                            支払いに成功しました
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={classes.message}>
                            {"いつもEnclopediaファッションレンタルサービスをご利用いただきありがとうございます。\
                            \n支払いが完了しました、ご購入頂いた商品に関しましては返却せずにそのままご利用いただけます。\
                            \n引き続きEnclopediaファッションレンタルサービスをよろしくお願い致します。"}
                        </Typography>
                        <Button onClick={handleSuccess} className={classes.button} fullWidth>
                            戻る
                        </Button>
                    </CardContent>
                </Card>
            </Container>
        </div>
        :
        error ?
        <div className={classes.root}>
            <Container maxWidth={"sm"} style={{padding:'initial'}}>
                <Typography variant="h2" component="h2" align="center" style={{fontWeight: 'bold'}}>
                    支払いに失敗しました
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.message}>
                    {`いつもEnclopediaファッションレンタルサービスをご利用いただきありがとうございます。\
                    \n支払いが失敗しました。以下の原因が考えられます。\
                    \n${error}`}
                </Typography>
                <Button onClick={handleFailed} className={classes.button} fullWidth>
                    戻る
                </Button>
            </Container>
        </div>
        :
        <Container maxWidth={"md"} style={{padding: 'initial'}}>
            <div className={classes.root}>
                <Typography variant="h1" component="h1" align="center" className={classes.title}>
                    購入を確定
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="caption" component="div" className={classes.caption}>
                    {"「確定する」ボタンを押してご注文いただくことで、\
                    お客様は当サイトの各種規約、プライバシー規約および当サイト上のレンタル、および購入条件に同意の上、\
                    商品を購入されたことになります。\
                    購入後のキャンセルはできませんのでご確認の上ご購入ください。"}
                </Typography>
                <Button className={classes.button} fullWidth onClick={handleClick}>
                    確定する
                </Button>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                    内容確認
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <div>
                    <Typography variant="subtitle1" component="p">
                        アイテム名
                    </Typography>
                    <Typography variant="subtitle1" component="p" >
                        {buying['product_name']}
                    </Typography>
                </div>
                <Divider classes={{root: classes.divider}}/>
                <div className={classes.content}>
                    <Typography variant="subtitle1" component="p">
                        合計代金
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.payment}>
                        {buying['total_price']} 円
                    </Typography>
                </div>
                <Divider classes={{root:classes.divider}}/>
                <div className={classes.content}>
                    <Typography variant="subtitle1" component="p">
                        支払い方法
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.payment}>
                        クレジットカード
                    </Typography>
                </div>
                <Divider classes={{root: classes.divider}}/>
                <Button
                onClick={handleBack}
                style={{background: 'initial'}}
                >
                    戻る
                </Button>
                <Modal
                    style={{zIndex: 3500}}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Container maxWidth={"sm"} className={classes.modal_container}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="subtitle1" component="p" align="center" style={{fontWeight: 'bold'}}>
                                    予約中のアイテムのみ購入できます
                                </Typography>
                                <Typography variant="subtitle1" component="p" className={classes.modal_message}>
                                    {"いつもEnclopediaファッションレンタルサービスをご利用いただきありがとうございます。\
                                    \n購入することができるのは予約中のアイテムのみとなっております。\
                                    \n大変申し訳ございませんが購入をお考えのお客様はレンタル後に予約履歴画面から購入手続きを行なってください。"}
                                </Typography>
                                <Button className={classes.modal_button} onClick={handleClose} fullWidth>
                                    閉じる
                                </Button>
                            </CardContent>
                        </Card>
                    </Container>
                </Modal>
            </div>
        </Container>
    )
}