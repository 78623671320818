import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from 'clsx'
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CssBaseline from '@material-ui/core/CssBaseline';
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//action
import { listProducts, search_products } from "../actions/productActions";
import { brand_products_get } from "../actions/categoryAction";
import { get_tag_product } from "../actions/productActions";

const useStyles = makeStyles((theme) => ({
    button:{
        backgroundColor: 'inherit',
        color:'black',
        borderRadius:'initial',
        boxShadow:'initial',
        borderBottom: 'solid 1px #eeeeee',
        padding:'initial',
        '&:hover':{
            background: 'initial',
            borderRadius:'initial',
            boxShadow:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        }
    },
    underline:{
        borderBottom:"solid 1px #263238",
    },
    category:{
        overflow: 'hidden',
        overflowX:'scroll',
    },
    list:{
        display:'flex',
        padding:'initial',
        //[theme.breakpoints.up('sm')]:{
        justifyContent: 'center',
        //},
        [theme.breakpoints.down('lg')]:{
            overflow:'hidden',
            overflowX:'scroll',
        }
    },
    list_item:{
        padding:'initial',
        width:'initial',
        [theme.breakpoints.up('xs')]:{
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]:{
            marginRight: theme.spacing(4)
        },
        [theme.breakpoints.up('lg')]:{
            marginRight: theme.spacing(5)
        }
    },
    root:{
       margin: theme.spacing(2,3,2,3),
       scrollbarWidth: 'none'
    }
}))

export default function PageSwitchingComponent({param}){
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const {accountInfo} = useSelector(state => state.account)

    const handleProduct = () => {
        switch(location.pathname){
            case '/':
                dispatch(listProducts(1))
                return
            case `/search/${param}`:
                dispatch(search_products(param, 1))
                return
            case `/brand/${param}/`:{
                const id = location.search ? location.search.split('=')[1]:''
                dispatch(brand_products_get(id, 1))
                return
            }
            case `/tag/${param}`:
                dispatch(get_tag_product(param, 1))
                return
            default:
                history.push('/')

        }
    }

    const handleBrand = () => {
        history.push('/brands')
    }

    const handleIdea = () => {
        history.push('/idea')
    }

    //const handleFavorite = () => {
        //history.push('/favorite/product/list')
    //}

    const handleReservation = () => {
        history.push('/reservation_history/')
    }

    return(
        <div className={classes.root}>
            <CssBaseline/>
            <List className={classes.list}>
                <ListItem key="new_item" className={clsx(classes.list_item, )}>
                    <Button variant="contained" className={classes.button} disableRipple disableFocusRipple  onClick={handleProduct}>
                        <Typography variant="subtitle1" component="p" noWrap>新着</Typography>
                    </Button>
                </ListItem>
                <ListItem key="brand_item" className={classes.list_item}>
                    <Button variant="contained" className={classes.button} disableRipple disableFocusRipple onClick={handleBrand}>
                        <Typography variant="subtitle1" component="p" noWrap>ブランド</Typography>
                    </Button>
                </ListItem>
                <ListItem key="recognize_item" className={classes.list_item}>
                    <Button variant="contained" className={classes.button} disableRipple disableFocusRipple onClick={handleIdea}>
                        <Typography variant="subtitle1" component="p" noWrap>アイデア</Typography>
                    </Button>
                </ListItem>
                {
                    accountInfo ?
                    <>
                    <ListItem key="reservation_history" className={classes.list_item}>
                    <Button variant="contained" className={classes.button} disableRipple disableFocusRipple onClick={handleReservation}>
                        <Typography variant="subtitle1" component="p" noWrap>予約履歴</Typography>
                    </Button>
                    </ListItem>
                    {/*
                    <ListItem key="favorite" style={{marginRight:'initial'}} className={classes.list_item} >
                        <Button variant="contained" className={classes.button} disableRipple disableFocusRipple  onClick={handleFavorite}>
                            <Typography variant="subtitle1" component="p" noWrap>お気に入り</Typography>
                        </Button>
                    </ListItem>
                    */}
                    </>
                    :
                    ''

                }
            </List>
        </div>
    )
}
