import { 
    SET_NEXT_STEP,
    SET_BACK_STEP,
    SET_RESET_STEP
 } from "../constants/checkout";

 export const next_step = () => (dispatch, getState) => {
     const step = getState().step.step
     const steps = step + 1
     dispatch({type: SET_NEXT_STEP, payload: steps})
 }

 export const back_step = () => (dispatch, getState) => {
     const step = getState().step.step
     const steps = step - 1
     dispatch({type: SET_BACK_STEP, payload: steps})
 }

 export const reset_step = () => (dispatch) => {
     dispatch({type: SET_RESET_STEP, payload: 0})
 }