import { 
    RESERVATION_GET_REQUEST,
    RESERVATION_GET_SUCCESS,
    RESERVATION_GET_FAIL,
    RESERVATION_LATEST_GET_REQUEST,
    RESERVATION_LATEST_GET_SUCCESS,
    RESERVATION_LATEST_GET_FAILED,
    RESERVATION_LIST_REQUEST,
    RESERVATION_LIST_SUCCESS,
    RESERVATION_LIST_FAIL,
    RENTAL_RESERVATION_LIST_REQUEST,
    RENTAL_RESERVATION_LIST_SUCCESS,
    RENTAL_RESERVATION_LIST_FAIL,
    RESERVATION_HALF_REQUEST,
    RESERVATION_HALF_SUCCESS,
    RESERVATION_HALF_FAIL,
    RETURN_RENTAL_PRODUCT_REQUEST,
    RETURN_RENTAL_PRODUCT_SUCCESS,
    RETURN_RENTAL_PRODUCT_FAIL,
    RESREVATION_ALREADY_REQUEST,
    RESERVATION_ALREADY_SUCCESS,
    RESERVATION_ALREADY_FAIL,
    RESERVATION_ITEM_GET_REQUEST,
    RESERVATION_ITEM_GET_SUCCESS,
    RESERVATION_ITEM_GET_FAILED,
    RESERVATION_DAY_SAVE
 } from "../constants/reservationConstan";

import { baseAxios } from "../jwtInterceptor";

 export const reservation_list_get = () => async(dispatch) => {
    dispatch({type: RESERVATION_LIST_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/reservation/list/`)
    .then(
        (response) => {
            dispatch({type: RESERVATION_LIST_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: RESERVATION_LIST_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
 }

//tental planの半年間分のreservationを取得する
export const reservation_half_get = () => async(dispatch) => {
    dispatch({type:RESERVATION_HALF_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
    await baseAxios.get('/api/reservation/rental/half_year/').then(
        (response) => {
            dispatch({type:RESERVATION_HALF_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:RESERVATION_HALF_FAIL,
                payload:error.response && error.response.data.message
               ? error.response.data.message
               : error.message})
        }
    )
}
 
//月毎の利用で取得できるようにする
export const rental_reservation_list_get = (plan, month=0) => async(dispatch) => {
    dispatch({type:RENTAL_RESERVATION_LIST_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/reservation/rental/?plan=${plan}&month=${month}`)
    .then(
        (response) => {
            dispatch({
                type: RENTAL_RESERVATION_LIST_SUCCESS, payload: response['data']
            })
        }
        
    ).catch(
        (error) => (
            dispatch({type:RENTAL_RESERVATION_LIST_FAIL, 
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message
            })
        )
        
    )
}

//商品を返却する際の処理を記述
export const return_rental_product = (id, shipping_number) => async(dispatch) => {
    dispatch({type:RETURN_RENTAL_PRODUCT_REQUEST})
    baseAxios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.patch(`/api/reservation/return_shipping/${id}`,{'status': 4, 'return_shipping_number':shipping_number}).then(
        (response) => {
            dispatch({type:RETURN_RENTAL_PRODUCT_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:RETURN_RENTAL_PRODUCT_FAIL,
                payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}

//status3のreservationデータを取得
export const get_reservation = (id) => async(dispatch) => {
    dispatch({type: RESERVATION_GET_REQUEST})
    baseAxios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/reservation/${id}`).then(
        (response) => {
            dispatch({type:RESERVATION_GET_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:RESERVATION_GET_FAIL, 
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}

//最新のreservationデータを取得
export const latest_reservation_get = () => async(dispatch) => {
    dispatch({type:RESERVATION_LATEST_GET_REQUEST})
    baseAxios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/reservation/latest').then(
        (response) => {
            dispatch({type:RESERVATION_LATEST_GET_SUCCESS, payload:response['data']})
        }
    ).catch(
        () => {
            dispatch({
                type:RESERVATION_LATEST_GET_FAILED
            })
        }
    )
}

export const reservation_already_rental = (product) => async(dispatch) => {
    dispatch({type: RESREVATION_ALREADY_REQUEST})
    baseAxios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/reservation/rental/product/confirm/?product=${product}`).then(
        (response) => {
            for(let i=0; i < response['data'].length; i++){
                for(let h=0; h < response['data'][i].reservation_item.length; i++){
                    if(response['data'][i].reservation_item[h].product.id === product){
                        dispatch({type:RESERVATION_ALREADY_SUCCESS, payload: true})
                        break;
                    }
                }
                break;
            }
        }
    ).catch(
        () => {
            dispatch({type: RESERVATION_ALREADY_FAIL})
        }
    )
}

export const reservation_item_get = (id) => async(dispatch) => {
    dispatch({type: RESERVATION_ITEM_GET_REQUEST})
    baseAxios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/reservation/item/${id}`).then(
        (response) => {
            dispatch({type:RESERVATION_ITEM_GET_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: RESERVATION_ITEM_GET_FAILED,            
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}

export const save_reservation_day = (start, end) => (dispatch) => {
    dispatch({type: RESERVATION_DAY_SAVE, payload:{'start_day': start, 'end_day': end}})
}