import React,{useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Button,} from '@material-ui/core'
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import { ClickAwayListener } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Grow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { IconButton } from '@material-ui/core';
import { MenuList } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { MenuItem } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import { Paper } from '@material-ui/core';
import { Popper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

//component
import Category from '../components/Category'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'
import PageSwitchingComponent from '../components/PageSwitchingComponent';

//action
import { createCart } from '../actions/cartAction';
import { cartErrorMessageClear } from '../actions/cartAction';
import { product_category } from '../actions/productActions'
import { product_category_name } from '../actions/productActions'
import { evaluation_favorite_product} from '../actions/productActions';
import { evaluation_favorite_product_desc} from '../actions/productActions';
import { evaluation_review_product } from '../actions/productActions';
import { evaluation_review_product_desc } from '../actions/productActions';
import { get_closet_list } from '../actions/closetAction';
import { get_profile } from '../actions/profileAction';
import { listProducts } from '../actions/productActions';
import { subscription_info_get } from '../actions/stripeAction';
import { read_get } from '../actions/notificationAction';
import { reservation_message_clear } from '../actions/stripeAction';
import { product_reservation_order, product_reservation_order_desc } from '../actions/productActions';
import { product_price_order_get } from '../actions/productActions';
import { product_price_order_desc_get } from '../actions/productActions';
import { reservation_list_get } from '../actions/reservationAction';
import { rental_reservation_list_get } from '../actions/reservationAction';
import { search_word_get } from '../actions/historyAction'
import { favorite_list } from '../actions/favoriteAction'
import { todo_list } from "../actions/notificationAction";
//filter
import { rental_fileter_product} from '../actions/productActions';
import { gender_filter } from '../actions/productActions'
import { gender_data_save } from '../actions/productActions'
import { product_type } from '../actions/productActions'
import { product_type_name } from '../actions/productActions'
import { type_get } from '../actions/categoryAction'
//function 
import { pageView } from '../function/gaTraking';

import { Helmet } from 'react-helmet-async'

const useStyles = makeStyles((theme) => ({
    all:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial',
        minWidth: '43px'
    },
    button:{
        backgroundColor:'inherit',
        color: 'white',
        border: 'solid 1px #fff',
        marginRight: theme.spacing(2),
        '&:hover':{
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 'inherit'
        }
    },
    checkbox:{
        marginTop: theme.spacing(2),
        marginLeft:'auto',
        display:'flex',
    },
    container :{
        width: '100%',

    },
    error_container:{
        height: '100vh',
        marginTop: theme.spacing(3)
    },
    female:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial'
    },
    gender:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    image:{
        position: 'relative'
    },
    icon_button:{
        paddingTop:'initial',
        paddingBottom: 'initial',
        '&:hover':{
            backgroundColor:'initial'
        }
    },
    inner:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    filter:{
        display:'flex',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'25%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },

    },
    male:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom:`solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial',
    },
    menu_item:{
        height:'initial'
    },
    message:{
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    message_modal:{
        marginTop: theme.spacing(2),
        margingBottom: theme.spacing(3),
        lineHeight: '25px',
        whiteSpace: 'pre-line'
    },
    modal:{
        backgroundColor: 'white',
        color:'black',
        zIndex: 3500
 
    },
    modal_button:{
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover': {
            backgroundColor: theme.palette.primary['400']
        },
        marginTop:theme.spacing(3),
    },
    modal_container:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    modal_inline:{
        margin: theme.spacing(2)
    },
    modal_title:{
        marginTop: theme.spacing(3),
    },
    order:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop:theme.spacing(1)
    },
    papper:{
        zIndex: '1000',
    },
    paper:{
        backgroundColor: 'white',
        color: 'black'
    },
    pagination:{
        margin: theme.spacing(3,0,2,0)
    },
    products_container:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(15),
        padding: 'initial'
    },
    title:{
        margin:theme.spacing(0, 0, 1, 1)
    }
}))

//urlパラメーターを使用してフィルタリングをする
function HomeScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const orderRef = useRef(null)
    const history = useHistory()
    const params = new URLSearchParams(location.search)
    const param = params.get('page') == null ? undefined : Number(params.get('page'))
    const param_title = location.search.split('=')[2] ? decodeURI(location.search.split('=')[2]) : undefined

    //screen内state
    const [orderOpen, setOrderOpen] = useState(false)
    const [title, setTitle] = useState(param_title == undefined ? '新着' : param_title)
    const [checked, setChecked] = useState(false)
    const [page, setPage] = useState(param == undefined ? 1 : param)

    //message cart内がレンタルできる数を超過している場合には表示するメッセージ
    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {categories} = useSelector(state => state.category)
    const {closets} = useSelector(state => state.closetList)
    const {error, loading, products} = useSelector(state => state.productList)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)
    const {message} = useSelector(state => state.createCartItem)
 
    const {reservation_message} = useSelector(state => state.reservation)

    const [open, setOpen] = useState(reservation_message ? true : false)


    //modalを閉じる
    //閉じると同時にmessageも削除をする
    const handleClose = () => {
        setOpen(false)
        dispatch(reservation_message_clear())
    }

    //並び替えメニュー
    const handleOrderToggle = () => {
        setOrderOpen((prevOrderOpen) => !prevOrderOpen)
    }

    const handleOrderClose = () => {
        setOrderOpen(false)
    }

    const listDownHandler = (event) => {
        if(event.key === 'Tab'){
            event.preventDefault();
            setOrderOpen(false)
        }
    }

    //レンタル可能な商品のみ表示する
    const handleRentalFilter = (check) => {
        setChecked((prevChecked) => !prevChecked)
        dispatch(rental_fileter_product(check, subscriptionInfo.plan))
    }

    const handleCategory = () => {
        setChecked(false)
    }

    const handleTitle = (title) => {
        setTitle(title)
    }

    //全て
    const handleProduct = async() => {
        history.push('/?page=1&title=新着')
        setPage(1)
        setChecked(false)
        setTitle('新着')
        await dispatch(listProducts(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }
    //評価高い順
    const handleEvaluation = async() => {
        history.push('/?page=1&title=評価が高い順')
        setPage(1)
        setChecked(false)
        setTitle('評価が高い順')
        await dispatch(evaluation_review_product(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }
    //評価が低い順
    const handleEvaluationDesc = async() => {
        history.push('/?page=1&title=評価が低い順')
        setPage(1)
        setChecked(false)
        setTitle('評価が低い順')
        await dispatch(evaluation_review_product_desc(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }
    //お気に入りが多い順
    const handleEvaluationFavorite = async() => {
        history.push('/?page=1&title=お気に入り数が多い順')
        setPage(1)
        setChecked(false)
        setTitle('お気に入り数が多い順')
        await dispatch(evaluation_favorite_product(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }
    //お気に入りが少ない順
    const handleEvaluationFavoriteDesc = async() => {
        history.push('/?page=1&title=お気に入り数が少ない順')
        setPage(1)
        setChecked(false)
        setTitle('お気に入り数が少ない順')
        await dispatch(evaluation_favorite_product_desc(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }
    //予約数の多い順に並び替える
    const handleReservationOrder = async() => {
        history.push('/?page=1&title=予約数が多い順')
        setPage(1)
        setChecked(false)
        setTitle('予約数が多い順')
        await dispatch(product_reservation_order(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }

    //予約数の少ない順に並び替える
    const handleReservationOrderDesc = async() => {
        history.push('/?page=1&title=予約数が少ない順')
        setPage(1)
        setChecked(false)
        setTitle('予約数が少ない順')
        await dispatch(product_reservation_order_desc(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }

    ///値段が安いものから並び替える
    const handlePriceOrder = async() => {
        history.push('/?page=1&title=料金が安い順')
        setPage(1)
        setChecked(false)
        setTitle('料金が安い順')
        await dispatch(product_price_order_get(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }

    //値段が高いものから並び替える
    const handlePriceOrderDesc = async() => {
        history.push('/?page=1&title=料金が高い順')
        setPage(1)
        setChecked(false)
        setTitle('料金が高い順')
        await dispatch(product_price_order_desc_get(1))
        dispatch(gender_data_save())
        dispatch(type_get())
    }

    const messageClearFunc = () => {
        if(message){
           dispatch(cartErrorMessageClear())
        }
    }
    
    //性別別商品
    const handleGenderFilter = (gender) => {
        setChecked(false)
        dispatch(gender_filter(gender))
    }

    //paginationHandler
    const handlePagenation = async(event, value) => {
        event.preventDefault();
        setPage(value)
        window.scrollTo({
            top: 0,
        });
        switch(title){
            case '新着':
                history.push(`/?page=${value}`)
                setChecked(false)
                await dispatch(listProducts(value))
                dispatch(gender_data_save())
                return
            case '評価が高い順':
                history.push(`/?page=${value}&title=評価が高い順`)
                setChecked(false)
                await dispatch(evaluation_review_product(value))
                dispatch(gender_data_save())
                return
            case '評価が低い順':
                history.push(`/?page=${value}&title=評価が低い順`)
                setChecked(false)
                await dispatch(evaluation_review_product_desc(value))
                dispatch(gender_data_save())
                return
            case 'お気に入り数が多い順':
                history.push(`/?page=${value}&title=お気に入りが多い順`)
                setChecked(false)
                await dispatch(evaluation_favorite_product(value))
                dispatch(gender_data_save())
                return
            case 'お気に入り数が少ない順':
                history.push(`/?page=${value}&title=お気に入り数が多い順`)
                setChecked(false)
                await dispatch(evaluation_favorite_product_desc(value))
                dispatch(gender_data_save())
                return
            case '予約数が多い順':
                history.push(`/page=${value}&title=予約数が多い順`)
                setChecked(false)
                await dispatch(product_reservation_order(value))
                dispatch(gender_data_save())
                return
            case '予約数が少ない順':
                history.push(`/page=${value}&title=予約数が少ない順`)
                setChecked(false)
                await dispatch(product_reservation_order_desc(value))
                dispatch(gender_data_save())
                return
            case '料金が安い順':
                history.push(`/page=${value}&title=料金が安い順`)
                setChecked(false)
                await dispatch(product_price_order_get(value))
                dispatch(gender_data_save())
                return
            case '料金が高い順':
                history.push(`/page=${value}&title=料金が高い順`)
                setChecked(false)
                await dispatch(product_price_order_desc_get(value))
                dispatch(gender_data_save())
                return
            case 'トップス' || 'ジャケット' || 'コート' || 'パンツ' || 'スカート' || 'ワンピース' || 'ドレス' || 'スーツ' || 'バッグ' || 'シューズ' || 'ハット' || 'アクセサリー' || 'ルームウェア' || 'その他':
                history.push(`/page=${value}&title=${title}`)
                {
                    const category = categories.find((cate) => {
                        cate.category_name === title
                    })
                    await dispatch(product_type(category.id, value))
                }
                setChecked(false)
                dispatch(gender_data_save())
                return
            default:
                history.push(`/page=${value}&title=${title}`)
                {
                    setChecked(false)
                    const category = categories.find((cate) => {
                        cate.category_name === title
                    })
                    dispatch(product_category(category.id, value))
                }
                dispatch(gender_data_save())
                return
        }
    }

    //category内page変更
    const handlePageUpdate = (number) => {
        setPage(number)
    }

    useEffect(() => {
        
        //accountが存在するときの動作
        if(accountInfo){
            dispatch(favorite_list())
            dispatch(get_closet_list())
            dispatch(subscription_info_get())
            dispatch(createCart()) 
            dispatch(read_get()) 
            dispatch(get_profile())
            dispatch(search_word_get())
            dispatch(todo_list())
            if(subscriptionInfo.plan === ('basic' || 'premium')){
                dispatch(reservation_list_get(subscriptionInfo.plan))
            }
            else{
                dispatch(rental_reservation_list_get(subscriptionInfo.plan))
            }
        }

        setTimeout(messageClearFunc, 10000)
        //google analytics
        pageView('home page', location.pathname)
        
        switch(param_title){ 
            case undefined:
            case '新着':
                dispatch(listProducts(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('新着')
                dispatch(type_get())
                return
            case '評価が高い順':
                dispatch(evaluation_review_product(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('評価が高い順')
                dispatch(type_get())
                return
            case '評価が低い順':
                dispatch(evaluation_review_product_desc(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('評価が低い順')
                dispatch(type_get())
                return
            case 'お気に入り数が多い順':
                dispatch(evaluation_favorite_product(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('お気に入り数が多い順')
                dispatch(type_get())
                return
            case 'お気に入り数が少ない順':
                dispatch(evaluation_favorite_product_desc(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('お気に入り数が少ない順')
                dispatch(type_get())
                return
            case '予約数が多い順':
                dispatch(product_reservation_order(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('予約数が多い順')
                dispatch(type_get())
                return
            case '予約数が少ない順':
                dispatch(product_reservation_order_desc(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('予約数が少ない順')
                dispatch(type_get())
                return
            case '料金が安い順':
                dispatch(product_price_order_get(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('料金が安い順')
                dispatch(type_get())
                return
            case '料金が高い順':
                dispatch(product_price_order_desc_get(param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                setTitle('料金が高い順')
                dispatch(type_get())
                return
            case 'トップス':
            case 'ジャケット':
            case 'コート':
            case 'パンツ':
            case 'スカート':
            case 'ワンピース':
            case 'ドレス':
            case 'スーツ':
            case 'バッグ':
            case 'シューズ':
            case 'ハット':
            case 'アクセサリー':
            case 'ルームウェア':
            case 'その他':
                setTitle(param_title)
                dispatch(product_type_name(param_title, param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                return
            default:
                setTitle(param_title)
                dispatch(product_category_name(param_title, param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                return
        }
    }, [location.search])
    //login中のユーザとしていないユーザでcomponentの表示を分けるようにする
    return(
            <div className={classes.container}>
                <Helmet>
                    <title>{'Enclopedia｜ファッションレンタル'}</title>
                    <meta name="description" content="Enclopedia（アンクロペディア）は、ハイブランドからビンテージまで貴重なアーカイブを取り揃えるファッションレンタルサービスです。"/>
                </Helmet>
                <div className={classes.message}>
                {message ? <div className={classes.message}><Message>{message}</Message></div>:''}
                </div>
                <PageSwitchingComponent/>
                <Category handle={handleCategory} handleTitle={handleTitle} pagination={handlePageUpdate}/>
                <div className={classes.filter}>
                    <Box className={classes.order}>
                        <div>
                            <Typography variant="subtitle2" component="p" style={{textAlign:'center', padding:'initial'}}>並び替え</Typography>
                            <IconButton 
                            ref={orderRef}
                            disableRipple={true}
                            color="inherit"
                            className={classes.icon_button}
                            size="medium"
                            aria-label='order_menu'
                            onClick={handleOrderToggle}
                            >
                                <Menu/>
                            </IconButton>
                        </div>
                        <Popper 
                        style={{zIndex:3500}} 
                        placement={"bottom-start"} 
                        popperOptions={'bottom-start'}
                        direction={'rtl'}
                        open={orderOpen} 
                        anchorEl={orderRef.current} 
                        role={undefined}
                        transition
                        disablePortal >
                            {({ TransitionProps}) => (
                                <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: 'left top' }}
                                >
                                    <Paper className={classes.paper}>
                                        <ClickAwayListener onClickAway={handleOrderClose}>
                                            <MenuList autoFocusItem={orderOpen} onKeyDown={listDownHandler}>
                                                <MenuItem key="new_item" onClick={handleProduct} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p" className={classes.menu_item}>新着順</Typography>
                                                </MenuItem>
                                                <MenuItem key="evaluate_item" onClick={handleEvaluation} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">評価が高い順</Typography>
                                                </MenuItem>
                                                <MenuItem key="evaluate_desc_item" onClick={handleEvaluationDesc} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">評価が低い順</Typography>
                                                </MenuItem>
                                                <MenuItem key="favorite_item" onClick={handleEvaluationFavorite} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">お気に入りが多い順</Typography>
                                                </MenuItem>
                                                <MenuItem key="favorite_desc_item" onClick={handleEvaluationFavoriteDesc} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">お気に入りが少ない順</Typography>
                                                </MenuItem>
                                                <MenuItem key="reservation_item" onClick={handleReservationOrder} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">予約数が多い順</Typography>
                                                </MenuItem>
                                                <MenuItem key="reservation_desc_item" onClick={handleReservationOrderDesc} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">予約数が少ない順</Typography>
                                                </MenuItem>
                                                <MenuItem key="price_item" onClick={handlePriceOrder} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">値段が安い順</Typography>
                                                </MenuItem>
                                                <MenuItem key="price_desc_item" onClick={handlePriceOrderDesc} className={classes.menu_item} style={{minHeight:'initial'}}>
                                                    <Typography variant="subtitle1" component="p">値段が高い順</Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                    <Box className={classes.checkbox}>
                        <Typography variant="subtitle2" component="p" style={{marginTop:18 }}>貸出可能な商品</Typography>
                        <Checkbox
                        checked={checked}
                        onChange={(event)=>{handleRentalFilter(event.target.checked)}} 
                        color='#bdbdbd'
                        style={{backgroundColor: 'initial'}}/>
                    </Box>
                </div>
                {<div className={classes.gender}>
                    <Button variant="contained" className={classes.all} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('all')}>
                        <Typography variant="subtitle2" component="p">すべて</Typography>
                    </Button>
                    <Button variant="contained" className={classes.male} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('メンズ')}>
                        <Typography variant="subtitle2" component="p">メンズ</Typography>
                    </Button>
                    <Button variant="contained" className={classes.female} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('レディース')}>
                        <Typography variant="subtitle2" component="p">レディース</Typography>
                    </Button>
                </div>}
                <Container maxWidth="xl" className={classes.products_container}>
                    <div className={classes.inner}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            {title} 
                        </Typography>
                        {
                        loading ? 
                        <div className={classes.loader}>
                            <Loader />
                        </div>
                        : error ?
                        <div className={classes.error_container}>
                            <Typography  variant="subtitle1" component="p" align='center'>商品が見つかりませんでした</Typography>
                        </div>
                        :
                        <>
                        <Grid container spacing={1} >
                            {products['results'].map(product => (
                                <Grid key={product.id} item xs={6} sm={6} md={4} lg={3} xl={3}>
                                    <Product product={product} closets={closets} token={accountInfo.access} key={product.id}/>
                                </Grid>))}
                        </Grid>
                        <div className={classes.pagination}>
                        <Pagination count={Math.ceil(products['count'] / 40)} page={page} onChange={handlePagenation}/>
                        </div>
                        </>
                        }
                    </div>
                </Container>
                <Modal
                 style={{zIndex:3500}}
                 open={open}
                 onClose={handleClose}
                 aria-labelledby="modal-modal-title"
                 aria-describedby="modal-modal-description"
                >
                    <Container maxWidth={"sm"} className={classes.modal_container}>
                        <Card className={classes.modal}>
                            <CardContent>
                                <Typography variant="subtitle1" component="p" align="center" style={{fontWeight: 'bold'}}>
                                    {reservation_message ? reservation_message.title : ''}
                                </Typography>
                                <Typography variant='subtitle1' component="p" className={classes.message_modal}>
                                    {reservation_message ? reservation_message.message : ''}
                                </Typography>
                                <Button onClick={handleClose} className={classes.modal_button} fullWidth>
                                    閉じる
                                </Button>
                            </CardContent>
                        </Card>
                    </Container>
                </Modal>
            </div>
    )
}

export default HomeScreen