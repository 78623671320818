import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react'



const Loader = () => {
    return (
        <CircularProgress size={50} style={{color:'#757575'}}/>
    );
}

export default Loader
