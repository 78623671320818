import React, {useState, useEffect} from  "react";
import { useDispatch } from "react-redux";
import { Button, CardContent, Checkbox } from "@material-ui/core";
import { BsChevronLeft } from "react-icons/bs";
import {Box} from "@material-ui/core";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { FormGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import {IconButton} from "@material-ui/core";
import { Switch } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
//action
import { subscribe_get } from "../actions/accountAction";
import { subscribe_update } from "../actions/accountAction";
import { update } from "../actions/accountAction";
import { account_remove } from "../actions/accountAction";
import { latest_reservation_get } from "../actions/reservationAction";
import { logout } from "../actions/accountAction";
//import formik
import { useFormik } from "formik";
import * as yup from 'yup';
import { useHistory } from "react-router-dom";

import { baseAxios } from "../jwtInterceptor";

const useStyles = makeStyles((theme) => ({
    account_delete_message:{
        height: '300px',
        width: '100%',
        border: 'solid 1px black',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY:'scroll',
    },
    button:{
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        }
    },
    card:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        boxShadow: 'initial'
    },
    card_content:{
        backgroundColor:'white'
    },
    checkbox:{
        marginLeft:'auto',
        display:'flex',
    },
    container:{
        padding: 'initial',
    },
    form:{
        color:'black',
        backgroundColor:'white'
    },
    icon_button:{
        '&:hover':{
            backgroundColor:'initial'
        },
        padding:'initial',
        marginBottom: theme.spacing(1)
    },
    inner:{
        margin: theme.spacing(0,1,0,1)
    },
    input_text:{
        color:'black'
    },
    list:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(2)
    },
    message:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    modal_button:{
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
        marginTop: theme.spacing(2)
    },
    modal_card:{
        color:'black',
        backgroundColor: 'white',
    },
    modal_container:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        padding: 'initial',
    },
    modal_description:{
        whiteSpace: 'pre-line',
        margin: theme.spacing(0,1,1,1)
    },
    modal_title:{
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3, 3, 15, 3)
        }
    },
    secure:{
        marginTop: theme.spacing(3)
    },
    switch:{
        '& .MuiSwitch-switchBase.Mui-checked':{
            color: 'blue',
            
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'blue',
          },
        '& .MuiSwitch-track': {
            backgroundColor: theme.palette.primary['400']
        }
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    title:{
        color:'black',
        backgroundColor:'white',
        marginBottom: theme.spacing(2)
    },
    topic_path:{
        display:'flex',
        marginBottom: theme.spacing(3)
    },
    topic_path_button:{
        '&:hover':{
            backgroundColor:'initial'
        }
    },
}))

const validationSchema = yup.object({
    first_name: yup
        .string('')
        .max(20, '20文字以内で入力して下さい')
        .required('入力は必須です'),
    last_name: yup
        .string('')
        .max(20, '20文字以内で入力し下さい')
        .required('入力は必須です'),
    username: yup
        .string('')
        .max(20, '20文字以内で入力して下さい')
        .required('入力は必須です')
})


function SecurityInfo(){
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()

    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const { accountPublicInfo } = useSelector(state => state.accountPublicInfo)
    const { subscriptionInfo } = useSelector(state => state.subscriptionInfo)
    const { subscribe } = useSelector(state => state.emailSubscribe)
    const {reservations} = useSelector(state => state.reservationLatestInfo)
    const formik = useFormik({
        initialValues:{
            first_name: accountPublicInfo.first_name,
            last_name: accountPublicInfo.last_name,
            username: accountPublicInfo.username
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            dispatch(update(values.first_name, values.last_name, values.username))
        }
    })

    const handleAccountService = () => {
        history.push('/management')
    }


     //useState
    const [checked, setChecked] = useState(subscribe ? subscribe.is_active: false)
    const [open, setOpen] = useState(false)
    const [updateOpen, setUpdateOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const [check, setCheck] = useState(false)

    const changeHandle = () => {
        if(checked){
            setChecked(false)
            dispatch(subscribe_update(false, accountPublicInfo.email, subscribe.recipient_id))
        }else{
            setChecked(true)
            dispatch(subscribe_update(true, accountPublicInfo.email))
        }
    }

    const handleOpen = () => {
        if(reservations){
            setErrorOpen(true)
        }else{
            setOpen(true)
        }
    }

    const handleClose = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleUpdateOpen = () => {
        setUpdateOpen(true)
    }

    const handleUpdateClose = () => {
        setUpdateOpen(!updateOpen)
    }

    const handleErrorClose = () => {
        setErrorOpen((prevErrorOpen) => !prevErrorOpen)
    }
 
    //handle
    const passwordChangeHandle = () => {
        history.push('/password/change')
    }

    const handleEmailUpdate = () => {
        history.push('/email_update')
    }

    const handleClick = () => {
        dispatch(account_remove(subscriptionInfo.plan, accountPublicInfo.email, checked))
    }

    const handleCheck = () => {
        setCheck((prevCheck) => !prevCheck)
    }

    const handleLogout = () => {
        dispatch(logout())
        history.push('/login')
    }

    //電話番号のボタンを押した際に認証番号確認メールを送信
    const handlePhoneNumberUpdate = async() => {
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        const data = {}
        await baseAxios.post('/api/account/phone_number_update/', data).then(
            () => {
                history.push('/phone_number_authentication')
            }
        )
    }

    useEffect(() => {
        if(accountInfo && accountPublicInfo.phone_number == null || accountPublicInfo.phone_number == undefined){
            history.push('/phone_number_register')
        }
        dispatch(subscribe_get())
        dispatch(latest_reservation_get())
    },[dispatch, subscribe_get])
    
    return(
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    {'アカウント情報の変更'}
                </Typography>
            </div>
            <Container maxWidth={'md'} className={classes.container}>
                <Card className={classes.card}>
                    <CardContent style={{backgroundColor:'white'}}>
                        <Typography variant="h2" className={classes.title} component="h2" style={{textAlign:'center', fontWeight: 'bold'}}>
                            ユーザー情報
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="username"
                                    label="ユーザーネーム"
                                    name="username"
                                    autoFocus
                                    autoComplete="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                    helperText={formik.touched.username && formik.errors.username}
                                    InputLabelProps={{
                                        classes:{
                                            root:classes.input_text
                                        }
                                    }}
                                    InputProps={{
                                        classes:{
                                            notchedOutline:classes.text_field,
                                            root: classes.text_field
                                        }
                                    }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        id="first_name"
                                        name="first_name"
                                        label="姓"
                                        autoComplete="first_name"
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                        helperText={formik.touched.first_name && formik.errors.first_name}
                                        InputLabelProps={{
                                            classes:{
                                                root:classes.input_text
                                            }
                                        }}
                                        InputProps={{
                                            classes:{
                                                notchedOutline:classes.text_field,
                                                root: classes.text_field
                                            }
                                        }}
                                        />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        variant="outlined"
                                        color="primary"
                                        required
                                        fullWidth
                                        id="last_name"
                                        name="last_name"
                                        label="名"
                                        autoComplete="last_name"
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                        helperText={formik.touched.last_name && formik.errors.last_name}
                                        InputLabelProps={{
                                            classes:{
                                                root:classes.input_text
                                            }
                                        }}
                                        InputProps={{
                                            classes:{
                                                notchedOutline:classes.text_field,
                                                root: classes.text_field
                                            }
                                        }}
                                        />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                    className={classes.button}
                                    type="submit"
                                    >
                                        変更
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>

                        <List className={classes.list}>
                            <ListItem keys='name' alignItems="flex-start" >
                                <ListItemText
                                primary={
                                <Typography variant="subtitle2" style={{fontWeight:'bold'}}>Eメール:</Typography>}
                                secondary={
                                <Typography variant="subtitle1" component="p">
                                    {accountPublicInfo.email.substr(0,1) + '******@*****'}
                                </Typography>}/>
                                <Button className={classes.button} onClick={handleEmailUpdate}>
                                    変更
                                </Button>
                            </ListItem>
                            <ListItem keys='name' alignItems="flex-start">
                                <ListItemText
                                primary={
                                <Typography variant="subtitle2" style={{fontWeight:'bold'}}>電話番号:</Typography>}
                                secondary={
                                <Typography variant="subtitle1"component="p" >
                                    {accountPublicInfo.phone_number != null || accountPublicInfo.phone_number != undefined ? '***-****-' + `${accountPublicInfo.phone_number.substr(-4)}` : ''}
                                </Typography>}/>
                                <Button onClick={handleUpdateOpen} className={classes.button}>
                                    変更
                                </Button>
                            </ListItem>
                            <ListItem keys='name' alignItems="flex-start" className={classes.list_item}>
                                <ListItemText
                                primary={
                                <Typography variant="subtitle2" style={{fontWeight:'bold'}}>パスワード:</Typography>}
                                secondary={
                                <Typography variant="subtitle1" component="p">
                                    ********
                                </Typography>}/>
                                <Button onClick={passwordChangeHandle} className={classes.button}>
                                    変更
                                </Button>
                            </ListItem>
                            <ListItem keys='subscribe' alignItems="flex-start" className={classes.list_item}>
                                <ListItemText
                                primary={
                                    <Typography variant="subtitle2" style={{fontWeight:'bold'}}>
                                        メール配信
                                    </Typography>
                                    }
                                secondary={
                                    <Typography variant="subtitle2" component="p">
                                        ※メール配信を受信するためのフォームです
                                    </Typography>
                                }
                                />
                                <FormGroup>
                                    <FormControlLabel control={<Switch color="primary" className={classes.switch} checked={checked} onChange={changeHandle} inputProps={{'aria-label': 'controlled'}}/>} />
                                </FormGroup>
                            </ListItem>
                            <ListItem key="account_delete" className={classes.list_item}>
                                <ListItemText
                                primary={
                                    <Typography variant="subtitle2" style={{fontWeight:'bold'}}>
                                        アカウント削除
                                    </Typography>
                                }/>
                                <Button className={classes.button} onClick={handleOpen}>
                                    アカウントを削除する
                                </Button>
                            </ListItem>
                            <ListItem keys='subscribe' alignItems="flex-start" className={classes.list_item}>
                                <Button className={classes.button} fullWidth onClick={handleLogout}>
                                    ログアウト
                                </Button>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Container>
            <Modal
            open={open}
            onClose={handleClose}
            >
                <Container maxWidth={"md"} className={classes.modal_container} style={{padding:'initial'}}>
                    <div className={classes.inner}>
                        <Card className={classes.modal_card}>
                            <CardContent>
                                <IconButton
                                onClick={handleClose}
                                disableRipple={true}
                                disableFocusRipple={true}
                                className={classes.icon_button}
                                size="medium"
                                color="inherit"
                                >
                                <BsChevronLeft/>
                            </IconButton>
                                <Typography variant="subtitle1" align="center" component="p" className={classes.modal_title}>
                                    アカウント削除
                                </Typography>
                                <Typography variant="subtitle2" component="p" >
                                    ※アカウントを削除する前に以下に同意した上でアカウント削除を行なって下さい
                                </Typography>
                                <div className={classes.account_delete_message}>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_note}>
                                        【注意事項】
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_description}>
                                        {
                                        'Enclopediaファッションレンタルサービスをご退会なされますと現在保持しているお客様に関する登録情報は破棄されサービスをご利用いただくことができなくなります。\
                                        \n現在ご加入中のサブスクリプションプランに関しましても同様にご利用がいただけなくなりますのでサブスクリプションの有効期間をご確認の上退会して下さい。'}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_description}>
                                        {'※以下に該当する場合退会することができません'}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_description}>
                                        {'・レンタル中のアイテムがある場合\
                                        \n・返却中のアイテムがある場合\
                                        \n・サブスクリプションに関するお支払い料金滞納がある場合'}
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_description}>
                                    上記の注意事項にご同意いただける場合は、【同意する】にチェックを入れ【アカウント削除】をクリックしてください。
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_description}>
                                    以上でアカウント削除の依頼が完了します。
                                    </Typography>
                                </div>
                                <Box className={classes.checkbox}>
                                    <Checkbox
                                    checked={check}
                                    onChange={handleCheck}
                                    style={{backgroundColor:'initial', color: 'black'}}
                                    />
                                    <Typography variant="subtitle2" component="p"style={{marginTop:18 }}>
                                        同意する
                                    </Typography>
                                </Box>
                                {check ?
                                <Button onClick={handleClick} fullWidth={true} align="center" className={classes.modal_button}>
                                    アカウント削除
                                </Button>
                                :
                                <Button onClick={handleClick} fullWidth={true} align="center" disabled={true} className={classes.modal_button}>
                                    アカウント削除
                                </Button>    
                                }

                            </CardContent>
                        </Card>
                    </div>
                </Container>
            </Modal>
            <Modal
             open={errorOpen}
             onClose={handleErrorClose}
            >
                <Container className={classes.modal_container}>
                    <div className={classes.inner}>
                        <Card className={classes.modal_card}>
                            <CardContent>
                                <IconButton
                                    onClick={handleErrorClose}
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    className={classes.icon_button}
                                    size="medium"
                                    color="inherit"
                                    >
                                    <BsChevronLeft/>
                                </IconButton>
                                    <Typography variant="subtitle1" component="p" className={classes.modal_title} align="center" style={{fontWeight:'bold'}}>
                                    アカウント削除ができない状態です
                                </Typography>
                                <Typography variant="subtitle1" component="p" style={{whiteSpace: 'pre-line'}}>
                                    {'現在レンタル中のアイテムがございます。\
                                    \nアイテムを返却後に再度アカウント削除の手続きを行なって下さい。'}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </Container>
            </Modal>
            <Modal
            open={updateOpen}
            onClose={handleUpdateClose}
            >
                <Container maxWidth={"sm"} className={classes.modal_container}>
                    <div className={classes.inner}>
                        <Card className={classes.modal_card}>
                            <CardContent>
                                <Typography variant="subtitle1" component="p" style={{textAlign:'center', fontWeight: 'bold'}}>
                                    電話番号の変更
                                </Typography>
                                <Typography variant="subtitle2" component="p" className={classes.message}>
                                    電話番号を変更するためには現在使用している電話番号を破棄する必要がございます,ご了承の上変更を行なってください。
                                </Typography>
                                <Typography variant="subtitle1" component="p" align="center">
                                    電話番号を変更しますか？
                                </Typography>
                                <Button onClick={handlePhoneNumberUpdate} className={classes.modal_button} fullWidth align="center">
                                    変更
                                </Button>
                                <Button onClick={handleUpdateClose} className={classes.modal_button} fullWidth align="center" disableFocusRipple={true} disableRipple={true}>
                                    キャンセル
                                </Button>
                            </CardContent>
                        </Card>
                    </div>
                </Container>
            </Modal>
        </div>
    )
}

export default SecurityInfo