import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import dayjs     from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Typography from '@material-ui/core/Typography';
import jaLocale from 'date-fns/locale/ja';

//action
import { save_reservation_day } from "../actions/reservationAction";

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
    '&.css-1vmb4ba-MuiButtonBase-root-MuiPickersDay-root.Mui-selected': {
        color: 'black !important',
        backgroundColor: 'white' /* 新しい背景色 */
    },
    shouldForwardProp: (prop) =>
      prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
    })(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
    ...(dayIsBetween && {
      borderRadius: 0,
      backgroundColor: '#1976d2',
      color: 'white',
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isFirstDay && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
}));

function Day(props){
    const { day, selectedDay, ...other } = props;

    if(selectedDay == null){
        return <PickersDay day={day} {...other} />;
    }

    const start = selectedDay;
    const end = selectedDay.clone().add(11, 'day')

    const dayIsBetween = day.isBetween(start, end, null, '[]');
    const isFirstDay = day.isSame(start, 'day');
    const isLastDay = day.isSame(end, 'day');

    return (
        <CustomPickersDay
          {...other}
          day={day}
          sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
        />
    );
}

Day.propTypes = {
    /**
     * The date to show.
     */
    day: PropTypes.object.isRequired,
    selectedDay: PropTypes.object,
  };

//到着日から選択できるように調整する
//到着日返却日含めずに１０日間レンタルできるようにする
//初期値には今日から2週間の値を入れておく必要がある
const ReservationCalendar = () => {

    const dispatch = useDispatch()
    const day = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
    const today = new Date()
    const [value, setValue] = React.useState(dayjs(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 3}`));
    const [end, setEnd] = React.useState(dayjs(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 14}`))
    const minday = dayjs(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 3}`)
    const maxday = dayjs(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 15}`)
    const handleChange = (value) => {
        setValue(value)
        const currentDate = new Date(value)
        currentDate.setDate(currentDate.getDate() + 11)
        setEnd(currentDate)
        dispatch(save_reservation_day(new Date(value), currentDate))
    }
    //onChangeされた際に返却日の書き換えを行う処理を作成
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={jaLocale}> 
                <DateCalendar
                    value={value}
                    onChange={(newValue) => handleChange(newValue)}
                    minDate={minday}
                    maxDate={maxday}
                    slots={{ day: Day }}
                    slotProps={{
                    day: {
                        selectedDay: value,
                    },
                    }}
                />
            </LocalizationProvider>
            <Typography variant='subtitle1' component='p' style={{fontWeight: 'bold'}}>
                お届け日:
            </Typography>
            <Typography variant="subtitle1" component="p" style={{fontWeight: 'bold'}}>
                {new Date(value).getMonth() + 1}月{new Date(value).getDate()}日（{day[new Date(value).getDay()]}）
            </Typography>
            <Typography variant='subtitle2' component='p' style={{marginBottom: 3}}>
                ※天候や道路状況によりお荷物の配達に遅延等が発生する場合がございます。
            </Typography>
            <Typography variant='subtitle1' component='p' style={{fontWeight: 'bold'}}>
                返却日:
            </Typography>
            <Typography variant="subtitle1" component="p" style={{fontWeight: 'bold'}}>
                {new Date(end).getMonth() + 1}月{new Date(end).getDate()}日（{day[new Date(end).getDay()]}）
            </Typography>
        </>
    )
}

export default ReservationCalendar
