export const ORDER_REQUEST = 'ORDER_REQUEST'
export const ORDER_SUCCESS = 'ORDER_SUCCESS'
export const ORDER_FAILED = 'ORDER_FAILED'
export const ORDER_DELETE = 'ORDER_DELETE'

export const ORDER_ITEM_LIST_REQUEST = 'ORDER_ITEM_LIST_REQUEST'
export const ORDER_ITEM_LIST_SUCCESS = 'ORDER_ITEM_LIST_SUCCESS'
export const ORDER_ITEM_LIST_FAILED = 'ORDER_ITEM_LIST_FAILED'

export const ORDER_ITEM_GET_REQUEST = 'ORDER_ITEM_GET_REQUES'
export const ORDER_ITEM_GET_SUCCESS = 'ORDER_ITEM_GET_SUCCESS'
export const ORDER_ITEM_GET_FAILED = 'ORDER_ITEM_GET_FAILED'

export const BUYING_INFO_SAVE = 'BUYING_INFO_SAVE'
export const BUYING_INFO_DELETE = 'BUYING_INFO_DELETE'