
import {
    FAVORITE_CREATE_REQUEST,
    FAVORITE_CREATE_SUCCESS,
    FAVORITE_CREATE_FAIL,
    FAVORITE_DELETE_REQUEST,
    FAVORITE_DELETE_SUCCESS,
    FAVORITE_DELETE_FAIL,
    FAVORITE_GET_REQUEST,
    FAVORITE_GET_SUCCESS,
    FAVORITE_GET_FAIL,
    FAVORITE_LIST_REQUEST,
    FAVORITE_LIST_SUCCESS,
    FAVORITE_LIST_FAIL,
} from '../constants/favoriteConstants'
import { baseAxios } from '../jwtInterceptor'


//お気に入りに登録するためのaction
export const favorite_create = (product_id) => async(dispatch) => {
    dispatch({type:FAVORITE_CREATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post(`/api/history/favorite/create/`,{'product': product_id})
    .then(
        async(response) => {
            dispatch({
                type: FAVORITE_CREATE_SUCCESS, payload: response['data']
            })
            await baseAxios.get(`/api/history/favorite/list/`).then(
                (response) => {
                    dispatch({
                        type:FAVORITE_LIST_SUCCESS, payload: response['data']
                    })
                    localStorage.setItem('favorites', JSON.stringify(response['data']))
                }
            ).catch(
                (error) => {
                    dispatch({
                        type:FAVORITE_LIST_FAIL, 
                        payload: error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                    })
                }
            )
        
        }
    ).catch(
        (error) => {
            dispatch({
                type:FAVORITE_CREATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

//favorite update
export const favorite_update = (id, is_notification) => async() => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        "favorite":id,
        "is_notification":is_notification
    }
    await baseAxios.patch('/api/history/favorite/update/', data)
    .then(
        (response) => {
            console.log(response['data'])
        }
    ).catch(
        (error) => {
            console.log(error)
        }
    )
}

//お気に入りから削除するためのaction
export const favorite_delete = (id) => async(dispatch) => {
    dispatch({type:FAVORITE_DELETE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.delete(`/api/history/favorite/delete/${id}`)
    .then(
        (response) => {
            const data = JSON.parse(localStorage.getItem('favorites'))
            for(let f in data){
                if(f['id'] == response['data']['id']){
                    data.pop(f)
                }
            }
            dispatch({
                type:FAVORITE_DELETE_SUCCESS, payload: data
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type:FAVORITE_DELETE_FAIL, payload:
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

//お気に入りの商品一覧を取得するためのaction
export const favorite_list = () => async(dispatch) => {
    dispatch({
        type:FAVORITE_LIST_REQUEST
    })
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/history/favorite/list/`).then(
        (response) => {
            dispatch({
                type:FAVORITE_LIST_SUCCESS, payload: response['data']
            })
            localStorage.setItem('favorites', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({
                type:FAVORITE_LIST_FAIL, 
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

//favoriteをproductscreenアイテムごとに取得する
export const favorite_get = (product) => async(dispatch) => {
    dispatch({type:FAVORITE_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/history/favorite/?product=${product}`).then(
        (response) => {
            dispatch({type: FAVORITE_GET_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:FAVORITE_GET_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}
