import {
    ACCOUNT_LOGIN_REQUEST,
    ACCOUNT_LOGIN_SUCCESS,
    ACCOUNT_LOGIN_FAIL,
    ACCOUNT_LOGOUT,
    ACCOUNT_UPDATE_SUCCESS,
    ACCOUNT_PUBLIC_INFO,
    ACCOUNT_UPDATE_EMAIL,
    EMAIL_UPDATE_REQUEST,
    EMAIL_UPDATE_SUCCESS,
    EMAIL_UPDATE_FAIL,
    EMAIL_SUBSCRIBE_GET_REQUEST,
    EMAIL_SUBSCRIBE_GET_SUCCESS,
    EMAIL_SUBSCRIBE_GET_FAIL,
    EMAIL_SUBSCRIBE_UPDATE_REQUEST,
    EMAIL_SUBSCRIBE_UPDATE_SUCCESS,
    EMAIL_SUBSCRIBE_UPDATE_FAIL,
    PHONE_NUMBER_AUTHENTICATION_REQUEST,
    PHONE_NUMBER_AUTHENTICATION_SUCCESS,
    PHONE_NUMBER_AUTHENTICATION_FAIL,
    PHONE_NUMBER_AUTHENTICATION_DELETE,
    SEND_AUTHENTICATION_NUMBER_REQUEST,
    SEND_AUTHENTICATION_NUMBER_SUCCESS,
    SEND_AUTHENTICATION_NUMBER_FAIL,
} from '../constants/accountConstants'



export const accountLoginReducer = (state = {}, action) => {
    switch(action.type){
        case ACCOUNT_LOGIN_REQUEST:
            return { loading: true }
        case ACCOUNT_LOGIN_SUCCESS:
            return { loading: true, accountInfo: action.payload}
        case ACCOUNT_LOGIN_FAIL:
            return { loading: false, error: action.payload}
        case ACCOUNT_LOGOUT:
            return {accountInfo: action.payload}
        default:
            return state
    }
}

export const accountPublicInfo = (state = {}, action) => {
    switch(action.type){
        case ACCOUNT_PUBLIC_INFO:
            return {accountPublicInfo: action.payload}
        case ACCOUNT_UPDATE_SUCCESS:
            return {accountPublicInfo: action.payload}
        case ACCOUNT_UPDATE_EMAIL:
            return {accountPublicInfo: action.payload}
        case ACCOUNT_LOGOUT:
            return {accountPublicInfo: action.payload}
        default:
            return state
    }
}

export const emailSubscribe = (state = {}, action) => {
    switch(action.type){
        case EMAIL_SUBSCRIBE_GET_REQUEST:
            return {loading: true}
        case EMAIL_SUBSCRIBE_GET_SUCCESS:
            return {loading:false, subscribe:action.payload}
        case EMAIL_SUBSCRIBE_GET_FAIL:
            return {loading:false, error:action.payload}
        case EMAIL_SUBSCRIBE_UPDATE_REQUEST:
            return {loading:true}
        case EMAIL_SUBSCRIBE_UPDATE_SUCCESS:
            return {loading:false, subscibe:action.payload}
        case EMAIL_SUBSCRIBE_UPDATE_FAIL:
            return {loading:false, error:action.payload}
        default:
            return state
    }
}

export const emailReducer = (state = {}, action) => {
    switch(action.type){
        case EMAIL_UPDATE_REQUEST:
            return {loading: true}
        case EMAIL_UPDATE_SUCCESS:
            return {loading: false, message: action.payload}
        case  EMAIL_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const authenticatePhoneNumberReducer = (state = {}, action) => {
    switch(action.type){
        case PHONE_NUMBER_AUTHENTICATION_REQUEST:
            return {loading: true}
        case PHONE_NUMBER_AUTHENTICATION_SUCCESS:
            return {loading: false, authentication: action.payload}
        case PHONE_NUMBER_AUTHENTICATION_DELETE:
            return {authentication: action.payload}
        case PHONE_NUMBER_AUTHENTICATION_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const sendAuthenticationNumberReducer = (state = {}, action) => {
    switch(action.type){
        case SEND_AUTHENTICATION_NUMBER_REQUEST:
            return { send_loading: true }
        case SEND_AUTHENTICATION_NUMBER_SUCCESS:
            return { loading: false, send: action.payload}
        case SEND_AUTHENTICATION_NUMBER_FAIL:
            return {loading: false, send_error: action.payload}
        default:
            return state
    }
}
