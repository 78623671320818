import axios from "axios";
import { 
    COUPON_GET_REQUEST,
    COUPON_GET_SUCCESS,
    COUPON_GET_FAIL,
    COUPON_DISCOUNT_REQUEST,
    COUPON_DISCOUNT_SUCCESS,
    COUPON_DISCOUNT_FAIL,
    COUPON_DISCOUNT_DELETE,
    UTILISER_COUPON_REQUEST,
    UTILISER_COUPON_SUCCESS,
    UTILISER_COUPON_FAIL,
    COUPON_INVITATION_REQUEST,
    COUPON_INVITATION_SUCCESS,
    COUPON_INVITATION_FAIL,
    ISSUING_LIST_REQUEST,
    ISSUING_LIST_SUCCESS,
    ISSUING_LIST_FAIL,
    INVITATION_CREATE_REQUEST,
    INVITATION_CREATE_SUCCESS,
    INVITATION_CREATE_FAIL
} from "../constants/couponConstans";

import { baseAxios } from "../jwtInterceptor";

export const get_invitation_coupon = () => async(dispatch) => {
    dispatch({type: COUPON_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/coupon/code/').then(
        (response) => {
            dispatch({type:COUPON_GET_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: COUPON_GET_FAIL,
                payload: 
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const coupon_discount = (coupon, price) => async(dispatch) => {
    dispatch({type:COUPON_DISCOUNT_REQUEST})
    const data = {
        'coupon': coupon,
        'price': price
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/coupon/discount', data).then(
        (response) => {
            dispatch({type: COUPON_DISCOUNT_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: COUPON_DISCOUNT_FAIL,
                payload: 
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const coupon_discount_delete = () => (dispatch) => {
    dispatch({type: COUPON_DISCOUNT_DELETE, payload: ''})
}

export const utiliser_coupon = (coupon) => async(dispatch) => {
    dispatch({type:UTILISER_COUPON_REQUEST})
    const data = {
        'coupon': coupon
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/coupon/utilised/', data).then(
        (response) => {
            dispatch({type: UTILISER_COUPON_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: UTILISER_COUPON_FAIL,
                payload: 
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const create_invitation_coupon = (invitation_code, phone_number) => async(dispatch) => {
    dispatch({type: COUPON_INVITATION_REQUEST})
    const data = {
        'phone_number': phone_number,
        'invitation_code': invitation_code
    }
    const config = {headers:{'Content-Type':'Application/json'}}
    await axios.post(process.env.REACT_APP_URL + '/api/coupon/invitation/', data, config).then(
        (response) => {
            dispatch({type: COUPON_INVITATION_SUCCESS, payload: response['data']})
            axios.post(process.env.REACT_APP_URL + '/api/coupon/create_invitation/', data, config)
        }
    ).catch(
        (error) => {
            dispatch({
                type: COUPON_INVITATION_FAIL,
                payload: 
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

//利用可能なクーポンを獲得
export const issuing_list = (type) => async(dispatch) => {
    dispatch({type: ISSUING_LIST_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/coupon/issuing_list/?type=${type}`).then(
        (response) => {
            dispatch({type: ISSUING_LIST_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: ISSUING_LIST_FAIL,
                payload: 
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

//招待した際に招待されたことがあるか電話番号で確認する
export const invitation_create = (invitation_code, phone_number) => async(dispatch) => {
    dispatch({type: INVITATION_CREATE_REQUEST})
    const data = {
        'invitation_code': invitation_code,
        'phone_number': phone_number
    }
    const config = {headers:{'Content-Type':'Application/json'}}
    await axios.post(process.env.REACT_APP_URL + '/api/coupon/create_invitation/', data, config).then(
        (response) => {
            dispatch({type: INVITATION_CREATE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: INVITATION_CREATE_FAIL,
                payload: 
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
    
}