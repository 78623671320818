import React,{useState} from 'react';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider' ;
import { makeStyles } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating'
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//action
import { createReview } from '../actions/productActions';

//formik
import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    button:{
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        },
        marginTop: theme.spacing(3)
    },
    card:{
        width: '100%',
        backgroundColor:'white',
        color:'black',
    },
    container:{
        padding:'initial',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    divider:{
        background:theme.palette.primary['400']
    },
    field:{
        marginTop: theme.spacing(3)
    },
    form:{
        borderColor:`${theme.palette.primary['900']} !important`,
    },
    input_text:{
        color:'black'
    },
    multilineColor:{
        color: theme.palette.primary['400'],
    },
    rating:{
        color: '#1de9b6',
    },
    text_field:{
        color: 'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    title:{
        marginTop: theme.spacing(1),
        color:'black'
    },
}))

const validationSchema = yup.object({
    title: yup
    .string('')
    .max(50, '50文字以内で入力して下さい')
    .required('入力は必須です'),
    comment: yup
    .string('')
    .max(1000, '1000字以内で入力して下さい')
    .required('入力は必須です')
})

function CommentField({product}){
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const [value, setValue] = useState(5)
    
    const formik = useFormik({
        initialValues:{
            title:'',
            comment:''
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            event.preventDefault()
            dispatch(createReview(product, values.title, values.comment, value))
            history.push(`/product/${product}`)
        }
    })

    return(
            <>
                <Card className={classes.card}>
                    <CardContent>
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <Typography
                            className={classes.title}
                            variant='h1'
                            component="h1"
                            align="center">
                                レビュー
                            </Typography>
                            <Divider classes={{root:classes.divider}}/>
                            <Typography
                            className={classes.title}
                            variant="subtitle1"
                            component="p"
                            >
                                評価
                            </Typography>
                            <Rating
                            name="review-controlled"
                            multiline
                            value={value}
                            precision={0.5}
                            className={classes.rating}
                            onChange={(event)=>{setValue(event.target.value)}}
                            />
                            <TextField
                            color='primary'
                            fullWidth
                            required
                            id="title"
                            label='タイトル'
                            value={formik.values.title}
                            variant="outlined"
                            className={classes.field}
                            name="title"
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                            InputLabelProps={{
                                classes:{
                                    root:classes.input_text
                                }
                            }}
                            InputProps ={{
                                classes: {
                                    root:classes.text_field,
                                    notchedOutline: classes.text_field
                                }
                            }}
                            onChange={formik.handleChange}
                            />
                            <TextField
                            fullWidth
                            id="comment"
                            name="comment"
                            label="レビュー"
                            required
                            multiline
                            className={classes.field}
                            value={formik.values.comment}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                            variant="outlined"
                            InputLabelProps={{
                                classes:{
                                    root:classes.input_text
                                }
                            }}
                            InputProps ={{
                                classes: {
                                    root:classes.text_field,
                                    notchedOutline: classes.text_field
                                }
                            }}
                            rows={10}
                            onChange={formik.handleChange}
                            />
                            <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            className={classes.button}>
                                投稿
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </>

    )
}

export default CommentField