import {
    ADRESS_GET_REQUEST,
    ADRESS_GET_SUCCESS,
    ADRESS_GET_FAIL,
    ADRESS_UPDATE_REQUEST,
    ADRESS_UPDATE_SUCCESS,
    ADRESS_UPDATE_FAIL,
} from '../constants/adressConstants'

export const adressGetReducer = (state = {}, action) => {
    switch(action.type){
        case ADRESS_GET_REQUEST:
            return {loading: true}
        case ADRESS_GET_SUCCESS:
            return { loading: false, adressInfo: action.payload}
        case ADRESS_GET_FAIL:
            return { loading: false, error: action.payload}
        case ADRESS_UPDATE_REQUEST:
            return {loading: true}
        case ADRESS_UPDATE_SUCCESS:
            return {loading:false,adressInfo: action.payload}
        case ADRESS_UPDATE_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}

