export const pageView = (title, path) => {
    if(process.env.REACT_APP_GOOGLE_ANALYTIC_ID){
        window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTIC_ID,{
            page_title: title, 
            page_path: path,
        });
    }else{
        console.log(
            "Tracking not enabled"
        )
    }
}