import {
    CLOSET_LIST_REQUEST,
    CLOSET_LIST_SUCCESS,
    CLOSET_LIST_FAIL,
    CLOSET_GET_REQUEST,
    CLOSET_GET_SUCCESS,
    CLOSET_GET_FAIL,
    CLOSET_CREATE_REQUEST,
    CLOSET_CREATE_SUCCESS,
    CLOSET_CREATE_FAIL,
    CLOSET_DELETE_REQUEST,
    CLOSET_DELETE,
    CLOSET_DELETE_FAIL,
    CLOSET_UPDATE_REQUEST,
    CLOSET_UPDATE,
    CLOSET_UPDATE_FAIL,
    BASIC_CLOTH_CREATE_REQUEST,
    BASIC_CLOTH_CREATE_SUCCESS,
    BASIC_CLOTH_CREATE_FAIL,
    PREMIUM_CLOTH_CREATE_REQUEST,
    PREMIUM_CLOTH_CREATE_SUCCESS,
    PREMIUM_CLOTH_CREATE_FAIL,
    CLOTH_LIST_REQUEST,
    CLOTH_LIST_SUCCESS,
    CLOTH_LIST_FAIL,
    CLOSET_REGISTER_CART_REQUEST,
    CLOSET_REGISTER_CART_SUCCESS,
    CLOSET_REGISTER_CART_FAIL
} from '../constants/closetConstant'

export const closetListReducer = (state = {closets: []}, action) => {
    switch(action.type){
        case CLOSET_LIST_REQUEST:
            return {loading: true, closets: []}
        case CLOSET_LIST_SUCCESS:
            return {loading:false, closets: action.payload}
        case CLOSET_LIST_FAIL:
            return {loading:false, error: action.payload}
       
        default:
            return state
    }
}

export const closetCreateReducer = (state = {}, action) => {
    switch(action.type){
        case CLOSET_GET_REQUEST:
            return {loading:true}
        case CLOSET_GET_SUCCESS:
            return {loading:false, closet:action.payload}
        case CLOSET_GET_FAIL:
            return {loading:false, error:action.payload}
        case CLOSET_CREATE_REQUEST:
            return {loading: true}
        case CLOSET_CREATE_SUCCESS:
            return {loading:false, closet: action.payload}
        case CLOSET_CREATE_FAIL:
            return {loading:false, error: action.payload}
        case CLOSET_UPDATE_REQUEST:
            return {loading: true}
        case CLOSET_UPDATE:
            return {loading:false, error: action.payload}
        case CLOSET_UPDATE_FAIL:
            return {loading:false, closet: action.payload}
        case CLOSET_DELETE_REQUEST:
            return {loading: true}
        case CLOSET_DELETE:
            return {loading:false, closets: action.payload}
        case CLOSET_DELETE_FAIL:
            return {loading:false, error: action.payload}
        case CLOSET_REGISTER_CART_REQUEST:
            return {loading:true}
        case CLOSET_REGISTER_CART_SUCCESS:
            return {loading:false, closets: action.payload}
        case CLOSET_REGISTER_CART_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}

export const clothCreateReducer = (state = {}, action) => {
    switch(action.type){
        case BASIC_CLOTH_CREATE_REQUEST:
            return{loading: true}
        case BASIC_CLOTH_CREATE_SUCCESS:
            return {loading:false, cloth: action.payload}
        case BASIC_CLOTH_CREATE_FAIL:
            return {loading:false, cloth: action.payload}
        case PREMIUM_CLOTH_CREATE_REQUEST:
            return {loading:true}
        case PREMIUM_CLOTH_CREATE_SUCCESS:
            return {loading: false, cloth: action.payload}
        case PREMIUM_CLOTH_CREATE_FAIL:
            return {loading:false, cloth: action.payload}
        default:
            return state
    }
}

export const clothListReducer = (state = {}, action) => {
    switch(action.type){
        case CLOTH_LIST_REQUEST:
            return {loading: true}
        case CLOTH_LIST_SUCCESS:
            return{loading: false, cloth: action.payload}
        case CLOTH_LIST_FAIL:
            return{loading:false, error: action.payload}
        default:
            return state
    }
}
