import React,{useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation,useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { Button} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ClickAwayListener } from '@material-ui/core';
import { Grow } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { MenuList } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { MenuItem } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Paper } from '@material-ui/core';
import { Popper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

//component
import Category from '../components/Category';
import Loader from '../components/Loader';
import Product from '../components/Product';
import PageSwitchingComponent from '../components/PageSwitchingComponent';

//action
import { brand_products_get } from '../actions/categoryAction';
import { brand_evaluate_get } from '../actions/categoryAction';
import { brand_evaluate_desc_get } from '../actions/categoryAction';
import { brand_favorite_get } from '../actions/categoryAction';
import { brand_favorite_desc_get } from '../actions/categoryAction';
import { brand_product_filter } from '../actions/categoryAction';
import { brand_reservation_get } from '../actions/categoryAction';
import { brand_reservation_desc_get } from '../actions/categoryAction';
import { brand_price_get } from '../actions/categoryAction';
import { brand_price_desc_get } from '../actions/categoryAction';
import { brand_category_get } from '../actions/categoryAction';
import { brand_category_name_get } from '../actions/categoryAction';
import { brand_type_get } from '../actions/categoryAction';
import { brand_type_name_get } from '../actions/categoryAction';
import { search_word_get } from '../actions/historyAction';
//gender fileter
import { brand_gender_filter } from '../actions/categoryAction';
import { brand_gender_data_save } from '../actions/categoryAction';
import { type_get } from '../actions/categoryAction';

import { headRewrite } from '../function/headRewrite';
//react-helmet-async

//function
import { pageView } from '../function/gaTraking';

const useStyles = makeStyles((theme) => ({
    all:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial',
        minWidth: '43px'
    },
    button:{
        backgroundColor:'inherit',
        color: 'white',
        border: 'solid 1px #fff',
        marginRight: theme.spacing(2),
        '&:hover':{
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 'inherit'
        }
    },
    category:{
        margin: theme.spacing(2,3,0,3)
    },
    container :{
        width: '100%',
    },
    checkbox:{
        marginTop:8,
        marginLeft:'auto',
        display:'flex'
    },
    error_container:{
        height:'100vh',
        marginTop: theme.spacing(3)
    },
    female:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial'
    },
    gender:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    filter:{
        display:'flex',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    image:{
        position: 'relative'
    },
    icon_button:{
        paddingTop: 'initial',
        paddingBottom: 'initial',
        '&:hover':{
            backgroundColor:'initial'
        }
    },
    inner:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'25%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    male:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial',
    },
    order:{
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: theme.spacing(1)
    },
    paper:{
        backgroundColor: 'white',
        color: 'black'
    },
    pagination:{
        margin: theme.spacing(3,0,2,0)
    },
    products_container:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(15),
        padding: 'initial'
    },
    subtitle:{
        margin: theme.spacing(0, 0, 1, 1)
    },
    title:{
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    }
}))



function BrandProductScreen(){
    const dispatch = useDispatch()
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()

    const {id} = useParams()
    const brand_name = id

    const params = new URLSearchParams(location.search)
    const brand_id = Number(params.get('number'))
    const param = params.get('page') == null ? undefined : Number(params.get('page'))
    const param_title = params.get('title') == null ? undefined : params.get('title')

    //useRef
    const orderRef = useRef(null)

    //usestate
    const [orderOpen, setOrderOpen] = useState(false)
    const [title, setTitle] = useState(param_title == undefined ? '新着' : param_title)
    const [checked, setChecked] = useState(false)
    const [page, setPage] = useState(1)

    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {categorys} = useSelector(state => state.category)
    const {closets} = useSelector(state => state.closetList)
    const {loading, products, error} = useSelector(state => state.brandProducts)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)

    //head info
    const head_title = `ファッションブランド${brand_name}のアイテム一覧からレンタル｜Enclopedia【公式サイト】`
    const head_description =   `ファッションブランド${brand_name}の過去アーカイブアイテムをファッションレンタルサービスEnclopedia（アンクロペディア）で借りよう。`

    headRewrite(head_title, head_description)

    const handleTitle = (title) => {
        setTitle(title)
    }

    const handleCategory = () => {
        setChecked(false)
    }

    //並び替えメニュー
    const handleOrderToggle = () => {
        setOrderOpen((prevOrderOpen) => !prevOrderOpen)
    }
    const handleOrderClose = () => {
        setOrderOpen(false)
     }
    const listDownHandler = (event) => {
        if(event.key === 'Tab'){
            event.preventDefault();
            setOrderOpen(false)
        }
    }
    //全て
    const handleProduct = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&title=新着&page=1`)
        setChecked(false)
        setTitle('新着')
        await dispatch(brand_products_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //評価高い順
    const handleEvaluation = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=評価が高い順`)
        setChecked(false)
        setTitle('評価が高い順')
        await dispatch(brand_evaluate_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //評価が低い順
    const handleEvaluationDesc = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=評価が低い順`)
        setChecked(false)
        setTitle('評価が低い順')
        await dispatch(brand_evaluate_desc_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
     }
    //お気に入りが多い順
    const handleEvaluationFavorite = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=お気に入り数が多い順`)
        setChecked(false)
        setTitle('お気に入り数が多い順')
        await dispatch(brand_favorite_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //お気に入りが少ない順
    const handleEvaluationFavoriteDesc = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=お気に入り数が少ない順`)
        setChecked(false)
        setTitle('お気に入り数が少ない順')
        await dispatch(brand_favorite_desc_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //予約件数の多い順位に並び替えを行う
    const handleReservation = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=予約数が多い順`)
        setChecked(false)
        setTitle('予約数が多い順')
        await dispatch(brand_reservation_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //予約件数の少ない順位並び替えを行う
    const handleReservationDesc = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=予約数が少ない順`)
        setChecked(false)
        setTitle('予約数が少ない順')
        await dispatch(brand_reservation_desc_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //価格の安い順に並び替えを行う
    const handlePrice = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=価格が安い順`)
        setChecked(false)
        setTitle('価格が安い順')
        await dispatch(brand_price_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }
    //価格が高い順
    const handlePriceDesc = async() => {
        history.push(`/brand/${brand_name}/?number=${brand_id}&page=1&title=価格が高い順`)
        setChecked(false)
        setTitle('価格が高い順')
        await dispatch(brand_price_desc_get(brand_id, 1))
        dispatch(brand_gender_data_save())
        dispatch(type_get())
    }

    //貸し出し可能な商品のみを表示する
    const handleFileter = (check) => {
        setChecked((prevChecked) => !prevChecked)
        dispatch(brand_product_filter(check, subscriptionInfo.plan))
    }

    const handleGenderFilter = (gender) => {
        setChecked(false)
        dispatch(brand_gender_filter(gender))
    }

    const handlePagination = async(event, value) => {
        event.preventDefault()
        setPage(value)
        setChecked(false)
        switch(title){
            case '新着':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=新着`)
                await dispatch(brand_products_get(brand_id, value))
                dispatch(brand_gender_data_save())
                return
            case '評価が高い順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=評価が高い順`)
                await dispatch(brand_evaluate_get(brand_id, value))
                dispatch(brand_gender_data_save())
                return
            case '評価が低い順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=評価が低い順`)
                await dispatch(brand_evaluate_desc_get(brand_id, value))
                dispatch(brand_gender_data_save())
                return
            case 'お気に入り数が多い順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=お気に入り数が多い順`)
                await dispatch(brand_favorite_get(brand_id, value))
                dispatch(brand_gender_data_save())
                return
            case 'お気に入り数が少ない順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=お気に入り数が少ない順`)
                await dispatch(brand_favorite_desc_get(brand_id, value))
                dispatch(brand_gender_data_save())
                return
            case '予約数が多い順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=予約数が多い順`)
                await brand_reservation_get(brand_id, value)
                dispatch(brand_gender_data_save())
                return
            case '予約数が少ない順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=予約数が少ない順`)
                await brand_reservation_desc_get(brand_id, value)
                dispatch(brand_gender_data_save())
                return
            case '価格が安い順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=価格が安い順`)
                await brand_reservation_get(brand_id, value)
                dispatch(brand_gender_data_save())
                return
            case '価格が高い順':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=価格が高い順`)
                await brand_reservation_desc_get(brand_id, value)
                dispatch(brand_gender_data_save())
                return
            case 'トップス' || 'ジャケット' || 'コート' || 'パンツ' || 'スカート' || 'ワンピース' || 'スーツ' || 'バッグ' || 'シューズ' || 'アクセサリー' || 'ルームウェア' || 'その他':
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=${title}`)
                {
                    setChecked(false)
                    if(categorys != undefined){
                        const category = categorys.find((cate) => {
                            cate.category_name === title
                        })
                        await dispatch(brand_type_get(brand_id, category.id, value))
                    }
                }
                dispatch(brand_gender_data_save())
                return
            default:
                history.push(`/brand/${brand_name}/?number=${brand_id}&page=${value}&title=${title}`)
                {
                    setChecked(false)
                    const category = categorys.find((cate) => {
                        cate.category_name === title
                    })
                    dispatch(brand_category_get(brand_id, category.id, value))
                }
                dispatch(brand_gender_data_save())
                return
        }
    }

    const handlePageUpdate = (number) => {
        setPage(number)
    }

    useEffect(() => {
        pageView(`brand:${id}`, location.pathname)
        if(accountInfo){
            dispatch(search_word_get)
        }
        switch(param_title){
            case undefined:
            case '新着':
                dispatch(brand_products_get(brand_id, param == undefined ? 1 : param))
                setTitle('新着')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '評価が高い順':
                dispatch(brand_evaluate_get(brand_id, param == undefined ? 1 : param))
                setTitle('評価が高い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '評価が低い順':
                dispatch(brand_evaluate_desc_get(brand_id, param == undefined ? 1 : param))
                setTitle('評価が低い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case 'お気に入り数が多い順':
                dispatch(brand_favorite_get(brand_id, param == undefined ? 1 : param))
                setTitle('お気に入り数が多い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case 'お気に入り数が少ない順':
                dispatch(brand_favorite_desc_get(brand_id, param == undefined ? 1 : param))
                setTitle('お気に入り数が少ない順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '予約数が多い順':
                dispatch(brand_reservation_get(brand_id, param == undefined ? 1 : param))
                setTitle('予約数が多い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '予約数が少ない順':
                dispatch(brand_reservation_desc_get(brand_id, param == undefined ? 1 : param))
                setTitle('予約数が少ない順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '料金が安い順':
                dispatch(brand_price_get(brand_id, param == undefined ? 1 : param))
                setTitle('料金が安い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '料金が高い順':
                dispatch(brand_price_desc_get(brand_id, param == undefined ? 1 : param))
                setTitle('料金が安い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case 'トップス':
            case 'ジャケット':
            case 'コート':
            case 'パンツ':
            case 'スカート':
            case 'ワンピース':
            case 'スーツ':
            case 'バッグ':
            case 'シューズ':
            case 'アクセサリー':
            case 'ルームウェア':
            case 'その他':
                setTitle(param_title)
                setPage(param == undefined ? 1 : param)
                dispatch(brand_type_name_get(brand_id, param_title, param == undefined ? 1 : param))
                return
            default:
                setTitle(param_title)
                setPage(param == undefined ? 1 : param)
                console.log(param_title)
                dispatch(brand_category_name_get(brand_id, param_title, param == undefined ? 1 : param))
        }
    }, [location.search])

    return(
        <>
        <div className={classes.container}>
        <PageSwitchingComponent param={id}/>
        <Category keyword={brand_id} brand_name={brand_name} handle={handleCategory} handleTitle={handleTitle} pagination={handlePageUpdate}/>
        <div className={classes.filter}>
            <Box className={classes.order}>
                <div>
                    <Typography variant="subtitle2" component="p" style={{textAlign:'center', padding:'initial'}}>並び替え</Typography>
                    <IconButton 
                    ref={orderRef}
                    color="inherit"
                    className={classes.icon_button}
                    size="medium"
                    aria-label='order_menu'
                    onClick={handleOrderToggle}
                    >
                        <Menu/>
                    </IconButton>
                </div>
                <Popper 
                style={{zIndex:3500}} 
                open={orderOpen} 
                anchorEl={orderRef.current} 
                role={undefined} 
                placement={"bottom-start"} 
                popperOptions={'bottom-start'}
                direction={'rtl'}
                transition 
                disablePortal >
                    {({ TransitionProps}) => (
                        <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'left top' }}
                        >
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleOrderClose}>
                                    <MenuList autoFocusItem={orderOpen} onKeyDown={listDownHandler}>
                                        <MenuItem keys="normal" onClick={handleProduct}>
                                            <Typography variant="subtitle1" component="p">新着</Typography>
                                        </MenuItem>
                                        <MenuItem keys="evaluation_order" onClick={handleEvaluation}>
                                            <Typography variant="subtitle1" component="p">評価の高い順</Typography>
                                        </MenuItem>
                                        <MenuItem keys="evaluation_order_desc" onClick={handleEvaluationDesc}>
                                            <Typography variant="subtitle1" component="p">評価の低い順</Typography>
                                        </MenuItem>
                                        <MenuItem keys="favorite_order" onClick={handleEvaluationFavorite}>
                                            <Typography variant="subtitle1" component="p">お気に入りの多い順</Typography>
                                        </MenuItem>
                                        <MenuItem  keys="favorite_order_desc" onClick={handleEvaluationFavoriteDesc}>
                                            <Typography variant="subtitle1" component="p">お気に入りの少ない順</Typography>
                                        </MenuItem>
                                        <MenuItem keys="reservation_order" onClick={handleReservation}>
                                            <Typography variant="subtitle1" component="p">予約数の多い順</Typography>
                                        </MenuItem>
                                        <MenuItem keys="reservation_order_desc" onClick={handleReservationDesc}>
                                            <Typography variant="subtitle1" component="p">予約件数の少ない順</Typography>
                                        </MenuItem>
                                        <MenuItem keys="price_order" onClick={handlePrice}>
                                            <Typography variant="subtitle1" component="p">価格が安い順</Typography>
                                        </MenuItem>
                                        <MenuItem keys="price_order_desc" onClick={handlePriceDesc}>
                                            <Typography variant="subtitle1" component="div">価格が高い順</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
            {
                error ?
                ''
                :
                <Box className={classes.checkbox}>
                    <Typography variant="subtitle2" component="p" style={{marginTop:18}}>貸出可能な商品</Typography>
                    <Checkbox 
                    checked={checked}
                    onChange={(event) => {handleFileter(event.target.checked)}} 
                    color='#bdbdbd'
                    style={{backgroundColor:'initial'}}
                    />
                </Box>
            }
        </div>
        {
            error ?
            ''
            :
            <div className={classes.gender}>
                <Button variant="contained" className={classes.all} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('all')}>
                    <Typography variant="subtitle2" component="p">すべて</Typography>
                </Button>
                <Button variant="contained" className={classes.male} onClick={() => handleGenderFilter('メンズ')}>
                    <Typography variant="subtitle2" component="p">メンズ</Typography>
                </Button>
                <Button variant="contained" className={classes.female} onClick={() => handleGenderFilter('レディース')}>
                    <Typography variant="subtitle2" component="p">レディース</Typography>
                </Button>
            </div>
        }
        <Container maxWidth="xl" className={classes.products_container}>
            <div className={classes.inner}>
                    {loading ? 
                        <div className={classes.loader}>
                            <Loader />
                        </div>
                        : error ? 
                        <div className={classes.error_container}>
                            <Typography variant='subtitle1' component="p" align='center'>
                                商品が見つかりませんでした
                            </Typography>
                        </div>
                        :
                        <>
                            <Typography variant="h1" component="h1" align='center' className={classes.title}>
                                {brand_name}
                            </Typography>
                            <Typography variant="h2" component="h2" className={classes.subtitle}>
                                {title} {products['results'] ? products['results'].length : 0}件
                            </Typography>
                            <Grid container spacing={1} >
                                {products['results'].map(product => (
                                <Grid key={product.id} item xs={6} sm={6} md={4} lg={3} xl={3}>
                                    <Product product={product} closets={closets} token={accountInfo.access} key={product.id}/>
                                </Grid>))}
                            </Grid>
                            <div className={classes.pagination}>
                                <Pagination count={products['count'] / 40 < 40 ? 1 : Math.ceil(products['count'] / 40)} page={page} onChange={handlePagination}/>
                            </div>
                        </>
                    }

                </div>
        </Container>
    </div>
    </>
    )
}

export default BrandProductScreen