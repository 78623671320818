export const PRODUCT_DETAIL_REQUEST = 'PRODUCT_DETAIL_REQUEST'
export const PRODUCT_DETAIL_SUCCESS = 'PRODUCT_DETAIL_SUCCESS'
export const PRODUCT_DETAIL_FAIL = 'PRODUCT_DETAIL_FAIL'

export const PRODUCT_GENDER_REQUEST = "PRODUCT_GENDER_REQUEST"
export const PRODUCT_GENDER_SUCCESS = "PRODUCT_GENDER_SUCCESS"
export const PRODUCT_GENDER_FAIL = "PRODUCT_GENDER_FAIL "

export const PRODUCT_SIZE_REQUEST = 'PRODUCT_SIZE_REQUEST'
export const PRODUCT_SIZE_SUCCESS = 'PRODUCT_SIZE_SUCCESS'
export const PRODUCT_SIZE_FAIL = 'PRODUCT_SIZE_FAIL'

export const REVIEW_CREATE_REQUEST = 'REVIEW_CREATE_REQUEST'
export const REVIEW_CREATE_SUCCESS = 'REVIEW_CREATE_SUCCESS'
export const REVIEW_CREATE_FAIL = 'REVIEW_CREATE_FAIL'
export const REVIEW_CREATE_RESET = 'REVIEW_CREATE_RESET'

export const REVIEW_LIST_GET_REQUEST = 'REVIEW_LIST_GET_REQUEST'
export const REVIEW_LIST_GET_SUCCESS = 'REVIEW_LIST_GET_SUCCESS'
export const REVIEW_LIST_GET_FAIL = 'REVIEW_LIST_GET_FAIL'

export const REVIEW_LIST_REQUEST = 'REVIEW_LIST_REQUEST'
export const REVIEW_LIST_SUCCESS = 'REVIEW_LIST_SUCCESS'
export const REVIEW_LIST_FAIL = 'REVIEW_LIST_FAIL'

export const VARIATION_GET_REQUEST = 'VARIATION_GET_REQUEST'
export const VARIATION_GET_SUCCESS = 'VARIATION_GET_SUCCESS'
export const VARIATION_GET_FAIL = 'VARIATION_GET_FAIL'

//男女別に商品を取得する
export const PRODUCT_GENDER_ORIGIN_DATA = 'PRODUCT_GENDER_ORIGIN_DATA'
export const PRODUCT_GENDER_FILTER_SUCCESS = 'PRODUCT_GENDER_FILTER_SUCCESS'

//home画面のプロダクトを首都kするためのconstants
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODCUT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

//お気に入りの多い順
export const PRODUCT_EVALUATION_FAVORITE_REQUEST = 'PRODUCT_EVALUATION_FAVORITE_REQUEST'
export const RRODUCT_EVALUATION_FAVORITE = 'RRODUCT_EVALUATION_FAVORITE'
export const PRODUCT_EVALUATION_FAVORITE_FAIL = 'PRODUCT_EVALUATION_FAVORITE_FAIL'
//お気に入りの少ない順
export const PRODUCT_EVALUATION_FAVORITE_DESC_REQUEST = 'PRODUCT_EVALUATION_FAVORITE_DESC_REQUEST'
export const PRODUCT_EVALUATION_FAVORITE_DESC = 'PRODUCT_EVALUATION_FAVORITE_DESC'
export const PRODUCT_EVALUATION_FAVORITE_DESC_FAIL = 'PRODUCT_EVALUATION_FAVORITE_DESC_FAIL'
//評価の高い順
export const PRODUCT_EVALUATION_REVIEW_REQUEST = 'PRODUCT_EVALUATION_REVIEW_REQUEST'
export const PRODUCT_EVALUATION_REVIEW = 'PRODUCT_EVALUATION_REVIEW'
export const PRODUCT_EVALUATION_REVIEW_FAIL = 'PRODUCT_EVALUATION_REVIEW_FAIL'
//評価の低い順
export const PRODUCT_EVALUATION_REVIEW_DESC_REQUEST = 'PRODUCT_EVALUATION_REVIEW_DESC_REQUEST'
export const PRODUCT_EVALUATION_REVIEW_DESC = 'PRODUCT_EVALUATION_REVIEW_DESC'
export const PRODUCT_EVALUATION_REVIEW_DESC_FAIL = 'PRODUCT_EVALUATION_REVIEW_DESC_FAIL'
//予約数の多い順
export const PRODUCT_RESERVATION_ORDER_REQUEST = 'PRODUCT_RESERVATION_ORDER_REQUEST'
export const PRODUCT_RESERVATION_ORDER = 'PRODUCT_RESERVATION_ORDER'
export const PRODUCT_RESERVATION_ORDER_FAIL = 'PRODUCT_RESERVATION_ORDER_FAIL'
//予約数の少ない順
export const PRODUCT_RESERVATION_ORDER_DESC_REQUEST = 'PRODUCT_RESERVATION_ORDER_DESC_REQUEST'
export const PRODUCT_RESERVATION_ORDER_DESC = 'PRODUCT_RESERVATION_ORDER_DESC'
export const PRODUCT_RESERVATION_ORDER_DESC_FAIL = 'PRODUCT_RESERVATION_ORDER_DESC_FAIL'
//値段順
export const PORDUCT_PRICE_ORDER_REQUEST = 'PORDUCT_PRICE_ORDER_REQUEST'
export const PRODUCT_PRICE_ORDER_SUCCESS = 'PRODUCT_PRICE_ORDER_SUCCESS'
export const PRODUCT_PRICE_ORDER_FAIL = 'PRODUCT_PRICE_ORDER_FAIL'

export const PRODCUT_PRICE_ORDER_DESC_REQUEST = 'PRODCUT_PRICE_ORDER_DESC_REQUEST'
export const PRODUCT_PRICE_ORDER_DESC_SUCCESS = 'PRODUCT_PRICE_ORDER_DESC_SUCCESS'
export const PRODUCT_PRICE_ORDER_DESC_FAIL = 'PRODUCT_PRICE_ORDER_DESC_FAIL'

export const PRODUCT_RENTAL = 'PRODUCT_RENTAL'

export const PRODUCT_PLAN = 'PRODUCT_PLAN'
export const PLAN_ORIGIN_DATA = 'PLAN_ORIGIN_DATA'

export const PRODUCT_CATEGORY_REQUEST = 'PRODUCT_CATEGORY_REQUEST'
export const PRODUCT_CATEGORY = 'PRODUCT_CATEGORY'
export const PRODUCT_CATEGORY_FAIL = 'PRODUCT_CATEGORY_FAIL'


//商品閲覧履歴を保存する
export const PRODUCT_HISTORY_SAVE = 'PRODUCT_HISTORY_SAVE'
export const PRODUCT_HISTORY_SAVE_FAIL = 'PRODUCT_HISTORY_SAVE_FAIL'

//productサーチ画面で使用するconstant
export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST'
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS'
export const PRODUCT_SEARCH_FAIL = 'PRODUCT_SEARCH_FAIL'

//realted product
export const PRODUCT_RELATED_REQUEST = 'PRODUCT_RELATED_REQUEST'
export const PRODUCT_RELATED_SUCCESS = 'PRODUCT_RELATED_SUCCESS'
export const PRODUCT_RELATED_FAIL = 'PRODUCT_RELATED_FAIL'

export const SEARCH_PLAN_ORIGIN_DATA = 'SEARCH_PLAN_ORIGIN_DATA'
export const SEARCH_PRODUCT_PLAN = 'SEARCH_PRODUCT_PLAN'

export const SEARCH_PRODUCT_GENDER_ORIGIN_DATA = 'SEARCH_PRODUCT_GENDER_ORIGIN_DATA'
export const SEARCH_PRODUCT_GENDER_FILTER_SUCCESS = 'SEARCH_PRODUCT_GENDER_FILTER_SUCCESS'


export const SEARCH_PRODUCT_RENTAL = 'SEARCH_PRODUCT_RENTA'

export const SEARCH_EVALUATION_FAVORITE_REQUEST = 'SEARCH_EVALUATION_FAVORITE_REQUEST '
export const SEARCH_EVALUATION_FAVORITE = 'SEARCH_EVALUATION_FAVORITE'
export const SEARCH_EVALUATION_FAVORITE_FAIL = 'SEARCH_EVALUATION_FAVORITE_FAIL'

export const SEARCH_EVALUATION_FAVORITE_DESC_REQUEST = 'SEARCH_EVALUATION_FAVORITE_DESC_REQUEST'
export const SEARCH_EVALUATION_FAVORITE_DESC = 'SEARCH_EVALUATION_FAVORITE_DESC'
export const SEARCH_EVALUATION_FAVORITE_DESC_FAIL = 'SEARCH_EVALUATION_FAVORITE_DESC_FAIL'

export const SEARCH_EVALUATION_REVIEW_REQUEST = 'SEARCH_EVALUATION_REVIEW_REQUEST'
export const SEARCH_EVALUATION_REVIEW = 'SEARCH_EVALUATION_REVIEW'
export const SEARCH_EVALUATION_REVIEW_FAIL = 'SEARCH_EVALUATION_REVIEW_FAIL'

export const SEARCH_EVALUATION_REVIEW_DESC_REQUEST = 'SEARCH_EVALUATION_REVIEW_DESC_REQUEST'
export const SEARCH_EVALUATION_REVIEW_DESC = 'SEARCH_EVALUATION_REVIEW_DESC'
export const SEARCH_EVALUATION_REVIEW_DESC_FAIL = 'SEARCH_EVALUATION_REVIEW_DESC_FAIL'

export const SEARCH_RESERVATION_ORDER_REQUEST = 'SEARCH_RESERVATION_ORDER_REQUEST'
export const SEARCH_RESERVATION_ORDER_SUCCESS = 'SEARCH_RESERVATION_ORDER_SUCCESS'
export const SEARCH_RESERVATION_ORDER_FAIL = 'SEARCH_RESERVATION_ORDER_FAIL'

export const SEARCH_RESERVATION_DESC_ORDER_REQUEST = 'SEARCH_RESERVATION_DESC_ORDER_REQUEST'
export const SEARCH_RESERVATION_DESC_ORDER_SUCCESS = 'SEARCH_RESERVATION_DESC_ORDER_SUCCESS'
export const SEARCH_RESERVATION_DESC_ORDER_FAIL = 'SEARCH_RESERVATION_DESC_ORDER_FAIL'
//値段順
export const SEARCH_PRICE_ORDER_REQUEST = 'SEARCH_PRICE_ORDER_REQUEST'
export const SEARCH_PRICE_ORDER_SUCCESS = 'SEARCH_PRICE_ORDER_SUCCESS'
export const SEARCH_PRICE_ORDER_FAIL = 'SEARCH_PRICE_ORDER_FAIL'

export const SEARCH_PRICE_ORDER_DESC_REQUEST = 'SEARCH_PRICE_ORDER_DESC_REQUEST'
export const SEARCH_PRICE_ORDER_DESC_SUCCESS = 'SEARCH_PRICE_ORDER_DESC_SUCCESS'
export const SEARCH_PRICE_ORDER_DESC_FAIL = 'SEARCH_PRICE_ORDER_DESC_FAIL'

export const SEARCH_PRODUCT_CATEGORY_REQUEST = 'SEARCH_PRODUCT_CATEGORY_REQUEST'
export const SEARCH_PRODUCT_CATEGORY = 'SEARCH_PRODUCT_CATEGORY'
export const SEARCH_PRODUCT_CATEGORY_FAIL = 'SEARCH_PRODUCT_CATEGORY_FAIL'

//タグから商品を取得する用意する
export const TAG_PRODUCT_REQUEST = 'TAG_PRODUCT_REQUEST'
export const TAG_PRODUCT_SUCCESS = 'TAG_PRODUCT_SUCCESS'
export const TAG_PORODUCT_FAIL = 'TAG_PORODUCT_FAIL'

//tagから評価順に取得するようにする
export const TAG_EVALUATE_REQUEST = 'TAG_EVALUATE_REQUEST'
export const TAG_EVALUATE_SUCCESS = 'TAG_EVALUATE_SUCCESS'
export const TAG_EVALUATE_FAIL = 'TAG_EVALUATE_FAIL'
//評価の低い順に並び替えを行う
export const TAG_EVALUATE_DESC_REQUEST = 'TAG_EVALUATE_DESC_REQUEST'
export const TAG_EVALUATE_DESC_SUCCESS = 'TAG_EVALUATE_DESC_SUCCESS'
export const TAG_EVALUATE_DESC_FAIL = 'TAG_EVALUATE_DESC_FAIL'

//お気に入りの多い順に並び替える
export const TAG_FAVORITE_REQUEST = "TAG_FAVORITE_REQUEST"
export const TAG_FAVORITE_SUCCESS = "TAG_FAVORITE_SUCCESS"
export const TAG_FAVORITE_FAIL = "TAG_FAVORITE_FAIL"
//お気に入りの少ない順に並び替える
export const TAG_FAVORITE_DESC_REQUEST = "TAG_FAVORITE_DESC_REQUEST"
export const TAG_FAVORITE_DESC_SUCCESS = "TAG_FAVORITE_DESC_SUCCESS"
export const TAG_FAVORITE_DESC_FAIL = "TAG_FAVORITE_DESC_FAIL"

//予約件数の多い順に並び替えを行う
export const TAG_RESERVATION_REQUEST = 'TAG_RESERVATION_REQUEST'
export const TAG_RESERVATION_SUCCESS = 'TAG_RESERVATION_SUCCESS'
export const TAG_RESERVATION_FAIL = 'TAG_RESERVATION_FAIL'

//予約件数の少ない順
export const TAG_RESERVATION_DESC_REQUEST = 'TAG_RESERVATION_DESC_REQUEST'
export const TAG_RESERVATION_DESC_SUCCESS = 'TAG_RESERVATION_DESC_SUCCESS'
export const TAG_RESERVATION_DESC_FAIL = 'TAG_RESERVATION_DESC_FAIL'

//価格
export const TAG_PRICE_REQUEST = 'TAG_PRICE_REQUEST'
export const TAG_PRICE_SUCCESS = 'TAG_PRICE_SUCCESS'
export const TAG_PRICE_FAIL = 'TAG_PRICE_FAIL'

export const TAG_PRICE_DESC_REQUEST = 'TAG_PRICE_DESC_REQUEST'
export const TAG_PRICE_DESC_SUCCESS = 'TAG_PRICE_DESC_SUCCESS'
export const TAG_PRICE_DESC_FAIL = 'TAG_PRICE_DESC_FAIL'

//タグとカテゴリーで絞り込む
export const TAG_CATEGORY_REQUEST = "TAG_CATEGORY_REQUEST"
export const TAG_CATEGORY_SUCCESS = "TAG_CATEGORY_SUCCESS"
export const TAG_CATEGORY_FAIL = "TAG_CATEGORY_FAIL"

export const TAG_PLAN_ORIGIN_DATA = 'TAG_PLAN_ORIGIN_DATA'
export const TAG_PRODUCT_PLAN = 'TAG_PRODUCT_PLAN'

export const TAG_PRODUCT_GENDER_ORIGIN_DATA = 'TAG_PRODUCT_GENDER_ORIGIN_DATA'
export const TAG_PRODUCT_GENDER_FILTER_SUCCESS = 'TAG_PRODUCT_GENDER_FILTER_SUCCESS'

//検索を行った時に似た用語を取り出す
export const SEARCH_WORD_REQUEST = 'SEARCH_WORD_REQUEST'
export const SEARCH_WORD_SUCCESS = 'SEARCH_WORD_SUCCESS'
export const SEARCH_WORD_FAIL = 'SEARCH_WORD_FAIL'

//検索欄からタグを封数取得する
export const SEARCH_TAG_REQUEST = 'SEARCH_TAG_REQUEST'
export const SEARCH_TAG_SUCCESS = 'SEARCH_TAG_SUCCESS'
export const SEARCH_TAG_FAIL = 'SEARCH_TAG_FAIL'

//複数タグを指定してtagからproductを取得
export const TAG_LIST_PRODUCTS_REQUEST = 'TAG_LIST_PRODUCTS_REQUEST'
export const TAG_LSIT_PRODUCTS_SUCCESS = 'TAG_LSIT_PRODUCTS_SUCCESS'
export const TAG_LIST_PRODUCTS_FAIL    = 'TAG_LIST_PRODUCTS_FAIL'