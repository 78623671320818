import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

//component
import Loader from '../components/Loader';
import Message from '../components/Message';
//import GoogleSocialLoginComponent from '../components/SocialLoginComponent';
import axios from 'axios';

//action
import { register } from '../actions/registerAction';
import { invitation_create } from '../actions/couponAction';

//function
import { headRewrite } from '../function/headRewrite';

//import formik and yup
import {useFormik} from 'formik';
import * as yup from 'yup';

//accountを作成した際に同時にプロフィールを作成するためのシグナルを作成する必要がある

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.enclopedia-official.com">
        Enclopedia Website
      </Link>{''}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


//react css style
const useStyles = makeStyles((theme) => ({
  also:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight:'bold'
  },
  button:{
    color:'black',
    backgroundColor:theme.palette.primary['200'],
    '&:hover':{
        backgroundColor:theme.palette.primary['400']
    }
  },
  content:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  copyright:{
    marginTop: theme.spacing(3)
  },
  error_message:{
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  paper: {
    color:'black',
    backgroundColor:'white',
    boxShadow: 'initial'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  loader:{
    [theme.breakpoints.down('sm')]:{
        marginTop:'60%',
        textAlign:'center',
        minHeight:'100vh',
    },
    [theme.breakpoints.up('sm')]:{
        marginTop:'35%',
        textAlign:'center',
        minHeight:'100vh',
    },
    [theme.breakpoints.up('md')]:{
        marginTop:'30%',
        textAlign:'center',
        minHeight:'100vh',
    },
  },
  message:{
    marginBottom: theme.spacing(1)
  },
  root:{
    [theme.breakpoints.down('sm')]:{
      margin: theme.spacing(10,1,15,1)
    },
    [theme.breakpoints.up('sm')]:{
        margin: theme.spacing(10,2,15,2)
    },
    [theme.breakpoints.up('md')]:{
        margin: theme.spacing(10,3,15,3)
    }
  },
  submit: {
    backgroundColor: theme.palette.primary['200'],
    '&:hover':{
      backgroundColor: theme.palette.primary['400']
    }
  },
  input_text:{
    color:'black'
  },
  text_field:{
      color:'black',
      borderWidth: '1px',
      borderColor:`${theme.palette.primary['500']} !important`,
  },
}));

const phoneRegex =/^[0-9]{2,3}?[0-9]{3,4}?[0-9]{3,4}?$/

const japanRegex = /^[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+$/

//yup バリデーション
const validationSchema = yup.object().shape({
  first_name: yup
    .string('')
    .max(15, '15文字以内で入力してください')
    .required('入力は必須です')
    .matches(japanRegex, 'ひらがな、カタカナ、漢字のみ使用可能です'),
  last_name: yup
    .string('')
    .max(15, '15文字以内で入力してください')
    .required('入力は必須です')
    .matches(japanRegex, 'ひらがな、カタカナ、漢字のみ使用可能です'),
  username: yup
    .string('')
    .max(30, '30文字以内で入力してください')
    .matches(/^[a-zA-Z0-9]+$/, 'アルファベットと数字のみで入力してください' )
    .required('入力は必須です'),
  phoneNumber: yup
  .string('')
  .required('電話番号の入力は必須です')
  .matches(phoneRegex, '正しい形式で入力してください'),
  email: yup
    .string('Eメールアドレスを入力してください')
    .email('正しい形式で入力してください')
    .required('emailアドレスは必須です'),
  //大文字も含めるようにする
  password: yup
    .string()
    .matches(/(?=.*[a-z])/, "小文字を含めてください")
    .matches(/(?=.*[A-Z])/, "大文字を含めてください")
    .matches(/(?=.*[0-9])/, "数字を含めてください")
    .min(8, 'パスワードは8文字以上です。')
    .required('パスワードは必須です。'),
  confirmPassword: yup
    .string()
    .matches(/(?=.*[a-z])/, "小文字を含めてください")
    .matches(/(?=.*[A-Z])/, "大文字を含めてください")
    .matches(/(?=.*[0-9])/, "数字を含めてください")
    .min(8, 'パスワードは8文字以上です。')
    .required('パスワードは必須です。'),
  invitation_code: yup
    .string()
    .min(6, '招待コードは６文字です。')
    .max(6, '招待コードは6文字です。'),
})

export default function LoginScreen() {
  
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  //const agent = window.navigator.userAgent.toLowerCase()

  //state
  const [message, setMessage] = useState('')

  
  //useSelector
  const { error, loading, accountInfo } = useSelector(state => state.register)

  //head meta data
  const title = "アカウント登録｜Enclopedia【公式サイト】"
  const description = "ファッションレンタルサービスEnclopedia（アンクロペディア）のアカウント登録画面です。"
  const keyword = "ファッションレンタルサービス、サブスクリプション、アンクロペディア、enclopedia"

  //formik
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name:'',
      username:'',
      phoneNumber: '',
      email: '',
      password: '',
      confirmPassword: '',
      invitation_code: ''
    },
    validationSchema:validationSchema,
    onSubmit:(values) => {
      event.preventDefault()
      if(values['password']!== values['confirmPassword']){
        setMessage('パスワードが一致していません')
        window.scrollTo({
          top: 0,
        });
      }else{
        window.scrollTo({
          top: 0,
        });
        if(values.invitation_code){
          const data = {'phone_number': values.phoneNumber, 'invitation_code': values.invitation_code}
          const header = {headers:{'Content-Type':'application/json'}}
          axios.post(process.env.REACT_APP_URL + '/api/coupon/invtion_code/validation/', data, header).then(
            () => {
              dispatch(register(values.first_name, values.last_name, values.username, values.phoneNumber, values.email, values.password))
              dispatch(invitation_create(values.invitation_code, values.phoneNumber))
            }
          ).catch(
            (error) => {
              setMessage(error.response.data.message)
            }
          )
        }else{
          dispatch(register(values.first_name, values.last_name, values.username, values.phoneNumber, values.email, values.password))
          window.scrollTo({
            top: 0,
          });
        }
      }
    }
  })

  //パスワードを忘れた場合の画面を表示
  const handleForgetPassword = () => {
    history.push('/passwrod_reset/email/confirm')
  }
  
  const handleLogin = () => {
    history.push('/login')
  }
  useEffect(() => {
    headRewrite(title, description, keyword)
    if(accountInfo){
      history.push('/provisianl_registration')
    }

  },[accountInfo])


 
  return (
    <Container component="main" maxWidth="md" style={{padding: 'initial'}}>
      <div className={classes.root}>
        {message && <div className={classes.message}><Message className={classes.error_messaga}>{message}</Message></div>}
        {error && <div className={classes.message}><Message className={classes.error_messaga}>{error}</Message></div>}
        {loading ?
        <div className={classes.loader}>
          <Loader/>
        </div>
        :
        <Card className={classes.paper}>
          <CardContent>
          <div className={classes.content}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h1">
                登録
            </Typography>
            <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                <TextField
                variant="outlined"
                required
                fullWidth
                id="first_name"
                label="姓"
                name="first_name"
                autoComplete="first_name"
                autoFocus
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
                />
                </Grid>
                <Grid item xs={6}>
                <TextField
                variant="outlined"
                required
                fullWidth
                id="last_name"
                label="名"
                name="last_name"
                autoComplete="last_name"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="ユーザーネーム"
                name="username"
                autoComplete="username"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.username}
                onChange={formik.handleChange}
                error={formik.touched.username && Boolean(formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
                />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id="phoneNumber"
                  label="電話番号"
                  InputLabelProps={{
                    classes:{
                        root: classes.input_text
                    }
                  }}
                  InputProps={{
                      classes:{
                          notchedOutline:classes.text_field,
                          root: classes.text_field
                      }
                  }}
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Eメールアドレス"
                name="email"
                autoComplete="email"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email&& Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="パスワード確認"
                type="password"
                id="confirmPassword"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword&& Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
                </Grid>
                <Grid item xs={12}>
                <TextField
                variant="outlined"
                fullWidth
                id="invitation_code"
                label="招待コード"
                name="invitation_code"
                autoComplete="invitation_code"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.invitation_codel}
                onChange={formik.handleChange}
                error={formik.touched.invitation_code && Boolean(formik.errors.invitation_code)}
                helperText={formik.touched.invitation_code && formik.errors.invitation_code}
                />
                </Grid>
                <Grid item xs={12}>
                {formik.values.password === formik.values.confirmPassword ? 
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                >
                登録
                </Button>
                :
                <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled
                >
                登録
                </Button>
                }
                </Grid>
                <Grid item xs={6}>
                <Typography variant="subtitle2" component="p" className={classes.subtitle}>
                    <Button className={classes.button} onClick={handleForgetPassword}>
                      パスワードを忘れた方
                    </Button>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" component="p" align='right' className={classes.subtitle}>
                      <Button className={classes.button} onClick={handleLogin}>
                        ログイン画面
                      </Button>
                    </Typography>
                </Grid>
              </Grid>
            </form>
              {/*
                agent.indexOf("line") != -1 | agent.indexOf("instagram") != -1 ?
                ''
                :
                <>
                <Typography variant="subtitle1" component="p" align="center" className={classes.also}>
                    または
                </Typography>
                <GoogleSocialLoginComponent/>
                </>
              */}
          </div>
          <Box className={classes.copyright}>
            <Copyright />
          </Box>
          </CardContent>
        </Card>
        }
      </div>
    </Container>
  );
  
}