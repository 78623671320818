import {
    CLOSET_LIST_REQUEST,
    CLOSET_LIST_SUCCESS,
    CLOSET_LIST_FAIL,
    CLOSET_GET_REQUEST,
    CLOSET_GET_SUCCESS,
    CLOSET_GET_FAIL,
    CLOSET_CREATE_REQUEST,
    CLOSET_CREATE_SUCCESS,
    CLOSET_CREATE_FAIL,
    CLOSET_DELETE_REQUEST,
    CLOSET_DELETE,
    CLOSET_DELETE_FAIL,
    CLOSET_UPDATE_REQUEST,
    CLOSET_UPDATE,
    CLOSET_UPDATE_FAIL,
    BASIC_CLOTH_CREATE_REQUEST,
    BASIC_CLOTH_CREATE_SUCCESS,
    BASIC_CLOTH_CREATE_FAIL,
    PREMIUM_CLOTH_CREATE_REQUEST,
    PREMIUM_CLOTH_CREATE_SUCCESS,
    PREMIUM_CLOTH_CREATE_FAIL,
    CLOTH_LIST_REQUEST,
    CLOTH_LIST_SUCCESS,
    CLOTH_LIST_FAIL,
    CLOTH_DELETE_REQUEST,
    CLOTH_DELETE_SUCCESS,
    CLOTH_DELETE_FAIL,
    CLOSET_REGISTER_CART_REQUEST,
    CLOSET_REGISTER_CART_SUCCESS,
    CLOSET_REGISTER_CART_FAIL
} from '../constants/closetConstant'

import { 
    CART_CREATE_SUCCESS,
    CART_CREATE_FAIL
 } from '../constants/cartConstans'
import { baseAxios } from '../jwtInterceptor'

//closetをlistで取得
export const get_closet_list = () => async(dispatch) => {
    dispatch({type:CLOSET_LIST_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/closet/list/')
    .then(
        (response) => {
            dispatch({
                type:CLOSET_LIST_SUCCESS, payload: response['data']
            })
            localStorage.setItem('closets',JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({
                type:CLOSET_LIST_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

//requestされた後に更新されずに終了
export const create_closet = (closet_name, description, product_id) => async(dispatch) => {
    dispatch({
        type:CLOSET_CREATE_REQUEST
    })
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/closet/create/',{
        'closet_name': closet_name,
        'description': description,
    }).then(
        (response) => {  

            baseAxios.post('/api/closet/cloth/basic',
            {'product':product_id, 'closet':response['data'].id})
            .then(
                (response) => {
                    console.log(response)
                }
            ).catch(
                (error) => {
                    console.log(error)
                }
            )
            dispatch({type:CLOSET_LIST_REQUEST})
            baseAxios.get('/api/closet/list').then(
                (response) => {
                    console.log(response)
                    dispatch({
                        type:CLOSET_LIST_SUCCESS, payload: response['data']
                    })
                    localStorage.setItem('closets',JSON.stringify(response['data']))
                }

            ).catch(
                (error) => {
                    dispatch({
                    type:CLOSET_LIST_FAIL,
                    payload:error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
                    })
                }
            )

            dispatch({
                type:CLOSET_CREATE_SUCCESS, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type:CLOSET_CREATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const get_closet = (closet_id) => async(dispatch) => {
    dispatch({type:CLOSET_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/closet/detail/${closet_id}`).then(
        (response) => {
            dispatch({type:CLOSET_GET_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CLOSET_GET_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}


//closetを変更
export const closet_update = (closet_id, closet_name, description) => async(dispatch) => {
    console.log(closet_id)
    dispatch({type:CLOSET_UPDATE_REQUEST})
    const data = {
        "closet_name": closet_name,
        "description": description
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.patch(`/api/closet/detail/${closet_id}`, data).then(
        (response) => {
            baseAxios.get('/api/closet/list')
            .then(
                (response) => {
                    dispatch({type:CLOSET_LIST_SUCCESS, payload:response['data']})
                    localStorage.setItem('closets',JSON.stringify(response['data']))
                }
            ).catch(
                (error) => {
                    dispatch({type:CLOSET_LIST_FAIL, 
                        payload:error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message})
                }
            )
            dispatch({type:CLOSET_UPDATE, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CLOSET_UPDATE_FAIL,
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}

//closetを削除する
export const closet_delete = (closet_id) => async(dispatch) => {
    dispatch({type:CLOSET_DELETE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.delete(`/api/closet/detail/${closet_id}`)
    .then(
        (response) => {
            dispatch({type:CLOSET_DELETE, payload: response['data']})
            baseAxios.get('/api/closet/list')
            .then(
                (response) => {
                    dispatch({type:CLOSET_LIST_SUCCESS, payload:response['data']})
                    localStorage.setItem('closets',JSON.stringify(response['data']))
                }
            ).catch(
                (error) => {
                    dispatch({type:CLOSET_LIST_FAIL,
                        payload:error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,})
                }
            )
        }
    ).catch(
        (error) => {
            dispatch({type:CLOSET_DELETE_FAIL, 
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}

//basic rental プラン用にclothを作成する
export const create_basic_cloth = (product_id, closet_id) => async(dispatch) => {
    dispatch({
        type:BASIC_CLOTH_CREATE_REQUEST,
    })
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/closet/cloth/basic',
    {'product': product_id,'closet': closet_id})
    .then(
        (response) => {
            dispatch({
                type:BASIC_CLOTH_CREATE_SUCCESS, payload: response['data']
            })
            baseAxios.get('/api/closet/list/')
            .then(
                (response) => {
                    dispatch({
                        type:CLOSET_LIST_SUCCESS, payload: response['data']
                    })
                    localStorage.setItem('closets',JSON.stringify(response['data']))
                }
            ).catch(
                (error) => {
                    dispatch({
                        type:CLOSET_LIST_FAIL,
                        payload:error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                    })
                }
            )
        }
    ).catch(
        (error) => {
            dispatch({
                type:BASIC_CLOTH_CREATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}


//premium プラン用にclothを作成する
export const create_premium_cloth = (product_id, closet_id) => async(dispatch) => {
    dispatch({
        type:PREMIUM_CLOTH_CREATE_REQUEST
    })
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/closet/cloth/premium',{
        'product': product_id,
        'closet': closet_id
    }).then(
        (response) => {
            dispatch({
                type:PREMIUM_CLOTH_CREATE_SUCCESS, payload: response['data']
            })
            baseAxios.get('/api/closet/list/').then(
                (response) => {
                    dispatch({type:CLOSET_LIST_SUCCESS, payload:response['data']})
                }
            ).catch(
                (error) => {
                    dispatch({type:CLOSET_LIST_FAIL, 
                        payload:error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,})
                }
            )
        }
    ).catch(
        (error) => {
            dispatch({
                type:PREMIUM_CLOTH_CREATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const get_cloth_list = (closet_id) => async(dispatch) => {
    dispatch({
        type: CLOTH_LIST_REQUEST
    })

    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/closet/cloth/list/?closet_id=${closet_id}`)
    .then(
        (response) => {
            dispatch({
                type:CLOTH_LIST_SUCCESS, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type:CLOTH_LIST_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const delete_cloth = (cloth_id, closet_id) => async(dispatch) => {
    dispatch({type:CLOTH_DELETE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
    await baseAxios.delete(`/api/closet/cloth/${cloth_id}`,{}).then(
        (response) => {
            dispatch({type:CLOTH_LIST_REQUEST})
            baseAxios.get(`/api/closet/cloth/list/?closet_id=${closet_id}`).then(
                (response) => {
                    dispatch({type:CLOTH_LIST_SUCCESS, payload: response['data']})
                }
            ).catch(
                (error) => {
                    dispatch({
                        type:CLOTH_LIST_FAIL,
                        payload:error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
                    })
                }
            )

            baseAxios.get('/api/closet/list/').then(
                (response) => {
                    dispatch({type:CLOSET_LIST_SUCCESS, payload:response['data']})
                    localStorage.setItem('closets',JSON.stringify(response['data']))
                }
            ).catch(
                (error) => {
                    dispatch({type:CLOSET_LIST_FAIL, 
                        payload:error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,})
                }
            )


            dispatch({type:CLOTH_DELETE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CLOTH_DELETE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}
//cartを作成できずにerrorが発生している
export const closet_register_cart = (closet_id, cart_id) => async(dispatch) => {
    dispatch({type:CLOSET_REGISTER_CART_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`

    await baseAxios.post('/api/cart/closet',{
        'closet_id': closet_id,
        'cart_id': cart_id,
    }
    ).then(
        
        (response) => {
            baseAxios.post('/api/cart/create/',{}).then(
                (response) => {
                    dispatch({type:CART_CREATE_SUCCESS, payload:response['data']})
                    localStorage.setItem('cart_id', JSON.stringify(response['data']))
                }
            ).catch(
                (error) => {
                    dispatch({type:CART_CREATE_FAIL, payload:error})
                }
            )
            dispatch({type:CLOSET_REGISTER_CART_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CLOSET_REGISTER_CART_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )

}