import React, { useState, useEffect, useRef } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { motion } from 'framer-motion'
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";


//function
import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    animate_container:{
        position: 'relative',
        marginBottom: theme.spacing(5),
    },
    caution_text:{
        marginTop: theme.spacing(5),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    title: {
        [theme.breakpoints.down('md')]:{
        },
        [theme.breakpoints.up('md')]:{
            fontSize: '35px',
            letterSpacing: '10px'
        },
        fontWeight: 'bold',
        whiteSpace: 'pre-line',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white'
    },
    title_img:{
        width: '100%'
    },
    title_img_container:{
        position: 'relative',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    text_box:{
        float: 'right'
    },
    sub_title:{
        fontWeight: 'bold',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5),
        borderStyle: 'solid',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderRadius:10,
        borderWidth: 1,
    },
    line_url:{
        [theme.breakpoints.down('md')]:{
            textAlign: 'center',
            marginBottom: theme.spacing(3)
        },
        [theme.breakpoints.up('md')]:{
            textAlign: 'center',
            marginBottom: theme.spacing(3)
        },
        height: '150px',
        

    },
    line_url_container:{
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    method_container:{
        marginTop: theme.spacing(20),
        position: 'static'
    },
    method_sub_title:{
        fontWeight: 'bold',
        marginBottom: theme.spacing(2)
    },
    movingText:{
        position: 'absolute',
        fontSize: '30px',
        overflow: 'hidden'
    },
    movingText2:{
        position: 'absolute',
        top: 25,
        overflow: 'hidden'
    },
    movingText3:{
        position: 'absolute',
        top: 40,
        fontSize: '25px',
        overflow: 'hidden'
    },
    movingText4:{
        position: 'absolute',
        top: 60,
        fontSize: '28px',
        overflow: 'hidden'
    },
    movingTextContainer:{
        
    },
    shooting_method:{
        [theme.breakpoints.down('md')]:{
            height: '300px'
        },
        [theme.breakpoints.up('md')]:{
            height: '300px'
        },
        marginTop: theme.spacing(2)
    },
    text:{
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        whiteSpace: 'pre-line'
    }
}))

const frist_text = ["COMME des GARCONS COMME des GARCONS HOMME PLUS YOHJI YAMAMOTO ALEXANDER MCQUEEN BALECIAGA BOTTER CECILIEBAHNSE CELINE DAIRIKU DIOR DOUBLET DRIES VAN NOTEN "]

function LineEstimate(){
    const classes = useStyles()
    const textRef = useRef(null)
    const [textWidth, setTextWidth] = useState()

    const title = "Line査定-ブランド服高価買取のアンクロペディア【Enclopedia】"
    const description = "買取ご希望の洋服などございましたら無料でLINE査定を行なっておりますLINE査定を行なっております。【ブランド洋服高価買取】"
    const keyword = "ブランド買取、高額査定、洋服買取"

    headRewrite(title, description, keyword)

    console.log(textWidth)

    useEffect(() => {
        const calculateTextWidth = () => {
            if (textRef.current) {
              setTextWidth(textRef.current.offsetWidth);
            }
          };
      
          calculateTextWidth(); // 初回の計算
      
          window.addEventListener('resize', calculateTextWidth);
      
          // コンポーネントがアンマウントされるときにイベントリスナーをクリーンアップ
          return () => {
            window.removeEventListener('resize', calculateTextWidth);
          };
      }, []);

    return(
        <div className={classes.root}>
            <Container maxWidth={"xl"} style={{padding: 'initial', overflow: 'hidden'}}>
                <div className={classes.title_img_container}>
                    <Typography variant={"h1"} component="h1" className={classes.title} align="center">
                        {`ブランド買取はEnclopediaにお任せください`}
                    </Typography>
                    <img className={classes.title_img} style={{width: '100%'}} src={process.env.REACT_APP_IMAGE_URL + "subscription/line_estimate5.jpg"}/>
                </div>
                <div style={{marginBottom: '100px', position: 'static'}}>
                    <div className={classes.animate_container}>
                        <motion.div
                        className={classes.movingTextContainer}
                        initial={{x: `-2884px`}}
                        animate={{x: `100%`}}
                        transition={{duration:30, ease:'linear', repeat: Infinity}}
                        >
                            <Typography variant={"h2"} component="h2" className={classes.movingText} ref={textRef} noWrap style={{fontWeight:'bold'}}>
                                COMME des GARCONS COMME des GARCONS HOMME PLUS YOHJI YAMAMOTO ALEXANDER MCQUEEN BALECIAGA BOTTER CECILIEBAHNSE CELINE DAIRIKU DIOR DOUBLET DRIES VAN NOTEN
                            </Typography>
                        </motion.div>
                        <motion.div
                        className={classes.movingTextContainer}
                        initial={{x: '100%'}}
                        animate={{x:`-1923px`}}
                        transition={{duration:25, ease:'linear', repeat: Infinity}}
                        >
                            <Typography variant={"h2"} component="h2" className={classes.movingText2} align="center" noWrap style={{fontWeight:'bold'}}>
                                {frist_text[0]}
                            </Typography>
                        </motion.div>
                        <motion.div
                        className={classes.movingTextContainer}
                        initial={{x: `-2404px`}}
                        animate={{x: `100%`}}
                        transition={{duration:20, ease:'linear', repeat: Infinity}}
                        >
                            <Typography variant={"h2"} component="h2" className={classes.movingText3} noWrap style={{fontWeight:'bold'}}>
                                COMME des GARCONS COMME des GARCONS HOMME PLUS YOHJI YAMAMOTO ALEXANDER MCQUEEN BALECIAGA BOTTER CECILIEBAHNSE CELINE DAIRIKU DIOR DOUBLET DRIES VAN NOTEN
                            </Typography>
                        </motion.div>
                        <motion.div
                        className={classes.movingTextContainer}
                        initial={{x: '100%'}}
                        animate={{x:`-1923px`}}
                        transition={{duration:35, ease:'linear', repeat: Infinity}}
                        >
                            <Typography variant={"h2"} component="h2" className={classes.movingText4} align="center" noWrap style={{fontWeight:'bold'}}>
                                {frist_text[0]}
                            </Typography>
                        </motion.div>
                    </div>
                </div>
                <div className={classes.method_container}>
                    <Typography variant={"h1"} component="h2" className={classes.sub_title} align="center">
                        査定方法
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"h3"} className={classes.method_sub_title} component="h3" align="center">
                                1. 友達追加
                            </Typography>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                LINEでEnclopediaを友達に追加
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"h3"} className={classes.method_sub_title} component="h3" align="center">
                                2. 撮影
                            </Typography>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                査定したいアイテムを撮影
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"h3"} className={classes.method_sub_title} component="h3" align="center">
                                3. 送信
                            </Typography>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                EnclopediaのLINEアカウントに撮影したアイテムと説明文を送信
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"h3"} className={classes.method_sub_title} component="h3" align="center">
                                4. 査定結果
                            </Typography>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                査定結果を24時間以内にLINEメッセージからお知らせ
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*査定方法のステップ*/}
                    <Typography variant={"h1"} component="h2" className={classes.sub_title} align="center">
                        撮影方法
                    </Typography>
                    {/*撮影方法のステップ*/}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                服の表を全体撮影
                            </Typography>
                            <div style={{textAlign: 'center'}}>
                                <img className={classes.shooting_method} src={process.env.REACT_APP_IMAGE_URL + "subscription/line_estimate3.jpg"}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                服の裏を全体撮影
                            </Typography>
                            <div style={{textAlign: 'center'}}>
                                <img className={classes.shooting_method} src={process.env.REACT_APP_IMAGE_URL + "subscription/line_estimate4.jpg"}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                ブランドタグ・ロゴの撮影
                            </Typography>
                            <div style={{textAlign: 'center'}}>
                                <img className={classes.shooting_method} src={process.env.REACT_APP_IMAGE_URL + "subscription/line_estimate1.jpg"}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Typography variant={"p"} component="p" align="center" className={classes.text}>
                                品質タグの撮影
                            </Typography>
                            <div style={{textAlign: 'center'}}>
                                <img className={classes.shooting_method} src={process.env.REACT_APP_IMAGE_URL + "subscription/ line_estimate2.jpg"}/>
                            </div>
                        </Grid>
                    </Grid>
                    <Typography variant={"p"} component="p" align="center" className={classes.caution_text}>
                        明るい場所でピントを合わせブレのない撮影をして頂くことで実際に査定させて頂いた際と乖離のない査定額を提示することができます。
                    </Typography>
                    <Typography variant={"h1"} component="h2" className={classes.sub_title} align="center">
                        注意事項
                    </Typography>
                    {/*査定時の注意事項*/}
                    <Typography variant={"p"} component="p" align="center" className={classes.text}>
                        {`通常24時間以内,10時から20時の間の返信となります。\n
                        実際に拝見した際のアイテムの状態、在庫状況により査定金額が変動する場合がございます。
                        商品の状態に関して細かくご提示頂くことで実際に拝見した際の査定額が正確になります。\n
                        ブランドタグ、品質タグがついていないアイテムに関しては査定ができない場合がございますのでご了承下さい。
                        `}
                    </Typography>
                    <Grid container spacing={1} className={classes.line_url_container}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{textAlign: 'center'}}>
                                <img className={classes.line_url} src="https://qr-official.line.me/gs/M_240yzqkh_BW.png?oat_content=qr"/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant={"h3"} className={classes.method_sub_title} align="center" component="h3">
                                「QRコード」または「@240yzqkh」でID検索
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )   
}

export default LineEstimate