import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "@material-ui/core";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

//action
import { news_list_get } from "../actions/notificationAction";
import { notification_list_get } from "../actions/notificationAction";
import { read_update } from "../actions/notificationAction";

//import component
import Loader from "../components/Loader";
import NotificationComponent from "../components/NotificationComponent";

const useStyles = makeStyles((theme) => ({
    company_name:{
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    error_container:{
        height:'100vh'
    },
    error_title:{
        margin: theme.spacing(3)
    },
    heading:{
        width:'50%',
        textAlign:'center',
        color:'black',
        borderBottom: `1px solid ${theme.palette.primary['700']}`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        cursor: 'pointer'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    news:{
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(2),
    },
    card:{
        backgroundColor:'white',
        color:'black',
        boxShadow: 'initial'
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(5, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10, 3, 15, 3)
        },
    },
    title:{
        display: 'flex',

    }
}))

//お知らせかニュースかで画面の切り替えりstore内のデータを切り替える必要がある
const NotificationListScreen = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    //useSelctor
    const {loading, notification, error} = useSelector(state => state.notification)
    //useState
    const [ident, setIdent] = useState('notification')

    //hadnle
    const handleNotificationGet = () => {
        setIdent('notification')
        dispatch(notification_list_get())
       
    }
    
    const handleNewsGet = () => {
        setIdent('news')
        dispatch(news_list_get())
    }
    //初めの個人に送った通知を表示数量にsるう
    useEffect(()=>{
        dispatch(read_update())
        dispatch(notification_list_get())
    }, [dispatch])

    return(
        loading ?
        <div className={classes.loader}>
             <Loader/>
        </div>
        :
        error ?
        <div className={classes.error_container}>
            <Typography className={classes.error_title} variant="h2" component="h2">通知はありません</Typography>
        </div>
        :
        notification ?
        <div className={classes.root}>
        <Container maxWidth={"md"} style={{padding: 'initial'}}>
            <Card className={classes.card}>
                <Typography align='center' className={classes.news} variant="h2" component="h2">
                    お知らせ
                </Typography>
                <Typography variant="subtitle2" component="p" align="right" className={classes.company_name}>
                    Enclopedia 運営
                </Typography>
                <div className={classes.title}>
                    <Typography className={classes.heading} variant="subtitle1" component="p" onClick={handleNotificationGet}>
                            あなた宛
                    </Typography>
                    <Typography className={classes.heading} variant="subtitle1" component="p" onClick={handleNewsGet}>
                            みんな宛
                    </Typography>
                </div>
                <NotificationComponent key={"notification_component"} notifications={notification} ident={ident} />
            </Card>
        </Container>
        </div>
        :
        ''
    )
}

export default NotificationListScreen