import {
    PROFILE_GET_REQUEST,
    PROFILE_GET_SUCCESS,
    PROFILE_GET_FAIL,
    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE,
} from '../constants/profileConstants'

import { baseAxios } from '../jwtInterceptor'

//this action is for create profile of user

export const get_profile = () => async(dispatch) => {
    dispatch({type:PROFILE_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/account/profile/')
    .then(
        (response) => {
            dispatch({
                type:PROFILE_GET_SUCCESS, payload:response['data']
            })
            localStorage.setItem('profile', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({
                type:PROFILE_GET_FAIL, payload:error
            })
        }
    )
}

export const update_profile = (data) => async(dispatch) => {
    dispatch({type:PROFILE_UPDATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.patch('/api/account/profile/', data).then(
        (response) => {
            dispatch({
                type:PROFILE_UPDATE, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type:PROFILE_UPDATE, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}