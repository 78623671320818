import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

//google social login
import { loadGapiInsideDOM } from "gapi-script";
//import component
import Loader from '../components/Loader'
import Message from '../components/Message'
//import GoogleSocialLoginComponent from '../components/SocialLoginComponent';

//import action
import { login } from '../actions/accountAction'

//function
import { headRewrite } from '../function/headRewrite';

//react formik
import {useFormik} from 'formik';
import * as yup from 'yup'

//clientIdに関してはバックエンド側からjsonで渡すようにする
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.enclopedia-official.com">
        Enclopedia Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

//formik バリデーション

const validationSchema = yup.object({
  email: yup
    .string('')
    .email('正しい形式で入力して下さい')
    .required('入力は必須です'),
  //大文字も含めるようにする
  password: yup
    .string()
    .min(8, 'パスワードは8文字以上です')
    .required('パスワードは必須です。')
})

const useStyles = makeStyles((theme) => ({
  also:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight:'bold'
  },
  button:{
    color:'black',
    backgroundColor:theme.palette.primary['200'],
    '&:hover':{
        backgroundColor:theme.palette.primary['400']
    }
  },
  content:{    
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  copyright:{
    marginTop: theme.spacing(3)
  },
  error_message:{
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  input_text:{
    color:'black'
  },
  loader:{
    [theme.breakpoints.down('sm')]:{
      marginTop:'40%',
      textAlign:'center',
      minHeight:'100vh',
  },
  message:{
    marginBottom: theme.spacing(2)
  },
  [theme.breakpoints.up('sm')]:{
      marginTop:'35%',
      textAlign:'center',
      minHeight:'100vh',
  },
  [theme.breakpoints.up('md')]:{
      marginTop:'30%',
      textAlign:'center',
      minHeight:'100vh',
  },
  },
  paper: {
    color:'black',
    backgroundColor:'white',
    boxShadow: 'initial'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  root:{
    [theme.breakpoints.down('sm')]:{
      margin: theme.spacing(10,1,15,1)
    },
    [theme.breakpoints.up('sm')]:{
        margin: theme.spacing(10,2,15,2)
    },
    [theme.breakpoints.up('md')]:{
        margin: theme.spacing(10,3,15,3)
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor:theme.palette.primary['200'],
    '&:hover':{
      backgroundColor:theme.palette.primary['400']
    }
  },

  subtitle:{
    color:'black',
  },
  text_field:{
      color:'black',
      borderWidth: '1px',
      borderColor:`${theme.palette.primary['500']} !important`,
  },
}));


function LoginScreen({location}) {
  // LOGIN FORM
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()
  //useState
  const redirect = location.search ? location.search.split('=')[1] : '/'
  //const agent = window.navigator.userAgent.toLowerCase()

  //useSelector
  const accountLogin = useSelector(state => state.account)
  const { error, loading, accountInfo } = accountLogin

  //head data
  const title = "ログイン｜Enclopedia【公式サイト】"
  const description = "ファッションレンタルサービスEnclopedia（アンクロペディア）のログイン画面です。"
  const keyword = "ファッションレンタルサービス、サブスクリプション、アンクロペディア、enclopedia"

  //formik 
  const formik = useFormik({
    initialValues:{
      email:'',
      password:'',
    },
    validationSchema:validationSchema,
    onSubmit:(values) => {
      dispatch(login(values.email, values.password))
    }
  })

  //パスワードを忘れた場合の画面を表示
  const handleForgetPassword = () => {
    history.push('/passwrod_reset/email/confirm')
  }

  const handleRegister = () => {
    history.push('/register')
  }

  useEffect(() => {
    headRewrite(title, description, keyword)
    if(accountInfo){
      setTimeout(
        function(){history.push(redirect)},200
      )
    }
    (
      async() => {
        await loadGapiInsideDOM();
      }
    )
  },[history, accountInfo, redirect])


  return (
    <>
    {loading
    ?
    <div className={classes.loader}>
      <Loader/>
    </div>
    :
    <Container component="main" maxWidth="md" style={{padding: 'initial'}}>
      <div className={classes.root}>
        <div className={classes.message}>
          {error && <Message className={classes.error_message}>5回失敗後に60分間アカウントがロックされます</Message>}
        </div>
        <Card className={classes.paper}>
          <CardContent>
            <div className={classes.content}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h1">
                  ログイン
              </Typography>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Eメール"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  InputLabelProps={{
                    classes:{
                        root: classes.input_text
                    }
                  }}
                  InputProps={{
                      classes:{
                          notchedOutline:classes.text_field,
                          root: classes.text_field
                      }
                  }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="パスワード"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  InputLabelProps={{
                    classes:{
                        root: classes.input_text
                    }
                  }}
                  InputProps={{
                      classes:{
                          notchedOutline:classes.text_field,
                          root: classes.text_field
                      }
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  ログイン
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="subtitle2" component="p">
                      <Button onClick={handleForgetPassword} className={classes.button}>
                        パスワードを忘れた方
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" component="p" align='right'>
                      <Button onClick={handleRegister} className={classes.button}>
                        アカウント登録画面
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
              {/*
                agent.indexOf("line") != -1 | agent.indexOf("instagram") != -1 ?
                ''
                :
                <>
                <Typography variant="subtitle1" component="p" align="center" className={classes.also}>
                    または
                </Typography>
                <GoogleSocialLoginComponent/>
                </>
              */}
            </div>
            <Box className={classes.copyright}>
              <Copyright />
            </Box>
          </CardContent>
        </Card>
      </div>
    </Container>
    }
    </>
  );
}

export default LoginScreen