import axios from 'axios'

import {
    CATEGORY_GET_REQUEST,
    CATEGORY_GET_SUCCESS,
    CATEGORY_GET_FAIL,
    CATEGORYITEMS_GET_REQUEST,
    CATEGORYITEMS_GET_SUCCESS,
    CATEGORYITEMS_GET_FAIL,
    BRANDS_GET_REQUEST,
    BRANDS_GET_SUCCESS,
    BRANDS_GET_FAIL,
    BRANDITEMS_GET_REQUEST,
    BRANDITEMS_GET_SUCCESS,
    BRANDITEMS_GET_FAIL,
    BRANDS_EVALUATE_REQUEST,
    BRANDS_EVALUATE_SUCCESS ,
    BRANDS_EVALUATE_FAIL,
    BRANDS_EVALUATE_DESC_REQUEST,
    BRANDS_EVALUATE_DESC_SUCCESS,
    BRANDS_EVALUATE_DESC_FAIL,
    BRANDS_FAVORITE_REQUEST,
    BRANDS_FAVORITE_SUCCESS,
    BRANDS_FAVORITE_FAIL,
    BRANDS_FAVORITE_DESC_REQUEST,
    BRANDS_FAVORITE_DESC_SUCCESS,
    BRANDS_FAVORITE_DESC_FAIL,
    BRANDS_RESERVATION_REQUEST,
    BRANDS_RESERVATION_SUCCESS,
    BRANDS_RESERVATION_FAIL,
    BRANDS_RESERVATION_DESC_REQUEST,
    BRANDS_RESERVATION_DESC_SUCCESS,
    BRANDS_RESERVATION_DESC_FAIL,
    BRANDS_CATEGORY_REQUEST,
    BRANDS_CATEGORY_SUCCESS,
    BRANDS_CATEGORY_FAIL,
    BRANDS_PRICE_REQUEST,
    BRANDS_PRICE_SUCCESS,
    BRANDS_PRICE_FAIL,
    BRANDS_PRICE_DESC_REQUEST,
    BRANDS_PRICE_DESC_SUCCESS,
    BRANDS_PRICE_DESC_FAIL,
    BRAND_PRODUCT_FILTER,
    BRAND_PRODUCT_ORIGIN,
    BRAND_GENDER_PRODUCT_FILTER,
    BRAND_GENDER_PRODUCT_ORIGIN
} from '../constants/categoryConstants'
import { baseAxios } from '../jwtInterceptor'

const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers:{
        'Content-Type': 'Application/json'
    }
})

export const type_get = () => async(dispatch) => {
    dispatch({type: CATEGORY_GET_REQUEST})
    await axiosApi.get('/api/search/type/list/').then(
        (response) => {
            dispatch({type: CATEGORY_GET_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: CATEGORY_GET_FAIL, 
                payload:error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
            })
        }
    )
}

//親のtype modelからcategoory一覧を取得
export const type_category_get = (id) => async(dispatch) => {
    dispatch({type: CATEGORY_GET_REQUEST})
    await axiosApi.get(`/api/search/type/?type=${id}`).then(
        (response) => {
            dispatch({type: CATEGORY_GET_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: CATEGORY_GET_FAIL, 
                payload:error.response && error.response.data.message 
                ? error.response.data.message 
                : error.message
            })
        }
    )
}

export const category_get = () => async(dispatch) => {
    try{
        dispatch({type:CATEGORY_GET_REQUEST})
        const {data} = await axiosApi.get('/api/search/category/list/')
        dispatch({type: CATEGORY_GET_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:CATEGORY_GET_FAIL,
            payload:error.response && error.response.data.message 
            ? error.response.data.message 
            : error.message
        })
    }
    
}

export const category_products_get = (id) => async(dispatch) => {
    try{
        dispatch({type:CATEGORYITEMS_GET_REQUEST})
        const {data} = await axiosApi.get(`/api/product/category/${id}`)
        dispatch({type: CATEGORYITEMS_GET_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:CATEGORYITEMS_GET_FAIL,
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//brandをアルファベットごとに取得する
export const brands_get = (alpha) => async(dispatch) => {
    try{
        dispatch({type:BRANDS_GET_REQUEST})
        const {data} = await axiosApi.get(`/api/search/brand/list/?brand=${alpha}`)
        dispatch({type:BRANDS_GET_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:BRANDS_GET_FAIL,
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

export const brand_products_get = (id, page) => async(dispatch) => {
    try{
        dispatch({type:BRANDITEMS_GET_REQUEST})
        const {data} = await axiosApi.get(`/api/product/brand/?brand=${id}&page=${page}`)
        dispatch({type: BRANDITEMS_GET_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:BRANDITEMS_GET_FAIL,
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//productを評価の高い順に並び替える
export const brand_evaluate_get = (id, page) => async(dispatch, getState) => {
    try{
        dispatch({type:BRANDS_EVALUATE_REQUEST})
        const {data} = await axiosApi.get(`/api/product/brand/evaluation_order/?brand=${id}&page=${page}`)
        dispatch({type:BRANDS_EVALUATE_SUCCESS, payload: data})
    }catch(error){
        dispatch({
            type:BRANDS_EVALUATE_FAIL, payload: getState().brandProducts.products
        })
    }
}

//brandから評価の低い順に取得していく
export const brand_evaluate_desc_get = (id, page) => async(dispatch, getState) => {
    try{
        dispatch({type:BRANDS_EVALUATE_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/brand/evaluation_order_desc/?brand=${id}&page=${page}`)
        dispatch({type:BRANDS_EVALUATE_DESC_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:BRANDS_EVALUATE_DESC_FAIL, payload: getState().brandProducts.products
        })
    }
}

//お気に入りの多い順から並べていく
export const brand_favorite_get = (id, page) => async(dispatch, getState) => {
    try{
        dispatch({type:BRANDS_FAVORITE_REQUEST})
        const {data} = await axiosApi.get(`/api/product/brand/favorite_order/?brand=${id}&page=${page}`)
        dispatch({type:BRANDS_FAVORITE_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:BRANDS_FAVORITE_FAIL, payload: getState().brandProducts.products
        })
    }
}

//お気にりの少ない順に並べていく
export const brand_favorite_desc_get = (id, page) => async(dispatch) => {
    try{
        dispatch({type:BRANDS_FAVORITE_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/brand/favorite_order_desc/?brand=${id}&page=${page}`)
        dispatch({type:BRANDS_FAVORITE_DESC_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:BRANDS_FAVORITE_DESC_FAIL, payload: error
        })
    }
}

//予約数の多い順に並び替えを行う
export const brand_reservation_get = (brand_id, page) => async(dispatch) => {
    dispatch({type:BRANDS_RESERVATION_REQUEST})
    await baseAxios.get(`/api/product/brand/reservation_order/?brand=${brand_id}&page=${page}`).then(
        (response) => {
            dispatch({type:BRANDS_RESERVATION_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:BRANDS_RESERVATION_FAIL, payload:error})
        }
    )
}

//予約数の少ない順に並び替えを行う
export const  brand_reservation_desc_get = (brand_id, page) => async(dispatch) => {
    dispatch({type:BRANDS_RESERVATION_DESC_REQUEST})
    await baseAxios.get(`/api/product/brand/reservation_order_desc/?brand=${brand_id}&page=${page}`).then(
        (response) => {
            dispatch({type:BRANDS_RESERVATION_DESC_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:BRANDS_RESERVATION_DESC_FAIL, payload:error})
        }
    )

}

//価格の安い順
export const brand_price_get = (brand_id, page) => async(dispatch) => {
    dispatch({type: BRANDS_PRICE_REQUEST})
    await baseAxios.get(`/api/product/brand/price_order/?brand=${brand_id}&page=${page}`).then(
        (response) => {
            dispatch({type: BRANDS_PRICE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: BRANDS_PRICE_FAIL, payload: error})
        }
    )
}

//価格の高い順
export const brand_price_desc_get = (brand_id, page) => async(dispatch) => {
    dispatch({type: BRANDS_PRICE_DESC_REQUEST})
    await baseAxios.get(`/api/product/brand/price_order_desc/?brand=${brand_id}&page=${page}`).then(
        (response) => {
            dispatch({type: BRANDS_PRICE_DESC_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: BRANDS_PRICE_DESC_FAIL, payload: error})
        }
    )
}


//brandとカテゴリーから取得していく
export const brand_category_get = (brand_id, category_id, page) => async(dispatch) => {
    try{
        dispatch({type:BRANDS_CATEGORY_REQUEST})
        const {data} = await axiosApi.get(`/api/product/brand/category/?brand=${brand_id}&category=${category_id}&page=${page}`)
        dispatch({type:BRANDS_CATEGORY_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:BRANDS_CATEGORY_FAIL, payload: error
        })
    }
}

export const brand_category_name_get = (brand_id, category_name, page) => async(dispatch) => {
    dispatch({type:BRANDS_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/brand/category_name/?brand=${brand_id}&category_name=${category_name}&page=${page}`).then(
        (response) => {
            dispatch({type:BRANDS_CATEGORY_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:BRANDS_CATEGORY_FAIL, payload: error
            })
        }
    )
}

export const brand_type_get = (brand_id, type, page) => async(dispatch) => {
    dispatch({type: BRANDS_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/brand/type/?brand=${brand_id}&type=${type}&page=${page}`).then(
        (response) => {
            dispatch({type:BRANDS_CATEGORY_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: BRANDS_CATEGORY_FAIL, payload: error})
        }
    )
}

export const brand_type_name_get = (brand_id, type_name, page) => async(dispatch) => {
    dispatch({type: BRANDS_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/brand/type_name/?brand=${brand_id}&type_name=${type_name}&page=${page}`).then(
        (response) => {
            dispatch({type: BRANDS_CATEGORY_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: BRANDS_CATEGORY_FAIL, payload: error})
        }
    )
}

//brandにuserがレンタルできる商品のみを表示するためのfilter action
export const brand_product_filter = (check, plan) => (dispatch, getState) => {
    const products = getState().brandProducts.products
    const origin_data = getState().brandOriginData.originData
    const productList = []
    if(check){
        //元のproduclistの値を保存すうるようにsrうう
        dispatch({type:BRAND_PRODUCT_ORIGIN, payload:products})
        switch(plan){
            case 'premium':
                for(let product in products['results']){
                    if(products['results'][product].stock !== 0){
                        productList.push(products['results'][product])
                    }
                }
                break;
            case 'basic':
                for(let product in products['results']){
                    if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
                break;
            case 'rental':
                for(let product in products['results']){
                    if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
                break;
        }
        dispatch({type: BRAND_PRODUCT_FILTER, payload: {'results':productList, 'count': products['count']}})
    }else{
        dispatch({type: BRAND_PRODUCT_FILTER, payload: origin_data})
    }
}

//brand gender filete

export const brand_gender_filter = (gender) => (dispatch, getState) => {
    const products = getState().brandProducts.products
    const origin_data = getState().brandGenderOriginData.originData
    if(!origin_data){
        dispatch({type: BRAND_GENDER_PRODUCT_ORIGIN, payload: products})
    }
    if(gender === 'all'){
        const data = getState().brandGenderOriginData.originData
        dispatch({type: BRAND_GENDER_PRODUCT_FILTER, payload: data})
    }else{
        const data = getState().brandGenderOriginData.originData
        const productList = []
        for(let product in data['results']){
            if(data['results'][product].gender === gender){
                productList.push(data['results'][product])
            }
        }
        dispatch({type: BRAND_GENDER_PRODUCT_FILTER, payload: {'results':productList, 'count': data['count']}})
    }
}

export const brand_origin_data_delete = () => (dispatch) => {
    dispatch({type: BRAND_GENDER_PRODUCT_ORIGIN, paylaod: ''})
}

export const brand_gender_data_save = () => (dispatch, getState) => {
    const products = getState().brandProducts.products
    dispatch({type: BRAND_GENDER_PRODUCT_ORIGIN, payload: products})
}