export const ACCOUNT_LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST'
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS'
export const ACCOUNT_LOGIN_FAIL = 'ACCOUNT_LOGIN_FAIL'
export const ACCOUNT_LOGOUT = 'ACCOUNT_LOGOUT'

export const ACCOUNT_PUBLIC_INFO = 'ACCOUNT_PUBLIC_INFO'

export const ACCOUNT_REGISTER_REQUEST = 'ACCOUNT_REGISTER_REQUEST'
export const ACCOUNT_REGISTER_SUCCESS = 'ACCOUNT_REGISTER_SUCCESS'
export const ACCOUNT_REGISTER_FAIL = 'ACCOUNT_REGISTER_FAIL'

export const ACCOUNT_UPDATE_REQUEST = 'ACCOUNT_UPDATE_REQUEST'
export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS'
export const ACCOUNT_UPDATE_FAIL = 'ACCOUNT_UPDATE_FAIL'

//emailを更新するため
export const EMAIL_UPDATE_REQUEST = 'EMAIL_UPDATE_REQUEST'
export const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS'
export const EMAIL_UPDATE_FAIL = 'EMAIL_UPDATE_FAIL'

export const ACCOUNT_UPDATE_EMAIL = 'ACCOUNT_UPDATE_EMAIL'

export const ACCOUNT_DELETE_REQUEST = 'ACCOUNT_DELETE_REQUEST'
export const ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELEETE_SUCCESS'
export const ACCOUNT_DELETE_FAIL = 'ACCOUNT_DELEET_FAIL'

export const ACCOUNT_REFRESH_TOKEN = 'ACCOUNT_REFRESH_TOKEN'
export const ACCOUNT_REFRESH_TOKEN_FAIL = 'ACCOUNT_REFRESH_TOKEN'

//sendgridについての操作
export const SENDGRID_REGISTER_REQUEST = 'SENDGRID_REGISTER_REQUEST'
export const SENDGRID_REGISTER_SUCCESS = 'SENDGRID_REGISTER_SUCCESS'
export const SENDGRID_REGISTER_FAIL = 'SENDGRID_REGISTER_FAIL'

export const SENDGRID_DELETE_REQUEST = 'SENDGRID_DELETE_REQUEST'
export const SENDGRID_DELETE_SUCCESS = 'SENDGRID_DELETE_SUCCESS'
export const SENDGRID_DELETE_FAIL = 'SENDGRID_DELETE_FAIL'

//email購読取得
export const EMAIL_SUBSCRIBE_GET_REQUEST = 'EMAIL_SUBSCRIBE_GET_REQUEST'
export const EMAIL_SUBSCRIBE_GET_SUCCESS = 'EMAIL_SUBSCRIBE_GET_SUCCESS'
export const EMAIL_SUBSCRIBE_GET_FAIL = 'EMAIL_SBUSCRIBE_GET_FAIL'

//email購読の更新
export const EMAIL_SUBSCRIBE_UPDATE_REQUEST = 'EMAIL_SUBSCRIBE_UPDATE_REQUEST'
export const EMAIL_SUBSCRIBE_UPDATE_SUCCESS = 'EMAIL_SUBSCRIBE_UPDATE_SUCCESS'
export const EMAIL_SUBSCRIBE_UPDATE_FAIL = 'EMAIL_SUBSCRIBE_UPDATE_FAIL'

//phone number更新
export const PHONE_NUMBER_AUTHENTICATION_REQUEST = 'PHONE_NUMBER_AUTHENTICATION_REQUEST'
export const PHONE_NUMBER_AUTHENTICATION_SUCCESS = 'PHONE_NUMBER_AUTHENTICATION_SUCCESS'
export const PHONE_NUMBER_AUTHENTICATION_FAIL = 'PHONE_NUMBER_AUTHENTICATION_FAIL'
export const PHONE_NUMBER_AUTHENTICATION_DELETE = 'PHONE_NUMBER_AUTHENTICATION_DELETE'

export const SEND_AUTHENTICATION_NUMBER_REQUEST = 'SEND_AUTHENTICATION_NUMBER_REQUEST'
export const SEND_AUTHENTICATION_NUMBER_SUCCESS = 'SEND_AUTHENTICATION_NUMBER_SUCCESS'
export const SEND_AUTHENTICATION_NUMBER_FAIL = 'SEND_AUTHENTICATION_NUMBER_FAIL'
