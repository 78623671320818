import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { CardContent } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

//component
import AddressForm from '../components/AdressFormComponent'
import Loader from '../components/Loader'

const useStyles = makeStyles((theme) => ({
    button:{
        color: 'black',
        marginTop: theme.spacing(1)
    },
    card:{
        backgroundColor:'white',
        color: 'black',
        marginTop:theme.spacing(2)
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
}))

const AddressUpdateScreen = () => {
    const classes = useStyles()

    const history = useHistory()

    const handleGoback = () => {
        history.goBack()
    }

    const {loading} = useSelector(state => state.adress)

    return (
        loading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        <div className={classes.root}>
            <Container maxWidth={"md"} style={{padding:'initial'}}>
                <Card className={classes.card}>
                    <CardContent>
                        <AddressForm/>
                        <Button onClick={handleGoback} className={classes.button} disableRipple={true} disableFocusRipple={true}>
                            戻る
                        </Button>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )

}

export default AddressUpdateScreen