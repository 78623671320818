 import React, {useState, useEffect} from 'react'
 import { useParams } from 'react-router-dom'
 import { makeStyles } from '@material-ui/core'
 import { Card } from  '@material-ui/core'
 import { CardContent } from '@material-ui/core'
 import { Container } from '@material-ui/core'
 import Button from '@material-ui/core/Button'
 import { Typography } from '@material-ui/core'
 import { useHistory } from 'react-router-dom'

 import axios from 'axios'

 const useStyles = makeStyles((theme) => ({
     button:{
         color:"#03a9f4",
         width: '100%',
         marginTop: theme.spacing(2)
     },
     card:{
         backgroundColor: 'white',
         color: 'black'
     },
     content:{
        padding: 'initial'
     },
     root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        },
        maxHeight: '100vh'
     },
     title:{
         marginBottom: theme.spacing(2)
     }

 }))

 //このpageで本登録完了のapiを叩くようにする
 const RegisterCompleteScreen = () => {
     const classes = useStyles()
     const history = useHistory()
     const {id} = useParams()
     //useState
     const [message, setMessage] = useState('')

     const submitHandler = () => {
         history.push('/login')
     }

     useEffect(() => {
         const config = {'Content-type':'application/json'}
         const data = {
             'id': id
         }
         axios.patch(process.env.REACT_APP_URL + '/api/account/register_confirm', data, config).then(
             (response) => {
                 setMessage(response['data'].message)
             }
         ).catch(
             (error) => {
                 setMessage(error.response.data.message)
             }
         )
     },[])

     //componetが反映されない原因がなんのかここに記述する必要がある
     return(
         <Container maxWidth={'md'} className={classes.content}>
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h1" component="h1" align="center" className={classes.title}>
                            {message}
                        </Typography>
                        <Typography variant="subtitle1" component="p" style={{ whiteSpace: 'pre-line'}}>
                            本登録完了が完了致しました。<br/>
                            早速Enclopediaを利用したい方は下記ボタンからログインを行なって下さい。
                        </Typography>
                        <Button onClick={submitHandler} className={classes.button}>
                            こちら
                        </Button>
                    </CardContent>
                </Card>
             </div>
         </Container>
     )
 }


 export default RegisterCompleteScreen