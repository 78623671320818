import React from 'react';
import { Card} from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    card:{
        width: "100%",
        backgroundColor: "white",
        color: 'black',
        height:"500px",
    },
    container:{
        padding: 'initial',
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    subtitle:{
        marginBottom: theme.spacing(1)
    },
    title:{
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    }
}))

const ProvisionalRegistrationScreen = () => {
    const classes = useStyles();
    return(
        <Container maxWidth={'sm'} className={classes.container}>
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography className={classes.title} variant='h2' component="h2" align='center'>
                            仮登録が完了しました
                        </Typography>
                        <Typography variant="subtitle2" component="p" align='right' className={classes.subtitle}>
                            Enclopedia運営
                        </Typography>
                        <Typography variant="subtitle1" component="p" tyle={{ whiteSpace: 'pre-line'}}>
                            仮登録が完了しました。<br/>
                            登録したEメールアドレスにメッセージを送信しました,本登録を完了させるにはEメールから手続きを行なって下さい。 <br/>
                            メールが届かない場合は迷惑メール、Gmailをご利用の方は合わせて「プロモーション」をご確認下さい。
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </Container>
    );
}

export default ProvisionalRegistrationScreen