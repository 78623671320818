export const READ_GET_REQUEST = 'READ_GET_REQUEST'
export const READ_GET_SUCCESS = 'READ_GET_SUCCESS'
export const READ_GET_FAIL = 'READ_GET_FAIL'

export const READ_UPDATE_REQUEST = 'READ_UPDATE_REQUEST'
export const READ_UPDATE_SUCCESS = 'READ_UPDATE_SUCCESS'
export const READ_UPDATE_FAIL = 'READ_UPDATE_FAIL'

export const NEWS_LIST_GET_REQUEST = 'NEWS_LIST_GET_REQUEST'
export const NEWS_LIST_GET_SUCCESS = 'NEWS_LIST_GET_SUCCESS'
export const NEWS_LIST_GET_FAIL = 'NEWS_LIST_GET_FAIL'

export const NOTIFICATION_LIST_GET_REQUEST = 'NOTIFICATION_LIST_GET_REQUEST'
export const NOTIFICATION_LIST_GET_SUCCESS = 'NOTIFICATION_LIST_GET_SUCCESS'
export const NOTIFICATION_LIST_GET_FAIL = 'NOTIFICATION_LIST_GET_FAIL'

export const NEWS_GET_REQUEST = 'NEWS_GET_REQUEST'
export const NEWS_GET_SUCCESS = 'NEWS_GET_SUCCESS'
export const NEWS_GET_FAIL = 'NEWS_GET_FAIL'

export const NOTIFICATION_GET_REQUEST = 'NOTIFICATION_GET_REQUEST'
export const NOTIFICATION_GET_SUCCESS = 'NOTIFICATION_GET_SUCCESS'
export const NOTIFICATION_GET_FAIL = 'NOTIFICATION_GET_FAIL'

export const TODO_LIST_REQUEST = 'TODO_LIST_REQUEST'
export const TODO_LIST_SUCCESS = 'TODO_LIST_SUCCESS'
export const TODO_LIST_FAIL = 'TODO_LIST_FAIL'

export const TODO_RETURN_ITEM_REQUEST = 'TODO_RETURN_ITEM_REQUEST'
export const TODO_RETURN_ITEM_SUCCESS = 'TODO_RETURN_ITEM_SUCCESS'
export const TODO_RETURN_ITEM_FAIL = 'TODO_RETURN_ITEM_FAIL'

export const TODO_PURCHASED_ITEM_REQUEST = 'TODO_PURCHASED_ITEM_REQUEST'
export const TODO_PURCHASED_ITEM_SUCCESS = 'TODO_PURCHASED_ITEM_SUCCESS'
export const TODO_PURCHASED_ITEM_FAIL = 'TODO_PURCHASED_ITEM_FAIL'