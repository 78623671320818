import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BsChevronLeft } from 'react-icons/bs'
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import { CardHeader } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { List } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { ListItemAvatar } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';


import{
    Avatar,
    Typography
} from '@material-ui/core'

import Rate from './Rating'

//component
import Loader from './Loader';

//action
import { review_all_get } from '../actions/productActions';

const useStyles = makeStyles(theme => ({
    all_reviews:{
        backgroundColor:'white',
        color: 'black',
        border: '1px solid white',
        [theme.breakpoints.down('xs')]:{
            width:'200px'
        },
        [theme.breakpoints.up('xs')]:{
            width:'250px'
        },
        [theme.breakpoints.down('sm')]:{
            height:'330px'
        },
        [theme.breakpoints.up('sm')]:{
            height:'350px'
        },
    },
    avatar:{
        [theme.breakpoints.down('xs')]:{
            width:'30px',
            height:'30px'
        }
        
    },
    button:{
        [theme.breakpoints.down('xs')]:{
            width:'100%'
        },
        [theme.breakpoints.up('xs')]:{
            width:'initial'
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.primary['100']}`,
    },
    card:{
        backgroundColor:'white',
        color:'black',
        [theme.breakpoints.down('sm')]:{
            height:'330px',
            width:'200px'
        },
        [theme.breakpoints.up('sm')]:{
            height:'350px',
            width:'250px'
        },
    },
    card_action:{
        padding: 'initial',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(15)
    },
    card_content:{
        paddingTop: theme.spacing(1)
    },
    comment:{
        marginBottom:theme.spacing(2)
    },
    description:{
        marginTop: theme.spacing(1)
    },
    root: {
        height:'500px',
        overflowY: "scroll",
        backgroundColor:'white',
        color:'black',
    },
    fonts:{
        fontWeight:"bold"
    },
    card_header:{
        padding: theme.spacing(1)
    },
    date:{
        textAlign:'right'
    },
    inline:{
        display: "inline"
    },
    list:{
        display:'flex',
        overflow: 'hidden',
        overflowX:'scroll',
        padding: 'initial'
    },
    list_item:{
        padding:'initial',
        width:'initial',
    },
    modal:{
        margin:theme.spacing(1)
    },
    modal_avtar:{
        width:'50px',
        height:'50px'
    },
    modal_button:{
        marginTop: theme.spacing(3),
        color: 'black',
        backgroundColor: theme.palette.primary["200"],
        '&:hover':{
            backgroundColor: theme.palette.primary["400"]
        }
    },
    modal_card:{
        height:'500px',
        color:'black',
        backgroundColor:'white',
        margin: theme.spacing(0,1,0,1)
    },
    modal_review_card:{
        color:'black',
        backgroundColor:'white'
    },
    modal_container:{
        height:'500px',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        padding: 'initial',
        index:1000
    },
    modal_day:{
        marginLeft: theme.spacing(2)
    },
    modal_list:{
        height:'400px',
        width:'100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY:'scroll',

    },
    modal_loader:{
        textAlign:'center',
        marginTop:'50%'
    },
    modal_review:{
        letterSpacing:2
    },
    modal_username:{
        marginLeft: theme.spacing(2)
    },
    review_title:{
        textAlign:'center',
    },
    review_evaluation:{
        display: 'flex',
        justifyContent: 'center',
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(3)
    },

    review_card:{
        margin:'auto',
        padding:'initial',
        backgroundColor:theme.palette.primary['200'],
        color:'black',
        marginBottom: theme.spacing(2)
    },
    review_content:{
        padding:'initial'
    },
    title:{
        marginLeft: theme.spacing(3),
        fontWeight:500
    },
}))

function Comment({comments, product}){

    const classes = useStyles();
    const dispatch = useDispatch();
    const today = new Date()
    const history = useHistory()

    //usestate
    const [open, setOpen] = useState(false)
    const [openReview, setOpenReview] = useState(false)

    //useSelector
    const {loading, reviews, error} = useSelector(state => state.reviewList)
    const {rental} = useSelector(state => state.rentalInfo)

    const handleOpen = () => {
        dispatch(review_all_get(product.id))
        setOpen(true)
    }

    const handleClose = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleOpenReview = () => {
        if(rental){
            history.push(`/review/${product.id}`)
        }else{
            setOpenReview(true)
        }
    }

    const handleCloseReview = () => {
        setOpenReview((prevOpen) => !prevOpen)
    }

    return (
        
        product.review_count == 0 ?
        <>
        <Typography variant="subtitle1" component="p" align="center">
            口コミはありません
        </Typography>
        <CardActions className={classes.card_action} onClick={handleOpenReview}>
            <Card className={classes.all_reviews}>
                
                    <Typography variant='subtitle1' component="p" style={{textAlign:'center', marginTop:'50%'}}>
                        口コミを投稿
                    </Typography>
                
            </Card>
        </CardActions>
        <Modal
            open={openReview}
            onClose={handleCloseReview}
            className={classes.modal}
            >
            <Container maxWidth={"md"} className={classes.modal_container}>
                <Card className={classes.modal_review_card}>
                    <CardContent>
                        <Typography variant='subtitle1' component='p'>
                            一ヶ月以内にレンタルしたことのある商品のみ口コミを投稿することができます。
                        </Typography>
                        <Button fullWidth className={classes.modal_button} onClick={handleCloseReview}>
                            閉じる
                        </Button>
                    </CardContent>
                </Card>
            </Container>
            </Modal>
        </>
        :
        <Container maxWidth={"xl"} style={{padding:'initial'}}>
            <div className={classes.review_evaluation}>
                    <Typography
                    variant="h2"
                    component="h2"
                    className={classes.title}
                    >
                        {product.rating}
                    </Typography>
                    <Typography
                    variant="h2"
                    component="h2"
                    className={classes.title}
                    >
                        レビュー数:&nbsp;{product.review_count}件
                    </Typography>
                    <Typography
                     variant="h2"
                     component="h2"
                     className={classes.title}>
                         お気に入り数:&nbsp;{product.number_of_like}
                     </Typography>
            
            </div>
            <List className={classes.list} style={{padding:'initial'}}>
                <ListItem style={{padding:'initial', width:'initial'}}>
                    <CardActions onClick={handleOpenReview} style={{padding: 'initial'}}>
                        <Card className={classes.all_reviews}>
                                <Typography variant='subtitle1' component="p" style={{textAlign:'center', marginTop:'50%'}}>
                                    口コミを投稿
                                </Typography>
                        </Card>
                    </CardActions>
                </ListItem>
            { comments ? comments.map((comment, index) => (
                <ListItem key={index} className={classes.list_name} style={{width:'initial', paddingLeft:'20px', paddingRight:'initial'}}>
                    <Card className={classes.card} key={comment.id}>
                            <CardHeader
                                    className={classes.card_header}
                                    avatar={
                                        <Avatar alt='無' className={classes.avatar} src={`http://127.0.0.1:8000${comment.user.profile}`}/>
                                    }
                                    title={comment.user.username}
                            />
                            <CardContent className={classes.card_content}>
                                <Typography component='p' variant="subtitle2" align='right'>
                                    {
                                    parseInt((today - new Date(comment.on_created.replace(/-/g,"/"))) / 1000 / 60 / 60 / 24 / 30) > 0 ? parseInt((today - new Date(comment.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60 / 24 /30) + 'ヶ月前' :
                                    parseInt((today - new Date(comment.on_created.replace(/-/g,"/"))) / 1000 / 60 / 60 / 24) > 0 ? parseInt((today - new Date(comment.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60 /24) + '日前' : 
                                    parseInt((today - new Date(comment.on_created.replace(/-/g,"/"))) / 1000 / 60 / 60) > 0 ? parseInt((today - new Date(comment.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60) + '時間前':
                                    parseInt((today - new Date(comment.on_created.replace(/-/g,"/"))) / 1000 / 60) > 0 ? parseInt((today - new Date(comment.on_created.replace(/-/g,'/'))) / 1000 / 60) + '分前': '1分以内'
                                    }
                                </Typography>
                                <Rate value={comment.rating}/>
                                <Typography variant="subtitle2" component="p" noWrap={true} className={classes.description}>
                                    {comment.review}
                                </Typography>
                            </CardContent>
                    </Card>
                </ListItem>
                )):''}
            </List>
            <Typography variant="subtitle1" component="p" className={classes.review_title}>
                <Button className={classes.button} onClick={handleOpen}>
                {product.review_count}件レビューをすべて表示
                </Button>
            </Typography>
            <Modal
             open={open}
             onClose={handleClose}
             aria-labelledby="modal-modal-title"
            >
                <Container maxWidth={"md"} className={classes.modal_container}>
                    <Card className={classes.modal_card}>
                        <CardContent className={classes.card_content}>
                        {
                        loading ?
                            <div className={classes.modal_loader}>
                                <Loader/>
                            </div>
                        :
                        error ?
                            <div className={classes.error_container}>
                                <Typography variant="subtitle1" component="p">
                                    この商品に対するレビューはありません
                                </Typography>
                            </div>
                        :
                        <>
                            <IconButton
                            onClick={handleClose}
                            disableRipple={true}
                            disableFocusRipple={true}
                            className={classes.icon_button}
                            size="medium"
                            color="inherit"
                            >
                                <BsChevronLeft/>
                            </IconButton>
                            <div className={classes.review_evaluation}>
                                <Typography
                                variant="h2"
                                component="h2"
                                className={classes.title}
                                >
                                    {product.rating}
                                </Typography>
                                <Typography
                                variant="h2"
                                component="h2"
                                className={classes.title}
                                >
                                    レビュー数:&nbsp;{product.review_count}件
                                </Typography>
                                <Typography
                                variant="h2"
                                component="h2v"
                                className={classes.title}>
                                    お気に入り数:&nbsp;{product.number_of_like}
                                </Typography>
                            </div>
                            <List className={classes.modal_list} style={{ overflow: 'hidden', overflowY:'scroll',}}>
                                {
                                    reviews.map((review) => (
                                        <>
                                            <ListItem key={review.id} className={classes.list_item}>
                                                <ListItemAvatar >
                                                    <Avatar className={classes.modal_avtar} alt={review.user.username} src={'http://127.0.0.1:8000' + review.user.profile}/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<Typography className={classes.modal_username} variant="subtitle1" component="div">{review.user.username}</Typography>}
                                                    secondary={<Typography className={classes.modal_day} variant="subtitle2" component="div">                                    
                                                    {
                                                        parseInt((today - new Date(review.on_created.replace(/-/g,"/"))) / 1000 / 60 / 60 / 24 / 30) > 0 ? parseInt((today - new Date(review.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60 / 24 /30) + 'ヶ月前' :
                                                        parseInt((today - new Date(review.on_created.replace(/-/g,"/"))) / 1000 / 60 / 60 / 24) > 0 ? parseInt((today - new Date(review.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60 /24) + '日前' : 
                                                        parseInt((today - new Date(review.on_created.replace(/-/g,"/"))) / 1000 / 60 / 60) > 1 ? parseInt((today - new Date(review.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60) + '時間前':
                                                        parseInt((today - new Date(review.on_created.replace(/-/g,"/"))) / 1000 / 60) > 0 ? parseInt((today - new Date(review.on_created.replace(/-/g,'/'))) / 1000 / 60 / 60) + '分前': '1分以内'
                                                    }
                                                    </Typography>}
                                                />
                                            </ListItem>
                                            <ListItem  key={'text' + review.id}>
                                                <Typography variant='h3' component="h3" sx={{fontWeight:'light', fontSize:10}} className={classes.modal_review}>
                                                    {review.review}
                                                </Typography>
                                            </ListItem>
                                            <ListItem   style={{marginBottom:20}} key={'review' + review.id}>
                                                <Rate value={review.rating}/>
                                            </ListItem>
                                        </>
                                    ))
                                }
                            </List>
                        </>
                        }
                        </CardContent>
                    </Card>
                </Container>
            </Modal>
            <Modal
            open={openReview}
            onClose={handleCloseReview}
            >
            <Container maxWidth={"md"} className={classes.modal_container}>
                <Card className={classes.modal_card}>
                    <CardContent>
                        <Typography variant='subtitle1' component='p'>
                            一ヶ月以内にレンタルしたことのある商品のみ口コミを投稿することができます。
                        </Typography>
                        <Button fullWidth className={classes.modal_button} onClick={handleCloseReview}>
                            閉じる
                        </Button>
                    </CardContent>
                </Card>
            </Container>
            </Modal>
        </Container>      
    )
}

export default Comment