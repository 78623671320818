export const CATEGORY_GET_REQUEST = 'CATEGORY_GET_REQUEST'
export const CATEGORY_GET_SUCCESS ='CATEGORY_GET_SUCCESS'
export const CATEGORY_GET_FAIL = 'CATEGORY_GET_FAIL'

export const CATEGORYITEMS_GET_REQUEST = 'CATEGORYITEMS_GET_REQUEST'
export const CATEGORYITEMS_GET_SUCCESS = 'CATEGORYITEMS_GET_SUCCESS'
export const CATEGORYITEMS_GET_FAIL = 'CATEGORYITEMS_GET_FAIL'

export const BRANDITEMS_GET_REQUEST = 'BRANDITEMS_GET_REQUEST'
export const BRANDITEMS_GET_SUCCESS = 'BRANDITEMS_GET_SUCCESS'
export const BRANDITEMS_GET_FAIL = 'BRANDITEMS_GET_FAIL'


export const BRANDS_GET_REQUEST = 'BRANDS_GET_REQUEST'
export const BRANDS_GET_SUCCESS = 'BRANDS_GET_SUCCESS'
export const BRANDS_GET_FAIL = 'BRANDS_GET_FAIL'

export const BRANDS_EVALUATE_REQUEST = 'BRANDS_EVALUATE_REQUEST'
export const BRANDS_EVALUATE_SUCCESS = 'BRANDS_EVALUATE_SUCCESS'
export const BRANDS_EVALUATE_FAIL = 'BRANDS_EVALUATE_FAIL'

export const BRANDS_EVALUATE_DESC_REQUEST = 'BRANDS_EVALUATE_DESC_REQUEST'
export const BRANDS_EVALUATE_DESC_SUCCESS = 'BRANDS_EVALUATE_DESC_SUCCESS'
export const BRANDS_EVALUATE_DESC_FAIL = 'BRANDS_EVALUATE_DESC_FAIL'

export const BRANDS_FAVORITE_REQUEST = 'BRANDS_FAVORITE_REQUEST'
export const BRANDS_FAVORITE_SUCCESS = 'BRANDS_FAVORITE_SUCCESS'
export const BRANDS_FAVORITE_FAIL = 'BRANDS_FAVORITE_FAIL'

export const BRANDS_FAVORITE_DESC_REQUEST = 'BRANDS_FAVORITE_DESC_REQUEST'
export const BRANDS_FAVORITE_DESC_SUCCESS = 'BRANDS_FAVORITE_DESC_SUCCESS'
export const BRANDS_FAVORITE_DESC_FAIL = 'BRANDS_FAVORITE_DESC_FAIL'

export const BRANDS_RESERVATION_REQUEST = 'BRANDS_RESERVATION_REQUES'
export const BRANDS_RESERVATION_SUCCESS = 'BRANDS_RESERVATION_SUCCESS'
export const BRANDS_RESERVATION_FAIL = 'BRANDS_RESERVATION_FAIL'

export const BRANDS_RESERVATION_DESC_REQUEST = 'BRANDS_RESERVATION_DESC_REQUEST'
export const BRANDS_RESERVATION_DESC_SUCCESS = 'BRANDS_RESERVATION_DESC_SUCCESS'
export const BRANDS_RESERVATION_DESC_FAIL = 'BRANDS_RESERVATION_DESC_FAIL'

export const BRANDS_PRICE_REQUEST = 'BRANDS_PRICE_REQUEST'
export const BRANDS_PRICE_SUCCESS = 'BRANDS_PRICE_SUCCESS'
export const BRANDS_PRICE_FAIL = 'BRANDS_PRICE_FAIL'

export const BRANDS_PRICE_DESC_REQUEST = 'BRANDS_PRICE_DESC_REQUEST'
export const BRANDS_PRICE_DESC_SUCCESS = 'BRANDS_PRICE_DESC_SUCCESS'
export const BRANDS_PRICE_DESC_FAIL = 'BRANDS_PRICE_DESC_FAIL'

export const BRANDS_CATEGORY_REQUEST = 'BRANDS_CATEGORY_REQUEST'
export const BRANDS_CATEGORY_SUCCESS = 'BRANDS_CATEGORY_SUCCESS'
export const BRANDS_CATEGORY_FAIL = 'BRANDS_CATEGORY_FAIL'

export const BRAND_PRODUCT_FILTER = 'BRAND_PRODUCT_FILTER'
export const BRAND_PRODUCT_ORIGIN = 'BRAND_PRODUCT_ORIGIN'

export const BRAND_GENDER_PRODUCT_FILTER = 'BRAND_GENDER_PRODUCT_FILTER'
export const BRAND_GENDER_PRODUCT_ORIGIN = 'BRAND_GENDER_PRODUCT_ORIGIN'