import {
    CART_ADD_ITEM, 
    CART_REMOVE_ITEM,
    CART_GET_SUCCESS,
    CART_GET_FAIL,
    CART_DELETE,
    CART_DELETE_FAIL,
    CART_CERATE_REQUEST,
    CART_CREATE_SUCCESS,
    CART_CREATE_FAIL,
    CARTITEM_GET_REQUEST,
    CARTITEM_GET_SUCCESS,
    CARTITEM_GET_FAIL,
    CARTITEM_UPDATE,
    CARTITEM_DELETE,
    CARTITEM_CREATE_REQUEST,
    CARTITEM_CREATE_SUCCESS,
    CARTITEM_CREATE_FAIL,
    CARTITEM_BASIC_CREATE_REQUEST,
    CARTITEM_BASIC_CREATE_SUCCESS,
    CARTITEM_BASIC_CREATE_FAIL,
    CARTITEM_PREMIUM_CREATE_REQUEST,
    CARTITEM_PREMIUM_CREATE_SUCCESS,
    CARTITEM_PREMIUM_CREATE_FAIL,
    CARTITEM_BASIC_UPDATE,
    CARTITEM_BASIC_UPDATE_FAIL,
    CARTITEM_PREMIUM_UPDATE,
    CARTITEM_PREMIUM_UPDATE_FAIL,
    CARTITEM_ERROR_MESSAGE_CREAR,
} from '../constants/cartConstans'
import axios from 'axios'
import { baseAxios } from '../jwtInterceptor'

//アカウントがあった場合にのみcart内情報を取得する
export const getCart = (token, product_id) => async (dispatch) => {
    try{
        const config = {headers : {'Content-type': 'application/json', 'Authorization': `Bearer ${token}`}}
        const {data} = await axios.get(process.env.REACT_APP_URL + `/api/product/${product_id}`, config)
        dispatch({type:CART_GET_SUCCESS, payload: data})
    }catch(error){
        dispatch({type:CART_GET_FAIL, payload:error})
    } 
}

export const createCart = () => async(dispatch) => {
    dispatch({type: CART_CERATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {}
    await baseAxios.post('/api/cart/create/', data)
    .then(
        (response) => {
            dispatch({type:CART_CREATE_SUCCESS, payload:response['data']})
            localStorage.setItem('cart_id', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({type:CART_CREATE_FAIL, payload:error})
        }
    )
}

//cartを削除
export const cart_delete = (token, id) => async(dispatch, getState) => {
    try{
        const config = {headers:{'Content-type': 'application/json', 'Authorization': `Bearer ${token}`}}
        await axios.delete(process.env.REACT_APP_URL + `/api/product/cart/edit/${id}`, config)
        dispatch({type:CART_DELETE, payload:{}})
    }catch{
        dispatch({type:CART_DELETE_FAIL, payload: getState().cart})
    }
}

//userがログインしていない状態の場合
export const addToCart = (id, product, quantity, variation=[], stock) => async (dispatch, getState) => {
    dispatch(
        {
            type: CART_ADD_ITEM,
            payload:{
                product:product,
                variation: variation,
                quantity: quantity,
                stock,
            }
        })
        localStorage.setItem('cartItems', JSON.stringify(getState().cartItem.cartItems))
}

//cartitemを作成する
export const createCartItem = (token, cart_id, product_id, quantity, variation=[], stock) => async (dispatch) => {
    try{
        dispatch({type:CARTITEM_CREATE_REQUEST})
        const config = {headers:{'Content-type':'application/json', 'Authorization':`Bearer ${token}`}}
        const data = {
            'product':product_id, 
            'cart':cart_id, 
            'quantite': quantity,
            'variation':variation,
            'stock': stock
        }
        const res = await axios.post(process.env.REACT_APP_URL + `/api/cart/cartitem/`, 
        data, config)
        dispatch({type:CARTITEM_CREATE_SUCCESS, payload: res['data']})
    }catch(error){
        dispatch({type:CARTITEM_CREATE_FAIL, payload:error})
    }
}

export const createBasicCartItem = (cart_id, product_id, quantity, variation=[], stock) => async(dispatch) => {
    dispatch({type:CARTITEM_BASIC_CREATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        'product':product_id, 
        'cart':cart_id, 
        'quantite': quantity,
        'variation':variation,
        'stock': stock
    } 
    await baseAxios.post('/api/cart/cartitem/basic/create', data)
    .then(
        (response) => {
            dispatch({type:CARTITEM_BASIC_CREATE_SUCCESS, payload:response['data']['message']})
        }
    ).catch(
        (error) => {
            dispatch({type:CARTITEM_BASIC_CREATE_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}



export const createPremiumCartItem = (cart_id, product_id, quantity, variation=[], stock) => async(dispatch) => {
    dispatch({type:CARTITEM_PREMIUM_CREATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        'product':product_id, 
        'cart':cart_id, 
        'quantite': quantity,
        'variation':variation,
        'stock': stock
    }
    await baseAxios.post('/api/cart/cartitem/premium/create',data).then(
        (response) => {
            dispatch({type:CARTITEM_PREMIUM_CREATE_SUCCESS, payload:response['data']['message']})
        }
    ).catch(
        (error) => {
            dispatch({type:CARTITEM_PREMIUM_CREATE_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}

//basic プランのcartitemの個数を制御しながら変更を行うためのaction
export const updateBasicCartItem = (product_id, cart_id, quantity) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.patch(`/api/cart/cartitem/edit/basic`, {'product':product_id, 'cart':cart_id, 'quantity': quantity}).then(
        (response) => {
            dispatch({type:CARTITEM_BASIC_UPDATE, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CARTITEM_BASIC_UPDATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}

//premiumプランのcariteimの個数を制御しながら変更を行うためのaction
export const updatePremiumCartItem = (product_id, cart_id, quantity) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.patch('/api/cart/cartitem/edit/basic',{'product':product_id, 'cart':cart_id, 'quantity': quantity}).then(
        (response) => {
            dispatch({type:CARTITEM_PREMIUM_UPDATE, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CARTITEM_PREMIUM_UPDATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}



//カートの作成に失敗しerrorが発生した場合に指定した時間後にクリアする
export const cartErrorMessageClear = () => (dispatch) => {
   dispatch({type:CARTITEM_ERROR_MESSAGE_CREAR, payload:''})
}
    

//cartscreen内でcartitem listを取得する
export const getCartItem = (cart_id) => async(dispatch) => {
    dispatch({type:CARTITEM_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get(`/api/cart/cartitem/edit/?cart=${cart_id}`).then(
        (response) => {
            dispatch({type:CARTITEM_GET_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:CARTITEM_GET_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}

//cartitemごとに変更
export const updateCartItem = (product_id, cart_id, quantity) => async(dispatch, getState) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.patch('/api/cart/cartitem/edit/',{'product':product_id, 'cart':cart_id, 'quantity': quantity}).then(
        (response) => {
            dispatch({type:CARTITEM_UPDATE, payload: response['data']})
        }
    ).catch(
        () => {
            dispatch({type:CARTITEM_UPDATE, payload: getState().cartItemDB.cartItem})
        }
    )
    dispatch({type: CART_CERATE_REQUEST})
    await baseAxios.get('/api/cart/edit/').then(
        (response) => {
            dispatch({type: CART_CREATE_SUCCESS, payload: response['data']})
            localStorage.setItem('cart_id', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({type: CART_CREATE_FAIL, payload: 
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}

//cart内のitemを処理する
export const deleteCartItem = (product_id, cart_id) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.delete(`/api/cart/cartitem/edit/?product=${product_id}&cart=${cart_id}`).then(
        (response) => {
            dispatch({type:CARTITEM_DELETE, payload:response['data']})
        }
    ).catch(
        () => {
            dispatch({type:CARTITEM_DELETE, payload: []})
        }
    )
    dispatch({type: CART_CERATE_REQUEST})
    await baseAxios.get('/api/cart/edit/').then(
        (response) => {
            dispatch({type: CART_CREATE_SUCCESS, payload: response['data']})
            localStorage.setItem('cart_id', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({type: CART_CREATE_FAIL, payload: 
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}

//localstorage内のみで管理をする
export const removeCartItem = (id) => async(dispatch, getState) => {
    dispatch({
        type:CART_REMOVE_ITEM,
        payload: id
    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))    
}

