import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Fade } from "@mui/material";
import { InputBase } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { List } from "@material-ui/core";
import {ListItem} from "@material-ui/core"
import {ListItemButton} from "@mui/material";
import { Paper } from "@material-ui/core";
import {Popper} from "@material-ui/core";
import {AiFillHome} from "react-icons/ai"
import SearchIcon from '@material-ui/icons/Search';
//action
import { search_products } from "../actions/productActions";
import { search_origin_data_delete } from "../actions/productActions";
import axios from "axios";
import { baseAxios } from "../jwtInterceptor";
const useStyles = makeStyles((theme) => ({
    button:{
        marginRight: theme.spacing(1)
    },
    disabled_search_icon:{
        color: theme.palette.primary['400'],
    },
    container:{
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(9.5),
            height: '200px'
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(10),
            height: '250px'
        },
        [theme.breakpoints.up('md')]:{
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(11)
        },
        [theme.breakpoints.up('lg')]:{
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(11)
        },
        [theme.breakpoints.up('xl')]:{
            marginRight: theme.spacing(11),
            marginLeft: theme.spacing(20)
        },
        color: 'black',
        backgroundColor: 'white',
        overflow: 'hidden',
        overflowY:'scroll',
    },
    icon:{
        color: theme.palette.primary['400'],
        '&:hover':{
            color: theme.palette.primary['800']
        }
    },
    inner:{
        padding: theme.spacing(1)
    },
    input_base:{
        backgroundColor:'white',
        color:'black',
        [theme.breakpoints.up('md')]:{
            width: '75%'
        }
    },
    home:{
        color:'#d32f2f',
        backgroundColor:'white',
        height:'50px',
        width:'50px',
        //boxShadow: `1px 1px 2px ${theme.palette.primary['400']}`
    },
    list:{
        height: '300px',
        display: 'flex',
        flexDirection: 'column',

    },
    papper:{
        backgroundColor:'white',
        borderRadius:'25px',
        color:'black',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: 'initial !important'
    },
    popper:{
        width: '100%',
        height: '300px',
        zIndex:10000,
    },
    root:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(2, 1, 0, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 0, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin:theme.spacing(3, 3, 0 ,3),
        },
        display: 'flex'
    },
    search_icon:{
        color:'black'
    }
}))

/*
const saveLocalstorage = (word) => {
    //検索を実行した時にこのhandleを続行巣rhいう強がる
    const words = []
    const data = JSON.parse(localStorage.getItem('searchHistory'))
    console.log(data)
    if(data === null){
        words.push(word)
        localStorage.setItem('searchHistory', JSON.stringify(words))
    }else{
        if(data.length < 10){
            for(let i=0; i<data.length; i++){
                if(data[i] !== word){
                    data.unshift(word)
                    localStorage.setItem('searchHistory', JSON.stringify(data))
                }
            }
        }else{
            data.pop()
            for(let i=0; i<data.length; i++){
                if(data[i] !== word){
                    data.unshift(word)
                    localStorage.setItem('searchHistory', JSON.stringify(data))
                }
            }
        }
    }
}
*/

//backendからdataを取得する
const getSearchWord = async(word, state) => {
    const header = {'headers': {'Content-type': 'Application/json'}}
    await axios.get(process.env.REACT_APP_URL + `/api/product/search_word/?word=${word}`, header).then(
        (response) => {
            state(response.data.slice(0,10))
        }
    ).catch(
        () => {
            console.log('関連ワードが取得できませんでした')
        }
    )
}

export default function SearchBarComponent(){
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()

    //useState
    const [searchWord, setSearchWord] = useState('')
    const [words, setWords] = useState([])
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {search_word} = useSelector(state => state.searchWord)

    const product_list = ['COMME des GARCONS', 'YOHJI YAMAMOTO', 'ISSEY MIYAKE', 'SACAI', 'UNDERCOVER']


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setWords(accountInfo ? search_word ? search_word : product_list : product_list )
        setOpen((prevOpen) => !prevOpen)
    }

    const changeHandler = (event) => {
        setSearchWord(event.target.value)
        getSearchWord(event.target.value, setWords)
    }

    const homeHandler = () => {
        history.push('/')
    }

    const handleSearchWords = async(event, word) => {
        event.preventDefault()
        setSearchWord(word)
        dispatch(search_origin_data_delete())
        dispatch(search_products(word))
        window.gtag('event', 'search', {
            search_term: word
        })
        setOpen(false)
        history.push(`/search/${word}/`)
        if(accountInfo){
            baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
            await baseAxios.post('/api/history/search_word', {"search_word": word})
        }
    }


    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    
    const searchClickHandler = (event) => {
        event.preventDefault()
        dispatch(search_origin_data_delete())
        dispatch(search_products(searchWord))
        window.gtag('event', 'search', {
            search_term: searchWord
        })
        setOpen(false)
        history.push(`/search/${searchWord}/`)
    }

    useEffect(() => {
        setSearchWord('')
    },[])

    return (
        location.pathname === '/help' ?
        ''
        :
        <>
            <div className={classes.root}>
                <div className={classes.button}>
                    <IconButton
                    aria-label="home icon"
                    className={classes.home}
                    onClick={homeHandler}
                    >
                        <AiFillHome className={classes.icon} fontSize="large"/>
                    </IconButton>

                </div>
            
                <Paper component="form" className={classes.papper} onFocus={handleClick}>
                    { searchWord ?
                        <IconButton aria-label="search" className={classes.input}>
                            <SearchIcon className={classes.search_icon}/>
                        </IconButton>
                        :
                        <IconButton aria-label="search" className={classes.input} disabled>
                            <SearchIcon className={classes.disabled_search_icon}/>
                        </IconButton>
                    }
                    <InputBase 
                    placeholder="検索する..." 
                    inputProps={{'aria-label':'search bar'}} 
                    className={classes.input_base}
                    value={searchWord}
                    onBlur={() => {
                        setSearchWord('')
                        setOpen(false)
                    }}
                    onChange={changeHandler}
                    onKeyDown={(event) => {
                        if(event.key === 'Enter'){
                            searchClickHandler(event)
                        }
                    }}
                    />
                    </Paper>
                    <Popper className={classes.popper} id={id} open={open} anchorEl={anchorEl}  transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.container}>
                                <div className={classes.inner}>
                                    <List className={classes.list}>
                                        {words.map((word) => (
                                        <>
                                        <ListItem key={word} classsName={classes.list_item} style={{cursor:'pointer', padding:'initial'}}>
                                            <ListItemButton onClick={(event) => {handleSearchWords(event, word)}}>
                                                {word}
                                            </ListItemButton>
                                        </ListItem>
                                        </>
                                        ))}
                                    </List>
                                </div>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </div>
        </>

    )

}