import {
    GET_IMAGEGALLARY_SUCCESS,
    GET_IMAGEGALLARY_REQUEST,
    GET_IMAGEGALLARY_FAIL
} from '../constants/imageGallaryConstants'

export const imageGallaryReducer = (state = {gallary_images:[]}, action) => {
    switch(action.type){
        case GET_IMAGEGALLARY_REQUEST:
            return {image_loading:true}
        case GET_IMAGEGALLARY_SUCCESS:
            return {loading:false, gallary_images: action.payload}
        case GET_IMAGEGALLARY_FAIL:
            return {image_loading:false, image_error: action.payload}
        default:
            return state
    }
}