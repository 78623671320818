import React from "react";
import { useHistory } from "react-router-dom";
import { Divider, List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) =>({
    date:{
        color:theme.palette.primary['700']
    },
    divider:{
        background:theme.palette.primary['400']
    },
    list:{
        height:'400px',
        marginBottom: theme.spacing(3),
        width:'100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY:'scroll',

    },
    list_item:{
        color:'black',
        paddingTop: 'initial',
        paddingBottom: 'initial',
        cursor: 'pointer',
        height:'intial'
    },

    title:{
        color:theme.palette.primary['800']
    }

}))

export default function NotificationComponent({notifications, ident}){
    const classes = useStyles()
    const history = useHistory()
    const handleClick = (notification) => {
        history.push(`/notification/${notification.id}/?ident=${ident}`)
    }
    const today = new Date()

    return(
        <List key={'notification_list'} sx={{ width:'100%'}} className={classes.list}>
            {
                notifications.map((notification) => (
                    <>
                        <ListItem key={notification.id} onClick={() => handleClick(notification)} className={classes.list_item}>
                            <ListItemText
                             key={notification.id}
                            className={classes.list_item}
                            primary={
                                <Typography className={classes.title} variant="subtitle1" component="p" >
                                    {notification.title}
                                </Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2" component="p" className={classes.date} align='right'>
                                    {
                                    parseInt((today - new Date(notification.created_at)) / 1000 / 60 / 60 / 24 /30) > 0 ? parseInt((today - new Date(notification.created_at)) / 1000 / 60 / 60 / 24 / 30) + 'ヶ月前':
                                    parseInt((today - new Date(notification.created_at)) / 1000 / 60 / 60 / 24) > 0 ? parseInt((today - new Date(notification.created_at)) / 1000 / 60 / 60 / 24) + '日前': 
                                    parseInt((today - new Date(notification.created_at)) / 1000 / 60 / 60) > 0 ? parseInt((today - new Date(notification.created_at)) / 1000 / 60 / 60) + '時間前': 
                                    parseInt((today - new Date(notification.created_at)) / 1000 / 60) > 0 ? parseInt((today - new Date(notification.created_at)) / 1000 / 60  ) + '分前': '1分以内'}
                                </Typography>
                            }/>
                        </ListItem>
                        <Divider classes={{root:classes.divider}}/>
                    </>
                ))
            }
        </List>
    )
}