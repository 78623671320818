import { string } from "yup";

export const headRewrite = (title, description, keyword) => {
    document.title = title;
    const headData = document.head.children;
    for (let i = 0; i < headData.length; i++){
        const nameVal = headData[i].getAttribute('name');
        if(nameVal !== null){
            if(nameVal.indexOf('keyword') != -1){
                headData[i].setAttribute('content', keyword);
            }
            if(nameVal.indexOf('description') != -1){
                headData[i].setAttribute('content', description);
            }
        }
    }
}

//seo対策構造化マークアップのためにscriptの書き換えを行う機能
export const headScriptRewrite = (url, product_name, description, image_url, stock, rating, price) => {
    const data = {
        "@context": "http://schema.org",
        "@type": "Product",
        "url": url,
        "name": product_name,
        "description": description,
        "image": {
            "@type": "ImageObject",
            "url": image_url,
            "width": 720,   
            "height": 720
        },
        "offers": {
            "@type": "Offer",
            "price": string(price),
            "priceCurrency": "JPY",
            "url": "商品の販売ページのurl",
            "availability": stock, //InStock [在庫あり] OutOfStock [在庫なし] PreOrder [予約]
            "validFrom": "販売開始日"
        },
        "brand": {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "ブランド名"
        },
        "review": {
            "@type": "Review",
            "reviewBody": rating,
            "author": {
                "@type": "Person",
                "name": "レビュアー"
            },
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": rating
            }
        },
    }
    const headData = document.head.children;
    for(let i = 0; i < headData.length; i++){
        const nameVal = headData[i].getAttribute('type')
        if(nameVal !== null){
            if(nameVal.indexOf("application/ld+json") != -1){
                headData[i].setAttribute('type', JSON.stringify(data))
            }
        }
    }
}