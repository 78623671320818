import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button:{
        backgroundColor: theme.palette.primary['200'],
        color: 'black',
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        },
        width:'100%',
        marginTop: theme.spacing(2),
        
    },
    card:{
        backgroundColor: 'white',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]:{
            marginRight: 'initial'
        }
    },
    card_content:{

    },
    card_info:{
        color:'black',
        marginTop: theme.spacing(1),
    },
    credit_brand:{
        display: 'flex'
    },
    image:{
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            marginRight: theme.spacing(2)
        },
        width:'40px'
    },
}))

function CreditInfoComponent({creditInfo}){
    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        history.push('/credit_list')
    }

    const cardLogos = {
        'visa': process.env.REACT_APP_IMAGE_URL +  "credit/visa.png",
        'jcb': process.env.REACT_APP_IMAGE_URL + "credit/jcb.png",
        'master': process.env.REACT_APP_IMAGE_URL + 'credit/master.png',
        'amex': process.env.REACT_APP_IMAGE_URL + "credit/amex.png"
    }

    //useSelector
    return (
            <>
                <div className={classes.credit_brand}>
                <Typography variant="subtitle1" component="p">
                    カード種類：
                </Typography>
                <div className={classes.image}>
                    <img className={classes.image} src={cardLogos[creditInfo.brand]}/>
                </div>
                <Typography variant="subtitle1" component="p">
                    {creditInfo.brand}
                </Typography>
                </div>
                
                <Typography variant='subtitle1' component="p" className={classes.card_info}>
                    カード番号： {`**** **** **** ${creditInfo.number}`}
                </Typography>
                <Typography variant='subtitle1' component="p" className={classes.card_info}>
                    有効期限： {creditInfo.exp_month + '/' + creditInfo.exp_year}
                </Typography>
                <Button onClick={handleClick} className={classes.button}>
                    クレジットカード情報を変更する
                </Button>
            </>
    )
}

export default CreditInfoComponent