export const BROWSING_HISTORY_REQUEST = "BROWSING_HISTORY_REQUEST"
export const BROWSING_HISTORY_SUCCESS = "BROWSING_HISTORY_SUCCESS"
export const BROWSING_HISTORY_FAIL = "BROWSING_HISTORY_FAIL"

export const BROWSING_HISTORY_CREATE_REQUEST = "BROWSING_HISTORY_CREATE_REQUEST"
export const BROWSING_HISTORY_CREATE_SUCCESS = "BROWSING_HISTORY_CREATE_SUCCESS"
export const BROWSING_HISTORY_CREATE_FAIL = "BROWSING_HISTORY_CREATE_FAIL"

export const SEARCH_WORDS_HISTORY_REQUEST = "SEARCH_WORDS_HISTORY_REQUEST"
export const SEARCH_WORDS_HISTORY_SUCCESS = "SEARCH_WORDS_HISTORY_SUCCESS"
export const SEARCH_WORDS_HISTORY_FAIL = "SEARCH_WORDS_HISTORY_FAIL"

export const SEARCH_WORDS_HISTORY_CREATE_REQUEST = "SEARCH_WORDS_HISTORY_CREATE_REQUEST"
export const SEARCH_WORDS_HISTORY_CREATE_SUCCESS = "SEARCH_WORDS_HISTORY_CREATE_SUCCESS"
export const SEARCH_WORDS_HISTORY_CREATE_FAIL = "SEARCH_WORDS_HISTORY_CREATE_FAIL"
