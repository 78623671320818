import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { MenuList } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//actio
import { brands_get } from "../actions/categoryAction";

//component
import BrandComponent from "../components/BrandComponent";
import Loader from "../components/Loader";
import PageSwitchingComponent from "../components/PageSwitchingComponent";

//function
import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    brand_list:{
        marginBottom:theme.spacing(15)
    },
    list:{
        display:'flex',
        overflow: 'hidden',
        overflowX:'scroll',
        padding: 'initial'
    },
    list_item:{
        width:'initial',
        textAlign: 'center',
        marginRight: theme.spacing(1),
        '&:hover':{
            backgroundColor:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        }
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,3,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,3,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,3,3)
        },
    },
    title:{
        marginTop: theme.spacing(3),
        marginBottom:theme.spacing(3)
    }
}))

const alphabetList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

//ブランドをリストで取得して表示する
function BrandListScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()

    //useState
    const [alphabet, setAlphabet] = useState('A')

    //useSelector
    const {loading, brands, error} = useSelector(state => state.brandList)

    //headerを書き換える情報
    const title = "Enclopedia取り扱いブランド一覧(アルファベット順)｜Enclopedia【公式サイト】"
    const description = "ファッションブランド一覧｜Enclopedia（アンクロペディア）が取り扱っている国内、海外のファッションブランドアルファベット順に紹介"
    headRewrite(title, description)

    //handle
    const handleClick = (alpha) => {
        dispatch(brands_get(alpha))
        setAlphabet(alpha)
    }
 
    useEffect(() => {
        dispatch(brands_get('A'))
    },[dispatch])


    return(
        <>
        {
       loading ?
       <div className={classes.loader}>
            <Loader/>
       </div>
       :
       error ?
       <div className={classes.root} style={{height:'100vh'}}>
        <PageSwitchingComponent/>
        <MenuList className={classes.list}>
            {
                alphabetList.map((alpha) => (
                    <MenuItem key={alpha} className={classes.list_item} onClick={() => handleClick(alpha)}>
                        {alpha}
                    </MenuItem>
                ))
            }
        </MenuList>

        <Typography variant="h1" component="h1" className={classes.title}>
            ブランド一覧&emsp;{alphabet}
        </Typography>
        <Typography variant="subttile1" component="p" className={classes.title}>
            ブランドが見つかりませんでした。
        </Typography>
       </div>
       :
       <div className={classes.root}>
        <PageSwitchingComponent/>
        <MenuList className={classes.list}>
            {
                alphabetList.map((alpha) => (
                    <MenuItem key={alpha} component="p" className={classes.list_item} onClick={() => handleClick(alpha)}>
                        {alpha}
                    </MenuItem>
                ))
            }
        </MenuList>


       <Typography variant="h1" component="h1" className={classes.title}>
           ブランド一覧&emsp;{alphabet}
       </Typography>
        <Grid container spacing={2} className={classes.brand_list}>
            {
                brands ?
                    brands.map((brand) => (
                        <Grid item xs={6} sm={4} md={4} lg={3} xl={3} key={brand.id}>
                            <BrandComponent brand={brand} key={brand.id}/>
                        </Grid>
                    )):
                ''
            }
        </Grid>
       </div>
       }
       </>
    )
}

export default BrandListScreen