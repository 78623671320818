import axios from 'axios';
import store from './store';
//intercepterの記述でをする401のauthorizationが返却されればそれに応じた処理をする必要がある
import { ACCOUNT_LOGIN_SUCCESS } from './constants/accountConstants';
import { ACCOUNT_LOGOUT } from './constants/accountConstants';
//import { ACCOUNT_LOGOUT } from './constants/accountConstants';
export const baseAxios = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers:{
        'Content-Type':'Application/json',
    }
})
//then或いはcatchが行われる前に処理を割り込ませる
//interceptorで共通のエラーハンドリングを行う
baseAxios.interceptors.response.use(
    function(response){
        return response;
    },
    async function(error){
        if(error.response && error.response.config && error.response.status === 401){
            //refresh tokenの処理を実行する
            const action = error.config
            const config = {'Content-Type':'Application/json'}
            await axios.post(process.env.REACT_APP_URL + '/api/account/token/refresh/', {"refresh":JSON.parse(localStorage.getItem('accountInfo')).refresh}, config)
            .then(async(response) => {
                store.dispatch({type:ACCOUNT_LOGIN_SUCCESS,payload:response['data']})
                localStorage.setItem('accountInfo', JSON.stringify(response['data']))
                action.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
                location.reload()
            })
            .catch(
                () => {
                    localStorage.clear()
                    store.dispatch({type:ACCOUNT_LOGOUT, payload:""})
                    location.href = '/login'
                }
            )
        }else{
            //元のerroを返すようにする
            Promise.reject(error)
        }
    }
)
