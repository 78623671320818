import { 
    COUPON_GET_REQUEST,
    COUPON_GET_SUCCESS,
    COUPON_GET_FAIL,
    ISSUING_LIST_REQUEST,
    ISSUING_LIST_SUCCESS,
    ISSUING_LIST_FAIL,
    COUPON_DISCOUNT_REQUEST,
    COUPON_DISCOUNT_SUCCESS,
    COUPON_DISCOUNT_FAIL,
    COUPON_DISCOUNT_DELETE,
    INVITATION_CREATE_REQUEST,
    INVITATION_CREATE_SUCCESS,
    INVITATION_CREATE_FAIL
} from "../constants/couponConstans";

export const couponReducer = (state = {}, action) => {
    switch(action.type){
        case COUPON_GET_REQUEST:
            return { loading: true }
        case COUPON_GET_SUCCESS:
            return {loading: false, coupon_code: action.payload}
        case COUPON_GET_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const issuingReducer = (state = {}, action) => {
    switch(action.type){
        case ISSUING_LIST_REQUEST:
            return {loading: true}
        case ISSUING_LIST_SUCCESS:
            return {loading: false, issuing: action.payload}
        case ISSUING_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const discountReducer = (state = '', action) => {
    switch(action.type){
        case COUPON_DISCOUNT_REQUEST:
            return {discount_loading: true, discount_price: ''}
        case COUPON_DISCOUNT_SUCCESS:
            return{discount_loading: false, discount_price: action.payload}
        case COUPON_DISCOUNT_FAIL:
            return {discount_loading: false, discount_price: ''}
        case COUPON_DISCOUNT_DELETE:
            return {discount_price: action.payload}
        default:
            return state
    }
}

export const createInvitationReducer = (state = '', action) => {
    switch(action.type){
        case INVITATION_CREATE_REQUEST:
            return {loading: true}
        case INVITATION_CREATE_SUCCESS:
            return {loading: false, invitation: action.payload}
        case INVITATION_CREATE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}