import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//component

const useStyles = makeStyles((theme) => ({
    button:{
        fontWeight: 'bold',
        width: '100%',
        marginTop: theme.spacing(1),
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
    },
    buying:{
        marginBottom: theme.spacing(2)

    },
    buying_button:{
        fontWeight: 'bold',
        width: '100%',
        marginTop: theme.spacing(1),
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
    },
    contact_button:{
        marginBottom:theme.spacing(2)
    },
    divider:{
        background:theme.palette.primary['400'],
        marginBottom: theme.spacing(2)
    },
    card:{
        color: "black",
        backgroundColor:"white",
        marginTop: theme.spacing(1),
        boxShadow: 'initial'
    },
    date:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    image:{
        [theme.breakpoints.up('xs')]:{
            width: '50px',
            height: '50px'
        },
        [theme.breakpoints.up('sm')]:{
            width:'55px',
            height: '55px'
        },
        [theme.breakpoints.up('md')]:{
            width:'60px',
            height: '60px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'70px',
            height: '70px'
        },
        [theme.breakpoints.up('xl')]:{
            width:'80px',
            height: '80px'
        }

    },
    item_divider:{
        background:theme.palette.primary['400'],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    product:{
        color:'black',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    product_container:{
        display: 'flex',
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    product_image:{
        [theme.breakpoints.up('xs')]:{
            width: '50px',
            height: '50px'
        },
        [theme.breakpoints.up('sm')]:{
            width:'55px',
            height: '55px'
        },
        [theme.breakpoints.up('md')]:{
            width:'60px',
            height: '60px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'70px',
            height: '70px'
        },
        [theme.breakpoints.up('xl')]:{
            width:'80px',
            height: '80px'
        }

    },
    product_inner:{
        marginLeft: theme.spacing(2)
    },
    root:{
        marginBottom: theme.spacing(3)
    },
    reservation_number:{
        marginBottom: theme.spacing(1)
    }

}))

const ReservationComponent = ({reservation}) => {
    const classes = useStyles()
    const history = useHistory()

    const start_date = new Date(reservation.reserved_start_date)
    const reserved_start_date = start_date.getFullYear() + '/' + (start_date.getMonth() + 1) + '/' + start_date.getDate()
    const end_date = new Date(reservation.reserved_end_date)
    const reserved_end_date = end_date.getFullYear() + '/' + (end_date.getMonth() + 1) + '/' + end_date.getDate()
    const today = new Date()
    const diff = end_date - today
    const diff_date = parseInt(diff / 1000 / 60 / 60 / 24)

    const handleShipping = () => {
        history.push(`/shipping/${reservation.id}`)
    }

    const handleBuying = (product_id) => {
        history.push(`/buying/${product_id}`)
    }

    const handleHistory = (id) => {
        history.push(`/order/history/${id}`)
    }

    const handleContact = () => {
        window.location.replace('https://toi.kuronekoyamato.co.jp/cgi-bin/tneko')
    }

    return(
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.cart_content}>
                    <Typography variant="subtitle1" component="p" className={classes.reservation_number}>
                        予約番号：&ensp;{reservation.id}
                    </Typography>
                    <Typography variant="subtitle2" component="p" align="right">
                        予約日：&ensp;{reserved_start_date}
                    </Typography>
                    <Typography variant="subtitle2" component="p" align="right">
                        返却日：&ensp;{reserved_end_date}
                    </Typography>
                    {
                        reservation.is_reserved === true ?
                            diff_date > 0 ?
                            <div className={classes.date}>
                                <Typography variant="subtitle1" component="p" align="right">
                                    返却日まで残り {diff_date}日です
                                </Typography>
                            </div>
                            :
                            <div className={classes.date}>
                                <Typography variant="subtitle2" component="p" style={{color:"#ff6f00"}} align="right">
                                    ⚠︎返却日を過ぎています,すぐにレンタル商品の返却を行なって下さい。
                                </Typography>
                            </div>
                            :
                            <div className={classes.date}>
                                <Typography variant="subtitle1" component="p" align="right">
                                    返却済み
                                </Typography>
                            </div>
                    }
                    <Typography variant="subtitle1" component="p">
                        プラン：<strong>{reservation.plan === 'rental' ? 'レンタル' : reservation.plan === 'basic' ? 'ベーシック' : 'プレミアム'}</strong>
                    </Typography>

                    {reservation.status == 1 ?
                    <Typography variant="subtitle1" component="p">
                       状況：<strong>発送準備中</strong>
                    </Typography>
                    :
                    reservation.status == 3 ?
                    <>
                        <Typography variant="subtitle1" component="p">
                            状況：<strong>発送完了</strong>
                        </Typography>
                        <Typography variant="subtitle1" component="p">
                            郵便番号：<strong>{reservation.shipping_number}</strong>
                        </Typography>
                        <Button onClick={handleContact} className={classes.contact_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                            <Typography variant="subtitle2" component="p">
                                お問い合わせ
                            </Typography>
                        </Button>
                    </>
                    :
                    reservation.status == 4 ?
                    <Typography variant="subtitle1" component="p">
                        状況：<strong>返却中</strong>
                    </Typography>
                    :
                    reservation.status==5 ?
                    <Typography variant="subtitle1" component="p">
                        状況：<strong>返却完了</strong>
                    </Typography>
                    :
                    ''
                    }
                     {
                        reservation.status == 3 ?
                            <Button className={classes.button} fullWidth onClick={handleShipping}>
                                商品を返却
                            </Button>
                        :
                        ''
                    }
                    <Divider classes={{root:classes.item_divider}}/>
                    <div className={classes.product}>
                        {
                            reservation.reservation_items.map((item) => (
                                <>
                                <div key={item.id} className={classes.product_container}>
                                    <div className={classes.product_image}>
                                        <img src={item.product.img} className={classes.image} alt={item.product.product_name}/>
                                    </div>
                                    <div className={classes.product_inner}>
                                        <Typography variant="subtitle1" component="p">
                                            {item.product.product_name}
                                        </Typography>
                                        <Typography variant="subtitle1" component="p">
                                            {item.quantity} 着
                                        </Typography>
                                    </div>
                                </div>
                                <div className={classes.buying}>
                                    {                                    
                                    reservation.status == 3 ?
                                    item.is_bought ?
                                    <>
                                    <Typography variant="subtitle2" component="p">
                                        ※こちらは購入したアイテムです
                                    </Typography>
                                    <Button className={classes.buying_button} onClick={() => { handleHistory(item.id)}}>
                                        購入履歴
                                    </Button>
                                    </>
                                    :
                                    <Button className={classes.button} onClick={() => { handleBuying(item.id)}}>
                                        購入する
                                    </Button>
                                    :
                                    ''
                                    }
                                    {                                    
                                    reservation.status == 4 && item.is_bought ?
                                    <>
                                    <Typography variant="subtitle2" component="p">
                                        ※こちらは購入したアイテムです
                                    </Typography>
                                    <Button className={classes.buying_button} onClick={() => { handleHistory(item.id)}}>
                                        購入履歴
                                    </Button>
                                    </>
                                    :
                                    ''
                                    }
                                     {                                    
                                    reservation.status == 5 && item.is_bought ?
                                    <>
                                    <Typography variant="subtitle2" component="p">
                                        ※こちらは購入したアイテムです
                                    </Typography>
                                    <Button className={classes.buying_button} onClick={() => { handleHistory(item.id)}}>
                                        購入履歴
                                    </Button>
                                    </>
                                    :
                                    ''
                                    }
                                </div>
                                <Divider classes={{root:classes.divider}}/>
                                </>
                            ))
                        }

                    </div>
                    
                    
                </CardContent>
            </Card>
        </div>
    )
}

export default ReservationComponent