import axios from 'axios'
import {
    ACCOUNT_LOGIN_REQUEST,
    ACCOUNT_LOGIN_SUCCESS,
    ACCOUNT_LOGIN_FAIL,
    ACCOUNT_LOGOUT,
    ACCOUNT_UPDATE_REQUEST,
    ACCOUNT_UPDATE_SUCCESS,
    ACCOUNT_UPDATE_FAIL,
    ACCOUNT_DELETE_REQUEST,
    ACCOUNT_DELETE_SUCCESS,
    ACCOUNT_DELETE_FAIL,
    ACCOUNT_PUBLIC_INFO,
    EMAIL_UPDATE_REQUEST,
    EMAIL_UPDATE_SUCCESS,
    EMAIL_UPDATE_FAIL,
    SENDGRID_REGISTER_REQUEST,
    SENDGRID_REGISTER_SUCCESS,
    SENDGRID_REGISTER_FAIL,
    SENDGRID_DELETE_REQUEST,
    SENDGRID_DELETE_SUCCESS,
    SENDGRID_DELETE_FAIL,
    EMAIL_SUBSCRIBE_GET_REQUEST,
    EMAIL_SUBSCRIBE_GET_SUCCESS,
    EMAIL_SUBSCRIBE_GET_FAIL,
    EMAIL_SUBSCRIBE_UPDATE_REQUEST,
    EMAIL_SUBSCRIBE_UPDATE_SUCCESS,
    EMAIL_SUBSCRIBE_UPDATE_FAIL,
    PHONE_NUMBER_AUTHENTICATION_REQUEST,
    PHONE_NUMBER_AUTHENTICATION_SUCCESS,
    PHONE_NUMBER_AUTHENTICATION_FAIL,
    PHONE_NUMBER_AUTHENTICATION_DELETE,
    SEND_AUTHENTICATION_NUMBER_REQUEST,
    SEND_AUTHENTICATION_NUMBER_SUCCESS,
    SEND_AUTHENTICATION_NUMBER_FAIL
} from '../constants/accountConstants'

import { baseAxios } from '../jwtInterceptor'

//social login フロントエンド側の処理についてもaccount内の記述にまとめる
const backend_url = process.env.REACT_APP_URL

export const accountTokenVerify  = () => {
    axios.create({
        baseURL: backend_url + '/api/account/tokne/verify',
        headers: {'Content-Type': 'Application/json'}
    })
}

export const account_get = (access_token) => async (dispatch) => {
    //emailアドレスを使用した
    try{
        dispatch({
            type:ACCOUNT_LOGIN_REQUEST
        })

        //jwt tokenを受け取るための処理
        const res1 = await axios.post(process.env.REACT_APP_URL + '/api/social_login/google/',
        {'access_token': access_token}, {
            headers:{'Content-Type': 'application/json'}
        })

        //backendからuser情報を取得するための処理
        const res2 = await axios.get(process.env.REACT_APP_URL + '/auth/user',
        {headers:{'Content-Type':'application/json', 'Authorization': `Bearer ${res1['data']['access_token']}`}})

        const data = {
            'access': res1['data']['access_token'],
            'refresh': res1['data']['refresh_token'],
            'id': res2['data']['pk'],
        }
        const publicInfo = {
            'email': res2['data']['email'],
            'phone_number': res2['data']['phone_number'],
            'first_name': res2['data']['first_name'],
            'last_name': res2['data']['last_name'],
            'username': res2['data']['username']
        }
        
        dispatch({
            type:ACCOUNT_LOGIN_SUCCESS, payload: data
        })

        dispatch({
            type:ACCOUNT_PUBLIC_INFO, payload: publicInfo
        })

        localStorage.setItem('accountInfo', JSON.stringify(data))
        localStorage.setItem('publicAccountInfo', JSON.stringify(publicInfo))
    }catch(error){
        dispatch({
            type:ACCOUNT_LOGIN_FAIL, payload: error
        })
    }
}


export const login = (email, password) => async (dispatch) => {
    dispatch({
            type:ACCOUNT_LOGIN_REQUEST
    })
    await baseAxios.post('/api/account/login', {'email':email, 'password':password})
    .then(
        (response) => {
            const publicInfo = {
                'email': response['data'].email,
                'phone_number': response['data'].phone_number,
                'username': response['data'].username,
                'first_name': response['data'].first_name,
                'last_name': response['data'].last_name
            }
            const secretInfo = {
                'refresh': response['data'].refresh,
                'access': response['data'].access,
                'id': response['data'].id
            }
        
                dispatch({type:ACCOUNT_LOGIN_SUCCESS, payload:secretInfo})
                dispatch({type:ACCOUNT_PUBLIC_INFO, payload: publicInfo})
                localStorage.setItem('accountInfo', JSON.stringify(secretInfo))
                localStorage.setItem('publicAccountInfo', JSON.stringify(publicInfo))
                
            }
        ).catch(
            (error) => {
                dispatch({
                    type:ACCOUNT_LOGIN_FAIL, payload:error
                })
            }
        )
}

export const logout = () => async (dispatch) => {
    localStorage.clear()
    dispatch({
        type: ACCOUNT_LOGOUT, payload:""
    })
    
}

export const update = (first_name, last_name, username) => async (dispatch) => {
    dispatch({
        type:ACCOUNT_UPDATE_REQUEST
    })
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        'first_name': first_name,
        'last_name': last_name, 
        'username': username,
    }
    await baseAxios.patch(`/api/account/method`, data).then(
        (response) => {
            dispatch({type:ACCOUNT_UPDATE_SUCCESS, payload:response['data']})
            localStorage.setItem('publicAccountInfo', JSON.stringify(response['data']))
    }
    ).catch(
        (error) => {
            dispatch({
                type:ACCOUNT_UPDATE_FAIL, payload:error
            })
        }
    )
}

//accountを削除する
//現在reservationがあった場合には削除ができないようにする必要がある
export const account_remove = (plan, email, sendgrid) => async(dispatch) => {
    dispatch({type: ACCOUNT_DELETE_REQUEST})

    if(sendgrid){
        dispatch({type:SENDGRID_DELETE_REQUEST})
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        await baseAxios.delete(`api/account/sendgrid/recipient`).then(
            (response) => {
                dispatch({type:SENDGRID_DELETE_SUCCESS, payload:response['data']})
            }
        ).catch(
            () => {
                console.log('マーケティングメールからユーザが削除できませんでした')
            }
        )
    }

    if(plan === 'basic' | plan === 'premium'){
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
    
        await baseAxios.delete(`/api/stripe/subscription/user_info`).then(
        async (response) => {
            if(response){
                await baseAxios.delete(`/api/account/method`).then(
                    () =>{
                        localStorage.clear()
                        dispatch({type:ACCOUNT_DELETE_SUCCESS, payload: {}})
                    }
                ).catch(
                    () => {
                        console.log('stripeアカウントが削除できませんでした')
                    }
                )
            }
        }).catch(
            () => {
                dispatch({type:ACCOUNT_DELETE_FAIL,
                payload:{message: 'アカウントの削除に失敗しました'}})
            }
        )
    }else{
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
        await baseAxios.delete(`/api/account/method`).then(
            () =>{
                localStorage.clear()
                dispatch({type:ACCOUNT_DELETE_SUCCESS, payload: {}})
            }
        ).catch(
            () => {
                dispatch({type:ACCOUNT_DELETE_FAIL,
                payload:{message: 'アカウントの削除に失敗しました'}})
            }
        )
    }

}

//emailを更新するためのaction
export const update_email = (email, password, new_email, subscription) => async(dispatch) => {
    baseAxios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
    const data ={
        'email': email,
        'password': password,
        'new_email': new_email
    }
    dispatch({type: EMAIL_UPDATE_REQUEST})
    await baseAxios.patch('/api/account/email_update', data).then(
        async(response) => {
            dispatch({type:ACCOUNT_UPDATE_SUCCESS, payload:response['data']})
            localStorage.setItem('publicAccountInfo', JSON.stringify(response['data']))
            dispatch({type: EMAIL_UPDATE_SUCCESS, payload: 'Eメールの変更が完了しました'})
            //emailのupdateに成功した場合にはsendgridとstripeのemail情報を更新する必要がある
            const information = {
                "email": new_email
            }
            //sendgrid情報の更新
            if(subscription === 'basic' | subscription === 'premium'){
                await baseAxios.put('/api/stripe/account',information)
            }
            await baseAxios.put('/api/account/sendgrid/recipient', information)
            //stripe情報の更新
        }
    ).catch(
        (error) => {
            dispatch({type: EMAIL_UPDATE_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message})
        }
    )
}

//tokenが有効かどうか検証するためのapi
export const jwt_token_verify = (access_token, refresh_token) => (dispatch) => {
    const config = {headers: {'Content-type': 'application/json'}}
    axios.post(backend_url + 
        'api/account/token/verify', {'token':access_token}, config)
        .then(function(response){
           return response
        })
        .catch(function(error){
            if(error.response.status === 401){
                dispatch(jwt_token_refresh(refresh_token))
            }
        })
}

//tokenをrefreshするためのapi
export const jwt_token_refresh = (refresh_token) => (dispatch) => {
    const config = {headers: {'Content-Type:a': 'application/json'}}
    axios.post(backend_url + 'api/account/token/refresh',
    {"refresh": refresh_token},config)
    .then(function(response){
        //accountLoginにdispatchで登録
        dispatch({type:ACCOUNT_LOGIN_SUCCESS, payload:response.data})
    })
    .catch(function(error){
       if(error.response && error.response.config && error.respones.status === 401){
           //refresh tokenが有効でない場合には
           dispatch(logout())
           window.location.href = '/'
       }
    })
}

//sendgrid contanct作成
export const sendgrid_contact_create = (email) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        "email": email
    }
    await baseAxios.post('api/account/sendgrid/contact', data).then(
        (response) => {
            dispatch({type: SENDGRID_REGISTER_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:SENDGRID_REGISTER_FAIL, payload:
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//sendgrid contact削除
export const sendgrid_contact_delete = () => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {}
    await baseAxios.delete('api/account/sendgrid/contact', data).then(
        (response) => {
            dispatch({type:SENDGRID_DELETE_SUCCESS, payload: response['data']})
        }
    ).catch(        
        (error) => {
            dispatch({type:SENDGRID_REGISTER_FAIL, payload:
            error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message})
        }
    )
}

//sendgridからのマーケティングメールを有効か無効を操作するaction
export const sendgrid_register = (email) => async(dispatch) => {
    dispatch({type:SENDGRID_REGISTER_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        "email":email
    }
    await baseAxios.post('api/account/sendgrid/suppressions', data)
    .then(
        (response) => {
            dispatch({type:SENDGRID_REGISTER_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:SENDGRID_REGISTER_FAIL, payload:
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

export const sendgrid_delete = (email) => async(dispatch) => {
    dispatch({type:SENDGRID_DELETE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.delete(`api/account/sendgrid/suppressions/?mail=${email}`).then(
        (response) => {
            dispatch({type:SENDGRID_DELETE_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:SENDGRID_DELETE_FAIL, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//userの購読を関する有無を取得するためのaction
export const subscribe_get = () =>  async(dispatch) => {
    dispatch({type:EMAIL_SUBSCRIBE_GET_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/account/email/subscribe')
    .then(
        (response) => {
            dispatch({type:EMAIL_SUBSCRIBE_GET_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:EMAIL_SUBSCRIBE_GET_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//userの購読に関する有無を更新するためのaction
//sendgirdのunsubscribeグループ利用して有効無効を操作する。
export const subscribe_update = (is_active, email, recipient_id) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    if(is_active){
        const data = {
            'email': email,
            'recipient_id': recipient_id
        }
        await baseAxios.post('/api/account/sendgrid/contact', data).then(
            async () => {
            await baseAxios.patch(`/api/account/email/subscribe`, data).then(
                (response) => {
                    dispatch({type:EMAIL_SUBSCRIBE_UPDATE_SUCCESS, payload: response['data']})
                }
            ).catch(
                (error) => {
                    dispatch({type:EMAIL_SUBSCRIBE_UPDATE_FAIL,
                        payload:error.response && error.response.data.message
                        ? 
                        error.response.data.message
                        :
                        error.message
                    })
                }
            )}
        ).catch(
            (error) => {
                dispatch({type:EMAIL_SUBSCRIBE_UPDATE_FAIL,
                    payload:error.response && error.response.data.message
                    ? 
                    error.response.data.message
                    :
                    error.message
                })
            }
        )
    }else{
        await baseAxios.delete(`/api/account/sendgrid/contact`).then(
            async() => {
                await baseAxios.patch(`/api/account/email/subscribe`, data).then(
                    (response) => {
                        dispatch({type:EMAIL_SUBSCRIBE_UPDATE_SUCCESS, payload: response['data']})
                    }
                ).catch(
                    (error) => {
                        dispatch({type:EMAIL_SUBSCRIBE_UPDATE_FAIL,
                            payload:error.response && error.response.data.message
                            ? 
                            error.response.data.message
                            :
                            error.message
                        })
                    }
                )
            }
        )
    }
    dispatch({type:EMAIL_SUBSCRIBE_UPDATE_REQUEST})
    const data = {
        'is_active':is_active
    }
    await baseAxios.patch(`/api/account/email/subscribe`, data).then(
        (response) => {
            dispatch({type:EMAIL_SUBSCRIBE_UPDATE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:EMAIL_SUBSCRIBE_UPDATE_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const phone_number_update = (phone_number) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
    const data = {
    "phone_number": phone_number
    }
    await baseAxios.put('/api/account/phone_number_update/', data).then(
        (response) => {
            const data = {
                'email': response['data']['email'],
                'phone_number': response['data']['phone_number'],
                'first_name': response['data']['first_name'],
                'last_name': response['data']['last_name'],
                'username': response['data']['username']
            }
            dispatch({
                type:ACCOUNT_PUBLIC_INFO, payload: data
            })
            localStorage.setItem('publicAccountInfo', JSON.stringify(data))
        }
    ).catch(
        () => {
        }
    )
}

export const authenticate_phone_number = (number) => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        'authentication_number': number
    }
    dispatch({type: PHONE_NUMBER_AUTHENTICATION_REQUEST})
    await baseAxios.post('/api/account/authenticate_phone_number/',data).then(
        (response) => {
            dispatch({type: PHONE_NUMBER_AUTHENTICATION_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: PHONE_NUMBER_AUTHENTICATION_FAIL,
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
            })
        }
    )
}

export const authenticate_delete = () => async(dispatch) => {
    dispatch({type: PHONE_NUMBER_AUTHENTICATION_DELETE, payload: {}})
}

export const send_authentication_number = () => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`
    dispatch({type: SEND_AUTHENTICATION_NUMBER_REQUEST})
    const data = {}
    await baseAxios.post('/api/account/phone_number_update/', data).then(
        (response) => {
            dispatch({type: SEND_AUTHENTICATION_NUMBER_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: SEND_AUTHENTICATION_NUMBER_FAIL, 
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}