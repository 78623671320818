import {
    FAVORITE_CREATE_REQUEST,
    FAVORITE_CREATE_SUCCESS,
    FAVORITE_CREATE_FAIL,
    FAVORITE_DELETE_REQUEST,
    FAVORITE_DELETE_SUCCESS,
    FAVORITE_DELETE_FAIL,
    FAVORITE_LIST_REQUEST,
    FAVORITE_LIST_SUCCESS,
    FAVORITE_LIST_FAIL,
    FAVORITE_GET_REQUEST,
    FAVORITE_GET_SUCCESS,
    FAVORITE_GET_FAIL,
} from '../constants/favoriteConstants'


//favoriteを制御するためのreducer
//requestを送り

export const favoriteCreateReducer = (state = {favorite:{}}, action) => {
    switch(action.type){
        case FAVORITE_CREATE_REQUEST:
            return {favorite_loading:true, favorite:{}}
        case FAVORITE_CREATE_SUCCESS:
            return {favorite_loading:false, favorite: action.payload}
        case FAVORITE_CREATE_FAIL:
            return {favorite_loading:false, error: action.payload}  
        default:
            return state
    }
}

export const favoriteDeleteReducer = (state = {favorite:{}}, action) => {
    switch(action.type){
        case FAVORITE_DELETE_REQUEST:
            return {favorite_loading:true}
        case FAVORITE_DELETE_SUCCESS:
            return {favorite_loading:false, favorite: {}}
        case FAVORITE_DELETE_FAIL:
            return {favorite_state: false, error: action.payload}
        default:
            return state
    }
}


export const favoriteListReducer = (state = {favorites:[]}, action) => {
    switch(action.type){
        case FAVORITE_LIST_REQUEST:
            return {favorite_loading:true,favorites:[]}
        case FAVORITE_LIST_SUCCESS:
            return {favorite_loading:false, favorites:action.payload}
        case FAVORITE_LIST_FAIL:
            return {favorite_loading:false, favorites:[]}
        default:
            return state
    }
}

export const favoriteGetReducer = (state = {favorite:{}}, action) => {
    switch(action.type){
        case FAVORITE_GET_REQUEST:
            return {favorite_loading:true, favorite:{}}
        case FAVORITE_GET_SUCCESS:
            return {favorite_loading:false, favorite: action.payload}
        case FAVORITE_GET_FAIL:
            return {favorite_loading:false, error: action.payload}
        default:
            return state
    }
}
