import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {Card} from '@material-ui/core';
import {CardContent} from '@material-ui/core'
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Modal } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

//component
import Loader from '../Loader';

//imoprt fomik and yup about from validation
import { useFormik } from 'formik';
import * as yup from 'yup';
import { baseAxios } from '../../jwtInterceptor';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{''}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

//react css style
const useStyles = makeStyles((theme) => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    button:{
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover': {
            backgroundColor: theme.palette.primary['400']
        },
    },
    container:{
        padding: 'initial'
    },
    content:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    error_message:{
      width: '100%',
      marginTop: theme.spacing(2)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    modal_button:{
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover': {
            backgroundColor: theme.palette.primary['400']
        },
        marginTop:theme.spacing(3),
    },
    modal_card:{
        margin: theme.spacing(1),
        backgroundColor: 'white',
        color:'black'
    },
    modal_container:{
        padding: 'initial',
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    paper: {
        backgroundColor:'white',
        color:'black',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
    },
    input_text:{
        color:'black'
      },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    title:{
        marginBottom: theme.spacing(3)
    }
  }));

  //yupバリデーション

 const validationSchema = yup.object().shape({
    email: yup
      .string('')
      .email('正しい形式で入力してください。')
      .required('emailアドレスは必須です。')
})

export default function PasswordResetEmail(){
    const classes = useStyles()
    const history = useHistory()
    //useState
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)


    //formk
    const formik = useFormik({
        initialValues: {
            email:''
        },
        validationSchema:validationSchema,
        onSubmit: async(values) => {
            setLoading(true)
            const data = {
                'email': values.email
            }
            baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
            await baseAxios.post('/api/password_reset/', data).then(
                () => {
                    setLoading(false)
                    setOpen(true)
                }
            ).catch(
                () => {
                    setLoading(false)
                    setError(true)
                }
            )
        }
    })

    //handler

    const handleHome = () => {
        history.push('/')
      }
    
    const handleLogin = () => {
        history.push('/login')
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleError = () => {
        setError(false)
    }

    return(
        <>
        <Container component="div" maxWidth="md" className={classes.container}>
            {loading && 
            <div className={classes.loader}>
                <Loader/>
            </div>
            }
            <Card className={classes.paper}>
                <CardContent>
                    <div className={classes.content}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant='h1'>
                            パスワード変更
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Eメール"
                            id="email"
                            name="email"
                            autoComplete='email'
                            InputLabelProps={{
                                classes:{
                                    root: classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                 }
                            }}
                            autoFocus
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email&Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            />
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            >
                                送信
                            </Button>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="p">
                                        <Button className={classes.button} onClick={handleLogin}>
                                            ログイン画面
                                        </Button>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" component="p" align='right'>
                                        <Button className={classes.button} onClick={handleHome}>
                                            ホームへ戻る
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Box>
                        <Copyright />
                    </Box>
                </CardContent>
            </Card>
        </Container>
        <Modal 
        open={open}
        className={classes.modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Container maxWidth="sm" className={classes.modal_container}>
                <Card className={classes.modal_card}>
                    <CardContent>
                        <Typography className={classes.title} variant="subtitle1" component="p" align="center" style={{fontWeight:'bold'}}>
                            メールを送信しました
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={classes.message}>
                            入力していただいたメールにメッセージを送信しました、受け取ったメールからパスワード変更の手続きを行なってください。
                        </Typography>
                        <Button className={classes.modal_button} onClick={handleClose} fullWidth>
                            閉じる
                        </Button>
                    </CardContent>
                </Card>
            </Container>
        </Modal>
        <Modal 
        open={error}
        className={classes.modal}
        onClose={handleError}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Container maxWidth="sm" className={classes.modal_container}>
                <Card className={classes.modal_card}>
                    <CardContent>
                        <Typography className={classes.title} variant="subtitle1" component="p" align="center" style={{fontWeight:'bold'}}>
                            メールの送信に失敗しました
                        </Typography>
                        <Typography variant="subtitle1" component="p"  className={classes.message}>
                            メールの送信に失敗しました、アカウントに登録した正しいEmailアドレスを入力して下さい。
                        </Typography>
                        <Button className={classes.modal_button} onClick={handleError} fullWidth>
                            閉じる
                        </Button>
                    </CardContent>
                </Card>
            </Container>
        </Modal>
        </>
    )
}

