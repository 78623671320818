import React, {useState, useEffect} from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Button, Divider, FormControlLabel } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Radio } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { RadioGroup } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//action
import { reservation_item_get } from "../actions/reservationAction";
import { buying_info_save } from "../actions/buyingAction";

import Loader from "../components/Loader";

import { baseAxios } from "../jwtInterceptor";

const useStyles = makeStyles((theme) => ({
    button:{
        background: 'white',
        textAlign: 'center',
        boxShadow: 'initial'
    },
    caption:{
        marginTop: theme.spacing(1)
    },
    content:{
        [theme.breakpoints.up("sm")]:{
            width: '100%',
            textAlign: 'right',
        },
    },
    divider:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
    },
    error:{
        width: '100%',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(15, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(20, 2, 20, 2)
        },        
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(20, 3, 20, 3)
        },
    },
    form_control:{
        color: 'black',
        width: '100%    '
    },
    image:{
        width: '100%',
        height:"100%",
        borderRadius:'1%'
    },
    image_container:{
        width: '100%',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    card:{
        color: 'black',
        background: 'white'
    },
    credit_image:{
        height: '40px'
    },
    message_divider:{
        marginTop:theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    modal_message:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre-line'
    },
    modal_button:{
        boxShadow: 'initial',
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover': {
            backgroundColor: theme.palette.primary['400']
        },
    },
    modal_container:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    loading:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    product_name:{
        fontWeight: 'bold'
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10, 2, 15, 2)
        },        
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10, 3, 15, 3)
        },
    },
    message:{
        textAlign: 'center',
    },
    price:{
        [theme.breakpoints.up("sm")]:{
            width: '100%',
            textAlign: 'right',
        },

        marginTop: theme.spacing(1)
    },
    product_description:{
        [theme.breakpoints.up("md")]:{
            display: 'flex'
        }
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            marginBottom: theme.spacing(3)
        },
        [theme.breakpoints.up('sm')]:{
            marginBottom: theme.spacing(4)
        },        
        [theme.breakpoints.up('md')]:{
            marginBottom: theme.spacing(5)
        },
    }
}))

export default function BuyingConfirmScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const {id} = useParams()

    const [payment, setPayment] = useState('card')
    const [credits, setCredits] = useState([])
    const [select, setSelect] = useState('')
    const [open, setOpen] = useState(false)


    const {loading, reservationItem, error} = useSelector(state => state.reservationItem)

    const handleChange = (event) => {
        event.preventDefault()
        setPayment(event.target.value)
    }

    const handleClick = () => {
        if(reservationItem.reservation.status !== 3 || reservationItem.is_bought){
            setOpen(true)
        }else{
            const total_price = reservationItem ? reservationItem.product.buying_price - (reservationItem.product.price.price + reservationItem.reservation.shipping_price) : ''
            const product_name = reservationItem ? reservationItem.product.product_name : ''
            const status = reservationItem ? reservationItem.reservation.status : ''
            dispatch(buying_info_save(payment, select, total_price, product_name, status))
            history.push(`/purchase/item/${id}`)
        }
    }

    const handleCredit = (event) => {
        setSelect(event.target.value)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const cardLogos = {
        'visa': process.env.REACT_APP_IMAGE_URL + 'credit/visa.png',
        'jcb': process.env.REACT_APP_IMAGE_RUL + 'credit/jcb.png',
        'master': process.env.REACT_APP_IMAGE_URL + 'credit/master.png',
        'amex': process.env.REACT_APP_IMAGE_URL + 'credit/amex.png'
    }

    useEffect(() => {
        dispatch(reservation_item_get(id))
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        baseAxios.get(`/api/stripe/credit/`).then(
            (response) => {
                setSelect(response['data']['default_payment'])
                setCredits(response['data']['cards'])
            }
        )
    }, [])

    return(
        loading ?
        <div className={classes.loading}>
            <Loader/>
        </div>
        :
        error ?
        <div className={classes.error}>
            <Typography variant="h1" component="h1" align="center">
                商品は現在購入できません
            </Typography>
        </div>
        :
        reservationItem ?
        <div className={classes.root}>
            <Typography variant="h1" component="h1" className={classes.title} align="center">
                アイテムの購入
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.message}>
                購入した商品に関しましては返却せずにそのままご利用いただくことができます。
            </Typography>
            <Divider classes={{root:classes.message_divider}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={5}>
                    <img src={reservationItem.product.img} alt={reservationItem.product.product_name} className={classes.image}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={7}>
                    <div className={classes.product_description}>
                        <Typography variant="h2" component="h2" className={classes.product_name}>
                           {reservationItem.product.product_name}
                        </Typography>
                        <div className={classes.content}>
                            <Typography variant="subtitle2" component="p" className={classes.caption}>
                               ※購入価格はレンタルした料金と返却料を差し引いた価格となります。
                            </Typography>
                            <Typography variant="h2" component="h2" className={classes.price}>
                            合計金額（税込）{reservationItem.product.buying_price - (reservationItem.product.price.price + reservationItem.reservation.shipping_price)} 円
                            </Typography>
                        </div>
                    </div>
                    <Divider classes={{root:classes.divider}}/>
                    <div className={classes.product_description}>
                        <div>
                            <Typography variant="h2"  noWrap component="h2">
                                付属品
                            </Typography>
                        </div>
                        <div className={classes.content}>
                            <Typography variant="subtitle2" component="p" className={classes.caption}>
                                タグなど付属品がある場合は後ほど郵送致します、送料に関しては無料となります
                            </Typography>
                            <Typography variant="h3" component="h3" className={classes.price}>
                                付属品はありません
                            </Typography>
                        </div>
                    </div>
                    <Divider classes={{root:classes.divider}}/>
                    <div className={classes.product_description}>
                        <Typography variant="h2" component="h2" className={classes.purchase_method}>
                            支払い方法を選択
                        </Typography>
                        <div className={classes.content}>
                            <Typography variant="subtitle2" component="p" className={classes.caption}>
                                ※現在クレジットカードのみでのお支払いとなります
                            </Typography>
                            <FormControl required component="fieldset">
                                <RadioGroup
                                key="payment"
                                margin="normal"
                                name="gender"
                                id="gender"
                                arial-label="gender"
                                value={payment}
                                onChange={(event) => {handleChange(event)}}
                                >
                                    <FormControlLabel value="card" control={<Radio color={"initial"}/>} label="クレジットカード"/>
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </div>
                    <FormControl className={classes.form_control}>
                    {
                        payment === 'card' ?
                        <Select
                        style={{color:'black'}}
                        inputProps={{
                            classes:{
                                root: classes.select_root,
                                icon: classes.select_icon
                            }
                        }}
                        labelId="payment_select"
                        id="product_select"
                        value={select}
                        onChange={handleCredit}
                        >
                            {
                                credits.map((cre, index) => (
                                    <MenuItem
                                    value={cre.payment_method_id}
                                    key={index}
                                    >
                                        <div style={{display: 'flex'}}>
                                            <div className={classes.image_container}>
                                                <img className={classes.credit_image} src={cardLogos[cre.brand]}/>
                                            </div>
                                            <Typography variant="subtitle1" component="p" style={{marginTop: '20px', width: '100%', textAlign:'right'}}>
                                                **** **** **** {cre.number}
                                            </Typography>
                                        </div>
                                    </MenuItem>
                                        
                                ))
                            }
                        </Select>
                        :
                        ''
                    }
                    </FormControl>
                    <Divider classes={{root:classes.divider}}/>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button fullWidth className={classes.button} onClick={handleClick}>
                            確認する
                        </Button>
                    </div>
                    <Divider classes={{root:classes.divider}}/>
                </Grid>
            </Grid>
            <Modal
             style={{zIndex: 3500}}
             open={open}
             onClose={handleClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
            >
                <Container maxWidth={"sm"} className={classes.modal_container}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="subtitle1" component="p" align="center" style={{fontWeight: 'bold'}}>
                                予約中のアイテムのみ購入できます
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.modal_message}>
                                {"いつもEnclopediaファッションレンタルサービスをご利用いただきありがとうございます。\
                                \n購入することができるのは予約中のアイテムのみとなっております。\
                                \n大変申し訳ございませんが購入をお考えのお客様はレンタル後に予約履歴画面から購入手続きを行なってください。"}
                            </Typography>
                            <Button className={classes.modal_button} onClick={handleClose} fullWidth>
                                閉じる
                            </Button>
                        </CardContent>
                    </Card>
                </Container>
            </Modal>
        </div>
        :
        ''
    )
}