import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(20,2,25,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(25,3,25,3)
        }
    },
    text:{
        marginBottom: theme.spacing(1)
    },
    title:{
        fontWeight: 'bold',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1)
    }
}))

export default function AntiqueDealerLicenseScreen(){
    const classes = useStyles()

    const title = "古物営業法に基づく表示 - Enclopedia(アンクロペディア)公式サイト";
    const description = "ファッションレンタルサービスEnclopedia(アンクロペディア)の古物取引営業許可に関するページです"
    headRewrite(title, description)
    
    return(
        <div className={classes.root}>
            <Typography variant={"h1"} component="h1" align="center">
                古物営業法に基づく表示
            </Typography>
            <Typography variant={"subtitle1"} component={"p"} align="center" className={classes.title}>
                ■古物商許可証番号
            </Typography>
            <Typography variant={"subtitle1"} component={"p"} align="center" className={classes.text}>
                東京都公安委員会 308832315505
            </Typography>
            <Typography variant={"subtitle1"} component={"p"} align="center">
                代表者 山口尚希
            </Typography>
        </div>
    )
}

