import {
    GET_IMAGEGALLARY_SUCCESS,
    GET_IMAGEGALLARY_REQUEST,
    GET_IMAGEGALLARY_FAIL
} from '../constants/imageGallaryConstants'
import axios from 'axios'

//image gallaryを取得するためのactionメソッド
//home画面からprduct詳細をクリックした商品のidを使用して取得できるプロダクトを動的に決定するようにする
export const imageGallaryAction = (product_id) => async(dispatch) => {
    try {
        dispatch({type:GET_IMAGEGALLARY_REQUEST})
        const config = {'headers': {'Content-Type':'application/json'}}
        const data = await axios.get(process.env.REACT_APP_URL + `/api/product/images/${product_id}/`,config)
        dispatch({type: GET_IMAGEGALLARY_SUCCESS, payload:data['data']})
    }catch(error){
        dispatch({
            type:GET_IMAGEGALLARY_FAIL,
            payload:error.resposen && error.response.data.message
            ? error.response.data.message
            : error.message
        })
    }
}