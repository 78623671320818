import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { Step } from '@material-ui/core'
import { StepLabel } from '@material-ui/core'
import { Button } from '@material-ui/core';

//actuin
import { back_step, reset_step } from '../actions/checkoutAction'

//component
import AdressConfirm from '../components/Checkout/AdressCofirm'
import SecretInfo from '../components/Checkout/SecretInfo'
import ConfirmationScreen from '../components/Checkout/ConfirmationScreen'

const steps = ['アドレス情報確認', 'お支払い情報確認', '確定']

const useStyles = makeStyles((theme) => ({

    inner:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    step:{
        color: 'white !important'
    },
    completed:{
        color: `${theme.palette.primary['400']} !important`
    }
}))

//このurlに飛飛ぶごとにnext_stepの値を初期化する
function CheckoutStep(){
    const location = useLocation()
    const history = useHistory()
    const split_word= location.search ? location.search.split('='):''
    const price = split_word[2]
    const {id} =  useParams()
    const plan = id

    const [activeStep, setActiveStep] = useState(0)
    const classes = useStyles()
    const dispatch = useDispatch()
    //useSelector
    const step_info = useSelector(state=> state.step)
    const {accountInfo} = useSelector(state => state.account)
    const { accountPublicInfo } = useSelector(state => state.accountPublicInfo)
    const {step} = step_info

    const handleNextStep = () => {
        setActiveStep(activeStep + 1)
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1)
        dispatch(back_step())
    };

    useEffect(() => {
        if(accountInfo && accountPublicInfo.phone_number == null  || accountPublicInfo.phone_number == undefined){
            history.push('/phone_number_register')
        }
        dispatch(reset_step())
        //購入手続きが開始されたことを表示し離脱率がどのくらいあるのかを検索 
        window.gtag('event', 'begin_checkout',{
            currency: 'jpy',
            value: price,
            items:[{
                name: plan,
                price: price
            }]
        })
    },[])
        

    //表示されないのは
    return (
        <Box sx={{ width: '100%' }} className={classes.root}>
            <div className={classes.inner}>
                <Stepper activeStep={activeStep} alternativeLabel className={classes.step} style={{backgroundColor:'transparent', marginTop:'3'}}>
                    {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel 
                                StepIconProps={{
                                    classes: {
                                        root: classes.step,
                                        completed: classes.completed,
                                        active: classes.active,
                                        disabled: classes.disabled
                                    }
                                }}
                                style={{color: "#bdbdbd !important"}}
                                {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                })}

                </Stepper>
                <>
                    {step+1 === 1? <AdressConfirm handler={handleNextStep}/>:''}
                    {step+1 === 2? <SecretInfo handler={handleNextStep}/>:''}
                    {step+1 === 3? <ConfirmationScreen/>:''}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2}}>
                        <Button
                        color="inherit"
                        disabled={ step=== 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                    戻る
                    </Button>
                    </Box>
                </>
            </div>
        </Box>
    )
}

export default CheckoutStep