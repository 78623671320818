import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CheckoutForm from "../components/Checkout/CheckoutForm";

const useStyles = makeStyles((theme) => ({
    button:{
        color: 'white',
        backgroundColor: 'initial',
        marginTop: theme.spacing(1)
    },
    inner:{
        marginTop:theme.spacing(10)
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,3,15,3)
        }
    },
    title:{
        marginBottom:theme.spacing(1)
    },
    topic_path:{
        display: 'flex',
    },
    topic_path_button:{
        '&:hover': {
            backgroundColor: 'initial'
        }
    }
}))

function CreditInfoUpdateScreen(){
    const classes = useStyles()
    const history = useHistory()

    const handleBack = () => {
        history.goBack()
    }

    const handleAccountService = () => {
        history.push('/management')
    }

    const handleCreditCard = () => {
        history.goBack()
    }
     
    return(
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Button onClick={handleCreditCard} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        {'クレジットカード情報'}
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    {'クレジットカード情報変更'}
                </Typography>
             </div>
            <Container maxWidth={'md'} style={{padding:'initial'}}>
                <div className={classes.inner}>
                    <CheckoutForm/>
                    <Button className={classes.button} onClick={handleBack}>
                        戻る
                    </Button>
                </div>
            </Container>
        </div>
    )
}

export default CreditInfoUpdateScreen