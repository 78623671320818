import React, {useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { Container } from '@material-ui/core';
import { Divider } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Modal } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

//action
import { addToCart, removeCartItem } from '../actions/cartAction';
import { deleteCartItem } from '../actions/cartAction';
import { getCartItem } from '../actions/cartAction';
import { get_adress } from '../actions/adressAction';
import { latest_reservation_get } from '../actions/reservationAction';
import { updateCartItem } from '../actions/cartAction';
import { subscription_reservation } from '../actions/stripeAction';
import { save_reservation_day } from '../actions/reservationAction';

//component
import Loader from '../components/Loader';
import Message from '../components/Message'
import ReservationCalendar from '../components/CalendarComponent';

const useStyles = makeStyles((theme) => ({
    address:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button:{
        textAlign:'center',
        width:'100%',
        backgroundColor:theme.palette.primary['200'],
        color:'black',
        marginTop: theme.spacing(2),
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
    },
    card:{
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.primary['100'],
        color:'black',
    },
    card_modal:{
        backgroundColor:'white',
        color:'black',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    container:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 15, 2)
        },        
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3, 3, 15, 3)
        },
    },
    content: {
        flex: '1 0 auto',
        color:'black'
    },
    //rentalプランとbasicでcss分けを行う
    cover:{
        [theme.breakpoints.up('xs')]:{
            height:'100px',
            width:'100px'

        },
        [theme.breakpoints.up('sm')]:{
            height:'100px',
            width:'100px'

        },
        [theme.breakpoints.up('md')]:{
            height:'150px',
            width:'150px'

        },
        [theme.breakpoints.up('lg')]:{
            height: '200px',
            width:'200px',
  
        },
        [theme.breakpoints.up('xl')]:{
            height: '200px'
        },
        width: '100%',
        display: 'flex',
    },
    cover_rental:{
        [theme.breakpoints.down('xs')]:{

        },
        [theme.breakpoints.up('xs')]:{
            height:'80px',
            width:'80px',
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.up('sm')]:{
            height:'80px',
            width:'80px',
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]:{
            height:'170px',
            width:'170px',
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0)
        },
        [theme.breakpoints.up('lg')]:{
            height: '200px',
            width:'200px',
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0)
        },
        [theme.breakpoints.up('xl')]:{
            height: '200px',
            width:'200px'
        },
        width: '100%',
        display: 'flex',
    },
    divider:{
        background: theme.palette.primary['700'],
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    error_container:{
        height:'100vh'
    },
    error_title:{
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(10)
        },
        [theme.breakpoints.up('sm')]:{
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10)
        },
        [theme.breakpoints.up('md')]:{
            marginTop: theme.spacing(15),
            marginBottom: theme.spacing(15)
        },
    },
    delay:{
        marginBottom: theme.spacing(1)
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        color:'black'
    },
    delivary:{
        color:theme.palette.secondary['800'],
        fontWeight: 'bold'
    },
    delivary_date:{
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    icon_button:{
        padding:'initial',
        marginBottom: theme.spacing(2)
    },
    list:{
        padding:'initial'
    },
    list_item:{
        paddingTop: 'initial',
        paddingLeft:'initial',
        paddingRight:'initial'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    message:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        whiteSpace: 'pre-line'
    },
    modal_container:{
        padding: 'initial',
        position:'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    modal_button:{
        textAlign: 'center',
        marginTop: theme.spacing(3),
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
    },
    sub_totla:{
        color: 'black',
        backgroundClor:'white',
        boxShadow: 'initial'
    },
    formControl: {
        width: 100,
        marginLeft: 20,
    },
    form_control: {
        color:'black'
    },
    select_root:{
        borderBottom: '1px solid black'
    },
    select_icon:{
        fill:'black'
    },
    title:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    }
}))


function CartScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()

    const location = useLocation()
    const history = useHistory()
    const {id} = useParams()

    const productId = id
    const quantity = location.search ? Number(location.search.split('=')[1]) : 1

  

    //配達日
    const delivary_last_day = new Date()
    const delivary_return_day = new Date()
    delivary_last_day.setDate(delivary_last_day.getDate() + 3);
    delivary_return_day.setDate(delivary_return_day.getDate() + 14)

    //state
    const [productQuantity, setProductQuantity] = useState()
    const [open, setOpen] =  useState(false)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [delivery, setDelivery] = useState('指定なし')

    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const { accountPublicInfo } = useSelector(state => state.accountPublicInfo)
    const {adressInfo} = useSelector(state => state.adress)
    const {cart} = useSelector(state => state.cart)
    const {cartItems} = useSelector(state => state.cartItem)
    const {loading, cartItem, error} = useSelector(state => state.cartItemDB)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)
    const {reservation_loading, reservation_message} = useSelector(state => state.reservation)
    const {reservations} = useSelector(state => state.reservationLatestInfo)
    const {reservation_day} = useSelector(state => state.reservationDay)

    const handleUpdate = (event) => {
        setDelivery(event.target.value)
    }
    //dbdata カートをアイテムごとに削除
    const handleCartItemDelete = (product_id) => {
        dispatch(deleteCartItem(product_id, cart['id']))
    }

    //個数制限があるのでplanごとに変更数の制御をしなければならい
    const handleCartItemUpdate= (product_id, quantity) => {
        setProductQuantity(quantity)
        dispatch(updateCartItem(product_id, cart['id'], quantity))}
    
    //userがsubscriptionに登録している場合にはこちらのhandoeを使用するようにする
    const subscriptionReservationHandler = () => {
        //subscriptionを登録するためのaction
        let contract_date = ''
        if(subscriptionInfo.update_date){
           contract_date = subscriptionInfo.update_date
        }else{
            contract_date = subscriptionInfo.start_date
        }
        dispatch(subscription_reservation(
            adressInfo.id,
            reservation_day.start_day,
            reservation_day.end_day,
            subscriptionInfo.plan,
            contract_date,
            cart.id,
            delivery

        ))
    }

    //プランがレンタルの場合のcheckout処理
    const handleRentalCheckout = () => {
        reservations.length > 0  ?
        reservations[0].is_reserved ?
        setConfirmOpen(true)
        :
        history.push('/checkout/rental')
        :
        history.push('/checkout/rental')
    }

    //userがアカウント登録していない場合
    const accountRegisterHandler = () => {
        history.push('/register')
    }

    //cartを削除するためのhandler
    const removeCartItemHandler = (id) => {
        dispatch(removeCartItem(id))
        window.gtag('event', 'remove_from_cart',{
            items:[{
                id: id,
                category: 'cart_item',
                quantity:1,
            }]
        })
    }

    //subscription予約用のモーダルを開くようにする・
    const handleOpen = () => {
        reservations.length > 0 ?
        reservations[0].is_reserved ?
        setConfirmOpen(true)
        :
        setOpen(true)
        :
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(!open)
    }

    const handleConfirmClose = () => {
        setConfirmOpen(!confirmOpen)
    }

    const handleContanct = () => {
        window.location.replace('https://enclopedia.jp/contact/')
    }
    
    useEffect(() => {
        if(reservation_message){
            history.push('/')
        }
        if(!accountInfo){
            dispatch(addToCart(productId, quantity))
        }

        if(subscriptionInfo.plan === 'basic' || subscriptionInfo.plan === 'premium'){
            dispatch(get_adress())
        }
        //dispatchミスにより
        dispatch(getCartItem(cart ? cart.id : ''))
        dispatch(latest_reservation_get())
        dispatch(save_reservation_day(delivary_last_day, delivary_return_day))
    },[dispatch, reservation_message])



    //subscriptionInfoがretalかbasic premiumかで分ける必要がある。
    return (

        accountInfo ?
        (loading || reservation_loading) ?
            <div className={classes.loader}>
                <Loader/>
            </div>
            :
            error ?
                <div className={classes.error_container} align='center'>
                    <Typography variant="h2" component="div" className={classes.error_title}>
                        予約リストに追加した商品がありません
                    </Typography>
                </div>
                :
                //plan別にcartの表示形式を変える必要がある。
                (subscriptionInfo.plan === 'basic') || ( subscriptionInfo.plan === 'premium') ?
                <div className={classes.container}>
                    <Typography variant="h1" component="h1" className={classes.title} style={{fontWeight:'bold'}}>レンタルリスト</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                                <List className={classes.list}>
                                    {cartItem.map(item => (
                          
                                        <ListItem key={item.id} className={classes.list_item}>
                                            <Card className={classes.card}>
                                                <Link to={`/product/${item['product']['id']}/`}>
                                                <CardMedia
                                                        className={classes.cover}
                                                        component="img"
                                                        src={item['product']['img']}
                                                        title="cart_item"
                                                />
                                                </Link>
                                                <div className={classes.details}>
                                                    <CardContent className={classes.content}>
                                                        <Typography variant="subtitle2" component="div">
                                                            {item['product']['product_name']}
                                                        </Typography>
                                                    </CardContent>
                                                    
                                                    <div className={classes.form_control}  >
                                                    <FormControl className={classes.formControl} style={{color:'black'}}>
                                                    <Select
                                                    style={{color:'black'}}
                                                    labelId="product_quantity"
                                                    id="product_quantity_select"
                                                    value={item.quantite}
                                                    inputProps={{
                                                        classes:{
                                                            root: classes.select_root,
                                                            icon: classes.select_icon,
                                                        }
                                                    }}
                                                    onChange={(e) => handleCartItemUpdate(item.product.id, e.target.value)}
                                                    >
                                                        <MenuItem value="" >
                                                            <em>{productQuantity}</em>
                                                        </MenuItem>
                                                        {
                                                            [...Array(item['product']['stock']).keys()].map((stock) => (
                                                                <MenuItem key={stock+1} value={stock+1}>{stock+1}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    </FormControl>
                                                    <IconButton  arria-label="delete" color='inherit' onClick={() => handleCartItemDelete(item.product.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>

                                                    
                                                    </div>
                                                </div>
                                            </Card>
                                        </ListItem>
                                        
                                    ))}
                                </List>
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                        {
                            
                        cartItem.length === 0 ? 
                            <div style={{height: '100vh'}}>
                                <Typography variant='subtitle1' component='p'>
                                    追加した商品がありません
                                </Typography>
                            </div>
                            :
                            //basicかpremiumプランの場合
                            <Card className={classes.sub_totla} style={{backgroundColor: 'white'}}>
                                <CardContent>
                                    <ReservationCalendar/>
                                    <Button
                                        onClick={handleOpen}
                                        variant="contained" 
                                        disabled= {cartItem.reduce((acc, item) => 
                                        acc.quantite + item.quantite) === 0}
                                        className={classes.button}
                                    >
                                        予約する
                                    </Button>
                                </CardContent>
                            </Card>
                            }
                        </Grid>
                    </Grid>
                    <Modal
                    open={confirmOpen}
                    onClose={handleConfirmClose}
                    >
                        <Container maxWidth={'sm'} className={classes.modal_container} >
                            <Card className={classes.card_modal}>
                            <CardContent>
                                <Typography variant="h2" align='center' component="h2" style={{fontWeight:'bold', marginBottom:15}}>
                                    予約に失敗しました
                                </Typography>
                                <Typography variant="subtitle1" align="center" component="p" style={{whiteSpace: 'pre-line'}}>
                                    {"予約ができない状態です。\n現在予約中の商品があります、商品の返却完了後に次回の予約手続きが可能となりますのでご了承下さい。"}
                                </Typography>
                                <Button fullWidth onClick={handleConfirmClose} className={classes.modal_button}>
                                    閉じる
                                </Button>
                            </CardContent>
                        </Card>
                        </Container>
                    </Modal>
                    <Modal
                     open={open}
                     onClose={handleClose}
                     aria-labelledby="modal-modal-title"
                    >
                        {subscriptionInfo.is_active ?
                            <Container maxWidth={'sm'} className={classes.modal_container}>
                                <Card className={classes.card_modal}>
                                    <CardContent>
                                        <IconButton
                                        onClick={handleClose}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        className={classes.icon_button}
                                        size="medium"
                                        color="inherit"
                                        >
                                            <BsChevronLeft/>
                                        </IconButton>
                                        <Typography variant="subtitle1" component="p" style={{fontWeight:'bold', textAlign:'center'}}>
                                            お届け情報を確認してから予約を確定して下さい
                                        </Typography>
                                        {
                                            adressInfo ?
                                            adressInfo.prefecture ?
                                            <>
                                            <Typography variant="subtitle1" component="p" className={classes.address}>
                                                お届け日: <strong>{reservation_day ? (reservation_day.start_day.getMonth() + 1) + '月' + reservation_day.start_day.getDate() + '日' : ''}</strong>
                                            </Typography>
                                            <Typography variant="subtitle1" component="p" className={classes.address}>
                                                お届け時間:
                                            </Typography>
                                            <FormControl required component="fieldset">
                                            <Select
                                            labelId="delevery_time_label"
                                            id="delevery_time"
                                            value={delivery}
                                            defaultValue={'指定なし'}
                                            onChange={handleUpdate}
                                            >
                                                <MenuItem value={'指定なし'}>指定なし</MenuItem>
                                                <MenuItem value={'午前中'}>午前中</MenuItem>
                                                <MenuItem value={'14時〜16時頃'}>14時〜16時頃</MenuItem>
                                                <MenuItem value={'16時〜18時頃'}>16時〜18時頃</MenuItem>
                                                <MenuItem value={'18時〜20時頃'}>18時〜20時頃</MenuItem>
                                                <MenuItem value={'19時〜21時頃'}>19時〜21時頃</MenuItem>
                                            </Select>
                                            </FormControl>
                                            <Typography variant="subtitle1" component="p" className={classes.address}>
                                                お届け先: <strong>{adressInfo.prefecture + adressInfo.region + adressInfo.address + adressInfo.building_name}</strong>
                                            </Typography>
                                            <Typography variant="subtitle1" component="p" className={classes.address}>
                                                電話番号: {accountPublicInfo.phone_number != null || accountPublicInfo.phone_number != undefined ? <strong>***-****-{accountPublicInfo.phone_number.substr(-4)}</strong> : ''}
                                            </Typography>
                                            </>
                                            :
                                            ''
                                            :
                                            ''
                                        }

                                        <Button
                                        className={classes.button}
                                        onClick={subscriptionReservationHandler}
                                        >
                                            予約を確定する
                                        </Button>
                                    </CardContent>
                                
                                </Card>
                            </Container>
                            :
                            <Container maxWidth={'sm'} className={classes.modal_container}>
                                <Card className={classes.card_modal}>
                                    <CardContent>
                                        <Typography variant="subtitle1" component="p" style={{fontWeight:'bold', textAlign:'center'}}>
                                            現在サブスクリプションをご利用になれません
                                        </Typography>
                                        <Typography variant="subtitle1" component="p" className={classes.message}>
                                            {'現在サブスクリプション登録プランをご利用いただけない状態です。\
                                            \nサブスクリプション更新料のお支払いが完了していない場合がございます、\
                                            クレジットカード情報をアカウント管理画面から変更を行なってください。\
                                            \n他問題点がございました下記ボタンからお問合せください。'}
                                        </Typography>
                                        <Button
                                        onClick={handleContanct}
                                        className={classes.button}
                                        >
                                            お問合せ
                                        </Button>
                                    </CardContent>
                                
                                </Card>
                            </Container>
                            }
                    </Modal>
                </div>
                
                :
                //retalプランの場合の表示
                <div className={classes.container}>
                    <Typography variant="h1" component="h1" className={classes.title}>レンタルリスト</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            {cartItem.length === 0 ? 
                            <div className={classes.error_container}>
                                <Typography variant="h2" component="h2" align='center' className={classes.error_title}>追加した商品がありません</Typography>
                            </div>
                            :
                            (
                                <List className={classes.list}>
                                    {cartItem.map(item => (
                          
                                        <ListItem key={item.id} className={classes.list_item}>
                                            <Card className={classes.card}>
                                                <Link to={`/product/${item['product']['id']}/`}>
                                                <CardMedia
                                                        className={classes.cover_rental}
                                                        component="img"
                                                        src={item['product']['img']}
                                                        title="cart_item"
                                                />
                                                </Link>
                                                <div className={classes.details}>
                                                    <CardContent className={classes.content}>
                                                        <Typography variant="subtitle2" component="p">
                                                            {item['product']['product_name']}
                                                        </Typography>
                                                        <Typography component="p" variant="subtitle2">
                                                            レンタル料: ¥{item['product']['price']['price']} 
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="p" style={{color:'black'}}>
                                                            送料: ¥{item['product']['price']['shipping']['shipping_price']}
                                                        </Typography>
                                                        <Typography variant="subtitle2" component="p">
                                                            ※こちらは単体発送の場合の料金です。まとめてレンタルする場合は発送料がお得になります。
                                                        </Typography>
                                                    </CardContent>
                                                    
                                                    <div className={classes.form_control}  >
                                                    <FormControl className={classes.formControl} style={{color:'black'}}>
                                                    <Select
                                                    style={{color:'black'}}
                                                    labelId="product_quantity"
                                                    id="product_quantity_select"
                                                    value={item.quantite}
                                                    inputProps={{
                                                        classes:{
                                                            root: classes.select_root,
                                                            icon: classes.select_icon,
                                                        }
                                                    }}
                                                    onChange={(e) => handleCartItemUpdate(item.product.id, e.target.value)}
                                                    >
                                                        <MenuItem value="" >
                                                            <em>{productQuantity}</em>
                                                        </MenuItem>
                                                        {
                                                            [...Array(item['product']['stock']).keys()].map((stock) => (
                                                                <MenuItem key={stock+1} value={stock+1}>{stock+1}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    </FormControl>
                                                    <IconButton  arria-label="delete" color='inherit' onClick={() => handleCartItemDelete(item.product.id)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    </div>
                                                </div>
                                            </Card>
                                        </ListItem>
                                        
                                    ))}
                                </List>
                                
                            )}
                        </Grid>
                        
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                        {
                            
                        cartItem.length === 0 ? 
                            ''
                            :
                            //rentalプランの場合
                            <Card className={classes.sub_totla} style={{backgroundColor: 'white'}}>
                                <CardContent>
                                    <ReservationCalendar/>
                                    <Divider className={classes.divider}/>
                                    <Typography variant="subtitle1" component="div" style={{fontWeight: 'bold'}}>
                                        小計金額:&emsp;¥{cartItem.length > 1 ?
                                        cart.total_price
                                        :
                                        cartItem[0].product.price.price * cartItem[0].quantite
                                    }
                                    </Typography>
                                    <Typography variant='subtitle1' component='p' style={{fontWeight: 'bold'}}>
                                        {cartItem.length > 1 ? 'まとめて配送料: ¥1000' : '配送料、手数料: ¥' + cartItem[0].product.price.shipping.shipping_price}
                                    </Typography>
                                    <Typography variant='subtitle2' component='p'>
                                        ※まとめて予約で送料がお得になります
                                    </Typography>
                                    <Button
                                        onClick = {handleRentalCheckout} 
                                        variant="contained" 
                                        disabled= {cartItem.reduce((acc, item) => 
                                        acc.quantite + item.quantite) === 0}
                                        className={classes.button}>
                                                予約する
                                    </Button>
                                </CardContent>
                            </Card>
                            }
                        </Grid>
                    </Grid>
                    <Modal
                    open={confirmOpen}
                    onClose={handleConfirmClose}
                    >
                        <Container maxWidth={'sm'} className={classes.modal_container} >
                            <Card className={classes.card_modal}>
                                <CardContent>
                                    <Typography variant="subtitle1" align='center' component="p" style={{fontWeight:'bold', marginBottom:15}}>
                                        現在予約するができません
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" style={{whiteSpace: 'pre-line'}}>
                                        {"現在予約ができない状態となっております。\n現在予約中の商品があります、商品の返却完了後に次回の予約手続きが可能となりますのでご了承下さい。"}
                                    </Typography>
                                    <Button fullWidth onClick={handleConfirmClose} className={classes.modal_button}>
                                        閉じる
                                    </Button>
                                </CardContent>
                            </Card>
                        </Container>
                    </Modal>
                </div>
                :
                //ログインをしていない場合
                <div className={classes.container}>
                    <Typography variant="h1" component="h1">レンタルリスト</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8}>
                            {cartItems.length === 0 ? 
                            (<Message>カート内は空です</Message>):
                            (
                                <List className={classes.list}>
                                    {cartItems.map(item => (
                                        item.product >= 1 ?
                                        <ListItem key={item.product} className={classes.list_item}>
                                            <Card className={classes.card}>
                                                <Link to={`/product/${productId}/`}>
                                                <CardMedia
                                                        className={classes.cover}
                                                        component="img"
                                                        src={item.image}
                                                        title="cart_item"
                                                />
                                                </Link>
                                                <div className={classes.details}>
                                                    <CardContent className={classes.content}>
                                                        <Typography component="p" variant="subtitle1" style={{fontWeight:'bold'}}>
                                                            商品名: {item.name}
                                                        </Typography>
                                                        <Typography component="p" variant="subtitle2" style={{fontWeight:'bold'}}>
                                                            ¥{item.price} 
                                                        </Typography>
                                                    </CardContent>
                                                    
                                                    <div className={classes.form_control}  >
                                                    <FormControl className={classes.formControl} style={{color:'black'}}>
                                                    <Select
                                                    style={{color:'black'}}
                                                    labelId="product_quantity"
                                                    id="product_quantity_select"
                                                    value={item.quantity}
                                                    onChange={(e) => dispatch(addToCart(item.product, e.target.value))}
                                                    inputProps={{
                                                        classes:{
                                                            root: classes.select_root,
                                                            icon: classes.select_icon,
                                                        }
                                                    }}
                                                    >
                                                        <MenuItem value="" >
                                                            <em>{item.quantity}</em>
                                                        </MenuItem>
                                                        {
                                                            [...Array(item.countInStock).keys()].map((stock) => (
                                                                <MenuItem key={stock+1} value={stock+1}>{stock+1}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                    </FormControl>
                                                    <IconButton  arria-label="delete" color="inherit" onClick={() =>removeCartItemHandler(item.product)}>
                                                        <DeleteIcon />
                                                    </IconButton>

                                                    
                                                    </div>
                                                </div>
                                            </Card>
                                        </ListItem>:
                                        ''
                                    ))}
                                </List>
                                
                            )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                        {cartItems.length === 0 ? 
                            (<Message>カート内は空です</Message>)
                            :
                            <Card className={classes.sub_totla} style={{backgroundColor: 'white'}}>
                                <CardContent>
                                    <Typography variant="subtitle1" component="p">
                                        小計（{ cartItems.length > 1 ? cartItems.reduce((acc, item) => acc.quantity + item.quantity) : cartItems[0].quantity}個の商品）（税込）
                                    </Typography>
                                    <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                                        : ¥{cartItems.length > 1 ? cartItems.reduce((acc, item) => 
                                            Math.floor(Number((acc.price * acc.quantity) + (item.price  * item.quantity)) * 1.1))
                                            : 
                                            Math.floor(Number(cartItems[0].price * cartItems[0].quantity + (cartItems[0].price * cartItems[0].quantity * cartItems[0].tax)))
                                        }
                                    </Typography>
                                    <Typography variant="subtitle2" component="p">
                                        送料: ¥{cartItems[0].shipping_price}
                                    </Typography>
                                    <Typography variant="caption" div="component">
                                        ※お二つ以上の商品をレンタルされる方の料金は一律120サイズとなっております。
                                    </Typography>
                                    <Typography variant='subtitle1' component='p' className={classes.delivary}>
                                        お届け日:
                                    </Typography>
                                    <Typography variant='subtitle1' component='p' style={{fontWeight:'bold'}}>
                                    {(delivary_last_day.getMonth() + 1) + '月' + delivary_last_day.getDate() + '日にお届け'}
                                    </Typography>
                                    <Typography variant='subtitle2' component='p' className={classes.delay}>
                                        ※天候や道路状況によりお荷物の配達に遅延等が発生する場合がございます。
                                    </Typography>
                                    <Typography variant='subtitle1' component='p' className={classes.delivary}>
                                        返却日:
                                    </Typography>
                                    <Typography variant='subtitle1' component='p' className={classes.delivary_date}>
                                    {(delivary_return_day.getMonth() + 1) + '月' + delivary_return_day.getDate() + '日までにご返却'}
                                    </Typography>
                                        <Button
                                                onClick = {accountRegisterHandler} 
                                                variant="contained" 
                                                disabled= {cartItems.reduce((acc, item) => 
                                                    acc + item.quantity, 0) === 0}
                                                className={classes.button}>
                                                    ログインして下さい
                                        </Button>
                                </CardContent>
                            </Card>}
                        </Grid>
                    </Grid>
                </div>
    )
}

export default CartScreen


