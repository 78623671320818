import React, {useEffect, useState} from "react";
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Popper } from "@material-ui/core";
import { Fade } 
from "@material-ui/core";

import { get_invitation_coupon } from "../actions/couponAction";

const useStyles = makeStyles((theme) => ({
    caption:{
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0.5),
        backgroundColor: 'white',
    },
    code:{
        backgroundColor: 'white',
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
        boxShadow: 'initial'
    },
    code_title:{
        marginBottom: theme.spacing(1)
    },
    context:{
        whiteSpace: 'pre-line'
    },
    divider:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    img:{
        height: '100%',
        
    },
    img_container:{
        height: '300px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'white',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3)
        }
    },
    subtitle:{
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    },
    title:{
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    },
    topic:{
        marginBottom: theme.spacing(1),
        fontWeight: 'bold'
    }
}))

const InvitationCouponScreen = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    //useState
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    

    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {loading, coupon_code, error} = useSelector(state => state.coupon)

    const handleClick = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setOpen((previousOpen) => !previousOpen);
        navigator.clipboard.writeText(coupon_code ? coupon_code.code : '')
        setTimeout(function(){
            setOpen(false)
        },5000)
      };
    

    useEffect(() => {
        if(accountInfo){
            dispatch(get_invitation_coupon())
        }
    },[])
    
    return (
        <Container maxWidth="lg" style={{padding: 'initial'}}>
            <div className={classes.root}>
                <div className={classes.img_container}>
                    <img className={classes.img} src="https://media.enclopedia-official.com/media/idea/enclopedia_coupon3.webp" alt="友達招待クーポン"/>
                </div>
                <Typography variant="h1" component="h1" align="center" className={classes.title}>
                    友達招待クーポン
                </Typography>
                {
                    accountInfo ?
                    loading ?
                    ''
                    :
                    error ?
                    ''
                    :
                    <>
                    <Typography variant="subtitle1" component="p" className={classes.code_title}>
                        あなたの招待コード
                    </Typography>
                    <Button
                    type="button"
                    onClick={handleClick}
                    color="primary"
                    variant="contained"
                    fullWidth
                    className={classes.code}
                    >
                        {coupon_code ? coupon_code.code : ''}
                    </Button>
                    <Popper  open={open} anchorEl={anchorEl} placement='top-end' transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                            <Typography variant="subtitle2" component="div" className={classes.caption}>
                                コピーしました
                            </Typography>
                            </Fade>
                        )}
                    </Popper>
                    </>
                    :
                    ''
                }
                <Typography variant="h2" component={"h2"} className={classes.subtitle} align="center">
                    招待方法
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.context}>
                    {"本サービスログイン後に【ホーム ＞ アイデア ＞ 友達招待クーポン獲得方法】から専用の招待コードをご確認いただけます。\
                    "}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h3" component="h3" className={classes.topic}>
                    招待コードを送る
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.context}>
                    {"友達があなたの贈った招待コードを入力してメルカリに新規会員登録すると、それぞれに１０００円分の割引クーポンを配布！\
                    \nクーポンはEnclopediaでのお支払い時に利用できます。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h3" component="h3" className={classes.topic}>
                    クーポン利用条件
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.context}>
                    {"•招待した方： 1000円クーポン配布\
                    \n•招待された方： 1000円クーポン配布\
                    \n•クーポンの有効期限： 招待された日から90日間有効\
                    \n•クーポンの配布： 登録から3日以内に配布いたします\
                    \n※何人招待しても毎回クーポンが発行されます。クーポンの発行枚数に上限はありません。\
                    "}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h3" component="h3" className={classes.topic}>
                    注意事項
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.context}>
                    {"•不正行為等によるクーポン取得を確認した場合にはクーポン配布の対象外とし、配布したクーポンに関しては取り消しとなる場合がございます。\
                    \n•招待クーポン配布は予告なく変更、中止、延長する場合があります。\
                    \n•招待クーポンの割引料金に関して予告なく変更される場合があります。\
                    \n•本サービス退会した方に対して再度入会時に招待コード利用したクーポンの発行は致しておりません。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
            </div>
        </Container>
    )
}

export default InvitationCouponScreen