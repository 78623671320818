import React,{ useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { BsChevronLeft } from 'react-icons/bs';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import { FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import { Radio } from '@material-ui/core';
import { RadioGroup } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//component
import Loader from '../components/Loader';
import Message from '../components/Message';
import ClosetComponent from '../components/Closet';
import { update_profile } from '../actions/profileAction';



const useStyles = makeStyles((theme) => ({
    avatar:{
        height:'100px',
        width:'100px'
    },
    closet_container:{
        margin: theme.spacing(1),
        marginBottom: theme.spacing(15)
    },
    closet_card:{
        marginTop: theme.spacing(3),
        position:'relative'
    },
    description:{
        textAlign: 'center',
        marginTop: theme.spacing(3)
    },
    first_name:{
        marginTop: theme.spacing(1),
        textAlign:'center',
        letterSpacing:'0.2em'
    },
    form_label:{
        color:'black'
    },
    icon_button:{
        '&:hover':{
            backgroundColor:'initial'
        },
        padding:'initial'
    },
    form_birth_day:{
        marginBottom: theme.spacing(3)
    },
    form_gender:{
        marginBottom: theme.spacing(3)
    },
    input:{
        display:'none'
    },
    input_text:{
        color:'black'
    },
    image_button:{
        margin:'auto'
    },
    iamge_input:{
        display:'flex',
        marginBottom: theme.spacing(3)
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    media:{
        width:'100%',
        height:'100%',
        borderRadius: '50%',
        objectFit: 'cover',
    },
    media_profile:{
        width:'150px',
        height:'150px',
        margin: 'auto',
        borderRadius: '50%',
        border: 'solid 3px white',
        marginTop: theme.spacing(3),
        background: theme.palette.primary['400']
        
    },
    profile:{
        fontFamily: 'Helvetica'
    },
    profile_button:{
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    profile_title:{
        marginBottom: theme.spacing(2)
    },
    profile_edit:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(10)
    },
    text_field:{
        color:'black',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    title:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        textDecoration: 'underline',
        textDecorationColor: 'black',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    username:{
        marginTop: theme.spacing(1),
        textAlign:'center',
        letterSpacing:'0.2em'
    },
    }
))

const ProfileScreen = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const inputElement = useRef(null)
    //useSelector
    const {loading, closets, error} = useSelector(state => state.closetList)
    const {profile_loading, profileInfo, profile_error} = useSelector(state => state.profile)
    const {accountPublicInfo} = useSelector(state => state.accountPublicInfo)
    //useState
    const [birth_day, setBirth_day] = useState(profileInfo ? profileInfo.birth_day : "1970-12-11")
    const [gender, setGender] = useState(profileInfo ? profileInfo.gender : '')
    const [open, setOpen] = useState(false)
    const [profileImg, setProfileImg] = useState(profileInfo ? profileInfo.img : null)
    const [binaryImg, setBinaryImg] = useState(profileInfo ? profileInfo.img : null)
    //fakcepathをどのように取り除くのかを調べ忘れないように記録する必要がある
    const imageHandler = (event) => {
        if(event.target.files && event.target.files[0]){
            setProfileImg(event.target.files[0])
            const reader = new FileReader()
            reader.onload = (event) => {
                setBinaryImg(event.target.result)
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    //profile update
    const submitHandler = (event) => {
        event.preventDefault()
        const form = new FormData()
        form.append('img', profileImg)
        form.append('gender', gender)
        form.append('birth_day', birth_day)
        dispatch(update_profile(form))
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setProfileImg(profileInfo.img)
    }

    //closetコンポーネントに置き換えを行う
    return(
        <div className={classes.closet_container}> 
            {profile_loading ? 
                <div className={classes.loader}>
                    <Loader/>
                </div>
                : profile_error ?
                    <Message>{profile_error['message']}</Message>
                    :
                    <>
                    <div className={classes.profile}>
                        <div className={classes.media_profile}>
                            <img alt="プロフィール画像" src={profileInfo.img ? profileInfo.img : 'https://media.enclopedia-official.com/non_image.png'} className={classes.media} loading="lazy"/>
                        </div>
                        <Typography variant="subtitle1" component="p" align="center" className={classes.first_name}>
                            {accountPublicInfo.username}
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={classes.username} >
                            <Button className={classes.profile_button} onClick={handleOpen} disableRipple={true} disableFocusRipple={true}>
                                プロフィール編集
                            </Button>
                        </Typography>
                    </div>

                    <Modal
                     open={open}
                     onClose={handleClose}
                     aria-labelledby="modal-modal-title"
                    >
                        <Container maxWidth="sm">
                        <Card className={classes.profile_edit}>
                            <CardContent>
                            <IconButton
                             onClick={handleClose}
                             disableRipple={true}
                             disableFocusRipple={true}
                             className={classes.icon_button}
                             size="medium"
                             color="inherit">
                                 <BsChevronLeft/>
                             </IconButton>
                            <Typography variant="h2" component="h2" className={classes.profile_title} align='center'>
                                プロフィール編集
                            </Typography>
                            <form onSubmit={submitHandler}>
                                <div className={classes.iamge_input}>
                                    <Avatar alt="プロフィール画像" src={binaryImg ? binaryImg : 'https://media.enclopedia-official.com/non_image.com'} aria-label="recipe" className={classes.avatar}/>
                                    <div className={classes.image_button}>
                                        <InputLabel>
                                            <FormControl required component='fieldset'>
                                                <input
                                                key="img"
                                                name="img"
                                                type="file"
                                                margin="normal"
                                                accept="image/*"
                                                multiple
                                                className={classes.input}
                                                id='img'
                                                value={inputElement.current}
                                                onChange={imageHandler}
                                                />
                                            </FormControl>
                                            <Button variant="contained" component="div">
                                                プロフィール画像変更
                                            </Button>
                                        </InputLabel>
                                    </div>
                                </div>
                                <div className={classes.form_gender}>
                                    <FormControl required component="fieldset">
                                    <FormLabel component='legend' className={classes.form_label}>{'性別'}</FormLabel>
                                        <RadioGroup
                                        key="gender"
                                        margin="normal"
                                        name="gender"
                                        id="gender"
                                        aria-label = "gender"
                                        value={gender}
                                        onChange={(event) => {
                                            setGender(event.target.value)
                                        }}>
                                            <FormControlLabel value="女性" control={<Radio style={{color:'initial'}}/>} label="女性"/>
                                            <FormControlLabel value="男性" control={<Radio style={{color:'initial'}} />} label="男性"/>
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                                <div className={classes.form_birth_day}>
                                    <FormControl required component="fieldset">
                                        <FormLabel component='legent' className={classes.form_label}>{'生年月日'}</FormLabel>
                                        <TextField
                                            margin="normal"
                                            key="brith_day"
                                            type="date"
                                            id="birth_day"
                                            name="birth_day"
                                            value={birth_day}
                                            InputLabelProps={{
                                                classes:{
                                                    root: classes.input_text
                                                }
                                            }}
                                            InputProps={{
                                                classes:{
                                                    notchedOutline:classes.text_field,
                                                    root: classes.text_field
                                                }
                                            }}
                                            onChange={(event) => setBirth_day(event.target.value)}
                                            />
                                    </FormControl>
                                </div>
                                <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                >
                                    変更
                                </Button>
                               
                            </form>
                            </CardContent>
                        </Card>
                        </Container>
                    </Modal>
                </>
            }

            <div>
                {
                    loading ?
                    <div className={classes.loader}>
                        <Loader/>
                    </div>
                    :
                    error ?
                    <>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            あなたのクローゼット
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={classes.description}>
                            クローゼットを取得できませんでした
                        </Typography>
                    </>
                    :
                    closets.length === 0 ?
                    <>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            あなたのクローゼット
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={classes.description}>
                            登録したクローゼットがありません
                        </Typography>
                    </>
                    :
                    <>
                        <Typography variant="h2" component="h2" align='center' className={classes.title}>
                            あなたのクローゼット
                        </Typography>
                        <Grid container spacing={2}>  

                        {
                            closets.map((closet)=>(
                                <>
                                <Grid key={closet.id} item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.closet_card}>
                                    <ClosetComponent key={closet.id} closet={closet}/>
                                </Grid>
                                </>
                            ))
                        }
                        </Grid>
                     </>
                }
            </div>
        </div>
   
    )
}

export default ProfileScreen