import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from '@material-ui/core'
import { headRewrite } from "../function/headRewrite";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    article:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    content:{
        whiteSpace: 'pre-line',
        lineHeight: '25px'
    },
    divider:{
        marginTop:theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    grid:{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5)
    },
    top_grid:{
        [theme.breakpoints.up('sm')]:{
            display: 'none'
        }
    },
    under_grid:{
        [theme.breakpoints.down('sm')]:{
            display: 'none'
        }
    },
    guide:{
        marginTop:theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    guide_title:{
        [theme.breakpoints.down('md')]:{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3)
        },
        [theme.breakpoints.up('md')]:{
            marginTop: '50%'
        }
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    subtitle:{
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    title:{
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    }
}))

export default function StartGuideScreen(){
   const classes = useStyles()
   const title = 'Enclopediaの使い方ガイド｜Enclopedia【公式サイト】'
   const description = 'Enclopedia（アンクロペディア）でのアイテムのレンタル方法、借りたアイテムの購入方法、アイテムの検索方法を紹介。'
   const keyword = 'ファッションレンタル,enclopedia,アンクロペディア,服レンタル,使い方'
   headRewrite(title, description, keyword)
   return(
    <div className={classes.root}>
        <Container maxWidth={"md"} style={{padding: 'initial'}}>
            <Typography variant={"h1"} component="h1" className={classes.title} align="center">
                {"ようこそEnclopediaへ"}
            </Typography>
            <Typography variant="subtitle1" component="p" align="center" className={classes.content}>
                {"モードを民主化する。"}
            </Typography>
            <Divider classes={{root:classes.divider}}/>
            <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                Enclopediaコミュニティ
            </Typography>
            <Typography variant="subtitle1" component="p" align="center" className={classes.content}>
                {"Enclopedia(アンクロペディア)は\
                \nブランドの貴重な過去アーカイブの保存とシェアリングを目的とした、\
                \nファッションレンタルサービスです。\
                \nEnclopediaコミュニティでは過去のコレクションを収集しシェリングすることで、コミュニティに参加していただいたみなさまが気軽にハイファッションを楽しめることを目指しております。\
                \nまたサービスを通して服は買うものではなく借りるものという価値観を一般的にすることで消費を少なくするエシカルファッションにも取り組んでいます。"}
            </Typography>
            <Divider classes={{root:classes.divider}}/>
            <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                Enclopedia利用ガイド
            </Typography>
            <Grid container spacing={5} className={classes.guide}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography variant="h3" component="h3" align="center" className={classes.guide_title}>
                        アイテムを検索
                    </Typography>
                    <Typography variant="subtitle1" component="p" align="center" style={{whiteSpace:'pre-line'}} >
                        {"Enclopediaで借りたいアイテムを検索しましょう。\
                        \n気に入ったアイテムや次にレンタルしたいアイテムを「お気に入り」に登録、\
                        \n考えたスタイリングをクローゼットに保管して今後のコーデの参考にしたりすることもできます。"}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={{width: "100%",
                height: "500px",
                position: "relative",
                overflow: "hidden",}}>
                <video loop muted autoPlay playsInline
                style={{
                    objectFit: "cover",
                    position: "absolute",
                    height: "500px",    
                    width: "100%",
                    top: 0,
                    left: 0
                }}
                >
                    <source src="https://media.enclopedia-official.com/media/movie/enclopedia_deploy_guide_1.mp4" type="video/mp4"/>
                </video>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={5} className={classes.guide}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.top_grid}>
                <Typography variant="h3" component="h3" align="center" className={classes.guide_title}>
                    アイテムを予約
                </Typography>
                <Typography variant="subtitle1" component="p" align="center" style={{whiteSpace:'pre-line'}} >
                    {"Enclopediaで借りたいアイテムを予約しましょう。\
                    \n気に入ったアイテムをアイテム詳細ページからレンタルリストに追加しましょう。\
                    \nあとはレンタルリストの予約ボタンからお支払いに関する手続きをするだけ。"}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div style={{width: "100%",
                height: "500px",
                position: "relative",
                overflow: "hidden",}}>
                <video loop muted autoPlay playsInline
                style={{
                    objectFit: "cover",
                    position: "absolute",
                    height: "500px",
                    width: "100%",
                    top: 0,
                    left: 0
                }}
                >
                    <source src="https://media.enclopedia-official.com/media/movie/enclopedia_guide2.mp4" type="video/mp4"/>
                </video>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.under_grid}>
                    <Typography variant="h3" component="h3" align="center" className={classes.guide_title}>
                        アイテムを予約
                    </Typography>
                    <Typography variant="subtitle1" component="div" align="center" style={{whiteSpace:'pre-line'}} >
                        {"Enclopediaで借りたいアイテムを予約しましょう。\
                        \n気に入ったアイテムをアイテム詳細ページからレンタルリストに追加しましょう。\
                        \nあとはレンタルリストの予約ボタンからお支払いに関する手続きをするだけ。"}
                </Typography>
                </Grid>
            </Grid>
            <Divider classes={{root:classes.divider}}/>
            <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                メンバーの信頼性基準
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.content}>
                {"Enclopediaコミュニティ参加いただいたみんさまには、アイテムを次の方にも気持ちよく使用していただくために以下のルールをお守り頂くことをお願いしております.\
                \n１、通常使用による摩耗、キズや汚れを超える汚損、損傷しないよう大切に扱う\
                \n２、破損した付属品(ボタンなど)はとっておき返却時に同封する\
                \n３、アイテムに問題（汚れ、破損）が発生した場合運営に連絡する\
                \nEnclopediaではメンバーの方が長く清潔な状態でご利用いただけるようアイテムのクオリティの維持に取り組んでいます。"}
            </Typography>
            <Divider classes={{root:classes.divider}}/>
            <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                利用開始準備
            </Typography>
            <Typography variant="h3" component="h3" className={classes.article}>
                アカウントの作成
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.content}>
                {"Enclopediaアカウントの作成は無料で行うことができます。\
                \nいくつかの基本情報を入力することでサービスの利用が開始できます。"}
            </Typography>
            <Typography variant="h3" component="h3" className={classes.article}>
                レンタル前に
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.content}>
                {"予約前に配送先とお支払い情報（クレジット）を入力していただく必要があります。\
                \nこれでレンタルする準備が整いました。"}
            </Typography>
            <Divider classes={{root:classes.divider}}/>
            <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                取扱アイテムに関して
            </Typography>
            <Typography variant="subtitle1" component="p" className={classes.content}>
                {"Enclopedia（アンクロペディア）では現在2次流通の古着を取り扱っております、各アイテムの状態に関しては商品詳細ページにてご覧いただけます。"}
            </Typography>
            <Divider classes={{root:classes.divider}}/>
        </Container>
    </div>
   )
}