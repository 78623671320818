import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//action
import { return_rental_product } from "../actions/reservationAction";
import { get_reservation } from "../actions/reservationAction";
import { todo_return_item } from "../actions/notificationAction";

//component
import Loader from "../components/Loader";

//useformik
import { useFormik } from "formik";
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    back_button:{
        color:'black'
    },
    button:{
        color:'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['00']
        }
    },
    card:{
        color:'white',
        backgroundColor:'white',
        marginTop:theme.spacing(10),
        marginBottom: theme.spacing(15),
        boxShadow: 'initial'
    },
    caption:{
        color: theme.palette.primary['700']
    },
    container:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(15,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin:theme.spacing(20,2,20,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(25,3,25,3)
        }
    },
    message:{
        marginTop: theme.spacing(3),
        whiteSpace: 'pre-line'
    },
    input_text:{
        color:'black'
    },
    info_button: {
        backgroundColor: 'white',
        marginTop: theme.spacing(3)
    },
    info_message:{
        marginBottom: theme.spacing(1),
        color: 'black'
    },
    loader:{
        marginTop:'50%',
        textAlign:'center',
        minHeight:'100vh',
        minWidth:'100vw'
    },
    root:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(1,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin:theme.spacing(2,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,3,15,3)
        }
    },
    shipping:{
        marginTop:theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    text_field:{
        color: 'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    title:{
        color:'black',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3)
    },
    topic_button:{
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    topic_path:{
        display: 'flex'
    }
}))
const shippingNumberRegex=/^[0-9]{12}$/

//yup バリデーション
const validationSchema = yup.object({
    shippingNumber: yup
    .string('')
    .max(12,'伝票番号はハイフンを含めない12桁の数字で入力して下さい')
    .min(12,'伝票番号はハイフンを含めない12桁の数字で入力して下さい')
    .required('入力は必須です.', '伝票番号はハイフンを含めない12桁の数字で入力して下さい')
    .matches(shippingNumberRegex,'12桁の番号を入力して下さい。')
})

//ヤマトapi連携まで顧客に伝票番号を入力してもらう必要がある
const ShippingForm = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const {id} = useParams()

    //useSelector
    const {loading, message, return_error} = useSelector(state => state.returnProduct)
    const {reservation_loading, reservation, error} = useSelector(state => state.reservationGetInfo)

    const handleGoBack = () => {
        history.goBack()
    }

    const handleAccountService = () => {
        history.push('/management')
    }

    const handleSuccess = () => {
        history.push('')
    }

    const handleFailed = () => {
        history.push('')
    }

    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            shippingNumber: reservation ? reservation.return_shipping_number : ''
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            dispatch(return_rental_product(id, values.shippingNumber))
            dispatch(todo_return_item(id))
        }
    })
    
    useEffect(() => {
        dispatch(get_reservation(id))
    },[])

    //返却完了後userがレビューするようにcommentfiedを表示数量に数r
    return(
        loading || reservation_loading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        error ?
        <div className={classes.container}>
            <Typography variant="h2" component="h2" align="center">
                アイテムを返却する必要はありません
            </Typography>
        </div>
        :
        return_error ?
        <div className={classes.container}>
            <Container maxWidth={"sm"} style={{padding: 'initial'}}>
                <Typography variant="h2" component="h2" align="center" syle={{fontWeight: 'bold'}}>
                    返却に失敗しました
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.info}>
                    {`いつもEnclopediaファッションレンタルサービスをご利用いただきありがとうございます。\
                    \nアイテムの返却に失敗しました、通信状況などがご確認の上再度手続きをしていただく必要がございます。\
                    \n解決しない場合はEnclopedia運営までお問い合わせください。`}
                </Typography>
                <Button onClick={handleFailed} className={classes.info_button} fullWidth>
                    戻る
                </Button>
            </Container>
        </div>
        :
        message ?
        <div className={classes.container}>
            <Container maxWidth={"sm"} style={{padding: 'initial'}}>
                <Typography variant="h2" component="h2" align="center" syle={{fontWeight: 'bold'}}>
                    返却申請が完了しました
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.info}>
                    {`いつもEnclopediaファッションレンタルサービスをご利用いただきありがとうございます。\
                    \nアイテムの返却申請が完了しました、アイテムがEnclopedia到着後に次のレンタルが可能となりますのでご了承ください。\
                    \n配送遅延がない場合はアイテム発送から２、３日程度で次のレンタルが可能となります。`}
                </Typography>
                <Button onClick={handleSuccess} className={classes.info_button} fullWidth>
                    戻る
                </Button>
            </Container>
        </div>
        :
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={ handleAccountService } className={classes.topic_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Button onClick={ handleGoBack } className={classes.topic_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        予約履歴
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    商品返却
                </Typography>
            </div>
            <Container maxWidth={"md"} style={{padding: 'initial'}}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="subtitle1" component="p" align="center" className={classes.title}>
                            レンタルした商品を返却する
                        </Typography>
                        <Typography variant="subtitle1" component="p" className={classes.message}>
                            アイテムお届け時に同梱されていた発送用紙お問い合わせ番号が下記の番号と一致するか確認してから発送作業を行なってください。
                        </Typography>
                        <Typography variant="subtitle2" component="p" className={classes.caption}>
                            ＊返却用の用紙をなくした場合はヤマト運輸着払の用紙をご用意ください、再度お問い合わせ番号を下記フォームに入力していただく必要がございます。商品返却の確認後次回予約が可能となります。
                        </Typography>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <TextField
                            color="primary"
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="shippingNumber"
                            label="伝票番号"
                            name="shippingNumber"
                            className={classes.shipping}
                            value={formik.values.shippingNumber}
                            onChange={formik.handleChange}
                            error={formik.touched.shippingNumber && Boolean(formik.errors.shippingNumber)}
                            helperText={formik.touched.shippingNumber && formik.errors.shippingNumber}
                            InputLabelProps={{
                                classes:{
                                    root: classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                }
                            }}
                        />
                        <Button
                        type="submit"
                        fullWidth
                        className={classes.button}
                        >
                            返却
                        </Button>
                        </form>
                        <Button
                        onClick={handleGoBack}
                        disableRipple={true}
                        disableFocusRipple={true}
                        className={classes.back_button}
                        >
                            戻る
                        </Button>
                        
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default ShippingForm