import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//componet
import Loader from '../Loader';
import Message from '../Message';
import CheckoutForm from './CheckoutForm';
import CreditInfoComponent from './CreditInfoComponent';

//action
import { stripe_user_info } from '../../actions/stripeAction';
import { next_step } from '../../actions/checkoutAction';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    button:{
        backgroundColor: theme.palette.primary['200'],
        marginTop: theme.spacing(2),
        color:'black',
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
    },
    button_update:{
        backgroundColor: theme.palette.primary['200'],
        color:'black',
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
    },
    card:{
        backgroundColor:'white',
        color:'black',
        [theme.breakpoints.down('xs')]:{
            marginRight: 'initial'
        }
    },
    card_content:{
        bgcolor: 'black',
        [theme.breakpoints.up('sm')]:{
        }

    },
    card_secret:{
        backgroundColor: 'white',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]:{
            marginRight: 'initial'
        }
    },
    card_form:{
        backgroundColor:'white',
        color:'black',
        marginTop: theme.spacing(2),
    },
    container:{
        padding:'initial'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
    },
    title:{
        marginBottom: theme.spacing(3)
    },
    title_credit:{
        color: 'black',
        textAlign:'center',
        borderBottom: 'solid 1px black',
        marginBottom: theme.spacing(2)
    }
}))

export default function SecretInfo({handler}){
    const classes = useStyles()
    const dispatch = useDispatch()

    const {id} = useParams()
    const plan = id

    //useState
    const [message, setMessage] = useState('')

    //useselctor
    const {accountInfo} = useSelector(state => state.account)

    const {loading, credit_info, error} = useSelector(state => state.secretInfo)
    
    const handleNext = () => {
        handler()
        dispatch(next_step())
        window.scrollTo({
            top: 0,
        });
    }

    //子compoentに渡すためのfunction
    const checkoutFunc = async(data) => {
        try{
            const config = {headers:{'Content-Type':'application/json', 'Authorization': `Bearer ${accountInfo.access}`}}
            await axios.post('http://127.0.0.1:8000/api/stripe/subscription/payment', data, config)
        }catch(error){
            setMessage(
                error.response && error.response.data.message
                ? error.response.data.message
                : error.message)
        }
        
    }

    useEffect(() => {
        dispatch(stripe_user_info())
    },[dispatch])

    return (
        <div className={classes.root}>
            {message && <Message>{message}</Message>}
            {
                loading ?
                <div className={classes.loader}>
                    <Loader/>
                </div>
                :
                error ?
                //errorだった場合にはcheckoutformを表示するようにする

                
                <Container maxWidth={'md'} className={classes.container}>
                        <Typography variant="h1" component="h1" align="center" className={classes.title}>
                            ② クレジット情報更新
                        </Typography>
                    <CheckoutForm checkoutFunc={checkoutFunc}/>
                </Container>
                :
                //creditカード情報の他に情報更新の欄を設けるようにする
                <>
                    <Typography variant="h1" component="h1" align="center" className={classes.title}>
                        ② クレジット情報確認
                    </Typography>
                    <Grid container spacing={2}>
                         <Grid item key="secret_info" xs={12} sm={12} md={8} lg={8} xl={8}>
                        
                        {
                            credit_info ?
                                <Card className={classes.card}>
                                    <CardContent className={classes.card_content}>
                                    <Typography variant="subtitle1" component="p" className={classes.title_credit}>
                                        クレジットカード情報
                                    </Typography>
                                      <CreditInfoComponent creditInfo={credit_info}/>
                                    </CardContent>
                                </Card>

                            :
                            ''
                        }
                         </Grid>
                        
                        <Grid item  xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Card className={classes.card}>
                                <CardContent className={classes.card_content}>
                                    <Typography variant="subtitle1" component="p">月額料金: {plan === 'basic' ? 7480 : plan === 'premium' ? '14800' : ''}</Typography>
                                    <Typography variant="caption" component="div">※次回以降のお支払いはサブスクリプション登録日から一ヶ月後となります</Typography>
                                    <Button onClick={handleNext} className={classes.button} fullWidth>このお支払い方法を使用する</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            }
        </div>
    )
}