import { 
    READ_GET_REQUEST,
    READ_GET_SUCCESS,
    READ_GET_FAIL,
    READ_UPDATE_REQUEST,
    READ_UPDATE_SUCCESS,
    READ_UPDATE_FAIL,
    NEWS_LIST_GET_REQUEST,
    NEWS_LIST_GET_SUCCESS,
    NEWS_LIST_GET_FAIL,
    NOTIFICATION_LIST_GET_REQUEST,
    NOTIFICATION_LIST_GET_SUCCESS,
    NOTIFICATION_LIST_GET_FAIL,
    NEWS_GET_REQUEST,
    NEWS_GET_SUCCESS,
    NEWS_GET_FAIL,
    NOTIFICATION_GET_REQUEST,
    NOTIFICATION_GET_SUCCESS,
    NOTIFICATION_GET_FAIL,
    TODO_LIST_REQUEST,
    TODO_LIST_SUCCESS,
    TODO_LIST_FAIL,

 } from "../constants/notificationConstants";

export const readReducer = (state = 0, action) => {
    switch(action.type){
        case READ_GET_REQUEST:
            return{
                loading:true
            }
        case READ_GET_SUCCESS:
            return {
                loading: false,
                notification: action.payload
            }
        case READ_GET_FAIL:
            return{
                loading:false,
                nofication: 0
            }
        case READ_UPDATE_REQUEST:
            return {
                loading:true
            }
        case READ_UPDATE_SUCCESS:
            return {
                loading: false,
                nofication: action.payload
            }
        case READ_UPDATE_FAIL:
            return {
                loading: false,
                nofication: 0,
            }
        default:
            return state
    }
}

//通知をストックするた目のreducerataの切り替えができるようにする
export const notificationReducer = (state = {notification:[]}, action) => {
    switch(action.type){
        case NEWS_LIST_GET_REQUEST:
            return{
                loading:true
            }
        case NEWS_LIST_GET_SUCCESS:
            return {
                loading: false,
                notification: action.payload
            }
        case NEWS_LIST_GET_FAIL:
            return{
                loading:false,
                error: action.payload
            }
        case NOTIFICATION_LIST_GET_REQUEST:
            return{
                loading:true
            }
        case NOTIFICATION_LIST_GET_SUCCESS:
            return{
                loading:false,
                notification: action.payload
            }
        case NOTIFICATION_LIST_GET_FAIL:
            return{
                loading:false, 
                error: action.payload

            }
        default:
            return state
    }
}

export const newsGetReducer = (state = {notifiacation:[]}, action) => {
    switch(action.type){
        case NEWS_GET_REQUEST:
            return{
                loading:true
            }
        case NEWS_GET_SUCCESS:
            return {
                loading: false,
                notification: action.payload
            }
        case NEWS_GET_FAIL:
            return{
                loading:false,
                error: action.payload
            }
        case NOTIFICATION_GET_REQUEST:
            return{
                loading:true
            }
        case NOTIFICATION_GET_SUCCESS:
            return{
                loading:false,
                notification: action.payload
            }
        case NOTIFICATION_GET_FAIL:
            return{
                loading:false, 
                error: action.payload

            }
        default:
            return state
    }
}

export const todoListReducer = (state={todo:[]}, action) => {
    switch(action.type){
        case TODO_LIST_REQUEST:
            return {loading: true, todo:[]}
        case TODO_LIST_SUCCESS:
            return {loading: false, todo: action.payload}
        case TODO_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}