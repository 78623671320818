import {
    ORDER_REQUEST,
    ORDER_SUCCESS,
    ORDER_FAILED,
    ORDER_DELETE,
    ORDER_ITEM_LIST_REQUEST,
    ORDER_ITEM_LIST_SUCCESS,
    ORDER_ITEM_LIST_FAILED,
    ORDER_ITEM_GET_REQUEST,
    ORDER_ITEM_GET_SUCCESS,
    ORDER_ITEM_GET_FAILED,
    BUYING_INFO_SAVE,
    BUYING_INFO_DELETE
} from "../constants/buyingConstants"

import { baseAxios } from "../jwtInterceptor"

export const order_action = (payment_method, payment_id, reservation_item_id, total_price) => async(dispatch) => {
    dispatch({type:ORDER_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    let data = {
        'payment_method': payment_method,
        'payment_id': payment_id,
    }
    await baseAxios.post('/api/buying/payment', data).then(
        async (payment_response) => {
            let data = {
                'payment': payment_response['data'].id,
                'total_price': total_price,
                'tax': 0.1
            }
            await baseAxios.post('/api/buying/order', data).then(
                async(order_response) => {
                    let data = {
                        'payment_method': 'card',
                        'payment_id': payment_id,
                        'total_price': total_price,
                        'order_id': order_response['data'].id,
                    }
                    await baseAxios.post('/api/buying/', data).then(
                        async() => {
                            let data = {
                                'order_id': order_response['data'].id,
                                'reservation_item_id': reservation_item_id,
                                'quantity': 1,
                            }
                            await baseAxios.post('/api/buying/order_item', data).then(
                                (response) => {
                                    dispatch({type: ORDER_SUCCESS, payload: response['data']})
                                }
                            ).catch(
                                () => {
                                    dispatch({type:ORDER_FAILED, payload:'アイテムの注文情報を作成できませんでした、Enclopedia運営までご連絡ください。'})
                                }
                            )
                        }
                    ).catch(
                        (error) => {
                            dispatch({type:ORDER_FAILED,
                                payload:error.response && error.response.data.message
                                ? error.response.data.message
                                : error.message,})
                        }
                    )
                }
            ).catch(
                () => {
                    dispatch({type:ORDER_FAILED, payload: '注文情報に関しての確認ができませんでした、Enclopedia運営までご連絡ください。'})
                }
            )
        }
    ).catch(
        () => {
            dispatch({type:ORDER_FAILED, payload: 'お支払い情報に関しての情報が確認できませんでした、Enclopedia運営までご連絡ください。'})
        }
    )
}

export const order_info_delete = () => (dispatch) => {
    dispatch({type: ORDER_DELETE, payload: ''})
}

export const order_item_list = () => async(dispatch) => {
    dispatch({
        type: ORDER_ITEM_LIST_REQUEST
    })

    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/buying/order_item_list').then(
        (response) => {
            dispatch({type: ORDER_ITEM_LIST_SUCCESS, payload:response['data']})
        }
    ).catch(
        () => {
            const data = {
                'message': '購入した商品がありません'
            }
            dispatch({type: ORDER_ITEM_LIST_FAILED, payload: data})
        }
    )
}

export const order_item_get = (id) => async(dispatch) => {

        dispatch({type: ORDER_ITEM_GET_REQUEST})
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        await baseAxios.get(`/api/buying/order_item/${id}`).then(
            (response) => {
                dispatch({type: ORDER_ITEM_GET_SUCCESS, payload: response['data']})
            }
        ).catch(
            () => {
                dispatch({type: ORDER_ITEM_GET_FAILED, payload: 'このアイテムについての購入履歴情報が取得できませんでした。'})
            }
        )
}

export const buying_info_save = (payment_method, payment_id, total_price, product_name, status) => (dispatch) => {
    const data = {
        "payment_method": payment_method,
        "payment_id": payment_id,
        "total_price": total_price,
        "product_name": product_name,
        "status": status
    }
    dispatch({
        type: BUYING_INFO_SAVE, payload:data
    })
    localStorage.setItem('buying', JSON.stringify(data))
}

export const buying_info_delete = () => (dispatch) => {
    localStorage.removeItem('buying')
    dispatch({
        type:BUYING_INFO_DELETE, payload:''
    })
}

