import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
//componet
import Loader from '../Loader';
import AddressForm from '../AdressFormComponent';

//action
import { get_adress } from '../../actions/adressAction';
import { next_step } from '../../actions/checkoutAction';
//adressinfoがない場合にはaddressの登録をおこなようにする

const useStyles = makeStyles((theme) => ({
    card:{
        backgroundColor:'white',
        color:'black',
        [theme.breakpoints.down('xs')]:{
            marginRight: 'initial'
        }
    },
    card_form:{
        backgroundColor:'white',
        color:'black',
    },
    container:{
        padding: 'initial'
    },
    button:{
        backgroundColor: theme.palette.primary['200'],
        marginTop: theme.spacing(2),
        color:'black',
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        }
    },
    font:{
        marginBottom:theme.spacing(1)
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    title:{
        marginBottom: theme.spacing(3)
    }
}))

export default function AdressConfirm({handler}){
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    
    //urlから情報を取得
    const {id} = useParams()
    const plan = id

    //useselctor
    const {loading, adressInfo, error} = useSelector(state => state.adress)

    const handleNext = () => {
        handler()
        dispatch(next_step())
        window.scrollTo({
            top: 0,
        });
    }

    //モーダルを開く
    const handleUpdate = () => {
        history.push('/address_update')
    }


    useEffect(() => {
        dispatch(get_adress())
    },[dispatch])

    return (
        <div>
            {
                    
                loading ?
                    <div className={classes.loader}>
                        <Loader/>
                    </div>
                    :
                    error ?
                        <Container maxWidth={'md'} className={classes.container}>
                            <Typography variant="h1" align='center' className={classes.title}>
                                ① お届け先を確認
                            </Typography>
                            <Card className={classes.card}>
                                <CardContent>
                                    <AddressForm/>
                                </CardContent>
                            </Card>
                        </Container>

                        :
                        adressInfo 
                        ?
                        adressInfo.prefecture ?
                        <>
                            <Typography variant="h1" component="h1" align='center' className={classes.title}>
                                ① お届け先を確認
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item key='payment' xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <Card className={classes.card}>
                                            <CardContent>
                                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                                    郵便番号: {adressInfo ? adressInfo.postalcode : ''}
                                                </Typography>
                                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                                    {adressInfo ? '都道府県：' + adressInfo.prefecture  : '住所を登録して下さい'}
                                                </Typography>
                                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                                    {adressInfo ? '市区町村:' + adressInfo.region : '住所を登録して下さい'}
                                                </Typography>
                                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                                    {adressInfo ? '以下の住所：' + adressInfo.region + adressInfo.address : '住所を登録して下さい'}
                                                </Typography>
                                                <Typography variant="subtitle1" component="p" className={classes.font}>
                                                    {adressInfo ? '建物名・部屋番号：' + adressInfo.building_name  : '住所を登録して下さい'}
                                                </Typography>
                                                <Button onClick={handleUpdate} style={{color:'#2196f3', marginTop:2}}>住所を編集する</Button>
                                            </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item key="next_form" xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Card className={classes.card_form}>
                                        <CardContent>
                                        <Typography variant="subtitle1" component="p">
                                            月額料金: {plan === 'basic' ? 7480 : plan === 'premium' ? '14800' : ''}
                                        </Typography>
                                        <Typography variant="caption" component="div">※次回以降のお支払いはサブスクリプション登録日から一ヶ月後となります</Typography>
                                            <Button onClick={handleNext} className={classes.button} fullWidth>
                                                この住所に発送する
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                        </>
                        :
                        <Container maxWidth={'md'} className={classes.container}>
                            <Typography variant="h1" component="h1" className={classes.title}>
                                ① お届け先を確認
                            </Typography>
                            <Card className={classes.card}>
                                <CardContent>
                                    <AddressForm/>
                                </CardContent>
                            </Card>
                        </Container>
                        :
                        <Container maxWidth={'md'} className={classes.container}>
                            <Typography variant="h1" component="h1" className={classes.title}>
                                ① お届け先を確認
                            </Typography>
                            <Card className={classes.card}>
                                <CardContent>
                                    <AddressForm/>
                                </CardContent>
                            </Card>
                        </Container>
                        

                }
        </div>
    )
}