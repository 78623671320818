import React,{useState,useEffect, useRef} from 'react'
import { useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/Toolbar';
import { Avatar, Divider } from '@material-ui/core'
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ClickAwayListener } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
//action

import logo from '../image/header_logo.png'

const useStyles = makeStyles((theme) => ({
    button:{
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    root:{
        background: theme.palette.primary['main'],
        color:'#212121',
        flexGrow: 1,
    },
    popper:{
        zIndex:'200'
    },
    avatarIcon:{
        borderRadius: '50%',
    },
    paper:{
        backgroundColor: 'white',
        color: 'black',
        [theme.breakpoints.down('xs')]:{
            width:'200px',
            marginTop:'initial'
        },
        [theme.breakpoints.up('xs')]:{
            width:'210px',
            marginTop:'initial'
        }
    },
    dividerColor:{
        backgroundColor: theme.palette.primary['400']
    },
    logo: {
        [theme.breakpoints.down('sm')]:{
            height:"22px",
        },
        [theme.breakpoints.up('sm')]:{
            height: "25px",
        }

    },
    logo_container:{
        [theme.breakpoints.down('sm')]:{
            height:"22px"
        },
        [theme.breakpoints.up('sm')]:{
            height: "25px",
        }
    },
    appbar: {
        [theme.breakpoints.down('sm')]:{
            paddingLeft: '4px',
            paddingRight: '4px'
        },
        [theme.breakpoints.up('sm')]:{
            paddingRight: '8px',
            paddingLeft: '8px'
        },
        [theme.breakpoints.up('md')]:{
            paddingRight: '16px',
            paddingLeft: '16px'
        },
    },
    toolbar: {
        [theme.breakpoints.up('sm')]:{
            paddingRight: '8px',
            paddingLeft: '8px'
        },
        [theme.breakpoints.up('md')]:{
            paddingRight: '16px',
            paddingLeft: '16px'
        },
    }
}))

//refに指定した見開きの設定リンクの位置設定調整が必要
function Header() {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation()
    const [open, setOpen] = useState(false);
    
    const anchorRef = useRef(null);

    //useSelector
    const {accountInfo} = useSelector(state => state.account);
    const {accountPublicInfo} = useSelector(state => state.accountPublicInfo)
    const {cart} = useSelector(state => state.cart) 
    const {notification} = useSelector(state => state.read)
    const { profile_loading, profileInfo} = useSelector(state => state.profile)
    const {todo} = useSelector(state => state.todo)

    const profileHandler = () => {
        history.push('/profile')
        
    }
    
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };


    const handleClose = (event) => {
        if(anchorRef.current && anchorRef.current.contains(event.target)){
            return;
        }
        setOpen(false);
    }

    const listKeyDownHandler = (event) => {
        if (event.key === 'Tab'){
            event.preventDefault();
            setOpen(false)
        }
    }

    const prevOpen = useRef(open);

    const handleLogin = () => {
        history.push('/login')
    }

    const handleRegister = () => {
        history.push('/register')
    }

    const handleFavorite = () => {
        history.push('/favorite/product/list')
    }

    const handleNotification = () => {
        history.push('/notifications')
    }

    const handleTodo = () => {
        history.push('/todo')
    }

    useEffect(() => {
        prevOpen.current = open;
        }, [open, accountInfo])


    return (
        <Box className={classes.root}> 
            <AppBar disableGutters={true}  position="static" style={{zIndex:3500}} className={classes.appbar} >
                <Toolbar disableGutters={true} className={classes.toolbar} style={{width:'100%', minWidth:'90%',margin:'none', paddingRight: '0'}}>
                    {
                        location.pathname === '/' ?
                        <div className={classes.logo_container}>
                            <Typography variant='h1' component='h1'itemProp='headline'>
                                <img className={classes.logo} src={logo} alt='Enclopediaファッションレンタルサービス｜アンクロペディア' />
                            </Typography>
                        </div>
                        :
                        <div className={classes.logo_container}>
                            <img className={classes.logo} src={logo} alt='Enclopedia(アンクロペディア)' />
                        </div>
                    }
                    <div style={{ flexGrow: 1 }}></div>
                        { accountInfo? 
                        <>
                            <IconButton
                                onClick= {handleTodo}
                                className={classes.button}
                                size="medium"
                                aria-label="shopping cart"
                                color="inherit"
                                aria-haspopup="true"
                            >
                                <Badge badgeContent={todo ? todo.length : 0} color="error" href='/cart'>
                                    <CheckIcon/>
                                </Badge>
                            </IconButton>
                            <IconButton
                            onClick={handleNotification}
                            className={classes.button}
                            size="small"
                            aria-label="show 17 new notifications"
                            aria-controls="menu-appbar"
                            color="inherit"
                            >
                                <Badge badgeContent={notification ? notification : 0} size='small' color="error">
                                    <NotificationsIcon size='small' />
                                </Badge>
                            </IconButton>
                            <IconButton
                                onClick= {() => history.push('/cart/your/')}
                                className={classes.button}
                                size="medium"
                                aria-label="shopping cart"
                                color="inherit"
                                aria-haspopup="true"
                            >
                                    <Badge badgeContent={cart ? cart.cartitems : 0} color="error" href='/cart'>
                                        <ShoppingCartIcon/>
                                    </Badge>
                            </IconButton>
                            <IconButton
                            onClick={profileHandler}
                            color="inherit" 
                            size="small"
                            disableRipple={true}
                            disableFocusRipple={true}
                            arial-controls={open ? 'menu' : undefined}
                            aria-haspopup="true"
                            edge="end"
                            className={classes.avatarIcon}
                            >
                                <Avatar src={profileInfo ? profileInfo.img : null} size="small"/>
                            </IconButton>

                            <IconButton
                            color="inherit"
                            ref={anchorRef} 
                            size="small"
                            aria-label='config'
                            className={classes.config}
                            onClick={handleToggle}
                            >
                                <KeyboardArrowDownIcon/>
                            </IconButton>
                        
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                    <Paper className={classes.paper}>
                                        <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} id={accountInfo.id} onKeyDown={listKeyDownHandler} >
                                            <MenuItem keys={"subtitle"}>
                                                <Typography variant="subtitle2" component="p">ログイン中のユーザ：</Typography>
                                            </MenuItem>
                                            <MenuItem keys={"profile1"} onClick={profileHandler}>
                                                                {
                                                    profile_loading ?
                                                    <IconButton
                                                    color="inherit" 
                                                    size="medium"
                                                    className={classes.papper_avatarIcon}
                                                    onClick={handleToggle}>
                                                        <Avatar size="medium"/>
                                                    </IconButton>:
                                                    <IconButton
                                                    color="inherit" 
                                                    size="medium"
                                                    className={classes.papper_avatarIcon}
                                                    onClick={handleToggle}>
                                                        <Avatar src={profileInfo ? profileInfo.img : ''} size="medium"/>
                                                    </IconButton>

                                                }
                                                <Typography variant="subtitle2" component="p">{accountPublicInfo.first_name}{accountPublicInfo.last_name}</Typography>
                                            </MenuItem>
                                            <Divider classes={{root: classes.dividerColor}}/>
                                            <MenuItem keys={"profile2"}  onClick={profileHandler}>
                                                <Typography variant="subtitle2" component="p">その他オプション：</Typography>
                                            </MenuItem>
                                            <MenuItem keys={"profile3"} onClick = {() => history.push('/management')}>アカウント管理</MenuItem>
                                            <MenuItem keys={"profile4"} onClick = {handleFavorite}>お気に入り</MenuItem>
                                            <MenuItem keys={"profile5"} onClick={() => history.push('reservation_history/')}>予約履歴</MenuItem>
                                            <MenuItem keys={"profile5"} onClick={() => history.push('/help')}>ヘルプ</MenuItem>
                                            <MenuItem keys={"profile7"} onClick={handleClose}>閉じる</MenuItem>
                                        </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            </>
                            :
                            <>
                                <Button color='primary' onClick={handleLogin}>
                                    <Typography variant='subtitle1' component='p' style={{color:'black'}} >ログイン</Typography>
                                </Button> 
                                <Button color='primary' onClick={handleRegister}>
                                    <Typography variant="subtitle1" component='p' style={{color:'black'}} >無料登録</Typography>
                                </Button> 
                            </>
                            
                        }
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default Header
