import React, {useState, useRef} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Button, Card, CardContent } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { ClickAwayListener } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Grid } from '@material-ui/core';
import { Fade } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { MenuList } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Popper } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";

//component
import ClosetUpdateComponent from "./ClosetUpdateComponent";
import ClothListComponent from "./ClothListComponent";

//action
import { get_cloth_list } from "../actions/closetAction";
import { closet_delete } from "../actions/closetAction";
import { closet_register_cart } from "../actions/closetAction";

const useStyles = makeStyles((theme) => ({
    card:{
        color:'black',
        borderRadius:'14px',
        height:'300px',
        zIndex:2000
    },
    card_modal:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(10),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    container:{
        position:'relative'
    },
    description:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        lineHeight: '25px'
    },
    list_item:{
        borderBottom:`solid 1px ${theme.palette.primary['300']}`
    },
    icon:{
        backgroundColor:'initial',
        '&:hover':{
            backgroundColor:'initial'
        },
        '&:active':{
            backgroundColor:'initial'
        },
        position:'absolute',
        top:'77%',
        zIndex:1000,
        padding:'initial',
        color:theme.palette.primary['700']
    },
    modal:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
    },
    modal_button:{
        marginTop: theme.spacing(2),
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        },
        width:"100%"
    },
    paper:{
        color:'black',
        backgroundColor:'white',
        [theme.breakpoints.down('xs')]:{
            width:'200px',
            marginTop:'initial'
        },
        [theme.breakpoints.up('xs')]:{
            width:'250px',
            marginTop:'initial'
        }
    },
    popper:{
        zIndex: "200"
    },
    title:{
        fontWeight:'bold',
        marginBottom: theme.spacing(1),
        textAlign:'center'
    },
}))

const ClosetComponent = ({closet}) => {
const classes = useStyles()
const dispatch = useDispatch()

//useRef
const anchorRef = useRef(null)

//useState
const [open, setOpen] = useState(false)
const [closetOpen, setClosetOpen] = useState(false)
const [updateOpen, setUpdateOpen] = useState(false)
const [deleteOpen, setDeleteOpen] = useState(false)
const [clothOpen, setClothOpen] = useState(false)

//useSelector
const {cart} = useSelector(state => state.cart)

const handleRegister = () => {
    setOpen(false)
    dispatch(closet_register_cart(closet.id, cart.id))
}

const handleOpen = () => {
    setClosetOpen(false)
    setOpen((prevReservationOpen) => !prevReservationOpen)
}

const handleClose = () => {
    setOpen(false)
}

const handleClosetOpen = () => {
    setClosetOpen((prevClosetOpen) => !prevClosetOpen);
};

const handleClosetClose = (event) => {
    if(anchorRef.current && anchorRef.current.contains(event.target)){
        return;
    }
    setClosetOpen(false);
}

const handleClothOpen = () => {
    dispatch(get_cloth_list(closet.id))
    setClothOpen(true)
}

const handleClothClose =  () => {
    setClothOpen(false)
}

const handleUpdateOpen = () => {
    setClosetOpen(false)
    setUpdateOpen(true)
}

const handleUpdateClose = () => {
    setUpdateOpen(false)
}

const handleDeleteOpen = () => {
    setClosetOpen(false)
    setDeleteOpen(true)
}

const handleDeleteClose = () => {
    setDeleteOpen(false)
}

const handleClosetDelete = () => {
    dispatch(closet_delete(closet.id))
    setDeleteOpen(false)
}

//fadeではなく上から下にスムーズに伸びるアニメーションを使用するようにする
return(
    <div className={classes.container} key={closet.id}>
    <IconButton
     key={closet.id}
     ref={anchorRef}
     aria-label="closet"
     disableRipple={true}
     disableFocusRipple={true}
     className={classes.icon}
     onClick={handleClosetOpen}
    >
        <BiDotsVerticalRounded size={55}/>
    </IconButton>
    <Popper open={closetOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.popper}>
         {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={300}>
            <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClosetClose}>
                    <MenuList autoFocusItem={closetOpen} id={'closer_list'}>
                        <MenuItem onClick={handleOpen} key={"all_reservation"} className={classes.list_item}>
                            <Typography variant="subtitle1" component="p">まとめて予約リストに追加</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleUpdateOpen} key={"closet_edit"} className={classes.list_item}>
                            <Typography variant="subtitle1" component="p">クローゼットを編集</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleDeleteOpen} key={"closet_delete"} className={classes.list_item}>
                            <Typography variant="subtitle1" component="p">クローゼットを削除</Typography>
                        </MenuItem>
                        <MenuItem variant="subtitle1"  ey={"closet_button"} component="div" onClick={() => {handleClosetClose(closet)}}>
                            閉じる
                        </MenuItem>
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Fade>
         )}
    </Popper>
    <Typography variant="subtitle1" component="p" className={classes.title}>
        {closet.closet_name}
    </Typography>
    <Card className={classes.card}>
        <CardActionArea onClick={handleClothOpen}>
            <Grid container>
                <Grid  key="image1" item xs={8} sm={8} md={8} lg={8} xl={8}>
                    <div>
                        <img src={closet.closet[0] ? "https://media.enclopedia-official.com" + closet.closet[0] : null} style={{width:'99%',objectFit: 'cover', height:'300px', backgroundColor: 'white'}}/>
                    </div>
                </Grid>
                <Grid key="image2" item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div>
                        <img src={closet.closet[1] ? "https://media.enclopedia-official.com"  + closet.closet[1] : null} style={{width:'100%',objectFit: 'cover',height:'150px', backgroundColor: 'white'}}/>
                    </div>
                    <div>
                        <img src={closet.closet[2] ? "https://media.enclopedia-official.com"  + closet.closet[2] : null} style={{width:'100%',objectFit: 'cover',height:'150px', backgroundColor: 'white'}}/>
                    </div>
                 </Grid>
             </Grid>
             
         </CardActionArea>
     </Card>
     <Modal
     open={open}
     onClose={handleClose}
     aria-labelledby="modal-modal-title"
     >
        <Container maxWidth={"xs"} className={classes.modal}>
            <Card className={classes.card_modal}>
                <CardContent>
                {
                    cart.cartitems > 0 ?
                    <>
                    <Typography variant="subtitle1" component="p" className={classes.description}>
                        予約リスト内に商品があります、削除してクローゼット内商品をまとめて予約リストに追加しますか？
                    </Typography>
                    <Button onClick={handleRegister} className={classes.modal_button}>
                        追加
                    </Button>
                    <Button onClick={handleClose} disableRipple disableFocusRipple style={{color:'black'}}>
                        閉じる
                    </Button>
                    </>
                    :
                    <>
                    <Typography variant="subtitle1" component="p" className={classes.description}>
                        クローゼット内商品をまとめて追加しますか？
                    </Typography>
                    <Button onClick={handleRegister} className={classes.modal_button}>
                        追加
                    </Button>
                    <Button onClick={handleDeleteClose} disableRipple disableFocusRipple style={{color:'black'}}>
                        閉じる
                    </Button>
                    </>
                    
                }
                </CardContent>
            </Card>
         </Container>
     </Modal>
     <Modal
     open={clothOpen}
     onClose={handleClothClose}
     aira-labelledby="modal-title">
         <Fade in={clothOpen}>
             <ClothListComponent closetInfo={closet} closetState={setClothOpen}/>
         </Fade>
     </Modal>
     <Modal
     open={updateOpen}
     onClose={handleUpdateClose}
     aria-labelleby="modal-modal-title">
         <Fade in={updateOpen}>
             <ClosetUpdateComponent closetInfo={closet} handleClose={handleUpdateClose}/>
         </Fade>
     </Modal>
     <Modal
      open={deleteOpen}
      onClose={handleClothClose}
      aria-labelledby="modal-modal-modal-title">
          <Fade in={deleteOpen}>
              <Container maxWidth={"xs"} className={classes.modal}>
                  <Card className={classes.card_modal}>
                        <CardContent>
                            <Typography variant="subtitle1" component="p" align="center"style={{fontWeight:'bold'}}>
                                クローゼットを削除しますか？
                            </Typography>
                            <Button onClick={handleClosetDelete} className={classes.modal_button}>
                                削除
                            </Button>
                            <Button onClick={handleDeleteClose} disableRipple disableFocusRipple style={{color:'black'}}>
                                閉じる
                            </Button>
                        </CardContent>
                  </Card>
              </Container>
          </Fade>
      </Modal>
    </div>
)
}

export default ClosetComponent