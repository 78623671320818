import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

//component
import Loader from '../Loader';
import Message from '../Message';

//import formik and yup
import {useFormik} from 'formik';
import * as yup from 'yup';
import axios from 'axios';

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{''}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}


//react css style
const useStyles = makeStyles((theme) => ({
  button:{
    color:'black'
  },
  content:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container:{
    padding: 'initial'
  },
  error_message:{
    width: '100%',
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(1),
    backgroundColor:'white',
    color:'black'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  message:{
    marginTop: theme.spacing(3)
  },
  loader:{
    [theme.breakpoints.down('sm')]:{
      marginTop:'40%',
      textAlign:'center',
      minHeight:'100vh',
  },
  [theme.breakpoints.up('sm')]:{
      marginTop:'35%',
      textAlign:'center',
      minHeight:'100vh',
  },
  [theme.breakpoints.up('md')]:{
      marginTop:'30%',
      textAlign:'center',
      minHeight:'100vh',
  }},
  root:{
    [theme.breakpoints.down('sm')]:{
      margin: theme.spacing(15, 1, 15, 1)
    },
    [theme.breakpoints.up('sm')]:{
        margin: theme.spacing(15, 2, 15, 2)
    },
    [theme.breakpoints.up('md')]:{
        margin: theme.spacing(20, 3, 20, 3)
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input_text:{
    color:'black'
  },
  text_field:{
      color:'black',
      borderWidth: '1px',
      borderColor:`${theme.palette.primary['500']} !important`,
  },
}));
  
const validationSchema = yup.object().shape({
    password: yup
        .string('')
        .matches(/(?=.*[a-z])/, "小文字を含めてください")
        .matches(/(?=.*[A-Z])/, "大文字を含めてください")
        .matches(/(?=.*[0-9])/, "数字を含めてください")
        .min(8, 'パスワードは8文字以上です')
        .required('パスワードは入力必須です'),
    confirmPassword: yup
        .string('')
        .matches(/(?=.*[a-z])/, "小文字を含めてください")
        .matches(/(?=.*[A-Z])/, "大文字を含めてください")
        .matches(/(?=.*[0-9])/, "数字を含めてください")
        .min(8, 'パスワードは8文字以上です')
        .required('パスワードは入力必須です'),

})

export default function PasswordReset(){

  const classes = useStyles();
  const history = useHistory()
  const location = useLocation();

  //state
  const [message, setMessage] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  
  //urlからtokenを取得する


  //formik handler

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema:validationSchema,
    onSubmit: async(values) => {
      //pathlocationで処理を分ける
      if(location.pathname === '/password_reset/password/confirm'){
        if(values.password !== values.confirmPassword){
          setError('パスワードが一致していません')
        }else{
          //パラメーターから取得したtokenとpasswordを
          setLoading(true)
          try{
          const token = location.search ? location.search.split('=')[1] : ''
          const config = {'headers':{'Content-type':'application/json'}}
          const data = {
            'password': values.password,
            'token': token
          }
          await axios.post(process.env.REACT_APP_URL + '/api/password_reset/confirm/', data, config)   
          setLoading(false)
          setMessage('パスワードの変更に成功しました')
        }catch(error){
          setLoading(false)
          setError('パスワードの変更に失敗しました')
        }}
      //passwordを変更するための処理
      }else if(location.pathname === '/password/change'){
      if(values.password !== values.confirmPassword){
        console.log('change')
      }else{
        setLoading(true)
      
        try{
          const config = {'headers':{'Content-type': 'application/json'}}
          const data = {
            'password': values.password
          }
          const res = await axios.post(process.env.REACT_APP_URL + '/api/password/change/', data, config)
          setLoading(false)
          setMessage('パスワードの更新に成功しました')
          console.log(res)
        }catch(error){
          setLoading(false)
          setError('パスワードの変更に失敗しました')
        }}
    }}
  })

  //handler

  const handleHome = () => {
    history.push('/')
  }

  const handleLogin = () => {
    history.push('/login')
  }

  return(
    loading ?
    <div className={classes.loader}>
      <Loader/>
    </div>
    :
    message ?
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h1" component="h1" align="center">
          {message}
        </Typography>
        <Typography variant="subtitle1" component="p" className={classes.message} align="center">
          パスワードの変更が完了しました、ログイン画面から再度アカウントへログインを行なってください。
        </Typography>
      </Container>
    </div>
    :
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        {error&& <Message>{error}</Message>}
        <Card className={classes.paper}>
          <CardContent>
            <div className={classes.content}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant1="h1">
                パスワード変更
              </Typography>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="パスワード"
                label="パスワード"
                type="password"
                name="password"
                autoComplete='password'
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                autoFocus
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                />
                <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                name="confirmPassword"
                label="パスワード確認"
                type="password"
                id="confirmPassword"
                InputLabelProps={{
                  classes:{
                      root: classes.input_text
                  }
                }}
                InputProps={{
                    classes:{
                        notchedOutline:classes.text_field,
                        root: classes.text_field
                    }
                }}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
                <Button
                type="submit"
                fullWidth
                variant="contained"
                className={classes.submit}
                >
                  登録
                </Button>
              </form>
              <Grid container>
                  <Grid item xs={6}>
                    
                    <Typography variant="subtitle2" component="p">
                      <Button onClick={handleLogin} className={classes.button}>
                        ログイン画面
                      </Button>
                    </Typography>
                    
                  </Grid>
                  <Grid item xs={6}>
                    
                    <Typography variant="subtitle2" component="p" align='right'>
                      <Button onClick={handleHome} className={classes.button}>
                        ホームへ戻る
                      </Button>
                    </Typography>
                    
                  </Grid>
                </Grid>
            </div>
            <Box>
              <Copyright />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}
