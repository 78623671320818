import React from 'react';
import { TableCell } from '@material-ui/core';
import { TableRow } from '@material-ui/core';


function SizeNotation(product_size){
    const size = product_size
    return(
      <>
            <TableRow
            key={size['product_size']['size']}
            sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row" >
                {size['product_size']['size'].toUpperCase()}
                </TableCell>
                <TableCell component="p" align="right">{size['product_size']['length'] === null ? '×':`${size['product_size']['length']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['shoulder_width'] === null ? '×':`${size['product_size']['shoulder_width']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['chest'] === null ? '×':`${size['product_size']['chest']} cm`}</TableCell>
                <TableCell component="p"align="right">{size['product_size']['sleeve_length'] === null ? '×':`${size['product_size']['sleeve_length']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['waist'] === null ? '×':`${size['product_size']['waist']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['hip'] === null ? '×':size['product_size']['hip'] === null ? '':`${size['product_size']['hip']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['rise'] === null ? '×':`${size['product_size']['rise']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['inseam'] === null ? '×':`${size['product_size']['inseam']} cm`}</TableCell>
                <TableCell component="p" align="right">{size['product_size']['hem_width'] === null ? '×':`${size['product_size']['hem_width']} cm`}</TableCell>
                </TableRow>
        </>
    )
}

export default SizeNotation