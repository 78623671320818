import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@mui/material';

import Message from '../components/Message';

import { useFormik } from 'formik'
import * as yup from 'yup';

import { authenticate_delete } from '../actions/accountAction';
import { phone_number_update } from '../actions/accountAction';

const useStyles = makeStyles((theme) => ({
    button: {
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    card: {
        width: '100%',
        color:'black',
        backgroundColor: 'white',
    },
    caption:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.primary['400']
    },
    container:{
        padding: 'initial'
    },
    inner:{
        margin: theme.spacing(10,1,15,1)
    },
    input_text:{
        color:'black'
    },
    title: {
        textAlign: 'center',
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    }
}))


const phoneRegex =/^[0-9]{3}?[0-9]{4}?[0-9]{4}?$/

const validationSchema = yup.object().shape({
    phone_number: yup
    .string('')
    .required('電話番号を入力してください')
    .matches(phoneRegex, '正しい形式で入力してください')
})

export default function PhoneNumberUpdateScreen() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const history = useHistory()
    const { authentication, error } = useSelector(state => state.authentication)
    
    const formik = useFormik({
        initialValues: {
            phone_number: ''
        },
        validationSchema: validationSchema,
        onSubmit:(values) => {
            event.preventDefault()
            dispatch(phone_number_update(values.phone_number))
            dispatch(authenticate_delete())
            history.push('/security')
        }
    })
    
    useEffect(() => {
        if(!authentication){
            history.push('/security')
        }
    },[])

    return(
        <Container maxWidth="sm" className={classes.container}>
            <div className={classes.inner}>
            {error && <div className={classes.error_message}><Message>電話番号の更新に失敗しました</Message></div>}
                <Card className={classes.card}>
                    <CardContent className={classes.card_content}>
                        <Typography variant="subtitle1" component="p" className={classes.title}>
                            電話番号を変更
                        </Typography>
                        <Typography variant="caption" component="div" className={classes.caption}>
                            電話番号は（-）ハイフンを入れずに入力してください
                        </Typography>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <TextField
                            variant="outlined"
                            fullWidth
                            id="phone_number"
                            label="新しい電話番号"
                            name="phone_number"
                            autoFocus
                            autoComplete="phone_number"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                            InputLabelProps={{
                                classes:{
                                    root:classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                }
                            }}
                            />
                            <Button type="submit" fullWidth className={classes.button}>
                                変更
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}