//reservationとreservationitemを逆参照で取得d軽量sにする

export const RESERVATION_LIST_REQUEST = 'RESERVATION_LIST_REQUEST'
export const RESERVATION_LIST_SUCCESS = 'RESERVATION_LIST_SUCCESS'
export const RESERVATION_LIST_FAIL = 'RESERVATION_LIST_FAIL'

//rentalプランの場合の予約
export const RENTAL_RESERVATION_LIST_REQUEST = 'RENTAL_RESERVATION_LIST_REQUEST'
export const RENTAL_RESERVATION_LIST_SUCCESS = 'RENTAL_RESERVATION_LIST_SUCCESS'
export const RENTAL_RESERVATION_LIST_FAIL = 'RENTAL_RESERVATION_LIST_FAIL'

//rentalプラン半年間
export const RESERVATION_HALF_REQUEST = 'RESERBATION_HALF_REQUEST'
export const RESERVATION_HALF_SUCCESS = 'RESERVATION_HALF_SUCCESS'
export const RESERVATION_HALF_FAIL = 'RESERVATION_HALF_FAIL'

//status返却を完了させる
export const RETURN_RENTAL_PRODUCT_REQUEST = 'RETURN_RENTAL_PRODUCT_REQUEST'
export const RETURN_RENTAL_PRODUCT_SUCCESS = 'RETURN_RENTAL_PRODUCT_SUCCESS'
export const RETURN_RENTAL_PRODUCT_FAIL = 'RETURN_RENTAL_PRODUCT_FAIL'

//最も新しいreservationのデータ
export const RESERVATION_LATEST_GET_REQUEST = 'RESERVATION_LATEST_GET_REQUEST'
export const RESERVATION_LATEST_GET_SUCCESS = 'RESERVATION_LATEST_GET_SUCCESS'
export const RESERVATION_LATEST_GET_FAILED = 'RESERVATION_LATEST_GET_FAILED'

//statusが3のreservationデータを取得
export const RESERVATION_GET_REQUEST = 'RESERVATION_GET_REQUEST'
export const RESERVATION_GET_SUCCESS = 'RESERVATION_GET_SUCCESS'
export const RESERVATION_GET_FAIL = 'RESERVATION_GET_FAIL'

//rentalしたことがあるか
export const RESREVATION_ALREADY_REQUEST = 'RESREVATION_ALREADY_REQUEST'
export const RESERVATION_ALREADY_SUCCESS = 'RESERVATION_ALREADY_SUCCESS'
export const RESERVATION_ALREADY_FAIL = 'RESERVATION_ALREADY_FAIL'

//reservation item取得
export const RESERVATION_ITEM_GET_REQUEST = 'RESERVATION_ITEM_GET_REQUEST'
export const RESERVATION_ITEM_GET_SUCCESS = 'RESERVATION_ITEM_GET_SUCCESS'
export const RESERVATION_ITEM_GET_FAILED = 'RESERVATION_ITEM_GET_FAILED'

//予約日時保存
export const RESERVATION_DAY_SAVE = 'RESERVATION_DAY_SAVE'