import axios from 'axios'
import {
    //全てのproductのための処理
    PRODUCT_DETAIL_REQUEST,
    PRODUCT_DETAIL_SUCCESS,
    PRODUCT_DETAIL_FAIL,
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    PRODUCT_GENDER_REQUEST,
    PRODUCT_GENDER_SUCCESS,
    PRODUCT_GENDER_FAIL,
    PRODUCT_SIZE_REQUEST,
    PRODUCT_SIZE_SUCCESS,
    PRODUCT_SIZE_FAIL,
    //productにフィルターをかける
    PRODUCT_RENTAL,
    PRODUCT_PLAN,
    PLAN_ORIGIN_DATA,
    //product性別フィルタ-
    PRODUCT_GENDER_ORIGIN_DATA,
    PRODUCT_GENDER_FILTER_SUCCESS,
    //productの順番を変更する
    PRODUCT_CATEGORY_REQUEST,
    PRODUCT_CATEGORY,
    PRODUCT_CATEGORY_FAIL,
    PRODUCT_EVALUATION_REVIEW_REQUEST,
    PRODUCT_EVALUATION_REVIEW,
    PRODUCT_EVALUATION_REVIEW_FAIL,
    PRODUCT_EVALUATION_REVIEW_DESC_REQUEST,
    PRODUCT_EVALUATION_REVIEW_DESC,
    PRODUCT_EVALUATION_REVIEW_DESC_FAIL,
    PRODUCT_EVALUATION_FAVORITE_REQUEST,
    RRODUCT_EVALUATION_FAVORITE,
    PRODUCT_EVALUATION_FAVORITE_FAIL,
    PRODUCT_EVALUATION_FAVORITE_DESC_REQUEST,
    PRODUCT_EVALUATION_FAVORITE_DESC,
    PRODUCT_EVALUATION_FAVORITE_DESC_FAIL,
    PRODUCT_RESERVATION_ORDER_REQUEST,
    PRODUCT_RESERVATION_ORDER,
    PRODUCT_RESERVATION_ORDER_FAIL,
    PRODUCT_RESERVATION_ORDER_DESC_REQUEST,
    PRODUCT_RESERVATION_ORDER_DESC,
    PRODUCT_RESERVATION_ORDER_DESC_FAIL,
    PORDUCT_PRICE_ORDER_REQUEST,
    PRODUCT_PRICE_ORDER_SUCCESS,
    PRODUCT_PRICE_ORDER_FAIL,
    PRODCUT_PRICE_ORDER_DESC_REQUEST,
    PRODUCT_PRICE_ORDER_DESC_SUCCESS,
    PRODUCT_PRICE_ORDER_DESC_FAIL,
    //search
    PRODUCT_SEARCH_REQUEST,
    PRODUCT_SEARCH_SUCCESS,
    PRODUCT_SEARCH_FAIL,
    PRODUCT_HISTORY_SAVE,
    SEARCH_PRODUCT_GENDER_ORIGIN_DATA,
    SEARCH_PRODUCT_GENDER_FILTER_SUCCESS,
    SEARCH_PLAN_ORIGIN_DATA,
    SEARCH_PRODUCT_PLAN,
    SEARCH_PRODUCT_RENTAL,
    SEARCH_EVALUATION_FAVORITE_REQUEST,
    SEARCH_EVALUATION_FAVORITE,
    SEARCH_EVALUATION_FAVORITE_FAIL,
    SEARCH_EVALUATION_FAVORITE_DESC_REQUEST,
    SEARCH_EVALUATION_FAVORITE_DESC,
    SEARCH_EVALUATION_FAVORITE_DESC_FAIL,
    SEARCH_EVALUATION_REVIEW_REQUEST,
    SEARCH_EVALUATION_REVIEW,
    SEARCH_EVALUATION_REVIEW_FAIL,
    SEARCH_EVALUATION_REVIEW_DESC_REQUEST,
    SEARCH_EVALUATION_REVIEW_DESC,
    SEARCH_EVALUATION_REVIEW_DESC_FAIL,
    SEARCH_PRODUCT_CATEGORY_REQUEST,
    SEARCH_PRODUCT_CATEGORY,
    SEARCH_PRODUCT_CATEGORY_FAIL,
    SEARCH_RESERVATION_ORDER_REQUEST,
    SEARCH_RESERVATION_ORDER_SUCCESS,
    SEARCH_RESERVATION_ORDER_FAIL,
    SEARCH_RESERVATION_DESC_ORDER_REQUEST,
    SEARCH_RESERVATION_DESC_ORDER_SUCCESS,
    SEARCH_RESERVATION_DESC_ORDER_FAIL,
    SEARCH_PRICE_ORDER_REQUEST,
    SEARCH_PRICE_ORDER_SUCCESS,
    SEARCH_PRICE_ORDER_FAIL,
    SEARCH_PRICE_ORDER_DESC_REQUEST,
    SEARCH_PRICE_ORDER_DESC_SUCCESS,
    SEARCH_PRICE_ORDER_DESC_FAIL,
    //tagか
    TAG_PRODUCT_REQUEST,
    TAG_PRODUCT_SUCCESS,
    TAG_PORODUCT_FAIL,
    TAG_EVALUATE_REQUEST,
    TAG_EVALUATE_SUCCESS,
    TAG_EVALUATE_FAIL,
    TAG_EVALUATE_DESC_REQUEST,
    TAG_EVALUATE_DESC_SUCCESS,
    TAG_EVALUATE_DESC_FAIL,
    TAG_FAVORITE_REQUEST,
    TAG_FAVORITE_SUCCESS,
    TAG_FAVORITE_FAIL,
    TAG_FAVORITE_DESC_REQUEST,
    TAG_FAVORITE_DESC_SUCCESS,
    TAG_FAVORITE_DESC_FAIL,
    TAG_CATEGORY_REQUEST,
    TAG_CATEGORY_SUCCESS,
    TAG_CATEGORY_FAIL,
    TAG_PLAN_ORIGIN_DATA,
    TAG_PRODUCT_PLAN,
    TAG_PRICE_REQUEST,
    TAG_PRICE_SUCCESS,
    TAG_PRICE_FAIL,
    TAG_PRICE_DESC_REQUEST,
    TAG_PRICE_DESC_SUCCESS,
    TAG_PRICE_DESC_FAIL,
    TAG_LIST_PRODUCTS_REQUEST,
    TAG_LSIT_PRODUCTS_SUCCESS,
    TAG_LIST_PRODUCTS_FAIL,
    SEARCH_TAG_REQUEST,
    SEARCH_TAG_SUCCESS,
    SEARCH_TAG_FAIL,
    //review
    REVIEW_CREATE_REQUEST,
    REVIEW_CREATE_SUCCESS,
    REVIEW_CREATE_FAIL,
    REVIEW_LIST_GET_REQUEST,
    REVIEW_LIST_GET_SUCCESS,
    REVIEW_LIST_GET_FAIL,
    REVIEW_LIST_REQUEST,
    REVIEW_LIST_SUCCESS,
    REVIEW_LIST_FAIL,
    //variationのための処理
    VARIATION_GET_REQUEST,
    VARIATION_GET_SUCCESS,
    VARIATION_GET_FAIL,
    TAG_RESERVATION_REQUEST,
    TAG_RESERVATION_SUCCESS,
    TAG_RESERVATION_FAIL,
    TAG_RESERVATION_DESC_REQUEST,
    TAG_RESERVATION_DESC_SUCCESS,
    TAG_RESERVATION_DESC_FAIL,
    TAG_PRODUCT_GENDER_ORIGIN_DATA,
    TAG_PRODUCT_GENDER_FILTER_SUCCESS,
    PRODUCT_RELATED_REQUEST,
    PRODUCT_RELATED_SUCCESS,
    PRODUCT_RELATED_FAIL,
    //related product
} from '../constants/productConstants'
import { baseAxios } from '../jwtInterceptor'
//import { baseAxios } from '../jwtInterceptor'

const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_URL,
    headers:{
        'Content-Type':'Application/json',
    }
})

//productを個別に取得するためのaction
export const get_product_detail = (id) => async(dispatch) => {
    try{
        dispatch({type: PRODUCT_DETAIL_REQUEST})
        const {data} = await axiosApi.get(`/api/product/detail/${id}`)
        dispatch({type:PRODUCT_DETAIL_SUCCESS, payload:data})
    }catch(error){
        dispatch({
            type:PRODUCT_DETAIL_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//search barで検索したproductを取得
export const search_products = (keyword, page=1) => async (dispatch) => {
    try{
        dispatch({type: PRODUCT_SEARCH_REQUEST})
        const {data} = await axiosApi.get(`/api/product/search/?keyword=${keyword}&page=${page}`)
        dispatch({
            type: PRODUCT_SEARCH_SUCCESS, payload: data
        })
    }catch(error){
        dispatch({
            type:PRODUCT_SEARCH_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//search product gender fileter
export const search_gender_filter = (gender)  => (dispatch, getState) => {
    const products = getState().searchProducts.products
    const origin_data = getState().searchGenderOriginData.originData
    if(!origin_data){
        dispatch({type: SEARCH_PRODUCT_GENDER_ORIGIN_DATA, payload: products})
    }
    if(gender === 'all'){
        const data = getState().searchGenderOriginData.originData
        dispatch({type:SEARCH_PRODUCT_GENDER_FILTER_SUCCESS, payload: data})
    }else{
        const data = getState().searchGenderOriginData.originData
        const productList = []
        for(let product in data['results']){
            if(data['results'][product].gender === gender){
                productList.push(data['results'][product])
            }
        }
        dispatch({type: SEARCH_PRODUCT_GENDER_FILTER_SUCCESS, payload:{'results': productList, 'count': data['count']}})
    }

}

export const search_gender_data_save = () => (dispatch, getState) => {
    const products = getState().searchProducts.products
    dispatch({type: SEARCH_PRODUCT_GENDER_ORIGIN_DATA, payload: products})
}


//search origin data delete
export const search_origin_data_delete = () => (dispatch) => {
    dispatch({type: SEARCH_PRODUCT_GENDER_ORIGIN_DATA, payload: ''})
}

//search product filter
export const plan_filter_search_product = (plan, checkbox) => (dispatch, getState) => {
    const products = getState().searchProducts.products
    const origin_data = getState().searchProducts.originData
    const productList = []

    //checkboxがtrueの時は絞り込みを行うようにする
    if(checkbox){
        //元のproductlistの値を保存するようにする
        dispatch({type:SEARCH_PLAN_ORIGIN_DATA, payload:products})
        switch(plan){
            case 'premium' :
                for(let product in products['results']){
                    if(products['results'][product].is_subscription === ('premium' || 'basic' || 'rental')){
                        productList.push(products['results'][product])
                    }
                }
                break
            case 'basic':
                for(let product in products['results']){
                    if(((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
                break
            case 'rental':
                for(let product in products['results']){
                    if(((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
                break
        }
        dispatch({type: SEARCH_PRODUCT_PLAN, payload: {'results': productList, 'count': productList.length}})
    //falseの場合は元のdataをセットするようにする
    }else{
        //checkoutがfalseになった場合の元のデータを渡すようにする
        dispatch({type: SEARCH_PRODUCT_PLAN, payload: origin_data})
    }
}

//在庫に余がある商品のみを表示するようにする
export const rental_filter_search_product = (check, plan) => (dispatch, getState) => {
    const products = getState().searchProducts.products
    const origin_data = getState().searchOriginData.originData
    console.log(origin_data)
    const productList = []
    if(check){
        dispatch({type:SEARCH_PLAN_ORIGIN_DATA, payload:products})
        if('premium' === plan){
            for(let product in products['results']){
                if(products['results'][product].stock !== 0){
                    productList.push(products['results'][product])
                }
            }
        }else if('basic' == plan){
            for(let product in products['results']){
                if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                    productList.push(products['results'][product])
                }
            }
        }else{
            for(let product in products['results']){
                if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                    productList.push(products['results'][product])
                }
            }
        }
        
        dispatch({type: SEARCH_PRODUCT_RENTAL, payload: {'results': productList, 'count': products['count']}})
    }else{
        //checkoutが存在しない場合日韓しては元のdataを保存するようにする
        dispatch({type:SEARCH_PRODUCT_RENTAL, payload: origin_data})
    }
}

//search検索した後のproductをcategory別に取得する
export const search_category = (keyword, category, page) => async(dispatch) => {
    try{
        dispatch({type:SEARCH_PRODUCT_CATEGORY_REQUEST})
        const {data} = await axiosApi.get(`/api/product/search/category/?keyword=${keyword}&category=${category}&page=${page}`)
        dispatch({
            type: SEARCH_PRODUCT_CATEGORY, payload:data
        })
    }catch(error){
        dispatch({
            type: SEARCH_PRODUCT_CATEGORY_FAIL , 
            payload: error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

export const search_category_name = (keyword, category_name, page) => async(dispatch) => {
    dispatch({type:SEARCH_PRODUCT_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/search/category_name/?keyword=${keyword}&category_name=${category_name}&page=${page}`).then(
        (response) => {
            dispatch({
                type: SEARCH_PRODUCT_CATEGORY, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type: SEARCH_PRODUCT_CATEGORY_FAIL , 
                payload: error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const search_type = (keyword, type, page) => async(dispatch) => {
    dispatch({type: SEARCH_PRODUCT_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/search/type/?keyword=${keyword}&type=${type}&page=${page}`).then(
        (response) => {
            dispatch({
                type: SEARCH_PRODUCT_CATEGORY, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({type: SEARCH_PRODUCT_CATEGORY_FAIL,
                payload:
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const search_type_name = (keyword, type_name, page) => async(dispatch) => {
    dispatch({type: SEARCH_PRODUCT_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/search/type_name/?keyword=${keyword}&type_name=${type_name}&page=${page}`).then(
        (response) => {
            dispatch({
                type: SEARCH_PRODUCT_CATEGORY, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({type: SEARCH_PRODUCT_CATEGORY_FAIL,
                payload:
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

//評価の高い順
export const search_evaluation_product = (keyword, page) => async(dispatch) => {
    try{
        dispatch({type:SEARCH_EVALUATION_REVIEW_REQUEST})
        const {data} = await axiosApi.get(`/api/product/search/evaluation_order/?keyword=${keyword}&page=${page}`)
        dispatch({
            type:SEARCH_EVALUATION_REVIEW, payload:data
        })
    }catch(error){
        dispatch({
            type: SEARCH_EVALUATION_REVIEW_FAIL, 
            payload: error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//評価の低い順
export const search_evaluation_product_desc = (keyword, page) => async(dispatch) => {
    try{
        dispatch({type:SEARCH_EVALUATION_REVIEW_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/search/evaluation_order_desc/?keyword=${keyword}&page=${page}`)
        dispatch({
            type:SEARCH_EVALUATION_REVIEW_DESC, payload:data
        })
    }catch(error){
        dispatch({
            type: SEARCH_EVALUATION_REVIEW_DESC_FAIL, 
            payload: error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//お気に入りの多い順
export const search_favorite_product = (keyword, page) => async(dispatch) => {
    try{
        dispatch({
            type:SEARCH_EVALUATION_FAVORITE_REQUEST
        })
        const {data} = await axiosApi.get(`/api/product/search/favorite_order/?keyword=${keyword}&page=${page}`)
        dispatch({
            type:SEARCH_EVALUATION_FAVORITE, payload:data
        })
    }catch(error){
        dispatch({
            type: SEARCH_EVALUATION_FAVORITE_FAIL, 
            payload: error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//お気に入りの少ない順
export const search_favorite_product_desc = (keyword, page) => async(dispatch) => {
    try{
        dispatch({type:SEARCH_EVALUATION_FAVORITE_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/search/favorite_order_desc/?keyword=${keyword}&page=${page}`)
        dispatch({
            type:SEARCH_EVALUATION_FAVORITE_DESC, payload:data
        })
    }catch(error){
        dispatch({
            type: SEARCH_EVALUATION_FAVORITE_DESC_FAIL, 
            payload: error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//予約数の多い順
export const search_reservatoin = (keyword, page) => async(dispatch) => {
    dispatch({type:SEARCH_RESERVATION_ORDER_REQUEST})
    await axiosApi.get(`/api/product/search/reservation_order/?keyword=${keyword}&page=${page}`).then(
        (response) => {
            dispatch({
                type:SEARCH_RESERVATION_ORDER_SUCCESS,
                payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type:SEARCH_RESERVATION_ORDER_FAIL,
                pyalaod:error,
            })
        }
    )
}

//予約数の少ない順位並び替えを行う
export const search_reservation_desc = (keyword, page) => async(dispatch) => {
    dispatch({type:SEARCH_RESERVATION_DESC_ORDER_REQUEST})
    await axiosApi.get(`/api/product/search/reservation_order_desc/?keyword=${keyword}&page=${page}`).then(
        (response) => {
            dispatch({
                type:SEARCH_RESERVATION_DESC_ORDER_SUCCESS, payload:response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type:SEARCH_RESERVATION_DESC_ORDER_FAIL, 
                payload: error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

//価格の高い順に並び替える
export const search_product_price = (keyword, page) => async(dispatch) => {
    dispatch({type:SEARCH_PRICE_ORDER_REQUEST})
    await axiosApi.get(`/api/product/search/price_order/?keyword=${keyword}&page=${page}`).then(
        (response) => {
            dispatch({
                type: SEARCH_PRICE_ORDER_SUCCESS, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type: SEARCH_PRICE_ORDER_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

//価格の安い順
export const search_product_price_desc = (keyword, page) => async(dispatch) => {
    dispatch({type:SEARCH_PRICE_ORDER_DESC_REQUEST})
    await axiosApi.get(`/api/product/search/price_order_desc/?keyword=${keyword}&page=${page}`).then(
        (response) => {
            dispatch({
                type: SEARCH_PRICE_ORDER_DESC_SUCCESS, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type: SEARCH_PRICE_ORDER_DESC_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

//hoem画面p流ダクト取得ようのaction
//お気に入りが多い順に並び替えを行う
export const evaluation_favorite_product = (page) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_EVALUATION_FAVORITE_REQUEST})
        const {data} = await axiosApi.get(`/api/product/favorite_order/?page=${page}`)
        dispatch({
            type: RRODUCT_EVALUATION_FAVORITE, payload: data
        })
    }catch(error){
        dispatch({
            type: PRODUCT_EVALUATION_FAVORITE_FAIL,
            payload: error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message
        })
    }
}

//お気に入り数が少ない順に並び替えを行_う
export const evaluation_favorite_product_desc = (page) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_EVALUATION_FAVORITE_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/favorite_order/desc/?page=${page}`)
        dispatch({
            type: PRODUCT_EVALUATION_FAVORITE_DESC, payload: data
        })
    }catch(error){
       dispatch({
           type:PRODUCT_EVALUATION_FAVORITE_DESC_FAIL, 
           payload: error.response && error.response.data.message
           ? 
           error.response.data.message
           :
           error.message
       })
    }
}

// review数とratingの高い順に並び替える
export const evaluation_review_product = (page) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_EVALUATION_REVIEW_REQUEST})
        const {data} = await axiosApi.get(`/api/product/evaluation_order/?page=${page}`)
        dispatch({
            type: PRODUCT_EVALUATION_REVIEW, payload: data
        })
    }catch(error){
       dispatch({
           type:PRODUCT_EVALUATION_REVIEW_FAIL, 
           payload: error.response && error.response.data.message
           ? 
           error.response.data.message
           :
           error.message
       })
    }
}

//reviws数とratingの低い順に並び替える
export const evaluation_review_product_desc = (page) => async (dispatch) => {
    try{
        dispatch({type:PRODUCT_EVALUATION_REVIEW_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/evaluation_order/desc/?page=${page}`)
        dispatch({
            type: PRODUCT_EVALUATION_REVIEW_DESC, payload: data
        })
    }catch(error){
       dispatch({
           type:PRODUCT_EVALUATION_REVIEW_DESC_FAIL, 
           payload:error.response && error.response.data.message
           ? 
           error.response.data.message
           :
           error.message
       })
    }
}

//reservationの多い順位productを並び替える
export const product_reservation_order = (page) => async(dispatch) => {
    try{
        dispatch({
            type:PRODUCT_RESERVATION_ORDER_REQUEST
        })
        const {data} = await axiosApi.get(`/api/product/reservation_order/?page=${page}`)
        dispatch({
            type: PRODUCT_RESERVATION_ORDER, payload: data
        })
    }catch(error){
        dispatch({type: PRODUCT_RESERVATION_ORDER_FAIL,  
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message})
    }
}

//reservationの少ない順に並び替えを行う
export const product_reservation_order_desc = (page) => async(dispatch) => {
    try{
        dispatch({type:PRODUCT_RESERVATION_ORDER_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/reservation_order/desc/?page${page}`)
        dispatch({
            type: PRODUCT_RESERVATION_ORDER_DESC, payload: data
        })
    }catch(error){
        dispatch({type: PRODUCT_RESERVATION_ORDER_DESC_FAIL,  
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message})
    }
}

//値段が安い順に並び替える
export const product_price_order_get = (page) => async(dispatch) => {
    dispatch({type:PORDUCT_PRICE_ORDER_REQUEST})
    await axiosApi.get(`/api/product/price_order/?page=${page}`).then(
        (response) => {
            dispatch({type:PRODUCT_PRICE_ORDER_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:PRODUCT_PRICE_ORDER_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//値段が高い順
export const product_price_order_desc_get = (page) => async(dispatch) => {
    dispatch({type:PRODCUT_PRICE_ORDER_DESC_REQUEST})
    await axiosApi.get(`/api/product/price_order_desc/?page=${page}`).then(
        (response) => {
            dispatch({type:PRODUCT_PRICE_ORDER_DESC_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:PRODUCT_PRICE_ORDER_DESC_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//ctegory別に商品を取得するにする
export const product_category = (id, page) => async(dispatch) => {
    try{
        dispatch({type:PRODUCT_CATEGORY_REQUEST})
        const {data} = await axiosApi.get(`/api/product/category/${id}/?page=${page}`)
        dispatch({
            type: PRODUCT_CATEGORY, payload: data
        })
    }catch(error){
        dispatch({
            type: PRODUCT_CATEGORY_FAIL, 
            payload:error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message 
        })
    }
}

export const product_category_name = (category_name, page) => async(dispatch) => {
    dispatch({type:PRODUCT_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/category_name/${category_name}/?page=${page}`).then(
        (response) => {
            dispatch({
                type: PRODUCT_CATEGORY, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type: PRODUCT_CATEGORY_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message 
            })
        }
    )
}

export const product_type = (type, page) => async(dispatch) => {
    dispatch({type: PRODUCT_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/type/?type=${type}&page=${page}`).then(
        (response) => {
            dispatch({
                type: PRODUCT_CATEGORY, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type: PRODUCT_CATEGORY_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message 
            })
        }
    )
}

export const product_type_name = (type_name, page) => async(dispatch) => {
    dispatch({type: PRODUCT_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/type_name/?type_name=${type_name}&page=${page}`).then(
        (response) => {
            dispatch({
                type: PRODUCT_CATEGORY, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                type: PRODUCT_CATEGORY_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message 
            })
        }
    )
}

//checformでtrueの時にrental商品のみ表示するfilterをかけるようにする
export const rental_fileter_product = (check, plan) => (dispatch, getState) => {
    const products = getState().productList.products
    const origin_data = getState().productOriginData.originData
    const productList = []

    if(check){
        dispatch({type:PLAN_ORIGIN_DATA, payload:products})
            if('premium' === plan){
                for(let product in products['results']){
                    if(products['results'][product].stock !== 0){
                        productList.push(products['results'][product])
                    }
                }
            }else if('basic' === plan){
                for(let product in products['results']){
                    if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
            }
            else{
                for(let product in products['results']){
                    if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
            }
        
        
        dispatch({type:PRODUCT_RENTAL, payload:{'results':productList, 'count': products['count']}})
    }else{
        //falseの場合においておいて元のdataに戻すようにする
        dispatch({type:PRODUCT_RENTAL, payload: origin_data})
    }
}

//checkoutボタンを使用してplan別にdataを取得するようにする
export const plan_filter_products = (plan, checkout) => (dispatch,getState) => {
    const products = getState().productList.products
    const origin_data = getState().productList.products
    const productList = []

    //checkoutがtrueの時にplan別に分けるようにする
    if(checkout){
        switch(plan){
            case 'premium' :
                for(let product in products['results']){
                    if(products['results'][product].is_subscription === ('premium' || 'basic' || 'rental')){
                        productList.push(products['results'][product])
                    }
                }
                break
            case 'basic':
                for(let product in products['results']){
                    if(((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
                break
            case 'rental':
                for(let product in products['results']){
                    if(((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                        productList.push(products['results'][product])
                    }
                }
                break
        }
        dispatch({type:PRODUCT_PLAN, payload:{'results':productList, 'count': products['count']}})
    }else{
        //checkoutがfalseの場合には元のデータを取得するようにする
        dispatch({PLAN_ORIGIN_DATA, payload:origin_data})
    }
    
}

//男女別にfilterをかけるようにする
//このパターンでは男性から女性に行く場合にうまくフィルタリングできない
export const gender_filter = (gender) => (dispatch, getState) => {
    const products = getState().productList.products
    const origin_data = getState().genderOriginData.originData
    if(!origin_data){
        dispatch({type: PRODUCT_GENDER_ORIGIN_DATA, payload: products})
    }
    if(gender === 'all'){
        const data = getState().genderOriginData.originData
        dispatch({type:PRODUCT_GENDER_FILTER_SUCCESS, payload: data})
    }else{
    const data = getState().genderOriginData.originData
    const productList = []
    for(let product in data['results']){
        if(data['results'][product].gender === gender){
            productList.push(data['results'][product])
        }
    }
    dispatch({type:PRODUCT_GENDER_FILTER_SUCCESS, payload: {'results':productList, 'count': data['count']}})
}}

export const gender_data_save = () => (dispatch, getState) => {
    const products = getState().productList.products
    dispatch({type:PRODUCT_GENDER_ORIGIN_DATA, payload: products})
}

//databaseからproductsのデータを取得する
export  const listProducts = (page) => async (dispatch) => {
        dispatch({type: PRODUCT_LIST_REQUEST})
        await axiosApi.get(`/api/product/list/?page=${page}`).then(
            (response) => {
                dispatch({
                    type:PRODUCT_LIST_SUCCESS,
                    payload:response['data']
                })
            }
        ).catch(
            (error) => {
                dispatch({
                    type:PRODUCT_LIST_FAIL,
                    payload:error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
                })
            }
        )
}

//databaseからprodcutのサイズを取得する
export const productSize = (id) => async(dispatch) => {
    try{
        dispatch({type: PRODUCT_SIZE_REQUEST})
        const data = await axiosApi.get(`/api/product/size/${id}`)
        dispatch({
            type:PRODUCT_SIZE_SUCCESS,
            payload:data['data']
        })
    }catch(error){
        dispatch({
            type:PRODUCT_SIZE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//genderによって取得するproductを分けるようにする
export const getGenderProduct = (gender) => async(dispatch) => {
    try{
        dispatch({type:PRODUCT_GENDER_REQUEST})
        const {data} = await axiosApi.get(`/api/product/list/${gender}`)
        dispatch({type:PRODUCT_GENDER_SUCCESS, payload: data})
    }catch(error){
        dispatch({
            type:PRODUCT_GENDER_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//variationを取得するためのaction
export const getVariation = (product_id) => async(dispatch) => {
    try{
        dispatch({type:VARIATION_GET_REQUEST})
        const {data} = await axiosApi.get(`/api/product/variation/${product_id}`)
        dispatch({type:VARIATION_GET_SUCCESS, payload: data})
    }catch(error){
        dispatch({
            type:VARIATION_GET_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//tagからproductを取得するようにする。
export const get_tag_product = (tag_id, page) => async(dispatch) => {
    try{
        dispatch({type:TAG_PRODUCT_REQUEST})
        const {data} = await axiosApi.get(`/api/product/tag/?tag=${tag_id}&page=${page}`)
        dispatch({type:TAG_PRODUCT_SUCCESS, payload:data})
    }catch(error){
        dispatch({type:TAG_PORODUCT_FAIL, payload:error})
    }
}

//tagから評価の高い順に取得する
export const get_tag_evaluate = (tag_id, page) => async(dispatch) => {
    try{
        dispatch({type:TAG_EVALUATE_REQUEST})
        const {data} = await axiosApi.get(`/api/product/tag/evaluation_order/?tag=${tag_id}&page=${page}`)
        dispatch({type:TAG_EVALUATE_SUCCESS, payload: data})
    }catch(error){
        dispatch({type:TAG_EVALUATE_FAIL, payload: error})
    }
}

//tagから評価の低い順に取得
export const get_tag_evaluate_desc = (tag_id, page) => async(dispatch) => {
    try{
        dispatch({type:TAG_EVALUATE_DESC_REQUEST})
        const {data} = await axiosApi.get(`/api/product/tag/evaluation_order_desc/?tag=${tag_id}&page=${page}`)
        dispatch({type:TAG_EVALUATE_DESC_SUCCESS, payload: data})
    }catch(error){
        dispatch({type:TAG_EVALUATE_DESC_FAIL, payload: error})
    }
}
//お気に理の多い順に並べる
export const get_tag_favorite = (tag_id, page) => async(dispatch) => {
    try{
        dispatch({type:TAG_FAVORITE_REQUEST})
        const {data} = await axiosApi.get(`api/product/tag/favorite/?tag=${tag_id}&page=${page}`)
        dispatch({type:TAG_FAVORITE_SUCCESS, payload: data})
    }catch(error){
        dispatch({type:TAG_FAVORITE_FAIL, payload:
            error.response && error.response.data.message
            ? 
            error.response.data.message
            :
            error.message})
    }
}


//お気に入りの少ない順に取得
export const get_tag_favorite_desc= (tag_id, page) => async(dispatch) => {
    try{
        dispatch({type:TAG_FAVORITE_DESC_REQUEST})
        const {data} = await axiosApi.get( `/api/product/tag/favorite_desc/?tag=${tag_id}&page=${page}`)
        dispatch({type:TAG_FAVORITE_DESC_SUCCESS, payload: data})
    }catch(error){
        dispatch({type:TAG_FAVORITE_DESC_FAIL, payload: error})
    }
}

///予約件数の多い順
export const get_tag_reservation_order = (tag_id, page) => async(dispatch) => {
    dispatch({type:TAG_RESERVATION_REQUEST})
    await axiosApi.get(`/api/product/tag/reservation_order/?tag=${tag_id}&page=${page}`).then(
        (response) => {
            dispatch({type:TAG_RESERVATION_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:TAG_RESERVATION_FAIL, payload:
                error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//予約件数の少ない順
export const get_tag_reservation_order_desc = (tag_id, page) => async(dispatch) => {
    dispatch({type:TAG_RESERVATION_DESC_REQUEST})
    await axiosApi.get(`/api/product/tag/reservation_order_desc/?tag=${tag_id}&page=${page}`).then(
        (response) => {
            dispatch({type:TAG_RESERVATION_DESC_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:TAG_RESERVATION_DESC_FAIL, 
                paylaod:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//価格の安い順
export const get_tag_price_order = (tag_id, page) => async(dispatch) => {
    dispatch({type: TAG_PRICE_REQUEST})
    await axiosApi.get(`/api/product/tag/price_order/?tag=${tag_id}&page=${page}`).then(
        (response) => {
            dispatch({type: TAG_PRICE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: TAG_PRICE_FAIL, 
                paylaod: error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

export const get_tag_price_desc_order = (tag_id, page) => async(dispatch) => {
    dispatch({type: TAG_PRICE_DESC_REQUEST})
    await axiosApi.get(`/api/product/tag/price_order_desc/?tag=${tag_id}&page=${page}`).then(
        (response) => {
            dispatch({type: TAG_PRICE_DESC_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: TAG_PRICE_DESC_FAIL, 
                payload: error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message})
        }
    )
}

//gender filter
export const tag_gender_filter = (gender) => (dispatch, getState) => {
    const products = getState().productTag.products
    const origin_data = getState().tagGenderOriginData.originData
    if(!origin_data){
        dispatch({type: TAG_PRODUCT_GENDER_ORIGIN_DATA, payload: products})
    }

    if(gender === "all"){
        const data = getState().tagGenderOriginData.originData
        dispatch({type: TAG_PRODUCT_GENDER_FILTER_SUCCESS, payload: data})
    }else{
        const data = getState().tagGenderOriginData.originData
        const productList = []
        for(let product in data['results']){
            if(data['results'][product].gender === gender){
                productList.push(data['results'][product])
            }
        }
        dispatch({type: TAG_PRODUCT_GENDER_FILTER_SUCCESS, payload:  {'results':productList, 'count': data['count']}})
    }
}

export const tag_gender_data_save = () => (dispatch, getState) => {
    const products = getState().productTag.products
    dispatch({type: TAG_PRODUCT_GENDER_ORIGIN_DATA, payload: products})
}


//filter
export const plan_filter_tag_products = (check, plan) => (dispatch, getState) => {
    const products = getState().productTag.products
    const origin_data = getState().tagOriginData.originData
    const productList = []
    if(check){
        dispatch({type: TAG_PLAN_ORIGIN_DATA, payload:products})
        if('premium' === plan){
            for(let product in products['results']){
                if(products['results'][product].stock !== 0){
                    productList.push(products['results'][product])
                }
            }
        }else if(('basic' === plan)){
            for(let product in products['results']){
                if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                    productList.push(products['results'][product])
                }
            }
        }else{
            for(let product in products['results']){
                if(products['results'][product].stock !== 0 && ((products['results'][product].is_subscription === 'basic') || (products['results'][product].is_subscription === 'rental'))){
                    productList.push(products['results'][product])
                }
            }
        }
        dispatch({type: TAG_PRODUCT_PLAN, payload: {'results':productList, 'count': products['count']}})
    }else{
        dispatch({type: TAG_PRODUCT_PLAN, payload: origin_data})
    }
}


//タグから取得するようにする。
export const get_tag_category = (tag_id, category_id, page) => async(dispatch) => {
    try{
        dispatch({type:TAG_CATEGORY_REQUEST})
        const {data} = await axiosApi.get(`/api/product/tag/category/?tag=${tag_id}&category=${category_id}&page=${page}`)
        dispatch({type:TAG_CATEGORY_SUCCESS, payload:data})
    }catch(error){
        dispatch({type:TAG_CATEGORY_FAIL, payload:error})
    }
}

export const get_tag_category_name = (tag_id, category_name, page) => async(dispatch) => {
    dispatch({type:TAG_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/tag/category_name/?tag=${tag_id}&category_name=${category_name}&page=${page}`).then(
        (response) => {
            dispatch({type:TAG_CATEGORY_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:TAG_CATEGORY_FAIL, payload:error})
        }
    )
}

export const get_tag_type = (tag_id, type_id, page) => async(dispatch) => {
    dispatch({type: TAG_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/tag/type/?tag=${tag_id}&type=${type_id}&page=${page}`).then(
        (response) => {
            dispatch({type: TAG_CATEGORY_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: TAG_CATEGORY_FAIL, payload:error})
        }
    )
}

export const get_tag_type_name = (tag_id, type_name, page) => async(dispatch) => {
    dispatch({type: TAG_CATEGORY_REQUEST})
    await axiosApi.get(`/api/product/tag/type_name/?tag=${tag_id}&type_name=${type_name}&page=${page}`).then(
        (response) => {
            dispatch({type: TAG_CATEGORY_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type: TAG_CATEGORY_FAIL, payload:error})
        }
    )
}

//productscreen画面遷移、もしくはレンダリング時に履歴としてproductを保存しておく。
export const add_history_products = (product) => (dispatch, getState) => {
    try{
        dispatch(
            {
                type: PRODUCT_HISTORY_SAVE,
                payload: product
            }
        )
        //保存数に個数制限を設ける
        localStorage.setItem('historyProducts', JSON.stringify(getState().productsHistory.products))
    }catch{
        localStorage.setItem('historyProducts', JSON.stringify(getState().productsHistory.products))
    }
}

export const createReview = (product_id, title, review, rating) => async(dispatch) => {
    dispatch({type:REVIEW_CREATE_REQUEST})
    const data = {
        'title': title,
        'review': review,
        'rating': rating
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post(`/api/product/review/create/${product_id}`, data)
    .then(
        (response) => {
            dispatch({
                type:REVIEW_CREATE_SUCCESS, payload:response['data']
            })
            //レビューに成功した場合にはlistを更新する必要がある
            dispatch({type:REVIEW_LIST_GET_REQUEST})
            baseAxios.get(`/api/product/review/list/${product_id}`).then(
                (response) => {
                    dispatch({
                        type:REVIEW_LIST_GET_SUCCESS, payload:response['data']
                    })
                }
            )
        }
    ).catch(
        (error) => {
        dispatch({
            type:REVIEW_CREATE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    })
}


//reviewを先頭の数個指定して取得
export const reviewList = (product_id) => async(dispatch) => {
    try{
        dispatch({type:REVIEW_LIST_GET_REQUEST})
        const {data} = await axiosApi.get( `/api/product/review/list/${product_id}`)
        dispatch({
            type:REVIEW_LIST_GET_SUCCESS, payload:data
        })
    }catch(error){
        dispatch({
            type:REVIEW_LIST_GET_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

export const review_all_get = (product_id) => async(dispatch) => {
    dispatch({type:REVIEW_LIST_REQUEST})
    await axiosApi.get(`api/product/reviews/${product_id}`).then(
        (response) => {
            dispatch({
                type:REVIEW_LIST_SUCCESS, payload:response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                tyep:REVIEW_LIST_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const get_related_product = (product_id) => async(dispatch) => {
    dispatch({type: PRODUCT_RELATED_REQUEST})
    await axiosApi.get(`api/product/related/?product=${product_id}`).then(
        (response) => {
            dispatch({
                type: PRODUCT_RELATED_SUCCESS, payload: response['data']
            })
        }
    ).catch(
        (error) => {
            dispatch({
                tyep: PRODUCT_RELATED_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const serch_tag_list = (word) => async(dispatch) => {
    dispatch({type: SEARCH_TAG_REQUEST})
    await axiosApi.get(`api/product/tag/search/?tag=${word}`).then(
        (response) => {
            dispatch({type:SEARCH_TAG_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                tyep: SEARCH_TAG_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const tag_list_products_get = (tags) => async(dispatch) => {
    dispatch({TAG_LIST_PRODUCTS_REQUEST})
    await axiosApi.get('api/product/tag/list/', {
        params:{
            tags: tags
        }
    }).then(
        (response) => {
            dispatch({TAG_LSIT_PRODUCTS_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                tyep: TAG_LIST_PRODUCTS_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}



