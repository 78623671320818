export const COUPON_GET_REQUEST = 'COUPON_GET_REQUEST';
export const COUPON_GET_SUCCESS = 'COUPON_GET_SUCCESS';
export const COUPON_GET_FAIL = 'COUPON_GET_FAIL';

export const COUPON_DISCOUNT_REQUEST = 'COUPON_DISCOUNT_REQUEST';
export const COUPON_DISCOUNT_SUCCESS = 'COUPON_DISCOUNT_SUCCESS';
export const COUPON_DISCOUNT_FAIL = 'COUPON_DISCOUNT_FAIL';
export const COUPON_DISCOUNT_DELETE = 'COUPON_DISCOUNT_DELETE'

export const UTILISER_COUPON_REQUEST = 'UTILISER_COUPON_REQUEST';
export const UTILISER_COUPON_SUCCESS = 'UTILISER_COUPON_SUCCESS';
export const UTILISER_COUPON_FAIL = 'UTILISER_COUPON_FAIL';

export const COUPON_INVITATION_REQUEST = 'COUPON_INVITATION_REQUEST';
export const COUPON_INVITATION_SUCCESS = 'COUPON_INVITATION_SUCCESS';
export const COUPON_INVITATION_FAIL    = 'COUPON_INVITATION_FAIL';

export const ISSUING_LIST_REQUEST = 'ISSUING_LIST_REQUEST';
export const ISSUING_LIST_SUCCESS = 'ISSUING_LIST_SUCCESS';
export const ISSUING_LIST_FAIL = 'SSUING_LIST_FAIL'

export const INVITATION_CREATE_REQUEST = 'INVITATION_CREATE_REQUEST';
export const INVITATION_CREATE_SUCCESS = 'INVITATION_CREATE_SUCCESS';
export const INVITATION_CREATE_FAIL    = 'INVITATION_CREATE_FAIL';



