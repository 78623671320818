import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { news_get } from "../actions/notificationAction";
import { notification_get } from "../actions/notificationAction";

//component
import Loader from "../components/Loader";

const useStyles = makeStyles((theme) => ({
    body:{
        marginTop: theme.spacing(5),
        margintBottom: theme.spacing(2),
        lineHeight: '25px',
        whiteSpace: 'pre-line'
    },
    card:{
        color:'black',
        background:'white',
        height: '100vh',
        boxShadow: 'initial'
    },
    container:{
        padding: 'initial'
    },
    date:{
        marginTop: theme.spacing(5)
    },
    error_container:{
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(10)
        },
        [theme.breakpoints.up('sm')]:{
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10)
        },
        [theme.breakpoints.up('md')]:{
            marginTop: theme.spacing(15),
            marginBottom: theme.spacing(15)
        },
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    title:{
        marginBottom: theme.spacing(2),
        fontWeight:'bold',
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(5, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10, 3, 15, 3)
        }
    }
}))

//お知らせを取得して表示するようにする
const NotificationScreen = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const location = useLocation()

 
    //urlから個別通知か全体通知
    const {id} = useParams()
    const ident = location.search ? location.search.split('=')[1]:''


    //useSelector
    const {loading, notification, error} = useSelector(state => state.news)

    //notificationかnewsのどちらかが入力されたか判別しどちらのdipatchを行うか条件分岐する必要がある。
    useEffect(() => {
        if( ident === 'notification'){
            dispatch(notification_get(id))
        }
        else if(ident === 'news'){
            dispatch(news_get(id))
        }
    },[])

    //獲得したニュースを取得する
    //日付を一番下に入力するよにする
    return(
        loading ? 
            <div className={classes.loader}>
                <Loader/>
            </div>
           :
           error ?
           <div className={classes.error_container}>
                <Typography varinat="subtitle1" component="p" align="center">
                    記事を取得できませんでいた、通信環境の良いところで接続して下さい。
                </Typography>
           </div>
           :
           notification ?
           <Container maxWidth={"md"} className={classes.container}>
                <div className={classes.root}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h2" align="center" component="h2" className={classes.title}>
                                {notification.title}
                            </Typography>
                            <Typography variant="subtitle1" component="p" align="right">
                                Enclopedia運営
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.body}>
                                {notification.body}
                            </Typography>
                            <Typography variant="subtitle2" component="p" className={classes.date} align='right'>
                                日付： {new Date(notification.created_at).getFullYear() + '年' + (new Date(notification.created_at).getMonth() + 1) + '月' + new Date(notification.created_at).getMonth() + '日'}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Container>
            :
            ''
    )
}

export default NotificationScreen