import React, {useEffect} from "react"
import { Card } from "@material-ui/core"
import { CardContent } from "@material-ui/core"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import { Typography } from "@material-ui/core"

//function
import { headRewrite } from "../function/headRewrite"

//プライバシーポリシーについて記述する
const useStyles = makeStyles((theme) => ({
    enforcement:{
        fontWeight:'bold',
        marginTop: theme.spacing(2),
    },
    inner:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
    },
    regulation:{
        textAlign:'center',
        fontWeight:'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    privacy_info:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    root:{
        color: 'black',
        backgroundColor: 'white',
    },
    subtitle:{
        textAlign: 'right',
    },
    title:{
        fontWeight:'bold',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

function PrivacyPolicyScreen(){
    const classes = useStyles()

    //head data
    const title = "プライバシーポリシー｜Enclopedia【公式サイト】"
    const description = "Enclopedia(アンクロペディア)ファッションレンタルサービスに関するプライバシーポリシーに関するページです。"

    useEffect(() => {
        headRewrite(title, description)
    }, [])

    return(
        <Container maxWidth={"md"} style={{padding: 'initial'}}>
            <div className={classes.inner}>
                <Card className={classes.root}>
                    <CardContent>
                    <Typography variant="subtitle1" component="p" className={classes.subtitle}>Enclopedia運営</Typography>
                    <Typography variant="h1" component="h1" className={classes.regulation}>
                        プライバシーポリシー
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        Enclopedia（アンクロペディア）（以下、「Enclopedia」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、
                        ユーザーの個人情報の取り扱いについて、以下の通りプライバシーポリシーを定めます。
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報位含まれる氏名、
                        生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、及び当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報の収集方法）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        Enclopediaは、ユーザが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレス、クレジットカード番号などの個人情報をお尋ねすることがあります。
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報を収集、利用する目的）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        <strong>Enclopediaが個人情報を収集・利用する目的は，以下のとおりです</strong>
                        （１）Enclopediaファッションレンタルサービスの提供・運営のため<br/>
                        （２）Enclopediaファッションレンタルサービスに関する登録の受付、お客様の本人確認、認証のため<br/>
                        （３）Enclopediaファッションレンタルサービスのお客様利用履歴を管理するため<br/>
                        （４）有料サービスにおいて，ユーザーに利用料金を請求するため<br/>
                        （５）Enclopediaファッションレンタルサービスにおけるお客様の行動を分析し、Enclopediaファッションレンタルサービスの向上と改善に役立てるため<br/>
                        （６）ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）<br/>
                        （７）ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及びEnclopediaが提供する他のサービスの案内のメールを送付するため<br/>
                        （８）メンテナンス，重要なお知らせなど必要に応じたご連絡のため<br/>
                        （９）利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため<br/>
                        （１０）ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため<br/>
                        （１１）上記の利用目的に付随する目的<br/>
                        <strong>安全管理のために講じた措置</strong><br/>
                        Enclopediaが、お客様から取得した情報に関しまして安全管理のため講じた処置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別に回答させて頂きます。

                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （アクセス解析ツール）
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        {"Enclopediaは、お客様のアクセス解析のために、「Googleアナリティクス」を利用しております。Googleアナリティクスは、トラフィックデータは、トラフィックデータの\
                        収集のためにCookieを使用しています。トラフィックデータは匿名で収集させており、個人を特定するものではありません。\
                        cookieを無効にすれば、一部情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。\
                        Googleアナリティクスにつきましては以下、以下urlからご確認下さい。\
                        "}<br/>
                        https://marktingplatform.google.com/about/analytics/term/jp/
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （利用目的の変更）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        Enclopediaが個人情報を収集・利用する目的は，以下のとおりです,
                        （１）Enclopediaは、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。<br/>
                        （２）利用目的の変更を行った場合には、変更後の目的について、当初所定の方法により、ユーザーに通知し、またはウェブサイト上に公表するものとします。<br/>
                        （３）ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及びEnclopediaが提供する他のサービスの案内のメールを送付するため<br/>
                        （４）メンテナンス，重要なお知らせなど必要に応じたご連絡のため<br/>
                        （５）利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため<br/>
                        （６）ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため<br/>
                        （７）有料サービスにおいて，ユーザーに利用料金を請求するため<br/>
                        （８）上記の利用目的に付随する目的<br/>
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報の第三者提供）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        Enclopediaは、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。<br/>
                        （１）人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき<br/>
                        （２）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき<br/>
                        （３）の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br/>
                        （４）予め次の事項を告知あるいは公表し，かつEnclopediaが個人情報保護委員会に届出をしたとき<br/>
                        &emsp;１、利用目的に第三者への提供を含むこと<br/>
                        &emsp;２、第三者に提供されるデータの項目<br/>
                        &emsp;３、第三者への提供お手段または方法<br/>
                        &emsp;４、本人の求めに応じて個人情報の第三者への提供を停止すること<br/>
                        &emsp;５、本人の求めを受け付ける方法
                        （５）次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。<br/>
                        &emsp;１、Enclopediaが利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部は委託する場合<br/>
                        &emsp;２、合併その他の事由による事業の承継に伴って個人情報が提供される場合<br/>
                        &emsp;３、個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，
                        &emsp;４、EnclopediaやEnclopediaファッションレンタルサービスが買収された場合
                        &emsp;５、事業パートナーと共同利用する場合（具体的な共同利用がある場合は、その内容を、その内容を別途公表いたします。）
                        利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合

                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報の訂正）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        Enclopediaは、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。<br/>
                        （１）ユーザーは、Enclopediaの保有する自己の個人情報が誤った情報である場合には、Enclopediaが定める手続きにより、Enclopediaに対して個人情報の訂正を請求することができます。<br/>
                        （２）Enclopediaは、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、当該個人情報の訂正を行うものとします。<br/>
                        （３）Enclopediaは、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をユーザに通知します。<br/>
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報の利用停止等）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        （１）Enclopediaは，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。。<br/>
                        （２）前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。。<br/>
                        （３）Enclopediaは、前項の規定に基づき利用停止等を行った場合、これをユーザーに通知します。<br/>
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （プライバシーポリシーの変更）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        （１）本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。<br/>
                        （２）Enclopediaが定める場合は除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。<br/>
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.title}>
                        （個人情報の取扱いに関する問い合わせ）
                    </Typography>
                    <Typography variant="subtitle1" component="p">
                        住所：東京都八王子市明神町４丁目２９丁目3号 メゾンYOSHIDA ３-A      <br/>
                        個人情報に関するお問い合わせ先：operation@enclopediai-info.com<br/>
                    </Typography>
                    <Typography variant="subtitle1" component="p" className={classes.enforcement}>
                        2023年1月31日施行
                    </Typography>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}

export default PrivacyPolicyScreen