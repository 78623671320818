import {
    CATEGORY_GET_REQUEST,
    CATEGORY_GET_SUCCESS,
    CATEGORY_GET_FAIL,
    CATEGORYITEMS_GET_REQUEST,
    CATEGORYITEMS_GET_SUCCESS,
    CATEGORYITEMS_GET_FAIL,
    BRANDS_GET_REQUEST,
    BRANDS_GET_SUCCESS,
    BRANDS_GET_FAIL,
    //ブランドに関連する商品を取得する
    BRANDITEMS_GET_REQUEST,
    BRANDITEMS_GET_SUCCESS,
    BRANDITEMS_GET_FAIL,
    BRANDS_EVALUATE_REQUEST,
    BRANDS_EVALUATE_SUCCESS ,
    BRANDS_EVALUATE_FAIL,
    BRANDS_EVALUATE_DESC_REQUEST,
    BRANDS_EVALUATE_DESC_SUCCESS,
    BRANDS_EVALUATE_DESC_FAIL,
    BRANDS_FAVORITE_REQUEST,
    BRANDS_FAVORITE_SUCCESS,
    BRANDS_FAVORITE_FAIL,
    BRANDS_FAVORITE_DESC_REQUEST,
    BRANDS_FAVORITE_DESC_SUCCESS,
    BRANDS_FAVORITE_DESC_FAIL,
    BRANDS_RESERVATION_REQUEST,
    BRANDS_RESERVATION_SUCCESS,
    BRANDS_RESERVATION_FAIL,
    BRANDS_RESERVATION_DESC_REQUEST,
    BRANDS_RESERVATION_DESC_SUCCESS,
    BRANDS_RESERVATION_DESC_FAIL,
    BRAND_PRODUCT_FILTER,
    BRAND_PRODUCT_ORIGIN,
    BRANDS_CATEGORY_REQUEST,
    BRANDS_CATEGORY_SUCCESS,
    BRANDS_CATEGORY_FAIL,
    BRAND_GENDER_PRODUCT_FILTER,
    BRAND_GENDER_PRODUCT_ORIGIN,
    BRANDS_PRICE_REQUEST,
    BRANDS_PRICE_SUCCESS,
    BRANDS_PRICE_FAIL,
    BRANDS_PRICE_DESC_REQUEST,
    BRANDS_PRICE_DESC_SUCCESS,
    BRANDS_PRICE_DESC_FAIL
} from '../constants/categoryConstants'

export const categoryReducer = (state = {categories:[]}, action) => {
    switch(action.type){
        case CATEGORY_GET_REQUEST:
            return {category_loading: true}
        case CATEGORY_GET_SUCCESS:
            return {category_loading: false, categories: action.payload}
        case CATEGORY_GET_FAIL:
            return { category_loading:false, category_error: action.payload}
        default: 
        return state
    }
}

export const categoryProductReducer = (state = {products:[]}, action) => {
    switch(action.type){
        case CATEGORYITEMS_GET_REQUEST:
            return {loading:true, products: []}
        case CATEGORYITEMS_GET_SUCCESS:
            return {loading:false, products: action.payload}
        case CATEGORYITEMS_GET_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}

//アルファベットごとにブランド一覧を取得するようにする
export const brandListReducer = ( state= {brands:[]}, action) => {
    switch(action.type){
        case BRANDS_GET_REQUEST:
            return {loading: true}
        case BRANDS_GET_SUCCESS:
            return {loading:false, brands: action.payload}
        case BRANDS_GET_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}



export const brandProductsGetReducer = (state = {products:{'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case  BRANDITEMS_GET_REQUEST:
            return {loading: true, products:{'results':[], 'count': 0}}
        case BRANDITEMS_GET_SUCCESS:
            return {loading:false, products: action.payload}
        case BRANDITEMS_GET_FAIL:
            return {loading: false, error: action.payload}
        case BRANDS_EVALUATE_REQUEST:
            return {loading:true, products:{'results':[], 'count': 0}}
        case BRANDS_EVALUATE_SUCCESS:
            return {loading: false, products: action.payload}
        case BRANDS_EVALUATE_FAIL:
            return {loading: false, products: action.payload}
        case BRANDS_EVALUATE_DESC_REQUEST:
            return {loading: true, products:{'results':[], 'count': 0}}
        case BRANDS_EVALUATE_DESC_SUCCESS:
            return {loading: false, products: action.payload}
        case BRANDS_EVALUATE_DESC_FAIL:
            return {loading: false, products: action.payload}
        case BRANDS_FAVORITE_REQUEST:
            return{loading:true, products:{'results':[], 'count': 0}}
        case BRANDS_FAVORITE_SUCCESS:
            return {loading:false, products: action.payload}
        case BRANDS_FAVORITE_FAIL:
            return {loading:false, products: action.payload}
        case BRANDS_FAVORITE_DESC_REQUEST:
            return {loading:true, products:{'results':[], 'count': 0}}
        case BRANDS_FAVORITE_DESC_SUCCESS:
            return {loading:false, products: action.payload}
        case BRANDS_FAVORITE_DESC_FAIL:
            return {loading:false, products: action.payload}
        case BRANDS_RESERVATION_REQUEST:
            return {loading:true, products:{'results':[], 'count': 0}}
        case BRANDS_RESERVATION_SUCCESS:
            return {loading:false, products:action.payload}
        case BRANDS_RESERVATION_FAIL:
            return {loading:false, error:action.payload}
        case BRANDS_RESERVATION_DESC_REQUEST:
            return {loading:true, products:{'results':[], 'count': 0}}
        case BRANDS_RESERVATION_DESC_SUCCESS:
            return {loading:false, products:action.payload}
        case BRANDS_RESERVATION_DESC_FAIL:
            return {loading:false, error: action.payload}
        case BRANDS_PRICE_REQUEST:
            return {loading: true, products:{'results':[], 'count': 0}}
        case BRANDS_PRICE_SUCCESS:
            return {loading: false, products: action.payload}
        case BRANDS_PRICE_FAIL:
            return {loading:false, error: action.payload}
        case BRANDS_PRICE_DESC_REQUEST:
            return {loading: true, products:{'results':[], 'count': 0}}
        case BRANDS_PRICE_DESC_SUCCESS:
            return {loading: false, products: action.payload}
        case BRANDS_PRICE_DESC_FAIL:
            return {loading: false, error: action.payload}
        case BRAND_PRODUCT_FILTER:
            return {products:action.payload}
        case BRANDS_CATEGORY_REQUEST:
            return {loading:true, products:{'results':[], 'count': 0}}
        case BRANDS_CATEGORY_SUCCESS:
            return {loading:false, products: action.payload}
        case BRANDS_CATEGORY_FAIL:
            return {loading:false, error: action.payload}
        case BRAND_GENDER_PRODUCT_FILTER:
            return {products: action.payload}
        default:
            return state
    }
}

export const brandGenderOriginDataReducer = (state = {originData: {'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case BRAND_GENDER_PRODUCT_ORIGIN:
            return {originData: action.payload}
        default:
            return state
    }
}

//filterを解除するために使用するreducer
export const brandOriginDataReducer = (state = {originData:{'results':[], 'count': 0}}, action) => {
    switch(action.type){
        case BRAND_PRODUCT_ORIGIN:
            return {originData: action.payload}
        default:
            return state
    }
}