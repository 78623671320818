import {React, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import StripeInput from '../components/Checkout/StripeInput';

import { baseAxios } from '../jwtInterceptor';

import Message from '../components/Message';

import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js"

const useStyles = makeStyles((theme) => ({
    button:{
        margin: theme.spacing(2),
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
    },
    card:{
        color: 'black',
        backgroundColor: 'white',
        margin: theme.spacing(3,8,15,0),
        width: '100%',
        boxShadow: 'initial'
    },
    credit_image:{
        display: 'flex',
        marginBottom: theme.spacing(1)
    },
    container:{
        padding:'initial',
        marginTop: theme.spacing(10)
    },
    image:{
        width:'10%',
        margin:'auto'
    },
    input_title:{
        color:theme.palette.primary['700'],
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3, 3, 15, 3)
        }
    },
    text:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    text_field:{
        border: `1px solid ${theme.palette.primary['400']}`,
        '&:focus':{
            border: 'white'
        },
        color:'black'
    },
    title:{
        color:theme.palette.primary['700'],
        textAlign:'center', 
        marginBottom: theme.spacing(2),
        fontWeight:'bold'
    },
    topic_path:{
        marginTop: theme.spacing(1),
        display:'flex'
    },
    topic_path_button:{
        '&:hover':{
            backgroundColor:'initial'
        }
    },
}))

function AddCreditScreen(){
    const cardLogos = [
        process.env.REACT_APP_IMAGE_URL + 'credit/visa.png',
        process.env.REACT_APP_IMAGE_URL + 'credit/jcb.png',
        process.env.REACT_APP_IMAGE_URL + 'credit/master.png',
        process.env.REACT_APP_IMAGE_URL + 'credit/amex.png',
    ]

    //useState
    const [error, setError] = useState(null);
        
    const classes = useStyles()
    const history = useHistory()
    //useSelector

    //stripe
    const stripe = useStripe();
    const elements = useElements()

    const handleAccountService = () => {
        history.push('management')
    }

    const handleCredit = () => {
        history.push('/credit_list')
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        const card_number = elements.getElement(CardNumberElement);
        const {paymentMethod, error} = await stripe.createPaymentMethod({
            type: 'card',
            card: card_number,
        });

        if(error){
            setError('クレジットカード情報の追加に失敗しました、登録情報に誤りがある可能性がございます。\
            再度別の情報を入力後登録を行なってください。')
        }else{
            baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
            const data = {
                'payment_method': paymentMethod.id
            }
            baseAxios.post('/api/stripe/credit/', data).then(
                () => {
                    history.push('/credit_list')
                }
            ).catch(
                () => {
                    setError('クレジットカード情報の追加に失敗しました、登録情報に誤りがある可能性がございます。\
                    再度別の情報を入力後登録を行なってください。')
                }
            )
        }
    }

    return(
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p" >
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Button onClick={handleCredit} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p" >
                        クレジットカード情報
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    クレジットカード情報追加
                </Typography>
            </div>
     
            <Container maxWidth={"md"} className={classes.container}>
                {
                    error ? <Message>{error}</Message> : ''
                }
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            クレジットカード情報を追加
                        </Typography>
                        <div className={classes.credit_image}>
                            {cardLogos.map((logo, index) => (
                                <div key={index} className={classes.image}>
                                    <img src={logo} alt='クレジットカード' style={{width:'100%'}}/>
                                </div>
                            ))}
                        </div>
                        <form onSubmit={handleSubmit} className={classes.stripe_form}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                    label="クレジットカード番号"
                                    name="credit_number"
                                    variant="outlined"
                                    autoFocus={true}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                        classes:{
                                            root:classes.input_title
                                    }}}
                                    InputProps={{
                                        inputComponent: StripeInput,
                                        inputProps:{
                                            component:CardNumberElement
                                        },
                                        classes:{
                                            notchedOutline:classes.text,
                                            root:classes.text,
                                        }
                                    }}/>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                        label="有効期限"
                                        name="credit_exp"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                            classes:{
                                                root:classes.input_title
                                            }
                                            }}
                                        InputProps={{
                                            inputComponent: StripeInput,
                                            inputProps:{
                                                component:CardExpiryElement
                                            },
                                            classes:{
                                                notchedOutline:classes.text,
                                                root:classes.text,
                                            }
                                    }}/>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                    <TextField
                                    label="せキュリティ番号"
                                    name="card_cvc"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                        classes:{
                                            root:classes.input_title
                                        }}}
                                    InputProps={{
                                        inputComponent:StripeInput,
                                        inputProps:{
                                            component:CardCvcElement
                                        },
                                        classes:{
                                            notchedOutline:classes.text,
                                            root:classes.text,
                                        }
                                    }}/>
                                </Grid>
                                <Button
                                fullWidth
                                type="submit"
                                className={classes.button}
                                >
                                    クレジットカードを追加
                                </Button>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}

export default AddCreditScreen