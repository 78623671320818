import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Loader from '../components/Loader';

import Message from '../components/Message';

import { authenticate_phone_number } from '../actions/accountAction';
import { send_authentication_number } from '../actions/accountAction';

const useStyles = makeStyles((theme) => ({
    button:{
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    card:{
        width: '100%',
        color:'black',
        backgroundColor: 'white',
    },
    container: {
        padding: 'initial'
    },
    dividerColor:{
        backgroundColor: theme.palette.primary['400']
    },
    error_message:{
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    inner:{
        margin: theme.spacing(10,1,15,1)
    },
    input_text:{
        color:'black'
    },
    message:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    resend_button:{
        color:'black',
        border: '1px solid black',
        backgroundColor:'white',
        '&:hover':{
            backgroundColor:theme.palette.primary['100']
        },
        marginBottom: theme.spacing(1)
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    }
}))

const authenticateRegex = /^[0-9]{3,4}?$/

const validationSchema = yup.object({
    authenticate_number: yup
    .string('')
    .required('4ケタ認証番号を入力してください')
    .matches(authenticateRegex, '数字４ケタで入力を行なって下さい')
})

export default function AuthenticationScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()


    const {accountPublicInfo} = useSelector(state => state.accountPublicInfo)
    const {loading, authentication, error} = useSelector(state => state.authentication)
    const {send, send_error} = useSelector(state => state.authNumber)

    const formik = useFormik({
        initialValues: {
            authenticate_number: ''
        },
        validationSchema: validationSchema,
        onSubmit:(values) => {
            event.preventDefault()
            dispatch(authenticate_phone_number(values.authenticate_number))
        }
    })

    const handleResend = (event) => {
        event.preventDefault()
        dispatch(send_authentication_number())
    }

    useEffect(() => {
        if(authentication){
            console.log(authentication)
            setTimeout(
                function(){history.push('/phone_number_update')},200
            )
        }
    },[history, authentication])

    return(
        
        loading
        ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :

        <Container maxWidth="sm" className={classes.container}>
            <div className={classes.inner}>
                {error && <div className={classes.error_message}><Message>認証に失敗しました、正しい認証コードを入力してください</Message></div>}
                {send && <div className={classes.error_message}><Message className={classes.error_message}>認証番号を再送信しました</Message></div>}
                {send_error && <div className={classes.error_message}><Message className={classes.error_message}>認証番号に失敗しました</Message></div>}
                <Card className={classes.card}>
                    <CardContent className={classes.card_content}>
                        <Typography variant="subtitle1" component="p" className={classes.message}>
                            {accountPublicInfo.email.substr(0,1) + '******@*****にお送りした4桁の認証番号を入力してください'}
                        </Typography>
                        <form className={classes.from} noValidate onSubmit={formik.handleSubmit}>
                            <TextField
                            variant='outlined'
                            fullWidth
                            id="authenticate_number"
                            label="4ケタの認証番号"
                            name="authenticate_number"
                            autoFocus
                            autoComplete="authenticate_number"
                            value={formik.values.authenticate_number}
                            onChange={formik.handleChange}
                            error={formik.touched.authenticate_number && Boolean(formik.errors.authenticate_number)}
                            helperText={formik.touched.authenticate_number && formik.errors.authenticate_number}
                            InputLabelProps={{
                                classes:{
                                    root:classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                }
                            }}
                            />
                            <Button type="submit" className={classes.button} fullWidth>
                                認証
                            </Button>
                        </form>
                        <Divider classes={{root: classes.dividerColor}} />
                        <Typography variant="subtitle1" component="p" className={classes.message}>
                            60秒経っても認証番号が届かない方
                        </Typography>
                        <Button onClick={handleResend} className={classes.resend_button} fullWidth>
                            認証番号を再送
                        </Button>
                        <Typography variant="subtitle2" component="p">
                            メールが届かない場合には<a href="https://enclopedia.jp/contact">こちら</a>からお問合せください
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )

}