import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';


import { useFormik } from 'formik';
import * as yup from 'yup';
import { phone_number_update } from '../actions/accountAction';

const useStyles = makeStyles((theme) => ({
    card:{
        color:'black',
        backgroundColor:'white'
    },
    message:{
        marginTop: theme.spacing(2)
    },
    caption:{
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        color: theme.palette.primary['700']
    },
    container:{
        padding: 'initial'
    },
    button:{
        color:'black',
        boxShadow: 'initial',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        },
        marginTop: theme.spacing(2)
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10, 3, 15, 3)
        }
    },
    input_text:{
        color:'black'
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
}))

const phoneRegex =/^[0-9]{3}?[0-9]{4}?[0-9]{4}?$/

const validationSchema = yup.object().shape({
    phone_number: yup
    .string('')
    .required('電話番号の入力は必須です')
    .matches(phoneRegex, '正しい形式で入力してください'),
})

export default function PhoneNumberRegisterScreen(){
    
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const {accountPublicInfo} = useSelector(state => state.accountPublicInfo)

    const formik = useFormik({
        initialValues: {
            phone_number: ''
        },
        validationSchema: validationSchema,
        onSubmit:(values) => {
            event.preventDefault()
            dispatch(phone_number_update(values.phone_number))
        }
    })



    useEffect(() => {
        if(accountPublicInfo === ''){
            history.push('/')
        }
        if(accountPublicInfo.phone_number){
            history.goBack()
        }
    }, [accountPublicInfo])

    return(
        <div className={classes.root}>
            <Container maxWidth={"md"} className={classes.container}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography component="h2" variant="h2" align="center" style={{fontWeight: 'bold'}}>
                            電話番号登録
                        </Typography>
                        <Typography variant='subtitle1' component="p" className={classes.message}>
                            電話番号の登録が完了していません、電話番号登録後に次の処理を行うことができます。
                        </Typography>
                        <Typography variant="caption" component="p" className={classes.caption}>
                            電話番号は（-）ハイフンを入れずに入力してください
                        </Typography>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <TextField
                            variant="outlined"
                            fullWidth
                            id="phone_number"
                            label="電話番号"
                            name="phone_number"
                            autoComplete="phone_number"
                            autoFocus
                            InputLabelProps={{
                                classes:{
                                    root: classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                }
                            }}
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                            />
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            >
                                登録
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </div>
    )
}
