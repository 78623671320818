
import {
    ADRESS_GET_REQUEST,
    ADRESS_GET_SUCCESS,
    ADRESS_GET_FAIL,
    ADRESS_UPDATE_REQUEST,
    ADRESS_UPDATE_SUCCESS,
    ADRESS_UPDATE_FAIL
} from '../constants/adressConstants'

import { baseAxios } from '../jwtInterceptor'

export const get_adress = () => async(dispatch) => {
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    dispatch({type:ADRESS_GET_REQUEST})
    await baseAxios.get('/api/account/adress/').
    then(
        (response) => {
            dispatch({type:ADRESS_GET_SUCCESS, payload:response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:ADRESS_GET_FAIL,
                payload:error.response && error.response.data.message
                ? 
                error.response.data.message
                :
                error.message
            })
        }
    )
}

export const update_adress = (country="japan", prefecture, region, address,building_name, postalcode) => async(dispatch, getState) => {
    dispatch({type:ADRESS_UPDATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    const data = {
        'country' : country,
        'prefecture': prefecture,
        'region': region,
        'address': address,
        'building_name': building_name,
        'postalcode': postalcode,
    }

    await baseAxios.patch('/api/account/adress/', data).then(
        (response) => {
            dispatch({type:ADRESS_UPDATE_SUCCESS, payload: response['data']})
        }
    ).catch(
        () => {
            dispatch({
                type:ADRESS_UPDATE_FAIL, payload: getState().adress.adressInfo
            })
        }
    )
}