import React,{useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { CardActionArea} from '@material-ui/core';
import { Container } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { useHistory } from 'react-router';

//import component
import ClosetListComponent from './ClosetList';

//import action
import { favorite_create } from '../actions/favoriteAction';
import { favorite_delete } from '../actions/favoriteAction';

import { baseAxios } from '../jwtInterceptor';

const useStyles = makeStyles((theme) => ({
    action_area:{
        '&:hover':{
            backgroundColor: theme.palette.primary['700'],
        }
    },
    add_icon:{
        backgroundColor: theme.palette.primary['700'],
        color:'white',
        '&:hover':{
            backgroundColor: theme.palette.primary['900']
        },
        [theme.breakpoints.down('xs')]:{
        width:'25px',
        height:'25px'
        },
    },
    add_icon_button:{
        position: 'absolute',
        [theme.breakpoints.up('xs')]:{
            top: '86%',
            right: '6%'
        },
        [theme.breakpoints.up('sm')]:{
            top: '87%',
            right: '4%'
        },
        [theme.breakpoints.up('md')]:{
            top: '88%',
            right: '5%'
        },
    },
    
    card:{
        background:  '#eeeeee',
        color:'black',
        boxShadow: `initial`,
        borderRadius: '3%',
        position: 'relative',
    },
    card_closet:{
        backgroundColor:'white', 
        color:'black', 
        marginBottom: theme.spacing(1)
    },
    card_media:{
        height:'100%',
        width: '100%'
    },
    closet_button:{
        backgroundColor: theme.palette.primary['400']
    },
    closet_form:{
        textAlign:'center',
        margin:'auto'
    },
    closet_modal:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.up('xs')]:{
            width: '90%'
        },
        [theme.breakpoints.up('md')]:{
            width: '80%'

        },
        [theme.breakpoints.up('lg')]:{
            width: '60%'
        },
        backgroundColor: 'white',
        color:'black',
        boxShadow:20,
        borderRadius:10,
    
    },
    favorite: {
        [theme.breakpoints.up('xs')]:{
            width:'13px',
            height:'13px'
        },
        [theme.breakpoints.up('sm')]:{
            width:'20px',
            height:'20px'
        },
        [theme.breakpoints.up('md')]:{
            width:'13px',
            height:'13px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'18px',
            height: '18px',
        },
        color: "#b71c1c",
    },
    favoriteBorder:{
        color: theme.palette.primary['800'],
        [theme.breakpoints.up('xs')]:{
            width:'13px',
            height:'13px'
        },
        [theme.breakpoints.up('sm')]:{
            width:'20px',
            height:'20px'
        },
        [theme.breakpoints.up('md')]:{
            width:'13px',
            height:'13px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'18px',
            height: '18px',
        }
    },
    form:{
        borderColor:"black !important",
    },
    icon: {
        [theme.breakpoints.down('xs')]:{

        },
        [theme.breakpoints.up('xs')]:{
            top:'6%',
            left:'75%',
        },
        [theme.breakpoints.up('sm')]:{
            top:'4%',
            left:'83%',
        },
        [theme.breakpoints.up('md')]:{
            top:'4%',
            left:'80%',
        },
        [theme.breakpoints.up('lg')]:{
            top:'4%',
            left:'81%',
        },
        position:'absolute',
    },
    iconButton:{
        zIndex:2500,
        backgroundColor: theme['palette']['primary']['200'],
        '&:hover':{
            backgroundColor: theme['palette']['primary']['400']
        }
    },
    image:{
        width:'100%'
    },
    loader:{
        paddingBottom: '100%'
    },
    media:{
        borderRadius: '5%',
        position: 'relative'
    },
    modal:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        width:400,
        backgroundColor: 'white',
        color:'black',
        boxShadow:20,
        borderRadius:10
    },
    modal_button:{
        marginTop: theme.spacing(3),
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['300']
        }
    },
    modal_card: {
        color:'black',
        backgroundColor:'white',
        margin: theme.spacing(0,1,0,1),
        zIndex:3000,
    },
    modal_container:{
        height:'500px',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        padding: 'initial',
    },
    modal_inbox:{
        margin: theme.spacing(4)
    },
    multilineColor:{
        color:'black',
    },
    stock:{
        position: 'absolute',
        [theme.breakpoints.up('xs')]:{
            top: '86%',
        },
        [theme.breakpoints.up('sm')]:{
            top: '89%',
        },
        [theme.breakpoints.up('md')]:{
            top: '91%',
        },
        [theme.breakpoints.up('lg')]:{
            top: '92%'
        },
        [theme.breakpoints.up('xl')]:{
            top: '92.5%'
        },
        color: 'white',
        backgroundColor: alpha(theme.palette.primary['900'], 0.7),
        padding: theme.spacing(0.1)
    },
}))

//subscriptionに登録している人の場合は予約ボタン、
//していない顧客に対してレンタルボタンを表示
function Product({product}){
    const classes = useStyles()
    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {favorites} = useSelector(state => state.favoriteList)
    const favo = favorites.find(favorite => favorite.product.id === product.id)
    //useState
    const [open, setOpen] = useState(false)
    const [favoriteOpen, setFavoriteOpen] = useState(false)
    const [favoriteState, setFavoriteState] = useState(false)
    const [load, setLoad] = useState(true)

    const handleImage = () => {
        setLoad(false)
    }
    
    const history = useHistory()
    const dispatch = useDispatch()

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => setOpen(false);

    const changeOpenState = (isState) => {
        setOpen(isState)
    }

    const handleSelect = async() => {
        history.push(`/product/${product.id}`)
        window.gtag('event', 'view_item',{
            "items": [{
                "item_id":product.id,
                "item_name": product.product_name,
                "item_brand": product.brand.id
            }]
        })
        if(accountInfo){
            const data = {
                "product_id": product.id
            }
            await baseAxios.post('/api/history/browsing', data)
        }
    }

    const favoriteHandler = () => {
        try{
            if(favoriteState === false){
                if(favorites.length >= 30){
                    setFavoriteOpen(true)
                }else{
                    setFavoriteState(true)
                    dispatch(favorite_create(product.id))
                }
            }else{
                dispatch(favorite_delete(favo.id))
                setFavoriteState(false)
            }
        }catch(error){
            console.log(error)
        }
    }

    const handleFavoriteClose = () => {
        setFavoriteOpen(prevFavoriteOpen => !prevFavoriteOpen)
    }

    useEffect(() => {
        const favorite = favorites.find(favorite => favorite.product.id === product.id)
        setFavoriteState(favorite ? true : false)
    }, [favorites, product.id])
    

    return (
        <>
            <Card className={classes.card} key={product.id}>
                {
                    accountInfo ?
                    <div className={classes.icon}>
                        <IconButton className={classes.iconButton} onClick={favoriteHandler}>
                            {
                                favoriteState ?
                                <FavoriteIcon className={classes.favorite}/>
                                :
                                <FavoriteBorderIcon className={classes.favoriteBorder}/>
                            }
                        </IconButton>
                    </div>
                    :
                    ''
                }

                <CardActionArea onClick={handleSelect} component={Link} to={`/product/${product.id}`}>
                    <div className={classes.media}>
                            {load && <div className={classes.loader}></div>}
                            <CardMedia
                            className={classes.card_media}
                            component="img"
                            src={product.img}
                            title="product"
                            alt={product.product_name}
                            onLoad={handleImage}
                            style={{display: load ? 'none' : 'block'}}
                            />
                        <div className={classes.stock}>
                            {
                                product.stock > 0 ?
                                <Typography variant="subtitle1" component="p" style={{fontSize:16}}>
                                    レンタル可
                                </Typography>
                                :
                                <Typography variant="subtitle1" component="p" style={{fontSize:16}}>
                                    レンタル不可
                                </Typography>
                            }
                        </div>
                    </div>
                    <CardContent style={{color:'black'}}>
                        <Typography variant="subtitle1" noWrap component="p" 
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginBottom:10,
                        }}>
                            {product.product_name}
                        </Typography>
                        <Typography variant="subtitle1" noWrap component="p" 
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginBottom:10,
                        }}>
                            {"レンタル料" + " ¥ " + (product.price.price)}
                        </Typography>
                    </CardContent>
                    </CardActionArea>
                    {
                    accountInfo ?
                    <div className={classes.add_icon_button}>
                        <IconButton
                        size='small'
                        className={classes.add_icon}
                        onClick={handleOpen}
                        aria-label="showons"
                        aria-controls="menu-appbar"
                        >
                            <AddIcon/>
                        </IconButton>
                    </div>
                    :
                    ''
                    }
            </Card>

            <Modal
            open={open}
            style={{zIndex:3500}}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            >
              <ClosetListComponent product_id={product.id} changeState={changeOpenState}/>
            </Modal>
            <Modal
            style={{zIndex:3500}}
            open={favoriteOpen}
            onClose={handleFavoriteClose}
            >
                <Container maxWidth={"sm"} className={classes.modal_container} style={{padding: 'initial'}}>
                    <Card className={classes.modal_card}>
                        <CardContent>
                            <Typography variant="subtitle1" component="p" align="center" style={{fontWeight: 'bold'}}>
                                エラー
                            </Typography>
                            <Typography variant="subtitle2" component="p" className={classes.error_message}>
                                {"お気に入りの登録数が制限を超えたため、これ以上登録できません。\
                                \n登録済みの不要なお気に入りリストから削除してください。"}
                            </Typography>
                            <Button
                            onClick={handleFavoriteClose}
                            className={classes.modal_button}
                            fullWidth>
                                閉じる
                            </Button>
                        </CardContent>
                    </Card>
                </Container>
            </Modal>
        </>
    );
}

export default Product
