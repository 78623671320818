import {
    CARD_ELEMENT_SAVE,
    CARD_ELEMENT_DELETE,
    CARD_ELEMENT_UPDATE_REQUEST,
    CARD_ELEMENT_UPDATE_SUCCESS,
    CARD_ELEMENT_UPDATE_FAIL,
    CHECKOUT_REQUEST,
    CHECKOUT_SUCCESS,
    CHECKOUT_FAILED,
    CREDIT_REGISTER_REQUEST,
    CREDIT_REGISTER_SUCCESS,
    CREDIT_REGISTER_FAIL,
    CREDIT_LIST_REQUEST,
    CREDIT_LIST_SUCCESS,
    CREDIT_LIST_FAIL,
    STRIPE_PLAN_REQUEST,
    STRIPE_PLAN_SUCCESS,
    STRIPE_PLAN_FAIL,
    SUBSCRIPTION_INFO_REQUEST,
    SUBSCRIPTION_INFO_SUCCESS,
    SUBSCRIPTION_INFO_FAIL,
    USER_SECRET_INFO_REQUEST,
    USER_SECRET_INFO_SUCCESS,
    USER_SECRET_INFO_FAIL,
    SUBSCRIPTION_REGISTER_REQUEST,
    SUBSCRIPTION_REGISTER_SUCCESS,
    SUBSCRIPTION_REGISTER_FAIL,
    SUBSCRIPTION_UPDATE_REQEUST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAIL,
    SUBSCRIPTION_CANCEL_REQUEST,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_FAIL,
    SUBSCRIPTION_RESERVATION_REQUEST,
    SUBSCRIPTION_RESERVATION_SUCCESS,
    SUBSCRIPTION_RESERVATION_FAIL,
    RESERVATION_MESSAGE_CLEAR,
    SUBSCRIPTION_UPCOMING_INVOICE_REQUEST,
    SUBSCRIPTION_UPCOMING_INVOICE_SUCCESS,
    SUBSCRIPTION_UPCOMING_INVOICE_FAIL,
    SUBSCRIPTION_INVOICE_REQUEST,
    SUBSCRIPTION_INVOICE_SUCCESS,
    SUBSCRIPTION_INVOICE_FAIL,
    INVOICE_REQUEST,
    INVOICE_SUCCESS,
    INVOICE_FAIL,
    INVOICE_CLEAR
} from '../constants/stripeConstants'

//確定ページで表示するようにする
//paymentmentodを登録しておく
export const cardElementReducer = (state={}, action) => {
    switch(action.type){
        case CARD_ELEMENT_SAVE:
            return {paymentMethod: action.payload}
        case CARD_ELEMENT_DELETE:
            return {paymentMethod: action.payload}
        default:
            return state
    }
}

//subscription userの契約プランについて登録するためのreducer
export const subscriptionGetReducer = (state={subscriptionInfo:{plan:''}}, action) => {
    switch(action.type){
        case SUBSCRIPTION_INFO_REQUEST:
            return {loading: true, subscriptionInfo:{plan:''}}
        case SUBSCRIPTION_INFO_SUCCESS:
            return {loading: false, subscriptionInfo: action.payload}
        case SUBSCRIPTION_INFO_FAIL:
            return {loading:false, subscriptionInfo:{plan:''}   }
        default:
            return state
    }
}

//subscription プラン情報について保存するためのreducer
export const stripePlanReducer = (state={plan:''}, action) => {
    switch(action.type){
        case STRIPE_PLAN_REQUEST:
            return {loading:true, plan: JSON.parse(localStorage.getItem('subscriptionInfo')).plan}
        case STRIPE_PLAN_SUCCESS:
            return {loading:false, plan: action.payload}
        case STRIPE_PLAN_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

//商品をレンタルする際に登録する
//reservation updateによって再度subscriptionInfoの値を更新する必要があるのでhome画面にリダイレクトするようにする
export const reservationReducer = (state={}, action) => {
    switch(action.type){
        case SUBSCRIPTION_RESERVATION_REQUEST:
            return {reservation_loading:true}
        case SUBSCRIPTION_RESERVATION_SUCCESS:
            return {reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_RESERVATION_FAIL:
            return {reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_REGISTER_REQUEST:
            return {reservation_loading:true}
        case SUBSCRIPTION_REGISTER_SUCCESS:
            return {reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_REGISTER_FAIL:
            return {reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_UPDATE_REQEUST:
            return{reservation_loading:true}
        case SUBSCRIPTION_UPDATE_SUCCESS:
            return{reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_UPDATE_FAIL:
            return{reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_CANCEL_REQUEST:
            return {reservation_loading:true}
        case SUBSCRIPTION_CANCEL_SUCCESS:
            return {reservation_loading:false, reservation_message: action.payload}
        case SUBSCRIPTION_CANCEL_FAIL:
            return {reservation_loading:false, reservation_message: action.payload}
        case CHECKOUT_REQUEST:
            return {reservation_loading:true}
        case CHECKOUT_SUCCESS:
            return {reservation_loading:false, reservation_message: action.payload}
        case CHECKOUT_FAILED:
            return {reservation_loading:false, reservation_message: action.payload}
        case RESERVATION_MESSAGE_CLEAR:
            return {reservation_message: action.payload}
        default:
            return state
    }
}

//userのクレジットカード情報を取得する他のreducer

export const creditInfoReducer = (state={}, action) =>{
    switch(action.type){
        case USER_SECRET_INFO_REQUEST:
            return {loading: true}
        case USER_SECRET_INFO_SUCCESS:
            return{loading:false, credit_info:action.payload}
        case USER_SECRET_INFO_FAIL:
            return {loading:false, error:action.payload}
        case CREDIT_REGISTER_REQUEST:
            return {loading:true}
        case CREDIT_REGISTER_SUCCESS:
            return {loading:false, credit_info: action.payload}
        case CREDIT_REGISTER_FAIL:
            return {loading:false, error: action.payload}
        case CARD_ELEMENT_UPDATE_REQUEST:
            return {loading: true}
        case CARD_ELEMENT_UPDATE_SUCCESS:
            return {loading:false, credit_info:action.payload}
        case CARD_ELEMENT_UPDATE_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}

//invoiceの情報

export const invoiceReducer = (state={}, action) => {
    switch(action.type){
        case SUBSCRIPTION_INVOICE_REQUEST:
            return {loading:true}
        case SUBSCRIPTION_INVOICE_SUCCESS:
            return {loading:false, invoice:action.payload}
        case SUBSCRIPTION_INVOICE_FAIL:
            return {loading:false, error: action.payload}
        case INVOICE_REQUEST:
            return {loading:true}
        case INVOICE_SUCCESS:
            return {loading:false, invoice: action.payload}
        case INVOICE_FAIL:
            return {loading:false, error: action.payload}
        case INVOICE_CLEAR:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const upcomingInvoiceReducer = (state = {}, action) => {
    switch(action.type){
        case SUBSCRIPTION_UPCOMING_INVOICE_REQUEST:
            return {loading:true}
        case SUBSCRIPTION_UPCOMING_INVOICE_SUCCESS:
            return {loading:false, upcomingInvoice: action.payload}
        case SUBSCRIPTION_UPCOMING_INVOICE_FAIL:
            return {loading:false, error: action.payload}
        default:
            return state
    }
}

export const creditListReducer = (state={creditInfo: ''}, action) => {
    switch(action.type){
        case CREDIT_LIST_REQUEST:
            return {loading: true, creditInfo: ''}
        case CREDIT_LIST_SUCCESS:
            return {loading: false, creditInfo: action.payload}
        case CREDIT_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}
