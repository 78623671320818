import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    button:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        border: `solid 1px black`,
        background: 'white'
    },
    content:{
        whiteSpace: 'pre-line',
        lineHeight: '25px'
    },
    divider:{
        marginTop:theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    subtitle:{
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    title:{
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    }
}))

export default function PriceHelpScreen(){
    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        history.push('/subscription/plan')
    }

    const title = 'ヘルプセンター（料金と購入について）｜Enclopedia【公式サイト】'
    const description = 'Enclopedia（アンクロペディア）ファッションレンタルサービスに関する料金と購入についてのサポート'

    headRewrite(title, description)

    return (
        <div className={classes.root}>
            <Container maxWidth={"md"} style={{padding: 'initial'}}>
                <Typography variant="h1" component="h1" className={classes.title} align="center">
                    料金と購入について
                </Typography>
                <Typography variant="subtitle1" component="p">
                    Enclopediaでは一着のレンタルごとに料金が設定されているプランと月額定額制のプランをご用意しております。
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                    レンタルプランについて
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    アカウント作成後、自動的にレンタルプランへと登録されます。レンタルプランに関して登録料はかかりません 。
                    レンタルプランでは一着のレンタルごとに料金を設定してあります、各アイテムの料金についてはアイテム詳細ページをご覧下さい。
                    一回につき３着までまとめてレンタルすることができ、アイテム返却確認後に月に何度でもレンタルすることが可能となっています。
                    往復の送料に関しては返却料のみいただいております、こちらに関しましても各アイテム詳細ページでご確認いただけます。
                    まとめてレンタルの場合一律で1000円の返却料がかかります。
                </Typography>
                <Divider classes={{root:classes.divider}} />
                <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                    サブスクリプションプランについて
                </Typography>
                <Typography variant="subtitle1" component={"p"} className={classes.content}>
                    Enclopediaでは３種類のサブスクリプションプランをご用意しております。詳細に関してましては下記のボタンから各サブスクリプションのプランをご確認ください。
                </Typography>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Button className={classes.button} onClick={handleClick}>
                        プラン詳細
                    </Button>
                </div>
                <Divider classes={{root:classes.divider}} />
                <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                    アイテムの購入について
                </Typography>
                <Typography variant="subtitle1" component={"p"} className={classes.content}>
                {"予約中のアイテムに限りアイテムの購入ができます、料金に関しましてレンタルプランのみアイテム代からレンタル料と返送料を差し引いた値段となります。\
                    \nサブスクリプションに登録いただいているお客様はアイテム代から20%の割引となります。\
                    \nアイテムをレンタル中のみやることリスト、予約履歴一覧から商品の購入を行うことができます。"}
                </Typography>
                <Divider classes={{root: classes.divider}}/>
                <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                    返却手数料について
                </Typography>
                <Typography variant="subtitle1" component={"p"} className={classes.content}>
                    {"レンタルプランにおいてアイテムの返却料を頂戴しております、料金に関しては各アイテムのサイズに対しての料金となりますので各アイテム詳細ページをご覧下さい。\
                    \n複数まとめてレンタルする場合は合計した配送料が1000円以上を超過する場合一律1000円でまとめて返却となります。\
                    サブスクリプションに登録しているユーザに関しては返却手数料はかかりません。"}

                </Typography>
                <Divider classes={{root:classes.divider}} />
            </Container>
            
        </div>
    )
}