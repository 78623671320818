import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
//action
import { reservation_already_rental } from "../actions/reservationAction";
//component
import Loader from "../components/Loader";
import CommentField from "../components/CommentField";

const useStyles = makeStyles((theme) => ({
    container:{
        padding: 'initial'
    },
    loading:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        }
    }
}))

const ReviewScreen = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()

    const {loading, rental} = useSelector(state => state.rentalInfo)
    const handleGoback = () => {
        history.goBack()
    }

    useEffect(() => {
        dispatch(reservation_already_rental(id))
    },[])

    return(
        <Container maxWidth={"md"} className={classes.container}>
            {
                loading ?
                    <div className={classes.loading}>
                        <Loader/>
                    </div>
                    :
                    rental ?
                    <div className={classes.root}>
                        <CommentField product={id}/>
                        <Button className={classes.button} onClick={handleGoback} disableRipple disableFocusRipple>
                            戻る
                        </Button>
                    </div>
                    :
                    <div classesName={classes.root}>
                        <Card className={classes.card}>
                            <CardContent>

                            </CardContent>
                        </Card>
                    </div>
            }
        </Container>
    )
}

export default ReviewScreen