import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { IconButton } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

//action
import { favorite_create } from "../actions/favoriteAction";
import { favorite_delete } from "../actions/favoriteAction";
import { favorite_update } from "../actions/favoriteAction";

const useStyles = makeStyles(theme => ({

    favorite:{
        [theme.breakpoints.up('xs')]:{
            width:'15px',
            height:'15px'

        },
        [theme.breakpoints.up('sm')]:{
            width: '15px',
            height: '15px'
        },
        [theme.breakpoints.up('md')]:{
            width: '15px',
            height: '15px',
        },
        color: "#b71c1c",
    },
    favorite_border:{
        [theme.breakpoints.up('xs')]:{
            width:'15px',
            height:'15px'

        },
        [theme.breakpoints.up('sm')]:{
            width:'15px',
            height:'15px',

        },
        [theme.breakpoints.up('md')]:{
            widht:'15px',
            height:'15px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'15px',
            height:'15px'
        },
        color: theme.palette.primary["800"]
    },
    favorite_icon:{
        [theme.breakpoints.up('xs')]:{
            top: '75%',
            left: '5%'
        },
        [theme.breakpoints.up('sm')]:{
            top: '80%',
            left: '3%'
        },
        [theme.breakpoints.up('md')]:{
            top:'80%',
            left:'3%'
        },
        [theme.breakpoints.up('lg')]:{
            top:'80%',
            left:'6%'
        },
        position:'absolute'
    },
    icon_button:{
        backgroundColor: theme['palette']['primary']['100'],
        '&:hover':{
            backgroundColor: theme['palette']['primary']['400']
        }
    },
    media:{
        width:"100%",
        position: 'relative',
    },
    notification:{
        [theme.breakpoints.up('xs')]:{
            width:'15px',
            height:'15px'

        },
        [theme.breakpoints.up('sm')]:{
            width: '15px',
            height: '15px'
        },
        [theme.breakpoints.up('md')]:{
            width: '15px',
            height: '15px',
        },
        color: "#fbc02d"
    },
    notification_icon:{
        [theme.breakpoints.up('xs')]:{
            top: '75%',
            left: '75%'
        },
        [theme.breakpoints.up('sm')]:{
            top: '80%',
            left: '86%'
        },
        [theme.breakpoints.up('md')]:{
            top:'80%',
            left:'80%'
        },
        [theme.breakpoints.up('lg')]:{
            top:'80%',
            left:'81%'
        },
        position:'absolute'
    },
    notification_border:{
        [theme.breakpoints.up('xs')]:{
            width:'15px',
            height:'15px'

        },
        [theme.breakpoints.up('sm')]:{
            width:'15px',
            height:'15px',

        },
        [theme.breakpoints.up('md')]:{
            widht:'15px',
            height:'15px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'15px',
            height:'15px'
        },
        color: theme.palette.primary["800"]
    },
    subtitle:{
        marginTop: theme.spacing(1),
    }
}))

function FavoriteComponent({favorite}){

    const classes = useStyles()

    const dispatch = useDispatch()

    const history = useHistory()

    const [favoriteState, setFavoriteState] = useState(true)
    const [notificationState, setNotificationState] = useState(favorite ? favorite.is_notification ? true : false : '')

    const favoriteHandler = () => {
        if(favoriteState === false){
            dispatch(favorite_create(favorite.product.id))
            setFavoriteState(true)
        }else{
            dispatch(favorite_delete(favorite.id))
            setFavoriteState(false)
        }
    }

    const notificationHandler = (event) => {
        event.preventDefault()
        if(notificationState){
            dispatch(favorite_update(favorite.id, false))
            setNotificationState(false)
        }else{
            dispatch(favorite_update(favorite.id, true))
            setNotificationState(true)
        }
    }

    //product画面を参考に修正する
    return(
        <>
        <Card>
            <div className={classes.media}>
                <CardActionArea style={{height:'100%'}} onClick={() => {history.push(`/product/${favorite.product.id}`)}}>
                    <CardMedia
                    component="img"
                    src={favorite.product.img}
                    title={favorite.product.product_name}
                    className={classes.media}
                    />
                </CardActionArea>
                <div className={classes.favorite_icon}>
                <IconButton className={classes.icon_button} onClick={favoriteHandler}>
                    { favoriteState ?<FavoriteIcon className={classes.favorite}/>: <FavoriteBorderIcon className={classes.favorite_border} />}
                </IconButton>
                </div>
                <div className={classes.notification_icon}>
                    <IconButton className={classes.icon_button} onClick={notificationHandler}>
                        {notificationState ? <NotificationsIcon className={classes.notification}/> : <NotificationsNoneIcon className={classes.notification_border}/>}
                    </IconButton>
                </div>
            </div>
        </Card>
        <Typography variant="h3" component="h3" noWrap className={classes.subtitle}>
            {favorite.product.product_name}
        </Typography>
        </>
    )
}

export default FavoriteComponent
