import { createStore, combineReducers, applyMiddleware }  from 'redux';
import thunk from 'redux-thunk';
import { accountLoginReducer } from './reducers/accountReducer';
import { accountPublicInfo } from './reducers/accountReducer';
import { accountRegisterReducer } from './reducers/registerReducer';
import { adressGetReducer } from './reducers/adressReducer';
import { authenticatePhoneNumberReducer } from './reducers/accountReducer';
import { brandListReducer } from './reducers/categoryReducer';
import { brandProductsGetReducer } from './reducers/categoryReducer';
import { brandOriginDataReducer } from './reducers/categoryReducer'
import { brandGenderOriginDataReducer } from './reducers/categoryReducer';
import { browsingHistoryReducer } from './reducers/historyReducer';
import { buyingInfoReducer } from './reducers/buyingReducer';
import { chatgptImageReducer } from './reducers/chatgptReducer';
import { composeWithDevTools } from 'redux-devtools-extension'
import { categoryReducer, categoryProductReducer } from './reducers/categoryReducer'
import { cartReducer } from './reducers/cartReducer'
import { cartItemReducer } from './reducers/cartReducer'
import { cartItemCreateReducer } from './reducers/cartReducer'
import { cartBackendReducer } from './reducers/cartReducer'
import { checkoutStepReducer } from './reducers/checkoutReducer'
import { closetListReducer } from './reducers/closetReducer'
import { closetCreateReducer } from './reducers/closetReducer'
import { clothCreateReducer } from './reducers/closetReducer'
import { clothListReducer } from './reducers/closetReducer';
import { createInvitationReducer } from './reducers/couponReducer';
import { creditInfoReducer } from './reducers/stripeReducer'
import { creditListReducer } from './reducers/stripeReducer';
import { couponReducer } from './reducers/couponReducer';
import { discountReducer } from './reducers/couponReducer';
import { emailSubscribe } from './reducers/accountReducer'
import { emailReducer } from './reducers/accountReducer';
import { favoriteCreateReducer } from './reducers/favoriteReducer'
import { favoriteListReducer } from './reducers/favoriteReducer'
import { featuredProductReducer } from './reducers/analyticsReducer'
import { featureBrandReducer } from './reducers/analyticsReducer'
import { genderOriginDataReducer } from './reducers/productReducers';
import { imageGallaryReducer } from './reducers/imageGallaryReducer'
import { invoiceReducer } from './reducers/stripeReducer';
import { issuingReducer } from './reducers/couponReducer';
import { newsGetReducer } from './reducers/notificationReducer'
import { notificationReducer } from './reducers/notificationReducer'
import { orderReducer } from './reducers/buyingReducer';
import { orderItemGet } from './reducers/buyingReducer';
import { profileGetReducer } from './reducers/profileReducer'
import { productSearchReducer } from './reducers/productReducers'
import { productTagReducer } from './reducers/productReducers'
import { productsListReducer } from './reducers/productReducers'
import { productDetailReducers } from './reducers/productReducers'
import { productSizeReducer } from './reducers/productReducers'
import { reviewsReducer } from './reducers/productReducers'
import { readReducer } from './reducers/notificationReducer'
import { relatedProductReducer } from './reducers/productReducers'
import { returnProductReducer } from './reducers/reservationReducer'
import { reviewCreateReducer } from './reducers/productReducers'
import { reviewListReducer} from './reducers/productReducers'
import { reservationGetReducer } from './reducers/reservationReducer';
import { reservationItemGetReducer } from './reducers/reservationReducer';
import { reservationReducer } from './reducers/stripeReducer'
import { reservationListReducer } from './reducers/reservationReducer'
import { reservationLatestReducer } from './reducers/reservationReducer';
import { reservationAlreadyRentalReducer } from './reducers/reservationReducer';
import { reservationDaySaveReducer } from './reducers/reservationReducer';
import { searchWordHistoryReducer } from './reducers/historyReducer';
import { searchTagListReducer } from './reducers/productReducers';
import { upcomingInvoiceReducer } from './reducers/stripeReducer'
import { variationReducer } from './reducers/productReducers'
import { stylingListReducer } from './reducers/chatgptReducer';
//stripeに関するreducer
import { subscriptionGetReducer } from './reducers/stripeReducer'
import { stripePlanReducer } from './reducers/stripeReducer'
import { searchGenderOriginProductsReducer } from './reducers/productReducers';
import { sendAuthenticationNumberReducer } from './reducers/accountReducer';
import { tagGenderOriginProductsReducer } from './reducers/productReducers';
import { todoListReducer } from './reducers/notificationReducer';

//product data save
import { originDataReducer } from './reducers/productReducers'
//search product origin data save
import { searchOriginDataReducer } from './reducers/productReducers'

import { originTagDataReducer } from './reducers/productReducers';
//必要のないstoreの値を削除するようにする
const reducer = combineReducers({ 
    account:accountLoginReducer,
    accountPublicInfo:accountPublicInfo,
    adress:adressGetReducer,
    authentication: authenticatePhoneNumberReducer,
    authNumber: sendAuthenticationNumberReducer,
    brandList:brandListReducer,
    brandProducts: brandProductsGetReducer,
    brandOriginData: brandOriginDataReducer,
    brandGenderOriginData: brandGenderOriginDataReducer,
    browsing: browsingHistoryReducer,
    buying: buyingInfoReducer,
    cart:cartBackendReducer,
    cartItem: cartReducer,
    cartItemDB: cartItemReducer,
    chatgpt: chatgptImageReducer,
    createCartItem: cartItemCreateReducer,  
    createInvitation: createInvitationReducer,    
    category: categoryReducer,
    category_product: categoryProductReducer,
    creditInfo: creditListReducer,
    coupon: couponReducer,
    discount: discountReducer,
    emailSubscribe:emailSubscribe,
    email: emailReducer,
    closet:closetCreateReducer,
    closetList:closetListReducer,
    cloth:clothCreateReducer,
    clothList:clothListReducer,
    favorite: favoriteCreateReducer,
    favoriteList: favoriteListReducer,
    featureProduct: featuredProductReducer,
    featureBrand: featureBrandReducer,
    genderOriginData: genderOriginDataReducer,
    imageGallary: imageGallaryReducer,
    invoice: invoiceReducer,
    issuing: issuingReducer,
    notification: notificationReducer,
    //listとgetでもっとわかりやすい表記にする
    news: newsGetReducer,
    order: orderReducer,
    orderItem: orderItemGet,
    product: productDetailReducers,
    productList:productsListReducer,
    productSize:productSizeReducer,
    productTag:productTagReducer,
    productOriginData: originDataReducer,
    profile:profileGetReducer,
    read: readReducer,
    related: relatedProductReducer,
    returnProduct: returnProductReducer,
    register:accountRegisterReducer,
    reviewList: reviewsReducer,
    reviews: reviewListReducer,
    review: reviewCreateReducer,
    reservation: reservationReducer,
    reservationInfo: reservationListReducer,
    reservationGetInfo: reservationGetReducer,
    reservationLatestInfo: reservationLatestReducer,
    reservationItem: reservationItemGetReducer,
    reservationDay: reservationDaySaveReducer,
    rentalInfo: reservationAlreadyRentalReducer,
    styling: stylingListReducer,
    searchProducts:productSearchReducer,
    searchOriginData:searchOriginDataReducer,
    step:checkoutStepReducer, 
    searchGenderOriginData:searchGenderOriginProductsReducer,
    searchWord: searchWordHistoryReducer,
    searchTags: searchTagListReducer,
    variation:variationReducer,      
    secretInfo: creditInfoReducer,                                      
    subscriptionInfo: subscriptionGetReducer,
    stripePlanInfo: stripePlanReducer,
    tagOriginData: originTagDataReducer,
    tagGenderOriginData: tagGenderOriginProductsReducer,
    todo: todoListReducer,
    upcomingInvoice:upcomingInvoiceReducer
                                })

//ローカルスロレージに保存してもセキュリティ上問題ないのかチェックaccountInfoにはcookie使用決定
const accountHistory = localStorage.getItem('accountInfo') ?
                        JSON.parse(localStorage.getItem('accountInfo')): ''
const accountPublicInfoHistory = localStorage.getItem('publicAccountInfo') ?
                    JSON.parse(localStorage.getItem('publicAccountInfo')): ''
const cartHistory = localStorage.getItem('cartItems') ?
                     JSON.parse(localStorage.getItem('cartItems')): []
const cartIdHistory = localStorage.getItem('cart_id') ?
                       JSON.parse(localStorage.getItem('cart_id')) : {id: ''}
const closetHistory = localStorage.getItem('closets') ?
                       JSON.parse(localStorage.getItem('closets')):[]
const favoriteHistory = localStorage.getItem('favorites') ?
                       JSON.parse(localStorage.getItem('favorites')):[]
const profileHistory = localStorage.getItem('profile') ?
                       JSON.parse(localStorage.getItem('profile')): []
const subscriptionHistory = localStorage.getItem('subscriptionInfo') ?
                        JSON.parse(localStorage.getItem('subscriptionInfo')): {plan: ''}
const browsingHistory = localStorage.getItem('browsingHistory') ?
                        JSON.parse(localStorage.getItem('browsingHistory')): []
const searchWordHistory = localStorage.getItem('searchWord') ?
                          JSON.parse(localStorage.getItem('searchWord')): []
const buyingHistory = localStorage.getItem('buying') ? 
                        JSON.parse(localStorage.getItem('buying')): []
const todoHistory = localStorage.getItem('todo') ?
                        JSON.parse(localStorage.getItem('todo')): []


const initialState = { 
    account: {'accountInfo':accountHistory},
    accountPublicInfo: {'accountPublicInfo':accountPublicInfoHistory},
    buying: {'buying': buyingHistory },
    cart: {'cart':cartIdHistory },
    cartItem: {'cartItems': cartHistory},
    closetList:{'closets':closetHistory},
    favoriteList:{'favorites':favoriteHistory},
    profile: {'profileInfo':profileHistory},
    subscriptionInfo:{'subscriptionInfo':subscriptionHistory},
    browsing:{'browsing_history': browsingHistory},
    searchWord:{'search_word': searchWordHistory},
    todo: {'todo': todoHistory}
} 

//apply middlewareをここに追加
const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store