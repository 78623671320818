import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//function
import { headRewrite } from '../function/headRewrite';

const useStyles = makeStyles((theme) => ({
    container:{
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center", 
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    line_url:{
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: '150px',
    },
    mail:{
        cursor: 'pointer',
        textAlign: 'center',
        textDecoration: "none",
    },
     root:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(1,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin:theme.spacing(2,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,15,15,15)
        }
    },
    subtitle:{
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5),
        textAlign: 'center'
    },
    title:{
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5)
        },
        [theme.breakpoints.up('sm')]:{
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10),
        },
        textAlign: 'center',
        fontWeight: 'bold'
    }
}))

function ContactScreen(){
    const classes = useStyles()

    const title = "お問い合わせ｜Enclopedia（アンクロペディア）【公式サイト】"
    const description = "Enclopedia（アンクロペディア）ファッションレンタルサービス問い合わせ"
    const keyword = "ファッションレンタルサービス、サブスクリプション、Enclopedia、アンクロペディア、問い合わせ"

    headRewrite(title, description, keyword)
    return(
        <>
            <div className={classes.root}>
                <Typography variant="h1" component="h1" className={classes.title}>
                    お問い合わせ
                </Typography>
                <div className={classes.container}>
                    <Typography
                    variant="h2"
                    align="center"
                    component="a" // 'a'タグとして機能させる
                    href="mailto:operation@enclopediai-official.com"
                    className={classes.mail}
                    >
                        operation@enclopediai-official.com
                    </Typography>
                </div>
                <Typography
                variant="h2"
                component="h2"
                className={classes.subtitle}
                >
                    LINEでのお問い合わせも受け付けております
                </Typography>
                <div style={{textAlign: 'center'}}>
                    <img className={classes.line_url} src="https://qr-official.line.me/gs/M_240yzqkh_BW.png?oat_content=qr"/>
                </div>
            </div>
        </>
    )
}

export default ContactScreen