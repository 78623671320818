import { 
    BROWSING_HISTORY_REQUEST,
    BROWSING_HISTORY_SUCCESS,
    BROWSING_HISTORY_FAIL,
    SEARCH_WORDS_HISTORY_REQUEST,
    SEARCH_WORDS_HISTORY_SUCCESS,
    SEARCH_WORDS_HISTORY_FAIL
} from "../constants/historyConstant";

export const browsingHistoryReducer = (state = {}, action) => {
    switch(action.type){
        case BROWSING_HISTORY_REQUEST:
            return {history_loading: true}
        case BROWSING_HISTORY_SUCCESS:
            return {history_loading: false, browsing_history: action.payload}
        case BROWSING_HISTORY_FAIL:
            return {history_loading: false, browsing_history: []}
        default:
            return state
    }
}

export const searchWordHistoryReducer = (state = [], action) => {
    switch(action.type){
        case SEARCH_WORDS_HISTORY_REQUEST:
            return {loading: true, search_word: []}
        case SEARCH_WORDS_HISTORY_SUCCESS:
            return {loading: false, search_word: action.payload.reverse()}
        case SEARCH_WORDS_HISTORY_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}