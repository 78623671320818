import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Link } from '@material-ui/core';
import { brand_origin_data_delete } from '../actions/categoryAction';

const useStyles = makeStyles((theme) => ({
    brand_name:{
        cursor: 'pointer',
        '&:hover':{
            backgroundColor:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        }
    },
    card:{
        display:'flex',
        backgroundColor: 'initial',
        boxShadow: 'initial',
        width: '100%'
    },
    card_action:{
        width: '100%'
    },
    card_content:{
        width: '100%'
    },
    media:{
        borderRadius:'50%'
    }
    
}))

//brandscreenを作成するためのcomponent

function BrandComponent({brand}){
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    //brandProductScreenに移動する
    const handleSubmit = () => {
        dispatch(brand_origin_data_delete())
        history.push(`/brand/${brand.brand_name}/?number=${brand.id}`)
        window.gtag('event','select_content',{
            content_type:brand.brand_name
        })
    }

    return(
        <Card key={brand.id} className={classes.card}>
            <CardActions component={Link} path={`/brand/${brand.brand_name}/?number=${brand.id}`} className={classes.card_action} onClick={handleSubmit} style={{color: 'black'}}>
                <CardContent className={classes.card_content}>
                    <Typography variant='subtitle1' component="p" align='center' className={classes.brand_name}>
                            {brand.brand_name}
                    </Typography>
                </CardContent>
            </CardActions>
        </Card>

    )

}

export default BrandComponent