import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
//使用できるカードの種類を記載する。
//productのトップ画像とimage_gallaryの複数画像を組み合わせて使用するように
const useStyles = makeStyles(() =>({
    swiper:{
        width:'100%',
    },
    image:{
        width:'100%',
        height:"100%",
        borderRadius:'1%'
    }
}))

const ImageGallaryComponent = () => {
    const {gallary_images} = useSelector(state => state.imageGallary)
    const {product} = useSelector(state => state.product)
    
    const classes = useStyles()
  return(
    <>
        <Swiper pagination={true} modules={[Pagination]} className={classes.swiper}>
            {product ?
            <SwiperSlide key={product.id}>
            <img src={product.img} className={classes.image} alt={product.product_name} />
            </SwiperSlide>
            :
            ''
            }
            {gallary_images ? gallary_images.map((image) => (
                <SwiperSlide key={image.id}>
                    <img src={image.original} className={classes.image} alt={product.product_name + image.id}/>
                </SwiperSlide>
            )):''}
        </Swiper>
    </>
  );
}

export default ImageGallaryComponent