import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core'

import { useFormik } from 'formik';
import * as yup from 'yup';

import { closet_update } from '../actions/closetAction';

const useStyles = makeStyles((theme) => ({
    button:{
        marginBottom: theme.spacing(2),
        color:'black',
        width:'100%',
        fontWeight:'bold',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        }
    },
    card:{
        color:'black',
        backgroundColor:'white'
    },
    close:{
        color:'black',
        padding:'initial'
    },
    container:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    field:{
        marginBottom: theme.spacing(2)
    },
    input_text:{
        color:'black'
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    }, 
    title:{
        textAlign:'center',
        fontWeight:'bold',
        marginBottom:theme.spacing(2)
    }   
}))

const validationSchema = yup.object().shape({
    closet_name:yup
        .string('')
        .max(30, '30文字以内で入力して下さい')
        .required('入力は必須です')
})

const ClosetUpdateComponent = ({closetInfo, handleClose}) => {
    const classes = useStyles()
    const dispatch = useDispatch()


    const formik = useFormik({
        initialValues:{
            closet_name: closetInfo.closet_name,
            description: closetInfo.description
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            dispatch(closet_update(closetInfo.id, values.closet_name, values.description))
            handleClose()
        }
    })


    return(
        <Container maxWidth="xs" className={classes.container}>
            {
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant='subtitle1' component="p" className={classes.title}>
                            クローゼット編集
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                            <TextField
                            fullWidth
                            id="closet_name"
                            label="クローゼット名"
                            variant="outlined"
                            color="primary"
                            autoFocus
                            className={classes.field}
                            value={formik.values.closet_name}
                            onChange={formik.handleChange}
                            error={formik.touched.closet_name && Boolean(formik.errors.closet_name)}
                                helperText={formik.touched.closet_name && formik.errors.closet_name}
                                InputLabelProps={{
                                    classes:{
                                        root: classes.input_text
                                    }
                                }}
                                InputProps={{
                                    classes:{
                                        notchedOutline:classes.text_field,
                                        root: classes.text_field
                                    }
                                }}
                            />
                            <TextField
                                fullWidth
                                id="description"
                                variant="outlined"
                                color="primary"
                                name="description"
                                label="説明"
                                multiline
                                rows={10}
                                className={classes.field}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && formik.errors.description}
                                helperText={formik.touched.description && formik.errors.description}
                                InputLabelProps={{
                                    classes:{
                                        root: classes.input_text
                                    }
                                }}
                                InputProps={{
                                    classes:{
                                        notchedOutline:classes.text_field,
                                        root: classes.text_field
                                    }
                                }}
                            />
                            <Button
                            type="submit"
                            className={classes.button}
                            >
                                作成
                            </Button>
                        </form>
                        <Button fullWidth onClick={handleClose} disableRipple disableFocusRipple  className={classes.close}>
                            閉じる
                        </Button>
                    </CardContent>
                </Card>
            }
            
        </Container>
    )
}

export default ClosetUpdateComponent