import axios from 'axios'

import {
    CHECKOUT_REQUEST,
    CHECKOUT_SUCCESS,
    CHECKOUT_FAILED,
    CARD_ELEMENT_SAVE,
    CARD_ELEMENT_DELETE,
    CARD_ELEMENT_DELETE_FAIL,
    CARD_ELEMENT_UPDATE_REQUEST,
    CARD_ELEMENT_UPDATE_SUCCESS,
    CARD_ELEMENT_UPDATE_FAIL,
    CREDIT_LIST_REQUEST,
    CREDIT_LIST_SUCCESS,
    CREDIT_LIST_FAIL,
    CREDIT_REGISTER_REQUEST,
    CREDIT_REGISTER_SUCCESS,
    CREDIT_REGISTER_FAIL,
    STRIPE_PLAN_REQUEST,
    STRIPE_PLAN_SUCCESS,
    STRIPE_PLAN_FAIL,
    SUBSCRIPTION_INFO_REQUEST,
    SUBSCRIPTION_INFO_SUCCESS,
    SUBSCRIPTION_INFO_FAIL,
    USER_SECRET_INFO_REQUEST,
    USER_SECRET_INFO_SUCCESS,
    USER_SECRET_INFO_FAIL,
    STRIPE_ELEMENT_REGISTER,
    SUBSCRIPTION_RESERVATION_REQUEST,
    SUBSCRIPTION_RESERVATION_SUCCESS,
    SUBSCRIPTION_PAYMENT_INFO_SAVE,
    SUBSCRIPTION_RESERVATION_FAIL,
    SUBSCRIPTION_REGISTER_REQUEST,
    SUBSCRIPTION_REGISTER_SUCCESS,
    SUBSCRIPTION_REGISTER_FAIL,
    RESERVATION_MESSAGE_CLEAR,
    SUBSCRIPTION_UPDATE_REQEUST,
    SUBSCRIPTION_UPDATE_SUCCESS,
    SUBSCRIPTION_UPDATE_FAIL,
    SUBSCRIPTION_CANCEL_REQUEST,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_FAIL,
    SUBSCRIPTION_UPCOMING_INVOICE_REQUEST,
    SUBSCRIPTION_UPCOMING_INVOICE_SUCCESS,
    SUBSCRIPTION_UPCOMING_INVOICE_FAIL,
    SUBSCRIPTION_INVOICE_REQUEST,
    SUBSCRIPTION_INVOICE_SUCCESS,
    SUBSCRIPTION_INVOICE_FAIL,
    INVOICE_REQUEST,
    INVOICE_SUCCESS,
    INVOICE_FAIL,

    INVOICE_CLEAR
 } from '../constants/stripeConstants'
import { baseAxios } from '../jwtInterceptor'

//subscriptionrentalプラン確定時のために保存
export const subscription_register = (email, price_id, plan, payment_method, price) => async(dispatch) => {
    dispatch({type:SUBSCRIPTION_REGISTER_REQUEST})
    const data = {
        'email': email,
        'price_id': price_id,
        'plan': plan,
        'payment_method_id': payment_method,
        'price': price
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/stripe/subscription/payment/', data).then(
        (response) => {
            dispatch({type:SUBSCRIPTION_REGISTER_SUCCESS, payload:response['data']})
            dispatch({type:INVOICE_CLEAR})
        }
    ).catch(
        () => {
            dispatch({
                type:SUBSCRIPTION_REGISTER_FAIL, 
                payload:  {                      
                'title' : '支払いに失敗しました。',
                'message':'支払いが受付けられませんでした、\
                お客様クレジットカードに問題がある場合がございます。\
                有効なクレジットカード情報を登録後、再度申し込み下さい。\
                問題が解決しない場合にはEnclopedia運営までご連絡下さい。\
                \n連絡先： operation@enclopediai-info.com'
                }
            })
        }
    )
}

//subscriptionプランを更新するためのaction
export const subscription_update = (plan, price) => async(dispatch) => {
    dispatch({type:SUBSCRIPTION_UPDATE_REQEUST})
    const data = {
        'plan': plan,
        'price_id': price
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.put('/api/stripe/subscription/payment/', data)
    .then(
        (response) => {
            dispatch({type:SUBSCRIPTION_UPDATE_SUCCESS, payload:response['data']})
        }
    ).catch(
        () => {
            dispatch({type:SUBSCRIPTION_UPDATE_FAIL, 
                payload: {'title' : 'サブスクリプションプラン変更に失敗しました',
                'message':'サブスクリプションの変更に失敗しました。\
                問題が解決しない場合はEnclopedia運営までご連絡下さい。\
                \n連絡先： operation@enclopediai-info.com'
            }
            })
        }
    )
}

//subscription planを解約するためのaction
export const subscription_cancel = () => async(dispatch) => {
    dispatch({type:SUBSCRIPTION_CANCEL_REQUEST})
    const data = {}
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.delete('/api/stripe/subscription/payment/', data).then(
        (response) => {
            dispatch({type:SUBSCRIPTION_CANCEL_SUCCESS, payload:response['data']})
        }
    ).catch(
        () => {
            dispatch({
                type:SUBSCRIPTION_CANCEL_FAIL, 
                payload:
                {'title' : 'サブスクリプションプラン解約に失敗しました',
                'message':'サブスクリプションの解約に失敗しました。\
                問題が解決しない場合はEnclopedia運営までご連絡下さい。\
                \n連絡先： operation@enclopediai-info.com'
            }})
        }
    )
}

//chekcour時にサブスクリプションの情報を保存しておくためのaction
export const subscription_rental_info_save = (payment_intent, email, price, plan, cart_id, address) => (dispatch) => {
    const data = {
        'payment_method': payment_intent,
        'email': email,
        'price':price,
        'plan':plan,
        'cart': cart_id,
        'address': address
    }
    dispatch({type:SUBSCRIPTION_PAYMENT_INFO_SAVE, payload: data})
}

//subscription登録者の契約プランについての情報を取得するためのaction
export const subscription_info_get = () => async(dispatch) => {
    dispatch({type: SUBSCRIPTION_INFO_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/stripe/subscription/user_info').then(
        (response) => {
            dispatch({type:SUBSCRIPTION_INFO_SUCCESS, payload:response['data']})
            localStorage.setItem('subscriptionInfo', JSON.stringify(response['data']))
        }
        
    ).catch(
        (error) => {
            dispatch({
                type: SUBSCRIPTION_INFO_FAIL,  
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

//subscription プランについての情報を返すためのaction
export const subscription_plan_info = () => async(dispatch) => {
    try{
        dispatch({
            type:STRIPE_PLAN_REQUEST
        })
        const config = {headers: {'Content-Type': 'application/json'}}
        const {data} = await axios.get(process.env.REACT_APP_URL + '/api/stripe/subscription/config/', config)
        dispatch({
            type: STRIPE_PLAN_SUCCESS, payload: data
        })
    }catch(error){
        dispatch({
            type:STRIPE_PLAN_FAIL, 
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//rentalプランのcheckoutようにinvoiceを取得
export const get_invoice = () => async(dispatch) => {
    dispatch({type:INVOICE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    baseAxios.get('/api/stripe/invoice').then(
        (response) => {
            dispatch({type:INVOICE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({type:INVOICE_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,})
        }
    )
}

//発行されたsubscription Invoiceを首都する
export const get_subscripiton_invoice = () => async(dispatch) => {
    dispatch({type:SUBSCRIPTION_INVOICE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/stripe/subscription/payment').then(
        (response) => {
            dispatch({type:SUBSCRIPTION_INVOICE_SUCCESS, payload: response.data})
        }
    ).catch(
        (error) => {
            dispatch({
                type:SUBSCRIPTION_INVOICE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

//将来の支払いに関してのdataを取得する
export const get_subscription_upcoming_invoice = () => async(dispatch) => {
    dispatch({type:SUBSCRIPTION_UPCOMING_INVOICE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/stripe/subscription/invoice/upcoming').then(
        (response) => {
            dispatch({type:SUBSCRIPTION_UPCOMING_INVOICE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type: SUBSCRIPTION_UPCOMING_INVOICE_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message
            })
        }
    )
}



//elementから取得したカード情報をstoreに保存確定時にcheckoutメソッドが実行されるようにする
//formの値を保存しておき最後の確定部分でクリアするようにする
//card番号は下四桁のみ表示するようにする
export const payment_method_save = (payment_method, email, card_number) => (dispatch) => {

    //card_numberした四桁のみを表示するようにする
    const data = {
        'payment_method': payment_method,
        'email': email,
        'card': card_number
    }

    dispatch({
        type: CARD_ELEMENT_SAVE, payload:  data
    })
}

//作成したpaymentmehotdをサブスクリプション登録ともに削除するようにする
export const payment_method_delete = (token, payment_method) => async(dispatch) => {
    try{
        const config = {headers:{'Content-Type': 'application/json', 'Authorization':`Bearer ${token}`}}
        const {data} = await axios.delete(process.env.REACT_APP_URL + 'api/stripe/secret_info', {'payment_method': payment_method}, config)
        dispatch({
            type:CARD_ELEMENT_DELETE, payload: data,
        })
    }catch(error){
        dispatch({
            type:CARD_ELEMENT_DELETE_FAIL,
            payload:error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })
    }
}

//subscriptionの一回払いについての処理をする
export const checkout = (email, address, payment_method, total_price, shipping_price, plan, cart_id, reserved_start_date, reserved_end_date, delivery, coupon='') => async(dispatch) => {
    dispatch({type:CHECKOUT_REQUEST})
        const data = {
            'email': email,
            'address': address,
            'cart_id': cart_id,
            'payment_method': payment_method,
            'total_price': total_price, 
            'shipping_price': shipping_price,
            'plan': plan,
            'reserved_start_date': reserved_start_date,
            'reserved_end_date': reserved_end_date,
            'delivery_time': delivery,
        }
        baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
        await baseAxios.post('/api/stripe/checkout/', data).then(
            (response) => {
                dispatch({type:CHECKOUT_SUCCESS, payload:response['data']})
                if(coupon){
                    baseAxios.post('/api/coupon/utilised/',{'coupon': coupon})
                }
            }
        ).catch(
            () => {
                dispatch({type:CHECKOUT_FAILED, 
                    payload: {
                        'title' : '支払いに失敗しました',
                        'message':`支払いが受付けられませんでした、\
                        商品詳細ページで商品の在庫状況を確認して下さい。 \
                        在庫がある場合にはお客様クレジットカードに問題がある場合がございます。\
                        有効なクレジットカード情報を登録後、再度申し込み下さい。\
                        問題が解決しない場合Enclopedia運営までご連絡下さい。\
                        \n連絡先： operation@enclopediai-info.com`
                    }
                })
            }
        )
}



//subscription用checkoutformのデータ登録
export const stripe_element_information = (email, plan, price, payment_method ) => async(dispatch) => {

    const data = {
        'email':email,
        'price':price,
        'plan': plan,
        'payment_method': payment_method
    }
    dispatch({
        type:STRIPE_ELEMENT_REGISTER,
        payload: data
    })
}

//stripeからクレジットカード情報を取得するためのaction
export const stripe_user_info = () => async(dispatch) => {
    dispatch({type:USER_SECRET_INFO_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/stripe/secret_info/')
        .then(
            (response) => {
                dispatch({type:USER_SECRET_INFO_SUCCESS, payload: response['data']})
            }
        ).catch(
            (error) => {
                dispatch({
                    type:USER_SECRET_INFO_FAIL, 
                    payload:error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
                })
            }
        )
    
}


//クレジットカードを登録する
export const stripe_card_register = (payment_method, email) => async(dispatch) => {
    dispatch({type:CREDIT_REGISTER_REQUEST})
    const data = {
        'email': email,
        'payment_method': payment_method,
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/stripe/secret_info/', data).then(
        (response) => {
            dispatch({type:CREDIT_REGISTER_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:CREDIT_REGISTER_FAIL, 
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            }) 
        }
    )
}


//登録したクレジットカード情報を更新する
export const stripe_card_update = (payment_method) => async(dispatch) => {
    dispatch({type:CARD_ELEMENT_UPDATE_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.put('/api/stripe/secret_info/',
    {'payment_method': payment_method})
    .then(
        (response) => {
            dispatch({type:CARD_ELEMENT_UPDATE_SUCCESS, payload: response['data']})
        }
    ).catch(
        (error) => {
            dispatch({
                type:CARD_ELEMENT_UPDATE_FAIL,
                payload:error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )

}


//plan別の予約するためのaction
export const subscription_reservation = (address, reserved_start_date, reserved_end_date, plan, contract_date, cart, delivery="指定なし") => async(dispatch) => {
    dispatch({type: SUBSCRIPTION_RESERVATION_REQUEST})
    const data = {
        'address': address,
        'reserved_start_date': reserved_start_date,
        'reserved_end_date': reserved_end_date,
        'plan': plan,
        'contract_date': contract_date,
        'cart': cart,
        'delivery_time': delivery
    }
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.post('/api/reservation/item/', data)
    .then(
        (response) => { 
            dispatch({type:SUBSCRIPTION_RESERVATION_SUCCESS, payload: response['data']})
        }
    ).catch(
        () => {
            dispatch({
                type:SUBSCRIPTION_RESERVATION_FAIL,
                payload:{
                    'title' : '支払いに失敗しました',
                    'message':'支払いが受付けられませんでした、\
                    現在在庫切れの場合がございます、商品詳細ページで商品の在庫状況を確認して下さい。\
                    問題が解決しない場合はEnclopedia運営までご連絡下さい。\
                    \n連絡先： operation@enclopediai-info.com'
                }
            })
        }
    )
}

//クレジット情報をリストで取得する
export const credit_list = () => async(dispatch) => {
    dispatch({type:CREDIT_LIST_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('/api/stripe/credit/').then(
        (response) => {
            dispatch({type:CREDIT_LIST_SUCCESS, payload: response['data']})
        }
    ).catch(
        () => {
            dispatch({
                type:CREDIT_LIST_FAIL,
                payload:{
                    'message': 'カード情報の取得に失敗しました'
                }
            })
        }
    )
}
//支払い後確認後のmessgeのクリアを行う

export const reservation_message_clear = () => (dispatch) => {
    dispatch({type:RESERVATION_MESSAGE_CLEAR, payload: ''})
}