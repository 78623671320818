import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { BsChevronLeft } from 'react-icons/bs';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Container } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { TextField } from '@material-ui/core';
//action method 
import { create_closet } from '../actions/closetAction';

//import formik
import { useFormik } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles((theme) => ({
    button:{
        marginBottom: theme.spacing(2),
        color:'black',
        width:'100%',
        fontWeight:'bold',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        }
    },
    card:{
        zIndex:4000,
        backgroundColor: 'white',
        color:'black',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    closet_modal:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        padding: 'initial'
    },
    icon_button:{
        '&:hover':{
            backgroundColor:'initial'
        },
        padding:'initial'
    },
    inner:{
        margin:theme.spacing(3)
    },
    input_text:{
        color:'black'
    },
    field:{
        marginBottom: theme.spacing(2)
    },
    postalcode:{
        marginBottom:theme.spacing(1)
    },
    title:{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign:'center',
        fontWeight:'bold'
    },
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },    
}))

const validationSchema = yup.object().shape({
    closet_name: yup
        .string('')
        .max(30, '30文字以内で入力して下さい')
        .required('入力は必須です'),
    description: yup
    .string('')
    .required('入力は必須です')
})

const ClosetCreateComponent = ({changeState,  productId}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    //formik
    //closetを作成した際にはclothも登録する必要がある
    const formik = useFormik({
        initialValues:{
            closet_name:'',
            description:'',
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            dispatch(create_closet(values.closet_name, values.description, productId))
            changeState(false)
        }
    },)

    const handleClose = () => {
        changeState(false)
    }

    return(
        <Container maxWidth={"sm"} className={classes.closet_modal}>
            <Card className={classes.card}>
                <CardContent>
                    <IconButton
                     onClick={handleClose}
                     disableRipple={true}
                     disableFocusRipple={true}
                     className={classes.icon_button}
                     size="medium"
                     color="inherit"
                     >
                         <BsChevronLeft/>
                     </IconButton>
                    <Typography variant="subtitle1" component="p" className={classes.title} style={{textAlign:'center', fontWeight:'bold'}}>
                        クローゼットを新規作成
                    </Typography>
                        <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            id="closet_name"
                            name="closet_name"
                            label="クローゼット名"
                            variant="outlined"
                            color="primary"
                            autoFocus
                            className={classes.field}
                            value={formik.values.closet_name}
                            onChange={formik.handleChange}
                            error={formik.touched.closet_name && Boolean(formik.errors.closet_name)}
                            helperText={formik.touched.closet_name && formik.errors.closet_name}
                            InputLabelProps={{
                                classes:{
                                    root: classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                }
                            }}
                        />
                        <TextField
                            fullWidth
                            id="description"
                            variant="outlined"
                            color="primary"
                            name="description"
                            label="説明"
                            multiline
                            rows={11}
                            className={classes.field}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            error={formik.touched.description && formik.errors.description}
                            helperText={formik.touched.description && formik.errors.description}
                            InputLabelProps={{
                                classes:{
                                    root: classes.input_text
                                }
                            }}
                            InputProps={{
                                classes:{
                                    notchedOutline:classes.text_field,
                                    root: classes.text_field
                                }
                            }}
                        />
                        <Button
                        type="submit"
                        color="primary"
                        className={classes.button}
                        >
                                作成
                        </Button>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}

export default ClosetCreateComponent
