import { 
    FEATURED_PRODUCT_REQUEST,
    FEATURED_PRODUCT_SUCCESS,
    FEATURED_PRODUCT_FAIL,
    FEATURED_BRAND_REQUEST,
    FEATURED_BRAND_SUCCESS,
    FEATURED_BRAND_FAIL
} from "../constants/analyticsConstants";

export const featuredProductReducer = (state={features: []}, action) => {
    switch(action.type){
        case FEATURED_PRODUCT_REQUEST:
            return {loading: true, features: []}
        case FEATURED_PRODUCT_SUCCESS:
            return {loading: false, features: action.payload}
        case FEATURED_PRODUCT_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const featureBrandReducer = (state={feature_brand: []}, action) => {
    switch(action.type){
        case FEATURED_BRAND_REQUEST:
            return {brand_loading: true, feature_brand:[]}
        case FEATURED_BRAND_SUCCESS:
            return {brand_loading: false, feature_brand: action.payload}
        case FEATURED_BRAND_FAIL:
            return {brand_loading: false, feature_brand:[]}
        default:
            return state
    }
}