import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { headRewrite } from "../function/headRewrite";

const useStyles = makeStyles((theme) => ({
    button:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        backgroundColor: 'white'
    },
    content:{
        whiteSpace: 'pre-line',
        lineHeight: '25px'
    },
    divider:{
        marginTop:theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(10,3,15,3)
        }
    },
    subtitle:{
        marginBottom: theme.spacing(2),
        fontWeight: 'bold'
    },
    title:{
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },

}))

export default function ReservationHelpScreen(){
    const classes = useStyles()
    const history = useHistory()

    const handleClick = () => {
        history.push('/subscription/plan')
    }

    const title = 'ヘルプセンター（レンタルまでの流れ）｜Enclopedia【公式サイト】'
    const description = 'Enclopedia(アンクロペディア）ファッションレンタルサービスに関するレンタルまでの流れについてのサポート'
    headRewrite(title, description)

    return(
        <div className={classes.root}>
            <Container maxWidth={"md"} style={{padding: 'initial'}}>
                <Typography variant="h1" component="h1" className={classes.title} align="center">
                    レンタルまでの流れ
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    {"アイテムの予約に関してはログインが完了しているユーザのみアイテム詳細からアイテムリストへアイテムを追加いただけます。\
                    \nアイテムのレンタル数の制限などは各プランにより異なりますので以下をご確認ください。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" align="center"  className={classes.subtitle}>
                    プラン別のレンタルに関するルール
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    {"Enclopediaファッションレンタルサービスではプラン別にレンタル内容がことなります,\
                    サブスクリプションのプラン内容に関してはサブスクリプション登録ページでご確認ください。\
                    \nここではアカウント登録と同時に登録されるプラン（登録料はかかりません）のレンタルプランに関して説明いたします。"}
                </Typography>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Button className={classes.button} onClick={handleClick}>
                        サブスクリプションプラン詳細
                    </Button>
                </div>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    {"レンタルプランの場合一回の予約でまとめて3着までお好きなものをお選びいただけます。\
                    \n商品到着日と返却日を含まない１０日間（汚れてしまった場合に汚れが酸化しケアが難しくなることを考慮した期間となっております、\
                    次のお客様にも気持ちよく使っていただくためにご了承下さい。）借りることができます、配送に各商品詳細ページでご確認ください(離島の場合や、天候などの影響により遅れる場合がございます)。\
                    \nアイテムをレンタルできる期間に関しましてはどのプランでも10日間(到着日と返却日を含まない)となっております。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" align="center"  className={classes.subtitle}>
                    アイテムを選ぶ
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    {"レンタルできる商品はプランごとに異なります。\
                    \nプレミアムプランに登録しているユーザのみレンタルできる商品がございますので\
                    商品詳細ページを確認いただくか,もしくはチェックボックス貸出可能な商品を押しチェックを入れていただくことでご登録いただいているプランで貸出可能な商品のみ表示ができます。\
                    \nレンタルプランではベーシックとレンタルプランの商品をお好きなアイテムを１回に３着までレンタルすることができます。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
                <Typography variant="h2" component="h2" align="center" className={classes.subtitle}>
                    全ての商品返却後に次のレンタルができる
                </Typography>
                <Typography variant="subtitle1" component="p" className={classes.content}>
                    {"レンタルした商品は購入したアイテム以外の商品を全て返却する必要がございます、返却がEnclopedia運営で確認でき次第次のレンタルが可能となります。\
                    \nレンタルプランには月に貸し出す回数に制限はありませんので返却後何度でもお好きな商品をレンタルすることができます。"}
                </Typography>
                <Divider classes={{root:classes.divider}}/>
            </Container>
        </div>
    )
}