import {
    SET_NEXT_STEP,
    SET_BACK_STEP,
    SET_RESET_STEP
 } from "../constants/checkout";

 export const checkoutStepReducer = (state = {step: 0}, action) => {
     switch(action.type){
         case SET_NEXT_STEP:
             return {step: action.payload}
         case SET_BACK_STEP:
             return {step: action.payload}
         case SET_RESET_STEP:
             return {step: action.payload}
         default:
             return state
     }
 }