import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';

//component
import Loader from '../components/Loader';
import FavoriteComponent from '../components/FavoriteProductComponent';

//action
import { favorite_list } from '../actions/favoriteAction';

const useStyles = makeStyles((theme) => ({
    button:{
        '&:hover':{
            backgroundColor: 'initial'
        }
    },
    container:{
        padding: 'initial'
    },
    error_container:{
        height:'100vh'
    },
    error_title:{
        marginTop: theme.spacing(3)
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(1,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin:theme.spacing(2,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3,3,15,3)
        }
    },
    title:{
        marginBottom: theme.spacing(3)
    },
    topic_path:{
        display: 'flex',
        marginBottom: theme.spacing(3)
    }
}))

const FavoriteProductScreen = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const {favorite_loading, favorites, favorite_error} = useSelector(state => state.favoriteList)

    const handleClick = () => {
        history.push('/management')
    }

    useEffect(() => {
        dispatch(favorite_list())
    },[dispatch])

    return(
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={ handleClick } className={classes.button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p">
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2, cursor:'pointer'}}>
                    {'お気に入り商品一覧'}
                </Typography>
            </div>
            {
                favorite_loading ?
                <div className={classes.loader}>
                    <Loader/>
                </div>
                :
                favorite_error ?
                <div className={classes.error_container}>
                    <Typography className={classes.error_title} variant="subtitle1" component="div">
                        お気に入りした商品がありません
                    </Typography>
                </div>
                :
                <div>
                    <Typography variant="h1" component="h1" className={classes.title}>
                        お気に入り商品
                    </Typography>
                    {
                    favorites.length === 0 ?
                        <div className={classes.error_container}>
                            <Typography className={classes.error_title} variant="subtitle1" component="p">
                                お気に入りした商品がありません
                            </Typography>
                        </div>
                    :
                        <Grid container spacing={2}>
                            {favorites.map(favorite => (
                                <Grid key={favorite.product.id} item xs={6} sm={6} md={3} lg={3} xl={3}>
                                    <FavoriteComponent favorite={favorite}/>
                                </Grid>
                            ))}
                        </Grid>
                    }
                </div>
            }
        </div>
    )
}

export default FavoriteProductScreen