import React, {useEffect} from "react";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {Table} from '@material-ui/core'
import { TableBody } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import { headRewrite } from "../function/headRewrite";
const useStyles = makeStyles((theme) => ({
    cell:{
        color:'black'
    },
    card:{
        color:'black',
        backgroundColor:'white'
    },
    description:{
        color:'black'
    },
    inner:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(10,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(10,2,15,2)
        },
    },
    table:{
        color:'black',
        backgroundColor:'white'
    },
    subtitle:{
        fontWeight:'bold',
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    title:{
        paddingTop:theme.spacing(2)
    },
    root:{
    }
}))

const SpecifiedCommercialScreen = () => {
    const classes = useStyles()

    const title = "特定商取引法に基づく表記｜Enclopedia【公式サイト】"
    const description = "ファッションレンタルサービスEnclopedia(アンクロペディア)の特定商取引法に関するページです"
    useEffect(() => {
        headRewrite(title, description)
    },[])
    return(
        <Container maxWidth={"md"} style={{padding: 'initial'}}>
            <div className={classes.inner}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="subtitle1" component="p" align="right" className={classes.subtitle}>
                            Enclopedia運営
                        </Typography>
                        <Typography className={classes.title} variant="subtitle1" component="p" align="center">
                            特定商取引法基づく表記
                        </Typography>
                        <Table aria-label="simpla table" className={classes.table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.cell} component="th" scope="row">
                                        事業代表者氏名
                                    </TableCell>
                                    <TableCell  className={classes.description}>
                                        山口尚希
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}  component="th" scope="row">
                                        住所
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        東京都八王子市明神町4丁目２９丁目３号 メゾンYOSHIDA 3-A
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell} l component="th" scope="row">
                                        お問い合わせ先
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        operation@enclopediai-info.com
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell  className={classes.cell} component="th" scope="row">
                                        サービスの価格
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        月額料金：<br/>
                                        1 レンタルプラン レンタルする商品ごとに料金をお支払い<br/>
                                        2 ベーシックプラン 7980円（税込）<br/>
                                        3 プレミアムプラン 14980（税込）<br/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}  component="th" scope="row">
                                        ご利用条件:
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        サービスご利用者様がレンタルできる回数とアイテム数は、本サービスに定めるプランの内容により異なります。<br/>
                                        各プランの内容は以下も通りとなっております。<br/>
                                        1 レンタルプラン レンタル回数 制限なし アイテム数 3着<br/>
                                        2 ベーシックプラン レンタル回数 4回 アイテム数 3着<br/>
                                        3 プレミアムプラン レンタル回数 制限なし アイテム数 4着<br/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}   scope="row">
                                        販売URL
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        https://www.enclopedia-official.com
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}   scope="row">
                                        お支払い方法
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        クレジットカード決済<br/>
                                        利用可能カード: VISA, Master, AMEXの3種類となります。
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}  component="th" scope="row">
                                        商品の引き渡し
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        ご注文を当社で確認後、通常３日前ヤマト運輸いてお届けいたします。<br/>
                                        配送遅延によりお届けが遅れる場合もございますのでご了承下さい。
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell}  component="th" scope="row">
                                        自動解約について
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        ご登録をしたクレジットカードが有効でない場合、料金未払いの場合は、
                                        または本サービスが定める利用規約に違反した場合、有料プランを自動的にキャンセルする可能性がございますことをご了承下さい。
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell} component="th" scope="row">
                                        定額払い、商品以外の必要金額
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        配送手数料：サイズに応じた料金を設定（商品詳細ページで確認)
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.cell} component="th" scope="row">
                                        申し込み有効期限
                                    </TableCell>
                                    <TableCell className={classes.description}>
                                        特にありません
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}

export default SpecifiedCommercialScreen