export const CLOSET_LIST_REQUEST = 'CLOSET_LIST_REQUEST'
export const CLOSET_LIST_SUCCESS = 'CLOSET_LIST_SUCCESS'
export const CLOSET_LIST_FAIL = 'CLOSET_LIST_FAIL'

export const CLOSET_CREATE_REQUEST = 'CLOSET_CREATE_REQUEST'
export const CLOSET_CREATE_SUCCESS = 'CLOSET_CREATE_SUCCESS'
export const CLOSET_CREATE_FAIL = 'CLOSET_CREATE_FAIL'

export const CLOSET_DELETE_REQUEST = 'CLOSET_DELETE_REQUEST'
export const CLOSET_DELETE = 'CLOSET_DELETE'
export const CLOSET_DELETE_FAIL = 'CLOSET_DELETE_FAIL'

export const CLOSET_UPDATE_REQUEST = 'CLOSET_UPDATE_REQUEST'
export const CLOSET_UPDATE = 'CLOSET_UPDATE'
export const CLOSET_UPDATE_FAIL = 'CLOSET_UPDATE_FAIL'

export const BASIC_CLOTH_CREATE_REQUEST = 'BASIC_CLOTH_CREATE_REQUEST'
export const BASIC_CLOTH_CREATE_SUCCESS = 'BASIC_CLOTH_CREATE_SUCCESS'
export const BASIC_CLOTH_CREATE_FAIL = 'BASIC_CLOTH_CREATE_FAIL'

export const PREMIUM_CLOTH_CREATE_REQUEST = 'PREMIUM_CLOTH_CREATE_REQUEST'
export const PREMIUM_CLOTH_CREATE_SUCCESS = 'PREMIUM_CLOTH_CREATE_SUCCESS'
export const PREMIUM_CLOTH_CREATE_FAIL = 'PREMIUM_CLOTH_CREATE_FAIL'

export const CLOTH_LIST_REQUEST = 'CLOTH_LIST_REQUEST'
export const CLOTH_LIST_SUCCESS ='CLOTH_LIST_SUCCESS'
export const CLOTH_LIST_FAIL = 'CLOTH_LIST_FAIL'

export const CLOTH_DELETE_REQUEST = 'CLOTH_DELETE_REQUEST'
export const CLOTH_DELETE_SUCCESS = 'CLOTH_DELETE_SUCCESS'
export const CLOTH_DELETE_FAIL = 'CLOTH_DELETE_FAIL'

export const CLOSET_GET_REQUEST = 'CLOSET_GET_REQUEST'
export const CLOSET_GET_SUCCESS = 'CLOSET_GET_SUCCESS'
export const CLOSET_GET_FAIL = 'CLOSET_GET_FAIL'

export const CLOSET_REGISTER_CART_REQUEST = "CLOSET_REGISTER_CART_REQUEST"
export const CLOSET_REGISTER_CART_SUCCESS = "CLOSET_REGISTER_CART_SUCCESS"
export const CLOSET_REGISTER_CART_FAIL = "CLOSET_REGISTER_CART_FAIL"
