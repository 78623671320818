import React,{useState, useEffect,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { ClickAwayListener } from '@material-ui/core';
import { Grow } from '@material-ui/core';
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { IconButton } from '@material-ui/core'
import {makeStyles, createStyles} from '@material-ui/core/styles'
import { Menu } from '@material-ui/icons';
import { MenuItem } from '@material-ui/core'
import { MenuList } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { Paper } from '@material-ui/core'
import { Popper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';

//action
import { search_evaluation_product } from '../actions/productActions'
import { search_evaluation_product_desc } from '../actions/productActions'
import { search_favorite_product } from '../actions/productActions'
import { search_favorite_product_desc } from '../actions/productActions'
import { search_products } from '../actions/productActions'
import { search_reservatoin} from '../actions/productActions'
import { search_reservation_desc } from '../actions/productActions'
import { rental_filter_search_product } from '../actions/productActions'
import { search_product_price } from '../actions/productActions'
import { search_product_price_desc } from '../actions/productActions'
import { search_category } from '../actions/productActions'
import { search_category_name } from '../actions/productActions'
import { search_type } from '../actions/productActions'
import { search_type_name } from '../actions/productActions'
import { search_word_get } from '../actions/historyAction'
//filter
import { search_gender_filter } from '../actions/productActions'
import { search_gender_data_save } from '../actions/productActions'
import { type_get } from '../actions/categoryAction'

//component
import Category from '../components/Category';
import Loader from '../components/Loader';
import Product from '../components/Product';
import PageSwitchingComponent from '../components/PageSwitchingComponent';

//import gtag function
import { pageView } from '../function/gaTraking'


const useStyles = makeStyles((theme) => createStyles({
    all:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial',
        minWidth: '43px'
    },
    button:{
        backgroundColor:'white',
        color: 'black',
        marginRight: theme.spacing(2)
    },
    checkbox:{
        marginTop: theme.spacing(2),
        marginLeft:'auto',
        display:'flex'
    },
    container :{
        width: '100%',
    },
    error_container:{
        height:'100vh',
        marginTop: theme.spacing(3)
    },
    filter:{
        display:'flex',
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    female:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial'
    },
    gender:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
        
    },
    image:{
        position: 'relative'
    },
    icon_button:{
        paddingTop:'initial',
        paddingBottom: 'initial',
        '&:hover':{
            backgroundColor:'initial'
        }
    },
    inner:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,0,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,0,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,0,3)
        }
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'25%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    male:{
        backgroundColor: 'initial',
        boxShadow: 'initial',
        '&:hover':{
            backgroundColor: 'initial',
            boxShadow: 'initial',
            borderRadius:'initial',
            borderBottom: `solid 1px ${theme.palette.primary['400']}`,
        },
        padding: 'initial',
    },
    modal:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        width:'60%',
        backgroundColor: 'white',
        color:'black',
        boxShadow:20,
        borderRadius:5,
    },
    order:{
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop:theme.spacing(1),
    },
    papper:{
        zIndex: '1000'
    },
    paper:{
        backgroundColor: 'white',
        color: 'black'
    },
    pagination:{
        margin: theme.spacing(3,0,2,0)
    },
    product_contaienr:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(15),
        padding: 'initial'
    },
    title:{
        margin:theme.spacing(0, 0, 1, 1)
    }
}))

function SearchProductScreen(){
    const classes = useStyles()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const {id} = useParams()

    const params = new URLSearchParams(location.search)
    const param = params.get('page') == null ? undefined : Number(params.get('page'))
    const param_title = location.search.split('=')[2] ? decodeURI(location.search.split('=')[2]) : undefined

    const orderRef = useRef(null)

    //google analytics

    //useState
    const [orderOpen, setOrderOpen] = useState(false)
    const [title, setTitle] = useState(param_title == undefined ? '新着' : param_title)
    const [checked, setChecked] = useState(false)
    const [page, setPage] = useState(1)

    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {categorys} = useSelector(state => state.category)
    const {closets} = useSelector(state => state.closetList)
    const {error, loading, products} = useSelector(state => state.searchProducts)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)

    const handleTitle = (title) => {
        setTitle(title)
    }
    //gender filter
    const handleGenderFilter = (gender) => {
        setChecked(false)
        dispatch(search_gender_filter(gender))
    }
 
    //並び替えメニュー
    const handleOrderToggle = () => {
        setOrderOpen((prevOrderOpen) => !prevOrderOpen)
    }

    const handleOrderClose = () => {
        setOrderOpen(false)
    }

    const listDownHandler = (event) => {
        if(event.key === 'Tab'){
            event.preventDefault();
            setOrderOpen(false)
        }
    }

    //レンタル可能な商品のみ表示する
    const handleRentalFilter = (check) => {
        setChecked((prevChecked) => !prevChecked)
        dispatch(rental_filter_search_product(check, subscriptionInfo.plan))
    }

    const handleCategory = () => {
        setChecked(false)
    }

    //全て
    const handleProduct = async() => {
        history.push(`/search/${id}/?page=1&title=新着`)
        setPage(1)      
        setChecked(false)
        setTitle('新着')
        await dispatch(search_products(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }
    //評価高い順
    const handleEvaluation = async() => {
        history.push(`/search/${id}/?page=1&title=評価が高い順`)
        setPage(1)
        setChecked(false)
        setTitle('評価が高い順')
        await dispatch(search_evaluation_product(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }
    //評価が低い順
    const handleEvaluationDesc = async() => {
        history.push(`/search/${id}/?page=1&title=評価が低い順`)
        setPage(1)
        setChecked(false)
        setTitle('評価が低い順')
        await dispatch(search_evaluation_product_desc(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }
    //お気に入りが多い順
    const handleEvaluationFavorite = async() => {
        history.push(`/search/${id}/?page=1&title=お気に入り数が多い順`)
        setPage(1)
        setChecked(false)
        setTitle('お気に入り数が多い順')
        await dispatch(search_favorite_product(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }
    //お気に入りが少ない順
    const handleEvaluationFavoriteDesc = async() => {
        history.push(`/search/${id}/?page=1&title=お気に入り数が少ない順`)
        setPage(1)
        setChecked(false)
        setTitle('お気に入り数が少ない順')
        await dispatch(search_favorite_product_desc(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }
    //予約数の多い順
    const handleReservation = async() => {
        history.push(`/search/${id}/?page=1&title=予約数が多い順`)
        setPage(1)
        setChecked(false)
        setTitle('予約数が多い順')
        await dispatch(search_reservatoin(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }
    //予約数の少ない順
    const handleReservationDesc = async() => {
        history.push(`/search/${id}/?page=1&title=予約数が少ない順`)
        setPage(1)
        setChecked(false)
        setTitle('予約数が少ない順')
        await dispatch(search_reservation_desc(id, 1))
        dispatch(search_gender_data_save())
        dispatch(type_get())
    }

    //料金の安い順
    const handlePrice = async() => {
        history.push(`/search/${id}/?page=1&title=料金が安い順`)
        setPage(1)
        setChecked(false)
        setTitle('料金が安い順')
        dispatch(search_product_price(id, 1))
        dispatch(type_get())
    }

    //料金の高い順
    const handlePriceDesc = async() => {
        history.push(`/search/${id}/?page=1&title=料金が高い順`)
        setPage(1)
        setChecked(false)
        setTitle('料金が高い順')
        dispatch(search_product_price_desc(id, 1))
        dispatch(type_get())
    }

    const handlePagination = async(event, value) => {
        event.preventDefault()
        setPage(value)
        setChecked(false)
        switch(title){
            case '新着':
                history.push(`/search/${id}/?page=${value}&title=新着`)
                await dispatch(search_products(id, value))
                dispatch(search_gender_data_save())
                return
            case '評価が高い順':
                history.push(`/search/${id}/?page=${value}&title=評価が高い順`)
                await dispatch(search_evaluation_product(id, value))
                dispatch(search_gender_data_save())
                return
            case '評価が低い順':
                history.push(`/search/${id}/?page=${value}&title=評価が低い順`)
                await dispatch(search_evaluation_product_desc(id, value))
                dispatch(search_gender_data_save())
                return
            case 'お気に入り数が多い順':
                history.push(`/search/${id}/?page=${value}&title=お気に入り数が多い順`)
                await dispatch(search_favorite_product(id, value))
                dispatch(search_gender_data_save())
                return
            case 'お気にりい数が少ない順':
                history.push(`/search/${id}/?page=${value}&title=お気にりい数が少ない順`)
                await dispatch(search_favorite_product_desc(id, value))
                dispatch(search_gender_data_save()) 
                return
            case '予約数が多い順':
                history.push(`/search/${id}/?page=${value}&title=予約数が多い順`)
                await dispatch(search_reservatoin(id, value))
                dispatch(search_gender_data_save())
                return
            case '予約数が少ない順':
                history.push(`/search/${id}/?page=${value}&title=予約数が少ない順`)
                await dispatch(search_reservation_desc(id, value))
                dispatch(search_gender_data_save())
                return
            case '料金が安い順':
                history.push(`/search/${id}/?page=${value}&title=料金が安い順`)
                await dispatch(search_product_price(id, value))
                dispatch(search_gender_data_save())
                return
            case '料金が高い順':
                history.push(`/search/${id}/?page=${value}&title=料金が高い順`)
                await dispatch(search_product_price_desc(id, value))
                dispatch(search_gender_data_save())
                return
            case 'トップス' || 'ジャケット' || 'コート' || 'パンツ' || 'スカート' || 'ワンピース' || 'スーツ' || 'バッグ' || 'シューズ' || 'アクセサリー' || 'ルームウェア' || 'その他':
                history.push(`/search/${id}/?page=${value}&title=${title}`)
                {
                    if(categorys != undefined){
                        const category = categorys.find((cate) => {
                            cate.category_name === title
                        })
                        dispatch(search_type(id, category.id, param == undefined ? 1 : param))
                    }
                }
                setChecked(false)
                dispatch(search_gender_data_save())
                return
            default:
                history.push(`/search/${id}/?page=${value}&title=${title}`)
                {
                    setChecked(false)
                    if(categorys != undefined){
                        const category = categorys.find((cate) => {
                            cate.category_name === title
                        })
                        dispatch(search_category(id, category.id, value))
                    }
                }
                dispatch(search_gender_data_save())
                return
        }
    }

    const handlePageUpdate = (number) => {
        setPage(number)
    }

    useEffect(() => {
        setTitle('新着')
        pageView(`search:${id}`, location.pathname)
        if(accountInfo){
            dispatch(search_word_get())
        }

        switch(param_title){
            case undefined:
            case '新着':
                dispatch(search_products(id, param == undefined ? 1 : param))
                setTitle('新着')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '評価が高い順':
                dispatch(search_evaluation_product(id, param == undefined ? 1 : param))
                setTitle('評価が高い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '評価が低い順':
                dispatch(search_evaluation_product_desc(id, param == undefined ? 1 : param))
                setTitle('評価が低い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case 'お気に入り数が多い順':
                dispatch(search_favorite_product(id, param == undefined ? 1 : param))
                setTitle('お気に入り数が多い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case 'お気にりい数が少ない順':
                dispatch(search_favorite_product_desc(id, param == undefined ? 1 : param))
                setTitle('お気にりい数が少ない順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '予約数が多い順':
                dispatch(search_reservatoin(id, param == undefined ? 1 : param))
                setTitle('予約数が多い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '予約数が少ない順':
                dispatch(search_reservation_desc(id, param == undefined ? 1 : param))
                setTitle('予約数が少ない順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '料金が安い順':
                dispatch(search_product_price(id, param == undefined ? 1 : param))
                setTitle('料金が安い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case '料金が高い順':
                dispatch(search_product_price_desc(id, param == undefined ? 1 : param))
                setTitle('料金が安い順')
                setPage(param == undefined ? 1 : param)
                dispatch(type_get())
                return
            case 'トップス':
            case 'ジャケット':
            case 'コート':
            case 'パンツ':
            case 'スカート':
            case 'ワンピース':
            case 'スーツ':
            case 'バッグ':
            case 'シューズ':
            case 'アクセサリー':
            case 'ルームウェア':
            case 'その他':
                setTitle(param_title)
                dispatch(search_type_name(id, param_title, param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                return
            default:
                setTitle(param_title)
                dispatch(search_category_name(id, param_title, param == undefined ? 1 : param))
                setPage(param == undefined ? 1 : param)
                return
                
        }
    }, [location.search])

    return(
            <div className={classes.container}>
                <PageSwitchingComponent param={id}/>
                <Category keyword={id} handle={handleCategory} handleTitle={handleTitle} pagination={handlePageUpdate}/>

                <div className={classes.filter}>
                    <Box className={classes.order}>
                        <div>
                            <Typography variant="subtitle2" component="p" style={{textAlign:'center', padding:'initial'}}>並び替え</Typography>
                            <IconButton 
                            style={{zIndex:3500}}
                            ref={orderRef}
                            color="inherit"
                            className={classes.icon_button}
                            size="medium"
                            aria-label='order_menu'
                            onClick={handleOrderToggle}
                            >
                                <Menu/>
                            </IconButton>
                        </div>
                        <Popper style={{zIndex:1000}} open={orderOpen} anchorEl={orderRef.current} role={undefined} transition disablePortal >
                            {({ TransitionProps}) => (
                                <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: 'left top' }}
                                >
                                    <Paper className={classes.paper}>
                                        <ClickAwayListener onClickAway={handleOrderClose}>
                                            <MenuList autoFocusItem={orderOpen} onKeyDown={listDownHandler}>
                                                <MenuItem keys="normal_order" onClick={handleProduct}>
                                                    <Typography variant="subtitle1" component="p">新着</Typography>
                                                </MenuItem>
                                                <MenuItem keys="evaluate_order" onClick={handleEvaluation}>
                                                    <Typography variant="subtitle1" component="p">評価が高い順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="evaluate_order_desc" onClick={handleEvaluationDesc}>
                                                    <Typography variant="subtitle1" component="p">評価が低い順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="favorite_order" onClick={handleEvaluationFavorite}>
                                                    <Typography variant="subtitle1" component="p">お気に入りが多い順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="favorite_order_desc" onClick={handleEvaluationFavoriteDesc}>
                                                    <Typography variant="subtitle1" component="p">お気に入りが少ない順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="reservation_order" onClick={handleReservation}>
                                                    <Typography variant='subtitle1' component="p">予約数が多い順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="reservation_order_desc" onClick={handleReservationDesc}>
                                                    <Typography variant='subtitle1' component="p">予約数が少ない順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="price_order" onClick={handlePrice}>
                                                    <Typography variant='subtitle1' component="p">料金が安い順</Typography>
                                                </MenuItem>
                                                <MenuItem keys="price_order_desc" onClick={handlePriceDesc}>
                                                    <Typography variant='subtitle1' component="p">料金が高い順</Typography>
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Box>
                    {
                        error ?
                        ''
                        :
                        <Box className={classes.checkbox}>
                        <Typography variant="subtitle2" component="p" style={{marginTop:18,}}>貸出可能な商品</Typography>
                        <Checkbox
                        checked={checked}
                        onChange={(event) => {handleRentalFilter(event.target.checked)}} 
                        color='#bdbdbd'
                        style={{backgroundColor:'initial'}}/>
                        </Box>
                    }
                </div>
                {
                    error ?
                    ''
                    :
                    <div className={classes.gender}>
                        <Button variant="contained" className={classes.all} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('all')}>
                            <Typography variant="subtitle2" component="p">すべて</Typography>
                        </Button>
                        <Button variant="contained" className={classes.male} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('メンズ')}>
                            <Typography variant='subtitle2' component="p">メンズ</Typography>
                        </Button>
                        <Button variant="contained" className={classes.female} disableFocusRipple={true} disableRipple={true} onClick={() => handleGenderFilter('レディース')}>
                            <Typography variant='subtitle2' component="p">レディース</Typography>
                        </Button>
                    </div>
                }
                <Container maxWidth="xl" className={classes.product_contaienr}>
                    <div className={classes.inner}>
                        <Typography variant="h2" component="h2" className={classes.title}>
                            {title}
                        </Typography>
                            {loading ? 
                            <div className={classes.loader}>
                                <Loader />
                            </div>
                                : error ? 
                                <div className={classes.error_container}>
                                    <Typography variant="subtitle1" component="p" align='center'>
                                        商品が見つかりませんでした
                                    </Typography>
                                </div>
                                :
                                <>
                                <Grid container spacing={1} >
                                    {products['results'].map(product => (
                                        <Grid key={product._id} item xs={6} sm={6} md={4} lg={3} xl={3}>
                                            <Product product={product} closets={closets} token={accountInfo.access} key={product.id}/>
                                        </Grid>))}
                                </Grid>
                                <div className={classes.pagination}>
                                        <Pagination count={products['count'] / 40 < 40 ? 1 : Math.ceil(products['count'] / 40)} page={page} onChange={handlePagination}/>
                                </div>
                                </>
                            }

                    </div>
                </Container>
                
            </div>
    )
}

export default SearchProductScreen