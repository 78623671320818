import { 
    BROWSING_HISTORY_REQUEST,
    BROWSING_HISTORY_SUCCESS,
    BROWSING_HISTORY_FAIL,
    SEARCH_WORDS_HISTORY_REQUEST,
    SEARCH_WORDS_HISTORY_SUCCESS,
    SEARCH_WORDS_HISTORY_FAIL
 } from "../constants/historyConstant";
import { baseAxios } from "../jwtInterceptor";

export const browsing_history_get = () => async(dispatch) => {
    dispatch({type:BROWSING_HISTORY_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('api/history/browsing').then(
        (response) => {
            dispatch({type:BROWSING_HISTORY_SUCCESS, payload: response['data']})
            localStorage.setItem('browsingHistory', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({
                type:BROWSING_HISTORY_FAIL, 
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

export const search_word_get = () => async(dispatch) => {
    dispatch({type:SEARCH_WORDS_HISTORY_REQUEST})
    baseAxios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(localStorage.getItem('accountInfo')).access}`;
    await baseAxios.get('api/history/search_word').then(
        (response) => {
            dispatch({type:SEARCH_WORDS_HISTORY_SUCCESS, payload: response['data']})
            localStorage.setItem('searchWord', JSON.stringify(response['data']))
        }
    ).catch(
        (error) => {
            dispatch({
                type:SEARCH_WORDS_HISTORY_FAIL, 
                payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
            })
        }
    )
}

