export const FAVORITE_CREATE_REQUEST = 'FAVORITE_CREATE_REQUEST'
export const FAVORITE_CREATE_SUCCESS = 'FAVORITE_CREATE_SUCCESS'
export const FAVORITE_CREATE_FAIL = 'FAVORITE_CREATE_FAIL'

export const FAVORITE_DELETE_REQUEST = 'FAVORITE_DELETE_REQUEST'
export const FAVORITE_DELETE_SUCCESS = 'FAVORITE_DELETE_SUCCESS'
export const FAVORITE_DELETE_FAIL = 'FAVORITE_DELETE_FAIL'

export const FAVORITE_GET_REQUEST = 'FAVORITE_GET_REQUEST'
export const FAVORITE_GET_SUCCESS = 'FAVORITE_GET_SUCCESS'
export const FAVORITE_GET_FAIL = 'FAVORITE_GET_FAIL'

export const FAVORITE_LIST_REQUEST = 'FAVORITE_LIST_REQUEST'
export const FAVORITE_LIST_SUCCESS = 'FAVORITE_LIST_SUCCESS'
export const FAVORITE_LIST_FAIL = 'FAVORITE_LIST_FAIL'