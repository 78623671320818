import React, {useState} from 'react';
import { useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Typography } from '@material-ui/core';

import { update_adress } from '../actions/adressAction';
import axiosJsonAdapter from 'axios-jsonp'

//import screen
import Message from './Message';

//import formik and yup
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';



const useStyles = makeStyles((theme) => ({
    button:{
        textAlign:'center',
        color:'black',
        backgroundColor:theme.palette.primary['200'],
        '&:hover':{
            backgroundColor:theme.palette.primary['400']
        }
    },
    card:{
        backgroundColor:'white',
        color: 'black',
        marginTop:theme.spacing(2)
    },
    form: {

    },
    message:{
        merginBottom:theme.spacing(2)
    },
    submit: {

    },
    input_text:{
        color:'black'
    },
    postalcode:{
        marginBottom:theme.spacing(1)
    },    
    text_field:{
        color:'black',
        borderWidth: '1px',
        borderColor:`${theme.palette.primary['500']} !important`,
    },
    title:{
        textAlign:'center',
        marginBottom:theme.spacing(2),
        fontWeight:'bold'
    }
}))

//yup バリデーション
const validationSchema = yup.object({
    prefecture: yup
    .string('')
    .max(20,'20文字以内で入力して下さい')
    .required('入力は必須です'),
    city: yup
    .string('')
    .max(20,'20文字以内で入力して下さい')
    .required('入力は必須です'),
    address: yup
    .string('')
    .max(100, '100文字以内で入力して下さい')
    .required('入力は必須です'),
    building: yup
    .string('')
    .max(100, '100文字以内で入力して下さい')
})

const AddressForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()

    const [postalcode, setPostalcode] = useState('');
    const [error, setError] = useState('')

    //郵便番号から住所を取得
    const submitPostalCode =  async (event) => {
        event.preventDefault()
        try{
            if(postalcode.length === 7){
            setError('')
            const config = {'headers': {'Content-Type': 'application/json'}}
            const data = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalcode}`, 
            {adapter:axiosJsonAdapter},config)
            formik.setFieldValue('prefecture', data['data']['results'][0]['address1'])
            formik.setFieldValue('city', data['data']['results'][0]['address2'])
            formik.setFieldValue('address', data['data']['results'][0]['address3'])
            }else{
                setError('郵便番号は７文字で入力して下さい')
            }
        }catch(e){
            setError('郵便番号が見つかりませんでした、もう一度入力して下さい。')
        }
    }
    
    //formik住所更新
    const formik = useFormik({
        initialValues:{
            prefecture: '',
            city: '',
            address: '',
            building: '',
        },
        validationSchema:validationSchema,
        onSubmit:(values) => {
            const country = 'japan'
            dispatch(update_adress(country, values.prefecture, values.city, values.address, values.building, postalcode))
            if(location.pathname === '/address_update'){
                history.goBack()
            }
        }
    })


    return(
        <>
            {error && <Message>{error}</Message>}
                    <Typography variant='h2' component="h2" className={classes.title}>
                        住所
                    </Typography>
                    <div className={classes.postalcode}>
                        <form className={classes.form} noValidate onSubmit={submitPostalCode}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}>
                                    <TextField
                                    margin='none'
                                    variant="outlined"
                                    color="primary"
                                    required={true}
                                    fullWidth
                                    id="postalcode"
                                    label={postalcode ? '' : "郵便番号"}
                                    name="postalcode"
                                    autoFocus
                                    value={postalcode}
                                    InputLabelProps={{
                                        classes:{
                                            root: classes.input_text
                                        }
                                    }}
                                    InputProps={{
                                        classes:{
                                            notchedOutline:classes.text_field,
                                            root: classes.text_field
                                        }
                                    }}
                                    onChange={(event) => {setPostalcode(event.target.value)}}
                                    />
                                    </Grid>
                                    <Grid item xs={3}>
                                    <Button
                                    type="submit"
                                    variant="contained"
                                    className={classes.button}
                                    >
                                        検索
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    color='primary'
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    id="prefecture"
                                    label="都道府県"
                                    name="prefecture"
                                    autoComplete='prefecture'
                                    value={formik.values.prefecture}
                                    onChange={formik.handleChange}
                                    error={formik.touched.prefecture && Boolean(formik.errors.prefecture)}
                                    helperText={formik.touched.prefecture && formik.errors.prefecture}
                                    InputLabelProps={{
                                        classes:{
                                            root: classes.input_text
                                        }
                                    }}
                                    InputProps={{
                                        classes:{
                                            notchedOutline:classes.text_field,
                                            root: classes.text_field
                                        }
                                    }}
                                />
                            </Grid>
                        <Grid item xs={6}>
                        <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="region"
                        label="市区町村"
                        name="region"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        InputLabelProps={{
                            classes:{
                                root: classes.input_text
                            }
                        }}
                        InputProps={{
                            classes:{
                                notchedOutline:classes.text_field,
                                root: classes.text_field
                            }
                        }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="address"
                        label="以下の住所"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        InputLabelProps={{
                            classes:{
                                root: classes.input_text
                            }
                        }}
                        InputProps={{
                            classes:{
                                notchedOutline:classes.text_field,
                                root: classes.text_field
                            }
                        }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <TextField
                        variant="outlined"
                        margin="none"
                        fullWidth
                        id="building"
                        label="建物名・号室"
                        name="building"
                        value={formik.values.building}
                        onChange={formik.handleChange}
                        error={formik.touched.building && Boolean(formik.errors.building)}
                        helperText={formik.touched.building && formik.errors.building}
                        InputLabelProps={{
                            classes:{
                                root: classes.input_text
                            }
                        }}
                        InputProps={{
                            classes:{
                                notchedOutline:classes.text_field,
                                root: classes.text_field
                            }
                        }}
                        />
                        </Grid>
                        <Grid item xs={12}>
                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        >
                            変更
                        </Button>
                        </Grid>
                        </Grid>
                    </form>
        </>

    )
}

export default AddressForm