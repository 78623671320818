import React,{useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button,} from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Modal } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableRow } from "@material-ui/core";

//actiont 
import { subscription_update } from "../../actions/stripeAction";
import { subscription_cancel } from "../../actions/stripeAction";
import { get_subscripiton_invoice } from "../../actions/stripeAction";
import { get_subscription_upcoming_invoice } from "../../actions/stripeAction";
//component
import Loader from '../../components/Loader';
import StripePlanScreen from "../../screens/StripePlanScreen";

const useStyles = makeStyles((theme) => ({
    button:{
        color:'#42a5f5',
        backgroundColor: 'white',
        '&:hover':{
            backgroundColor: 'white'
        },
        '&:active':{
            backgroundColor:'white'
        },
        marginTop: theme.spacing(1)
    },
    cancel_card:{
        width: '100%',
        color: 'black',
        backgroundColor: 'white',
        height: '500px',
        boxShadow: 'initial'
    },
    card:{
        color:'black',
        backgroundColor:'white',
        boxShadow: 'initial'
    },
    card_outer:{
        marginTop:theme.spacing(10)
    },
    cell:{
        color:'black',
        [theme.breakpoints.down('xs')]:{
            fontSize:'10px'
        },
        [theme.breakpoints.up('xs')]:{
            fontSize:'12px'
        }
    },
    container:{
        padding: 'initial'
    },
    description:{
        marginTop: theme.spacing(3),
        whiteSpace: 'pre-line'
    },
    invoice:{
        textAlign:'right'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    modal_button:{
        color:'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        },
    },
    modal_container:{
        position: 'absolute',
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(0,1,15,1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(0,2,15,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(0,3,15,3)
        }
    },
    title:{
        marginTop:theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    subscr_title:{
        marginBottom: theme.spacing(3),
        fontWeight: 'bold'
    },
    subscription_button_container:{
        textAlign:'center'
    },
    subscription_button:{
        color:'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['400']
        },
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2)
    },
    topic_path:{
        marginTop: theme.spacing(1),
        display:'flex'
    },
    topic_path_button:{
        '&:hover':{
            backgroundColor:'initial'
        }
    },
    update_day:{
        marginTop: theme.spacing(2),
        textAlign:'right',
        fontWeight: 'bold'
        
    }
}))

export const InvoiceScreen = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const [openUpdate, setOpenUpdate] = useState(false)
    const [openCancel, setOpenCancel] = useState(false)

    //useSelector
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)
    const {loading, invoice, error} = useSelector(state => state.invoice)
    const {upcomingInvoice} = useSelector(state => state.upcomingInvoice)
    const {reservation_message, reservation_loading} = useSelector(state => state.reservation)
    
    //handle 

    //サブスクリプションプランのupdate
    const subscriptionUpdateHandler = () => {
        if(subscriptionInfo.plan == 'basic'){
            dispatch(subscription_update('premium', "price_1JXDspFO9XZPj0xl5koqAR5Q"))
        }else(
            dispatch(subscription_update('basic', "price_1JZ6oAFO9XZPj0xlgMK3B0tB"))
        )
    }

    //サブスクリプションを削除する
    const subscriptionCancelHandler = () => {
        dispatch(subscription_cancel())
    }
    
    const managementHandler = () => {
        history.push('/management')
    }

    //open
    const openUpdateHandler = () => {
        setOpenUpdate(true);
    };

    const openCancelHandler = () => {
        setOpenCancel(true)
    };

    //close
    const closeUpdateHandler = () => {
        setOpenUpdate(false)
    };

    const closeCancelHandler = () => {
        setOpenCancel(false)
    };

    useEffect(() => {
        if(reservation_message){
            setTimeout(
                function(){history.push('/')}, 200
            )
        }
        dispatch(get_subscripiton_invoice())
        dispatch(get_subscription_upcoming_invoice())

    },[reservation_message])

    //プラン変更についてもinvoiceで行えるようにする
    //planがレンタルになっている場合はsubscription登録への誘導をするようにする
    return(
        loading | reservation_loading ? 
        <div className={classes.loader}>
            <Loader/> 
        </div>
        :
        error ?
        //rentalの場合にはこちらの画面が表示されるようにする
        <StripePlanScreen/>
        :
        subscriptionInfo.plan === 'rental'
        ?
        <StripePlanScreen/>
        :
        subscriptionInfo.cancel_date ?
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={managementHandler} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p" >
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    サブスクリプション管理
                </Typography>
            </div>
            <Container maxWidth={'md'} className={classes.container}>
                <div className={classes.card_outer}>
                    <Card className={classes.cancel_card}>
                        <CardContent>
                            <Typography variant="h2" component="h2">
                                サブスクリプションの解約が完了しました
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.description}>
                                {`現在ご利用中の${subscriptionInfo.plan === 'basic' ? 'ベーシック' : 'プレミアム'}プランは\
                                ${(new Date(subscriptionInfo.cancel_date)).getFullYear() + '年' 
                                + ((new Date(subscriptionInfo.cancel_date)).getMonth() + 1) + '月' 
                                + (new Date(subscriptionInfo.cancel_date)).getDate() + '日'}までご利用頂けます。\
                                \n以降ご利用をお考えのお客様は再度サブスクリプションプランへの登録手続きを行なって下さい。`}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </Container>
        </div>
        :
        <div className={classes.root}>
            <div className={classes.topic_path}>
                <Button onClick={managementHandler} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                    <Typography variant="subtitle2" component="p" >
                        アカウントサービス
                    </Typography>
                </Button>
                <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                    {'＞'}
                </Typography>
                <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                    サブスクリプション管理
                </Typography>
            </div>
            <Container maxWidth={"md"} style={{padding: 'initial'}}>
            <div className={classes.card_outer}>
                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h2" component="h2" align="center" className={classes.subscr_title}>
                            サブスクリプション情報
                        </Typography>
                        <div>
                            <Typography variant="subtitle2" component="p" align="right">
                                登録日:    {invoice ? 
                                (new Date(invoice[0].created * 1000)).getFullYear() + '年' 
                                + ((new Date(invoice[0].created * 1000)).getMonth() + 1) + '月' 
                                + (new Date(invoice[0].created * 1000)).getDate() + '日': ''}
                            </Typography>
                        </div>
                        <div className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.cell}>加入中プラン</TableCell>
                                        <TableCell align="right" className={classes.cell}>個数</TableCell>
                                        <TableCell align="right" className={classes.cell}>プラン利用料</TableCell>
                                        <TableCell align="right" className={classes.cell}>合計</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {
                                        invoice ?
                                        <>
                                        <TableCell component="th" scope="row" className={classes.cell}>
                                            {(subscriptionInfo ? subscriptionInfo.plan : '') + ' (' 
                                            + new Date(invoice[0].current_period_start * 1000).getFullYear() + '/' 
                                            + ((new Date(invoice[0].current_period_start * 1000)).getMonth() + 1) + '/' 
                                            + (new Date(invoice[0].current_period_start * 1000)).getDate() + '-' 
                                            + (new Date(invoice[0].current_period_end * 1000)).getFullYear() + '/'
                                            + ((new Date(invoice[0].current_period_end * 1000)).getMonth() + 1) + '/'
                                            + (new Date(invoice[0].current_period_end * 1000)).getDate() 
                                            + ')'}
                                        </TableCell>
                                        <TableCell align="right" className={classes.cell}>1</TableCell>
                                        <TableCell align="right" className={classes.cell}>
                                            ¥{invoice[0].plan.amount}
                                        </TableCell>
                                        <TableCell align="right" className={classes.cell}>¥{invoice[0].plan.amount}</TableCell>
                                        </>
                                        : ''}
                                    </TableRow>
                                    
                                </TableBody>
                            </Table>
                            { upcomingInvoice ?
                            <>
                            <Typography variant="subtitle1" component="p" className={classes.update_day}>
                                次回更新日: {
                                    (new Date(upcomingInvoice.next_payment_attempt * 1000)).getFullYear() + '年'
                                    + ((new Date(upcomingInvoice.next_payment_attempt * 1000)).getMonth() + 1) + '月'
                                    + (new Date(upcomingInvoice.next_payment_attempt * 1000)).getDate() + '日'
                                }
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.update_day}>
                                有効期間: {
                                    (new Date(upcomingInvoice.period_start * 1000)).getFullYear() + '/'
                                    + ((new Date(upcomingInvoice.period_start * 1000)).getMonth() + 1) + '/'
                                    + (new Date(upcomingInvoice.period_start * 1000)).getDate() + '-'
                                    + (new Date(upcomingInvoice.period_end * 1000)).getFullYear() + '/'
                                    + ((new Date(upcomingInvoice.period_end * 1000)).getMonth() + 1) + '/'
                                    + (new Date(upcomingInvoice.period_end * 1000)).getDate() + ''
                                    }
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.update_day}>
                                更新料金: ¥{upcomingInvoice.amount_due}
                            </Typography>
                            </>
                            : ''}
                            
                            <div className={classes.subscription_button_container}>
                                <Button className={classes.subscription_button} onClick={openUpdateHandler}>
                                    プランを変更
                                </Button>
                                <Button className={classes.subscription_button} onClick={openCancelHandler}>
                                    プランを解約
                                </Button>
                            </div>       
                        </div>
                    </CardContent>
                </Card>
                <Modal
                open={openUpdate}
                onClose={closeUpdateHandler}
                aria-labelledby="modal-modal"
                >
                    <Container maxWidth={"xs"} className={classes.modal_container}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="subtitle1" component="p" style={{marginBottom: 20}}>
                                    プラン変更を確定する場合は下記変更ボタンをクリックして下さい。
                                </Typography>
                                
                                {
                                    subscriptionInfo.plan == 'basic' ?
                                    <>
                                        <Typography variant="subtitl1" component="p" style={{marginBottom: 10}}>
                                            プラン名： <strong>プレミアムプラン</strong>
                                        </Typography>
                                        <Typography variant="subtitl1" component="p" style={{marginBottom: 20}}>
                                            月額料金： <strong>¥14980</strong>
                                        </Typography>
                                        <div style={{textAlign:'center'}}>
                                            <Button onClick={subscriptionUpdateHandler} className={classes.modal_button}>
                                                <Typography variant="subtitle1" component="p" style={{fontWeight:'bold'}}>
                                                    プレミアムプランに変更
                                                </Typography>
                                            </Button>
                                        </div>
                                        <Button onClick={closeUpdateHandler} style={{color:'black', padding:'initial', marginTop:5}}>
                                            <Typography variant="subtitle2" component="p">
                                                閉じる
                                            </Typography>
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Typography variant="subtitl1" component="p" style={{marginBottom: 10}}>
                                            プラン名： <strong>ベーシックプラン</strong>
                                        </Typography>
                                        <Typography variant="subtitl1" component="p" style={{marginBottom: 20}}>
                                            月額料金： <strong>¥7980</strong>
                                        </Typography>
                                        <Typography variant="caption" component="p" style={{marginTop: 20,marginBottom: 20}}>
                                           ※ベーシックプランに変更した時点で、レンタルできる回数、着数ともにベーシックプランのサービス内容が適用されますのご了承下さい。
                                        </Typography>
                                        <div style={{textAlign:'center'}}>
                                        <Button onClick={subscriptionUpdateHandler} fullWidth className={classes.modal_button}>
                                            <Typography variant="subtitle1" component="p">
                                                ベーシックプランに変更
                                            </Typography>
                                        </Button>
                                        </div>
                                        
                                        <Button onClick={closeUpdateHandler} style={{color:'black', padding:'initial', marginTop:5}}>
                                            <Typography variant="subtitle2" component="p">
                                                閉じる
                                            </Typography>
                                        </Button>
                                    </>
                                }
                                
                            </CardContent>
                        </Card>
                    </Container>
                </Modal>

                <Modal
                open={openCancel}
                onClose={closeCancelHandler}
                aria-labelledby="modal-modal-"
                > 
                <Container maxWidth={"xs"} className={classes.modal_container}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="subtitle1" component="p" style={{marginBottom: 20}}>
                                プランの解約を確定する場合は下記変更ボタンをクリックして下さい。
                            </Typography>
                            {
                                subscriptionInfo.plan == 'basic' ?
                                <>
                                <Typography variant="subtitl1" component="p" style={{marginBottom: 10}}>
                                        プラン名： <strong>ベーシックプラン</strong>
                                    </Typography>
                                    <Typography variant="subtitl1" component="p" style={{marginBottom: 20}}>
                                        月額料金： <strong>¥7980</strong>
                                    </Typography>
                                    <Typography variant="caption" component="p" style={{marginBottom: 20}}>
                                           ※予約中の商品がある場合は返却後に解約手続きを行なって下さい。一度解約しますとサブスクリプションプラン内容は解約時点からご利用ができなくなりますのでご了承下さい。
                                     </Typography>
                                    <div style={{textAlign:'center'}}>
                                    <Button onClick={subscriptionCancelHandler} fullWidth className={classes.modal_button}>
                                        <Typography variant="subtitle1" component="p">
                                            解約を確定
                                        </Typography>
                                    </Button>
                                    </div>
                                    <Button onClick={closeCancelHandler} style={{color:'black', padding:'initial', marginTop:5}}>
                                        <Typography variant="subtitle2" component="p">
                                            閉じる
                                        </Typography>
                                    </Button>
                                    
                                </>
                                :
                                <>
                                    <Typography variant="subtitl1" component="p" style={{marginBottom: 10}}>
                                        プラン名： <strong>プレミアムプラン</strong>
                                    </Typography>
                                    <Typography variant="subtitl1" component="p" style={{marginBottom: 20}}>
                                        月額料金： <strong>¥14980</strong>
                                    </Typography>
                                    <Typography variant="caption" component="div" style={{marginBottom: 20}}>
                                           ※予約中の商品がある場合は返却後に解約手続きを行なって下さい。一度解約しますとサブスクリプションプラン内容は解約時点からご利用ができなくなりますのでご了承下さい。
                                     </Typography>
                                    <div style={{textAlign:'center'}}>
                                        <Button fullWidth className={classes.modal_button}>
                                            <Typography onClick={subscriptionCancelHandler}  variant="subtitle1" component="div" style={{fontWeight:'bold'}}>
                                                解約を確定
                                            </Typography>
                                        </Button>
                                    </div>
                                    <Button onClick={closeCancelHandler} disableRipple={true} disableFocusRipple={true} style={{color:'black', padding:'initial', marginTop:5}}>
                                        <Typography variant="subtitle2" component="p">
                                            閉じる
                                        </Typography>
                                    </Button>
                                </>
                            }
                        
                    </CardContent>
                </Card>
            </Container>
            </Modal>
            </div>
        </Container>
        </div>
    
        
    )
}

