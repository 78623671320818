import React, { useEffect} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import {Divider} from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Loader from "../components/Loader";

const useStyles = makeStyles((theme) => ({
    content:{
        display: 'flex'
    },
    image:{
        width: '50px',
        height: '50px'
    },
    list:{
    },
    list_item:{
        color: 'black',
        backgroundColor: 'white',
        padding: 'initial'
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'25%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'20%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(5, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(20, 2, 20, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(20, 3, 20, 3)
        },
    },
    title:{
    }
}))

export default function TodoScreen(){
    const classes = useStyles()
    const history = useHistory()
    const {loading, todo, error} = useSelector(state => state.todo)

    const handleClick = (url) => {
        history.push(url)
    }

    useEffect(() => {

    },[])
    return(
        loading ?
        <div className={classes.loader}>
            <Loader/>
        </div>
        :
        error ?
        <div className={classes.root}>
            <Typography variant="h2" component={"h2"} align="center" className={classes.title}>
                やることはありません
            </Typography>
        </div>
        :
        <div className={classes.root}>
            <Container maxWidth="md" style={{padding: 'initial'}}>
                <Typography variant="h1" component="h1" align="center">
                    やることリスト
                </Typography>

                <List classname={classes.list}>
                <Divider classes={{root: classes.divider}}/>
                {todo.map((doing) => (
                    <>
                    <ListItem className={classes.list_item} 
                    key={doing.id}
                    button={true} 
                    onClick={() => handleClick(doing.url)}>
                        <div className={classes.content}>
                            <img src={'https://media.enclopedia-official.com/media/' + doing.thumbnail} alt={doing.title} className={classes.image}/>
                            <Typography variant="subtitle2" component="p">
                                {doing.title}
                            </Typography>
                        </div>
                    </ListItem>
                    <Divider classes={{root: classes.divider}}/>
                    </>
                ))}
                </List>
            </Container>
        </div>
    )
}