import React, {useEffect} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Container } from "@material-ui/core";

//component
import Loader from "../components/Loader";
import CreditInfoComponent from "../components/Checkout/CreditInfoComponent";

//action
import { stripe_user_info } from "../actions/stripeAction";
import CheckoutForm from "../components/Checkout/CheckoutForm";


const useStyles = makeStyles((theme) => ({
    button:{
        color:'black',
        backgroundColor: theme.palette.primary['300'],
        '&:hover': {
            backgroundColor: theme.palette.primary['400']
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%'
        }
    },
    card:{
        color:'black',
        backgroundColor:'white',
        marginTop: theme.spacing(10)
    },
    card_content:{
        padding: theme.spacing(1)
    },
    error_message:{
        marginBottom: theme.spacing(3)
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'40%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    inner:{
        marginTop: theme.spacing(10)
    },
    root:{
        [theme.breakpoints.down('sm')]:{
            margin: theme.spacing(1, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(2,2,25,2)
        },
        [theme.breakpoints.up('md')]:{
            margin: theme.spacing(3 ,3, 25, 3)
        }
    },

    topic_path:{
        display: 'flex',
    },
    title:{
        marginBottom: theme.spacing(3)
    }
}))

//クレジットカード情報が取得できない場合にはcreditカードformを表示する
const CreditInfoScreen  = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    //useSelecotr
    const {accountInfo} = useSelector(state => state.account)
    const {loading, credit_info, error} = useSelector(state => state.secretInfo)

    const handleAccountService = () => {
        history.push('/management')
    }

    useEffect(() => {
        dispatch(stripe_user_info(accountInfo.access))
    },[])

    return(
        <div className={classes.root}>
                <div className={classes.topic_path}>
                    <Button onClick={handleAccountService} className={classes.topic_path_button} style={{padding:'initial'}} disableFocusRipple={true} disableRipple={true}>
                        <Typography variant="subtitle2" component="p">
                            アカウントサービス
                        </Typography>
                    </Button>
                    <Typography variant="subtitle2" component="p" style={{ marginLeft:10, marginRight:10, paddingTop:2}}>
                        {'＞'}
                    </Typography>
                    <Typography variant="subtitle2" component="p" style={{paddingTop:2}}>
                        {'クレジットカード情報'}
                    </Typography>
                </div>
            <Container maxWidth={'md'} style={{padding:'initial'}}>
                {
                    loading ?
                    <div className={classes.loader}>
                        <Loader/>
                    </div>
                 
                    :
                    error ?
                    <div className={classes.inner}>
                        <CheckoutForm/>
                    </div>
                    :
                    credit_info ?
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography varitn="h2" component="h2" align="center">
                                クレジットカード情報
                            </Typography>
                            <CreditInfoComponent creditInfo={credit_info}/>
                        </CardContent>
                    </Card>
                    :
                    <Card className={classes.card}>
                        <CardContent className={classes.card_content}>
                            <Typography variant="subtitle1" component="p" className={classes.title}>
                                クレジットカード情報の登録を行なって下さい。
                            </Typography>
                            <Button className={classes.button}>
                                クレジットカードを登録する
                            </Button>
                        </CardContent>
                    </Card>
                }
            </Container>
        </div>
    )
}

export default CreditInfoScreen

