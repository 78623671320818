
import {
    PROFILE_GET_REQUEST,
    PROFILE_GET_SUCCESS,
    PROFILE_GET_FAIL,
    PROFILE_UPDATE_REQUEST,
    PROFILE_UPDATE,
} from '../constants/profileConstants'


export const profileGetReducer = (state = {}, action) => {
    switch(action.type){
        case PROFILE_GET_REQUEST:
            return {profile_loading: true, profileInfo: JSON.parse(localStorage.getItem('profile'))}
        case PROFILE_GET_SUCCESS:
            return {profile_loading:false, profileInfo: action.payload}
        case PROFILE_GET_FAIL:
            return {profile_loading:false, profile_error:action.payload}
        case PROFILE_UPDATE_REQUEST:
            return {profile_loading:true}
        case PROFILE_UPDATE:
            return {profile_loading:false, profileInfo: action.payload}
        default:
            return state
    }
}
