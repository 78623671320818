    import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card } from "@material-ui/core";
import { CardActionArea } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Link } from "@material-ui/core";

//component
import Loader from "./Loader";

//action
import { getVariation, get_related_product } from '../actions/productActions';
import { imageGallaryAction } from '../actions/imageGallaryAction';
import { get_product_detail } from '../actions/productActions';
import { reviewList } from '../actions/productActions';
import { reservation_already_rental } from '../actions/reservationAction';

//function
import { pageView } from '../function/gaTraking';



const useStyles = makeStyles((theme) => ({
    list:{
        display: 'flex',
        overflow: 'hidden',
        overflowX: 'scroll',
        padding: 'initial'
    },
    list_item:{
        padding: 'initial',
        width: 'initial',
        marginRight: theme.spacing(1)
    },
    image:{
        [theme.breakpoints.down('sm')]:{
            height:"150px",
            width:"150px"
        },
        [theme.breakpoints.up('sm')]:{
            height:"175px",
            width:"175px"
        },
        [theme.breakpoints.up('md')]:{
            height:"200px",
            width:"200px"
        },
    },
    title:{
        marginBottom: theme.spacing(3)
    }
}))

const RelatedProductComponent = ({product_id}) => {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    //useSelector
    const {loading, related, error} = useSelector(state => state.related)

    const handleClick = (id) => {
        history.push(`/product/${id}`)
        pageView(`product:${id}`, '/product/${id}');
        dispatch(imageGallaryAction(id))
        dispatch(reviewList(id))
        dispatch(getVariation(id))
        dispatch(get_product_detail(id))
        dispatch(reservation_already_rental(id))
        dispatch(get_related_product(id))
    }
    return(
        error 
        ?
        ''
        :
        related.length === 1 ?
        ''
        :
        <>
        <Typography variant="h2" component="h2" className={classes.title}>
            関連した商品
        </Typography>
        <List className={classes.list}>
            {
                related.map((product) => (
                    loading ?
                    <div className={classes.loader}>
                        <Loader/>
                    </div>
                    :
                    product.id === product_id
                    ?
                    ''
                    :
                    <ListItem key={product.id} className={classes.list_item}>
                        <Card className={classes.card}>
                            <CardActionArea component={Link} path={`/product/${product.id}`} onClick={() => {handleClick(product.id)}}>
                                <CardMedia
                                component="img"
                                src={product.img}
                                className={classes.image}
                                title={product.product_name}
                                alt={product.product_name}
                                />
                            </CardActionArea>
                        </Card>
                    </ListItem>
                ))
            }
        </List>
        </>
    )
}

export default RelatedProductComponent